import { UserState, UserActions } from '../../interfaces/redux-types/userReduxTypes'
import { userConstants } from '../constants/userConstants'

export const initialUserState: UserState = {
  isLoading: false,
  isConfirmationEmailLoading: false,
  isConfirmationEmailLoaded: false,
  isLoaded: false,
  userDetails: null,
  error: '',
  userCountryList: [],
  emailResendTimer: 0,
  userLimits: [],
  userAml: null,
  userAmlList: [],
}

export const userReducer = (state: UserState = initialUserState, action: UserActions) => {
  switch (action?.type) {
    case userConstants.GET_USER_DETAILS_REQUEST:
      return {
        ...state,
        isLoading: true,
        isLoaded: false,
        error: '',
      }
    case userConstants.GET_USER_DETAILS_SUCCESS:
      return {
        ...state,
        isLoaded: true,
        isLoading: false,
        // userDetails: state.userDetails
        //   ? { ...state.userDetails, ...action.payload }
        //   : action.payload,
        userDetails: action.payload,
        loggedIn: true,
      }
    case userConstants.GET_USER_DETAILS_FAILURE:
      return {
        ...state,
        isLoaded: false,
        isLoading: false,
        error: action.payload,
      }
    case userConstants.UPDATE_USER_DETAIL_REQUEST:
      return {
        ...state,
        isLoading: true,
        isLoaded: false,
        error: '',
      }
    case userConstants.UPDATE_USER_DETAIL_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isLoaded: true,
        userDetails: { ...state.userDetails, ...action.payload },
      }
    case userConstants.UPDATE_USER_DETAIL_FAILURE:
      return {
        ...state,
        isLoaded: false,
        isLoading: false,
        error: action.payload,
      }
    case userConstants.UPDATE_USER_ADDRESS_REQUEST:
      return {
        ...state,
        isLoading: true,
      }
    case userConstants.UPDATE_USER_ADDRESS_SUCCESS:
      return {
        ...state,
        isLoading: false,
      }
    case userConstants.UPDATE_USER_ADDRESS_FAILURE:
      return {
        ...state,
        isLoading: false,
      }
    case userConstants.CHANGE_USER_EMAIL_REQUEST:
      return {
        ...state,
      }
    case userConstants.CHANGE_USER_EMAIL_SUCCESS:
      return {
        ...state,
        userDetails: { ...state.userDetails, ...{ email: action.payload.email } },
      }
    case userConstants.CHANGE_USER_EMAIL_FAILURE:
      return {
        ...state,
        error: action.payload,
      }
    case userConstants.RESEND_EMAIL_REQUEST:
      return {
        ...state,
      }
    case userConstants.RESEND_EMAIL_SUCCESS:
      return {
        ...state,
        emailResendTimer: action.payload,
      }
    case userConstants.RESEND_EMAIL_FAILURE:
      return {
        ...state,
      }
    case userConstants.CONFIRM_CHANGED_EMAIL_REQUEST:
      return {
        ...state,
        isConfirmationEmailLoading: true,
        isConfirmationEmailLoaded: false,
        error: '',
      }
    case userConstants.CONFIRM_CHANGED_EMAIL_SUCCESS:
      return {
        ...state,
        isConfirmationEmailLoading: false,
        isConfirmationEmailLoaded: true,
        userDetails: { ...state.userDetails, ...{ isEmailConfirmed: true } },
      }
    case userConstants.CONFIRM_CHANGED_EMAIL_FAILURE:
      return {
        ...state,
        isConfirmationEmailLoading: false,
        isConfirmationEmailLoaded: true,
        error: action.payload,
      }
    case userConstants.GET_USER_COUNTRY_LIST_REQUEST:
      return {
        ...state,
      }
    case userConstants.GET_USER_COUNTRY_LIST_SUCCESS:
      return {
        ...state,
        userCountryList: action.payload,
      }
    case userConstants.GET_USER_COUNTRY_LIST_FAILURE:
      return {
        ...state,
      }
    case userConstants.DELETE_PROFILE_REQUEST:
      return {
        ...state,
      }
    case userConstants.DELETE_PROFILE_SUCCESS:
      return {
        ...state,
      }
    case userConstants.DELETE_PROFILE_FAILURE:
      return {
        ...state,
      }
    case userConstants.GET_USER_CALCULATION_LIMITS_REQUEST:
      return {
        ...state,
        isLoading: true,
        isLoaded: false,
        error: '',
      }
    case userConstants.GET_USER_CALCULATION_LIMITS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isLoaded: true,
        userLimits: action.payload,
      }
    case userConstants.GET_USER_AML_LIST_REQUEST:
      return {
        ...state,
        isLoading: true,
        isLoaded: false,
        error: '',
      }
    case userConstants.GET_USER_AML_LIST_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isLoaded: true,
        userAml: action.payload.userAml,
        userAmlList: action.payload.userAmlList,
      }
    case userConstants.UPDATE_USER_SUBSCRIPTION_REQUEST:
      return {
        ...state,
        isLoading: true,
        isLoaded: false,
      }
    case userConstants.UPDATE_USER_SUBSCRIPTION_SUCCESS:
      return {
        ...state,
        isLoading: true,
        isLoaded: true,
        userDetails: { ...state.userDetails, ...{ isSubscribedToNewsAndUpdates: action.payload } },
      }
    default:
      return state
  }
}
