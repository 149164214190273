// export const customSelectorStyles = (withCurrency: boolean) => ({
  export const customSelectorStyles =  ({
  container: (provided: any) => ({
    ...provided,
    width: 'fit-content',
    fontSize: 14,
    paddingLeft: 10,
    // marginBottom: '16px'
    '&:after': {
      backgroundColor: '#fff',
      content: '""',
      display: 'block',
      height: '44px',
      left: '90px',
      top: -10,
      position: 'absolute',
      width: '1px',
    }
  }),
  option: (provided: any, state: any) => ({
    ...provided,
    display: 'flex',
    alignItems: 'center',
    padding: '10px',
    margin: 0,
    color: '#000000',
    borderRadius: '5px',
    '&:active': {
      backgroundColor: '#2D82F5',
    },
    backgroundColor: state.isFocused ? '#D3E6FF' : '#FFFFFF',
    ...(state.isSelected && { fontWeight: 600, color: '#000000' }),
    cursor: 'pointer',
    '.currency-code': {
      marginLeft: 'auto',
    }
  }),
  control: (provided: any) => ({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  }),
  valueContainer: (provided: any) => ({
    ...provided,
    width: 0,
    padding: 0,
    // display: 'none',
    // ...provided,
    // padding: 0,
    // marginRight: '5px',
  }),
  singleValue: (provided: any) => ({
    // ...provided,
    display: 'none',
    // color: '#3171D8',
    // margin: 0,
  }),
  indicatorSeparator: () => ({}),
  dropdownIndicator: (provided: any, state: any) => ({
    ...provided,
    padding: 0,
    cursor: 'pointer',
    color: '#3171D8',

    transform: state.selectProps.menuIsOpen && 'rotate(180deg)',
    '&:hover': {
      color: '#3171D8',
    }
  }),
  menu: (provided: any) => ({
    ...provided,
    // width: '100%',
    minWidth: 240,
    maxWidth: 300,
    top: 35,
    left: 0,
    margin: 0,
  }),
  menuList: (provided: any) => ({
    ...provided,
    fontSize: 16,

    padding: 0,
    overflowY: 'auto',

  }),
})
