import {
  Dispatch,
  FormEvent,
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import { useAppDispatch, useAppSelector } from '../../hooks/dispatch'
import PhoneNumberInput from '../interfaceComponents/PhoneNumberInput'
import { userActions } from '../../redux/action-creators/userActions'
import { Country } from '../../interfaces/auth'
import CalculationSection from '../calculation/CalculationSection'
import { authActions } from '../../redux/action-creators/authActions'
import { calculationHelper } from '../../helpers/calculationData'
import ReCAPTCHA from 'react-google-recaptcha'
import { localStorageHelper } from '../../helpers/useLocalStorage'

const captchaKey = process.env.REACT_APP_RECAPTCHA_KEY
const captchaDevKey =
  process.env.REACT_APP_RECAPTCHA_DEV_KEY || '6Lf_DT4hAAAAAMX0TF-IStGROGMXiU_CKZimRONI'

type MobileVerificationProps = {
  onChangeSection: Dispatch<SetStateAction<string>>
  from: string
}

const MobileVerification = ({ onChangeSection, from }: MobileVerificationProps) => {
  const dispatch = useAppDispatch()
  const { userCountryList } = useAppSelector(state => state.user)
  const {
    phoneNumber: previousPhoneNumber,
    isLoading,
    errorCode,
  } = useAppSelector(state => state.auth)
  const [phoneNumber, setPhoneNumber] = useState<string>('')
  const [errorField, setErrorField] = useState<string>()
  const [receiveAmount, setReceiveAmount] = useState<number | undefined | string>()
  const [isDisabledBtn, setIsDisabledBtn] = useState(false)
  const [captcha, setCaptcha] = useState<string>('')

  const receivePaymentData = localStorageHelper.getData('receiveMethod')

  const captchaRef = useRef<ReCAPTCHA>(null)

  const isDisabledButton = useMemo(
    () =>
      isDisabledBtn ||
      Boolean(errorField) ||
      isLoading ||
      !Boolean(phoneNumber.length >= 7) ||
      !captcha,
    [captcha, errorField, isDisabledBtn, isLoading, phoneNumber.length]
  )

  const receiveAmountFromLocalStorage = calculationHelper.getCalculationData()

  useEffect(() => {
    if (receiveAmountFromLocalStorage) {
      setReceiveAmount(+receiveAmountFromLocalStorage)
    }
  }, [receiveAmountFromLocalStorage])

  useEffect(() => {
    if (previousPhoneNumber) {
      setPhoneNumber(previousPhoneNumber)
    }
  }, [previousPhoneNumber])

  useEffect(() => {
    if (errorCode === '4001000036') {
      setErrorField('Please enter a valid phone number')
    }
  }, [errorCode])

  useEffect(() => {
    if (userCountryList.length === 0) {
      dispatch(userActions.getUserCountryList())
    }
  }, [dispatch, userCountryList.length])

  const modifiedCountryCodeList = useMemo(() => {
    return userCountryList.map((country: Country) => ({
      value: country.phoneCode,
      label: country.phoneCode,
      iso2Code: country.isO2Code,
    }))
  }, [userCountryList])

  const onNextClick = useCallback(
    async (event: FormEvent) => {
      event.preventDefault()

      const trimmedPhoneNumber = phoneNumber.replace(/_/g, '')
      const token = captcha

      if (from === 'continue-transaction' || (receiveAmount && +receiveAmount)) {
        typeof receiveAmount === 'number' &&
          dispatch(
            authActions.verifyMobileNumber(
              trimmedPhoneNumber,
              token,
              onChangeSection,
              receiveAmount
            )
          )
      } else {
        dispatch(authActions.verifyMobileNumber(trimmedPhoneNumber, token, onChangeSection))
      }
    },
    [captcha, dispatch, from, onChangeSection, phoneNumber, receiveAmount]
  )

  const onPhoneNumberChange = useCallback((value: string) => {
    setPhoneNumber(value)
    setErrorField('')
  }, [])

  return (
    <>
      {from === 'continue-transaction' && (
        <CalculationSection
          mode="calculation-input"
          receiveAmount={receiveAmount}
          setReceiveAmount={setReceiveAmount}
          setIsDisabledButton={setIsDisabledBtn}
          currency={receivePaymentData.currency}
          currencySign={receivePaymentData.currencySign}
        />
      )}
      <div className="phone-block">
        <h4>Verify your mobile</h4>
        <form id="mobile-form">
          <div className="mobile-number">
            <PhoneNumberInput
              id="phoneNumber"
              // value={phoneNumber}
              options={modifiedCountryCodeList}
              onChange={onPhoneNumberChange}
              defaultSelectedValue="+44"
              // format='national'
              format="national"
              mask="_"
              placeholder="Your Phone Number"
              error={errorField}
              autofocus={true}
              valueFromServer={previousPhoneNumber}
              // error={errors.phoneNumber?.message}
              // disabled={!canFormChange}
            />
          </div>
        </form>
      </div>
      <div className="captha mobile-captcha" style={{ marginTop: 0 }}>
        <ReCAPTCHA
          sitekey={(captchaKey as string) || (captchaDevKey as string)}
          ref={captchaRef}
          onChange={value => {
            setCaptcha(value!)
          }}
        />
      </div>
      <div className="show-mobile t-center">
        <button
          form="mobile-form"
          className="btn btn-primary"
          disabled={isDisabledButton}
          onClick={onNextClick}
        >
          Next
        </button>
      </div>
      <div className="btn-wrapper hide-mobile">
        <button
          form="mobile-form"
          className="btn btn-primary"
          disabled={isDisabledButton}
          onClick={onNextClick}
        >
          Next
        </button>
      </div>
    </>
  )
}

export default MobileVerification
