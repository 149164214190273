import { ChangeEvent, SyntheticEvent, useCallback, useEffect, useMemo, useState } from 'react'
import { detectMobile } from '../../helpers/utils'
import Modal from '../Modal'
import { useAppDispatch, useAppSelector } from '../../hooks/dispatch'
import { userActions } from '../../redux/action-creators/userActions'
import debounce from 'lodash/debounce'

const Preferences: React.FC = () => {
  const dispatch = useAppDispatch()
  const isMobile = detectMobile()

  const isSubscribedToNewsAndUpdates = useAppSelector(
    state => state.user.userDetails?.isSubscribedToNewsAndUpdates
  )

  const [isOpenModal, setIsOpenModal] = useState(false)
  const [checkBoxValue, setCheckBoxValue] = useState<boolean | undefined>(undefined)

  useEffect(() => {
    if (typeof isSubscribedToNewsAndUpdates !== 'undefined') {
      setCheckBoxValue(isSubscribedToNewsAndUpdates)
    }
  }, [isSubscribedToNewsAndUpdates])

  const onDelete = (event: SyntheticEvent) => {
    event.stopPropagation()

    // setShowToolTip(false)
    setIsOpenModal(true)
  }

  const updateUserSubscription = useCallback(
    (checkBoxValue: boolean) => {
      dispatch(userActions.updateUserSubscription(checkBoxValue))
    },
    [dispatch]
  )

  const debounceFunction = useMemo(
    () => debounce(updateUserSubscription, 800),
    [updateUserSubscription]
  )

  const onChangeCheckBox = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const checked = event.target.checked

      setCheckBoxValue(checked)

      debounceFunction(checked)
    },
    [debounceFunction]
  )

  return (
    <>
      {typeof checkBoxValue === 'boolean' ? (
        <div className="preferences">
          <p>Email Communications - News and updates</p>
          <div className="toggler">
            <p>Enabled</p>
            <div className="checkbox-toggle">
              <input
                type="checkbox"
                name="toggle"
                id="toggle"
                onChange={onChangeCheckBox}
                checked={checkBoxValue}
              />
              <label htmlFor="toggle"></label>
            </div>
          </div>
          {isMobile && (
            <button type="button" className="btn btn-secondary btn-xs" onClick={onDelete}>
              Delete Account
            </button>
          )}
        </div>
      ) : null}
      <Modal
        setIsOpenModal={setIsOpenModal}
        isOpenModal={isOpenModal}
        modalHeader="Account Deletion "
        modalMainWord="Warning"
        modalBody=" - this action will delete your account and you will no longer be able to access your acount, your past transactions or any of your account activity. Please type “I Understand” in the box below to confirm account deletion."
        modalType="deleteProfileModal"
        confirmFunction={() => {}}
      />
    </>
  )
}
export default Preferences
