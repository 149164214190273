import { Dispatch, SetStateAction, useCallback, useMemo, useRef, useState } from 'react'
import InnerHeader from '../../components/innerHeader'
import { useAppSelector } from '../../hooks/dispatch'
import { PuffLoader } from 'react-spinners'
import { CompanyRecipient, KWLRecipient } from '../../interfaces/beneficiary'
import CalculationSection from '../calculation/CalculationSection'
import { CreateTransactionServerRequest } from '../../interfaces/remittanceType'
import { transactionDataInLocalStorage } from '../../helpers/localStorage/transactionData'
import { PaymentMethod } from '../../interfaces'
import { localStorageHelper } from '../../helpers/useLocalStorage'
import Modal from '../Modal'

type TermsAndConditionsProps = {
  recipientData: KWLRecipient | CompanyRecipient | undefined
  transactionData: CreateTransactionServerRequest
  createTransaction: (receiveAmount: number | undefined) => void
  setCurrentSection: Dispatch<SetStateAction<string>>
}

const TermsAndConditions = ({
  recipientData,
  createTransaction,
  setCurrentSection,
}: TermsAndConditionsProps) => {
  const { isLoading } = useAppSelector(state => state.remittance)
  const preset = useAppSelector(state => state.preset.preset)

  const [checked, setChecked] = useState<boolean>(false)
  const [showError, setShowError] = useState(false)
  const [isDisabledButton, setIsDisabledButton] = useState(false)
  const [receive, setReceive] = useState<number | undefined | string>()
  const [isIframeLoading, setIsIframeLoading] = useState(true)
  const [isOpenConfirmedModal, setIsOpenConfirmedModal] = useState(false)

  const errorPlace = useRef<HTMLDivElement>(null)

  const isShownBeneficiaryAccount = useMemo(() => {
    const receiveMethodFromLocalStorage = Number(
      transactionDataInLocalStorage.getTransactionData().receiveMethod?.paymentMethod
    )

    return receiveMethodFromLocalStorage === PaymentMethod.BankTransfer
  }, [])

  const receivePaymentData = localStorageHelper.getData('receiveMethod')

  const onCheck = () => {
    setChecked(!checked)
    setShowError(false)
  }

  const onContinue = () => {
    if (!checked) {
      setShowError(true)
      return
    }

    setIsOpenConfirmedModal(true)
  }

  const onGoBack = () => {
    setCurrentSection('other-transaction-details')
  }

  const hideSpinner = useCallback(() => {
    setIsIframeLoading(false)
  }, [])

  const onConfirmModal = useCallback(() => {
    typeof receive === 'number' && createTransaction(receive)
  }, [createTransaction, receive])

  return (
    <>
      <section className="h-100-mb">
        {isLoading && (
          <div className="spinner spinner-fixed">
            <PuffLoader size={70} color="#3171d8" />
          </div>
        )}
        <div className="container container-md container-100">
          <InnerHeader title="Terms and Conditions" onGoBack={onGoBack} />
          <CalculationSection
            mode="calculation-block-with-recipient"
            receiveAmount={receive}
            setReceiveAmount={setReceive}
            setIsDisabledButton={setIsDisabledButton}
            isShownBeneficiaryAccount={isShownBeneficiaryAccount}
            recipient={recipientData as KWLRecipient}
            currency={receivePaymentData.currency}
            currencySign={receivePaymentData.currencySign}
          />
          <div ref={errorPlace} className="terms-block">
            <h4 className="terms-block__title">Terms and Conditions</h4>
            {isIframeLoading && (
              <div className="spinner spinner-absolute">
                <PuffLoader size={70} color="#3171d8" />
              </div>
            )}
            <iframe
              title="Terms and Conditions"
              className="terms-block__iframe"
              onLoad={hideSpinner}
              src={preset?.termAndConditionsHtml}
            ></iframe>
            <div className="terms-block__checkbox checkbox-wrapper checkbox-wrapper-green checkbox-wrapper-terms form-input">
              <input id="terms" name="terms" type="checkbox" checked={checked} onChange={onCheck} />
              <label htmlFor="terms" className="fz-xSmall">
                I agree to the terms and conditions
              </label>
              <span className="form-input-error text-center" hidden={!showError}>
                You must accept the terms and conditions
              </span>
            </div>
          </div>
          <div className="btn-wrapper mb-10">
            <button type="button" className="btn btn-cancel" onClick={onGoBack}>
              Cancel
            </button>
            <button
              onClick={onContinue}
              className="btn btn-next"
              disabled={isDisabledButton || isLoading}
            >
              {isLoading ? (
                <div className="center">
                  <PuffLoader size={21} color="white" />
                </div>
              ) : (
                'Next'
              )}
            </button>
          </div>
        </div>
      </section>
      <Modal
        setIsOpenModal={setIsOpenConfirmedModal}
        isOpenModal={isOpenConfirmedModal}
        modalHeader="Warning Info"
        modalType="confirmationBeforeCreatingTx"
        confirmFunction={onConfirmModal}
      />
    </>
  )
}
export default TermsAndConditions
