import { PostCalculateServerRequest } from "../interfaces/calculation";
import { localStorageHelper } from "./useLocalStorage";

function setCalculationData(receivedAmount: number | undefined | string) {
  localStorageHelper.setData("receivedAmount", receivedAmount as string);
}

function setCalculationDataV2(calcData: PostCalculateServerRequest) {
  localStorage.setItem("calcData", JSON.stringify(calcData));
}

function getCalculationData() {
  return localStorage.getItem('receivedAmount');
}

function removeCalculationData() {
  localStorageHelper.removeData('receivedAmount')
}

export const calculationHelper = {
  setCalculationData,
  getCalculationData,
  removeCalculationData,

  setCalculationDataV2,
};
