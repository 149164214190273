import { useCallback, useEffect, useMemo, useState } from 'react'
import FormInput from '../interfaceComponents/FormInput'
import {
  IndividualBeneficiaryFormType,
  CountryOption,
  DocumentTypeOptions,
  GetBeneficiaryFieldsConfigResponse,
  KWLRecipient,
} from '../../interfaces/beneficiary'
import PhoneNumberInput from '../interfaceComponents/PhoneNumberInput'
import FormCustomDateInput from '../interfaceComponents/FormCustomDateInput'
import SelectForm from '../interfaceComponents/SelectForm'
import { useAppDispatch, useAppSelector } from '../../hooks/dispatch'
import { Controller, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { beneficiaryYupSchemes } from '../../helpers/yupSchemes/beneficiaryYupSchemes'
import { documentTypeOptionList, genderOptions } from '../../helpers/configs/optionConfigs'
import { getDateFormatWithoutTimeForInput } from '../../helpers/dateFormat'
import { isEmpty } from 'lodash'
import { Icon } from '../icons'
import { PhoneNumberUtil } from 'google-libphonenumber'
import { convertFormDataDependingOnBeneficiaryConfig, trimObjectFields } from '../../helpers/utils'
import { Option } from '../../interfaces/commonTypes'
import { beneficiaryActions } from '../../redux/action-creators/beneficiaryActions'

type Options = {
  value: string | number
  label: string
  iso2Code?: string
}

type IndividualBeneficiaryFormProps = {
  beneficiaryFieldsConfig: GetBeneficiaryFieldsConfigResponse
  canFormChange: boolean
  recipientCountry?: CountryOption | null
  toggleEditingForm: () => void
  sendRequest: (recipient: KWLRecipient) => void
  textButton: string
  countryPhoneCodeOptions: Array<Options>
  documentCountryOptions: Array<Options>
  addressSectionCountryOptions: Array<Options>
  documentTypeOptions: Array<DocumentTypeOptions>
  onCancel: () => void
  recipient: KWLRecipient
  providerListForWithdrawalOptions?: Array<Option>
  isShownEditBtn: boolean
  type: 'recipient' | 'beneficiary'
}

const IndividualBeneficiaryForm = ({
  beneficiaryFieldsConfig,
  canFormChange,
  recipientCountry,
  toggleEditingForm,
  sendRequest,
  onCancel,
  textButton,
  countryPhoneCodeOptions,
  documentCountryOptions,
  addressSectionCountryOptions,
  recipient,
  documentTypeOptions,
  providerListForWithdrawalOptions,
  isShownEditBtn,
  type,
}: IndividualBeneficiaryFormProps) => {
  const dispatch = useAppDispatch()

  const {
    beneficiaryCreationLoadState,
    beneficiaryUpdateLoadState,
    error: errorBeneficiary,
    validateWithdrawalAccountNumberLoadState,
    withdrawalAccountNumber,
    withdrawalBankId,
    withdrawalAccountNumberInfo,
  } = useAppSelector(state => state.beneficiary)
  const errorTx = useAppSelector(state => state.remittance.error)
  const updateRecipientInTopUpLoadState = useAppSelector(
    state => state.remittance.updateRecipientInTopUpLoadState
  )
  const [isDisabledButton, setIsDisabledButton] = useState(false)
  const [isShownBankDetails, setIsShownBankDetails] = useState(false)

  const isLoadingDisabledBtn = useMemo(() => {
    return (
      beneficiaryCreationLoadState.isLoading ||
      beneficiaryUpdateLoadState.isLoading ||
      updateRecipientInTopUpLoadState.isLoading ||
      validateWithdrawalAccountNumberLoadState.isLoading
    )
  }, [
    beneficiaryCreationLoadState.isLoading,
    beneficiaryUpdateLoadState.isLoading,
    updateRecipientInTopUpLoadState.isLoading,
    validateWithdrawalAccountNumberLoadState.isLoading,
  ])

  const isVisibleWithdrawalFields = useMemo(
    () =>
      beneficiaryFieldsConfig?.Individual.AccountNumber.visible &&
      beneficiaryFieldsConfig?.Individual.BankName.visible &&
      beneficiaryFieldsConfig?.Individual.NotificationEmail.visible &&
      beneficiaryFieldsConfig?.Individual.Reference.visible,
    [
      beneficiaryFieldsConfig?.Individual.AccountNumber.visible,
      beneficiaryFieldsConfig?.Individual.BankName.visible,
      beneficiaryFieldsConfig?.Individual.NotificationEmail.visible,
      beneficiaryFieldsConfig?.Individual.Reference.visible,
    ]
  )

  useEffect(() => {
    return () => {
      dispatch(beneficiaryActions.clearWithdrawalAccountInfo())
    }
  }, [dispatch])

  const countryId = useMemo(() => {
    if (recipient.countryId) {
      return recipient.countryId
    }

    if (recipientCountry) {
      return recipientCountry.countryId
    }

    return ''
  }, [recipientCountry, recipient.countryId])

  const documentCountryIso = useMemo(() => {
    if (recipient.documentCountryIso) {
      return recipient.documentCountryIso
    }

    if (recipientCountry) {
      return recipientCountry.iso2Code
    }
    return ''
  }, [recipient.documentCountryIso, recipientCountry])

  const phoneNumberCode = useMemo(() => {
    if (!recipient.phoneNumber && recipientCountry) {
      return recipientCountry.phoneCode
    }
    return String(countryPhoneCodeOptions[0].value)
  }, [countryPhoneCodeOptions, recipient.phoneNumber, recipientCountry])

  const documentType = useMemo(() => {
    const docList = documentTypeOptionList(beneficiaryFieldsConfig)
    const isExistDocType = docList.find(
      documentItem => documentItem.value === recipient?.documentType
    )
    if (isExistDocType) {
      return isExistDocType.value
    } else {
      return ''
    }
  }, [beneficiaryFieldsConfig, recipient?.documentType])

  const isExistBeneficiaryBankDetails = useMemo(
    () =>
      Boolean(recipient.bankId) ||
      Boolean(recipient.accountNumber) ||
      Boolean(recipient.reference) ||
      Boolean(recipient.notificationEmail),
    [recipient.accountNumber, recipient.bankId, recipient.notificationEmail, recipient.reference]
  )

  useEffect(() => {
    setIsShownBankDetails(isExistBeneficiaryBankDetails)
  }, [isExistBeneficiaryBankDetails])

  const { register, handleSubmit, setError, clearErrors, formState, control, setValue, watch } =
    useForm<IndividualBeneficiaryFormType>({
      resolver: yupResolver(beneficiaryYupSchemes.beneficiaryScheme(beneficiaryFieldsConfig)),
    })

  const watchedBankId = watch('bankId')
  const watchedAccountNumber = watch('accountNumber')
  const watchedReference = watch('reference')
  const watchedNotificationEmail = watch('notificationEmail')

  const isCertainProviderName = useMemo(() => {
    if (!providerListForWithdrawalOptions) {
      return false
    }

    const currentWithdrawalProviderName = providerListForWithdrawalOptions.filter(
      providerItem => String(providerItem.value) === String(watchedBankId)
    )

    return currentWithdrawalProviderName[0]?.label === 'CBZ'
  }, [providerListForWithdrawalOptions, watchedBankId])

  const isValidAccountNumber = useMemo(() => {
    if (!isCertainProviderName || !isVisibleWithdrawalFields) {
      return true
    }

    const currentAccountNumber = watchedAccountNumber ? String(watchedAccountNumber).trim() : ''

    return (
      currentAccountNumber === withdrawalAccountNumber &&
      String(watchedBankId) === String(withdrawalBankId) &&
      !errorBeneficiary &&
      withdrawalAccountNumberInfo
    )
  }, [
    errorBeneficiary,
    isCertainProviderName,
    isVisibleWithdrawalFields,
    watchedAccountNumber,
    watchedBankId,
    withdrawalAccountNumber,
    withdrawalAccountNumberInfo,
    withdrawalBankId,
  ])

  const isShownWithdrawalInfo = useMemo(() => {
    if (!isCertainProviderName || !isVisibleWithdrawalFields) {
      return false
    }

    const currentAccountNumber = watchedAccountNumber ? String(watchedAccountNumber).trim() : ''

    return (
      currentAccountNumber === withdrawalAccountNumber &&
      String(watchedBankId) === String(withdrawalBankId) &&
      !errorBeneficiary &&
      withdrawalAccountNumberInfo
    )
  }, [
    errorBeneficiary,
    isCertainProviderName,
    isVisibleWithdrawalFields,
    watchedAccountNumber,
    watchedBankId,
    withdrawalAccountNumber,
    withdrawalAccountNumberInfo,
    withdrawalBankId,
  ])

  const updateCashOutAccountSectionErrors = useCallback(() => {
    setValue('accountNumber', watchedAccountNumber, { shouldValidate: true })
    setValue('reference', watchedReference, { shouldValidate: true })
    setValue('notificationEmail', watchedNotificationEmail, { shouldValidate: true })
  }, [setValue, watchedNotificationEmail, watchedReference, watchedAccountNumber])

  useEffect(() => {
    if (watchedBankId && watchedBankId !== '-' && formState.isSubmitted) {
      updateCashOutAccountSectionErrors()
    }

    if (watchedBankId === '-' && formState.isSubmitted) {
      updateCashOutAccountSectionErrors()
    }
  }, [formState.isSubmitted, updateCashOutAccountSectionErrors, watchedBankId])

  useEffect(() => {
    if (errorBeneficiary) {
      return setError('accountNumber', { message: errorBeneficiary })
    } else {
      return clearErrors('accountNumber')
    }
  }, [clearErrors, errorBeneficiary, setError])

  useEffect(() => {
    if (errorTx && typeof errorTx === 'string') {
      return setError('accountNumber', { message: errorTx })
    } else {
      return clearErrors('accountNumber')
    }
  }, [clearErrors, errorTx, setError])

  const setFormValue = useCallback(
    (recipient: KWLRecipient) => {
      setValue('firstName', recipient.firstName)
      setValue('lastName', recipient.lastName || '')
      setValue('phoneNumber', recipient.phoneNumber)
      setValue(
        'dateOfBirth',
        recipient.dateOfBirth ? getDateFormatWithoutTimeForInput(recipient.dateOfBirth) : ''
      )
      setValue('documentType', documentType)
      setValue('documentNumber', recipient?.documentNumber)
      setValue('documentCountryIso', documentCountryIso)
      setValue('gender', recipient.gender)
      setValue('street', recipient.street)
      setValue('city', recipient.city)
      setValue('countryId', countryId)
      setValue('postcode', recipient.postcode)
      // setValue('bankName', recipient.bankName || undefined)
      setValue('bankId', recipient.bankId || '')
      setValue('accountNumber', recipient.accountNumber || '')
      setValue('reference', recipient.reference || '')
      setValue('notificationEmail', recipient.notificationEmail || '')
    },
    [countryId, documentCountryIso, documentType, setValue]
  )

  useEffect(() => {
    if (isVisibleWithdrawalFields && providerListForWithdrawalOptions && recipient) {
      setFormValue(recipient)
      return
    }
    if (recipient) {
      setFormValue(recipient)
      return
    }
  }, [isVisibleWithdrawalFields, providerListForWithdrawalOptions, recipient, setFormValue])

  useEffect(() => {
    if (isEmpty(formState.errors)) {
      setIsDisabledButton(false)
    } else {
      setIsDisabledButton(true)
    }
  }, [formState])

  const onVerify = (data: IndividualBeneficiaryFormType) => {
    let isValid

    const phoneUtil = PhoneNumberUtil.getInstance()
    const phoneNumber = data.phoneNumber.trim()
    const bankId = !data.bankId || data.bankId === '-' ? null : data.bankId

    const recipientDetails = { ...data, phoneNumber, bankId }
    const modifiedRecipientDetails = convertFormDataDependingOnBeneficiaryConfig(
      recipientDetails,
      beneficiaryFieldsConfig
    ) as IndividualBeneficiaryFormType
    const recipient = trimObjectFields<IndividualBeneficiaryFormType>(modifiedRecipientDetails)

    try {
      isValid = phoneUtil.isValidNumber(phoneUtil.parse(phoneNumber))
    } catch (error) {
      return setError('phoneNumber', {
        message: 'Please, enter a phone number with a country code',
      })
    }

    if (!isValid) {
      setError('phoneNumber', { message: 'Please, enter a valid phone number' })
      return
    }

    if (!isValidAccountNumber && canFormChange) {
      if (data.accountNumber && data.bankId) {
        const requestData = {
          accountNumber: data.accountNumber,
          bankId: data.bankId,
        }

        dispatch(beneficiaryActions.validateWithdrawalAccountNumber(requestData))
        return
      }
    }

    sendRequest(recipient)
  }

  const textConfirmButton = useMemo(() => {
    if (isValidAccountNumber || !canFormChange) {
      return textButton
    }

    return 'Validate'
  }, [canFormChange, isValidAccountNumber, textButton])

  const onToggleShowingBankDetails = useCallback(() => {
    setIsShownBankDetails(prev => !prev)
  }, [])

  return (
    <>
      <form id="beneficiary-details-form" onSubmit={handleSubmit(onVerify)}>
        <div className="details-section">
          <div className="details-section__title">
            <h2>Beneficiary Personal Details</h2>
            {!canFormChange && isShownEditBtn && (
              <button className="editButtons-text" onClick={toggleEditingForm} type="button">
                <Icon.EditIcon />
                <span className="fz-small">Edit...</span>
              </button>
            )}
          </div>

          <FormInput<IndividualBeneficiaryFormType>
            id="firstName"
            register={register}
            error={formState.errors?.firstName?.message}
            className="formInput"
            type="text"
            label="First Name"
            maxLength={32}
            disabled={!canFormChange}
          />
          <FormInput<IndividualBeneficiaryFormType>
            id="lastName"
            register={register}
            error={formState.errors.lastName?.message}
            className="formInput"
            type="text"
            label="Last Name"
            maxLength={32}
            disabled={!canFormChange}
          />
          <div className="mobile-number details">
            <Controller
              control={control}
              name="phoneNumber"
              render={({ field: { onChange, value } }) => (
                <PhoneNumberInput
                  id="phoneNumber"
                  placeholder="Phone Number"
                  value={value}
                  options={countryPhoneCodeOptions}
                  onChange={onChange}
                  format="simple"
                  defaultSelectedValue={phoneNumberCode}
                  valueFromServer={recipient.phoneNumber}
                  error={formState.errors.phoneNumber?.message}
                  disabled={!canFormChange}
                />
              )}
            />
          </div>
          {beneficiaryFieldsConfig?.Individual.DateOfBirth.visible && (
            <Controller
              control={control}
              name="dateOfBirth"
              render={({ field: { onChange, value } }) => (
                <FormCustomDateInput
                  placeholder="dd-mm-yyyy"
                  error={formState.errors?.dateOfBirth?.message}
                  value={value}
                  onParentChange={onChange}
                  disabled={!canFormChange}
                  label="Date of Birth (dd-mm-yyyy)"
                />
              )}
            />
          )}
          {documentTypeOptions.length > 0 && (
            <SelectForm<IndividualBeneficiaryFormType>
              options={documentTypeOptions}
              id="documentType"
              register={register}
              label="Identity Documents"
              className="select-form"
              disabled={!canFormChange}
              watch={watch}
              defaultOption="Select Document Type"
              error={formState.errors.documentType?.message}
            />
          )}

          {beneficiaryFieldsConfig?.Individual.DocumentNumber.visible && (
            <FormInput<IndividualBeneficiaryFormType>
              id="documentNumber"
              register={register}
              error={formState.errors?.documentNumber?.message}
              className="formInput"
              type="text"
              label="Beneficiary ID Number"
              maxLength={32}
              disabled={!canFormChange}
            />
          )}
          {beneficiaryFieldsConfig?.Individual.DocumentIssuingCountry.visible && (
            <SelectForm<IndividualBeneficiaryFormType>
              id="documentCountryIso"
              register={register}
              error={formState.errors.documentCountryIso?.message}
              options={documentCountryOptions}
              className="select-form"
              defaultOption="Select ID Issuing Country"
              disabled={!canFormChange}
              watch={watch}
            />
          )}
          {beneficiaryFieldsConfig?.Individual.Gender.visible && (
            <SelectForm<IndividualBeneficiaryFormType>
              id="gender"
              register={register}
              error={formState.errors.gender?.message}
              options={genderOptions}
              className="select-form"
              defaultOption="Select Gender"
              disabled={!canFormChange}
              watch={watch}
            />
          )}
        </div>
        {(beneficiaryFieldsConfig?.Individual.AddressCountry.visible ||
          beneficiaryFieldsConfig?.Individual.AddressCity.visible ||
          beneficiaryFieldsConfig?.Individual.AddressStreet.visible ||
          beneficiaryFieldsConfig?.Individual.AddressPostCode.visible) && (
          <div className="details-section">
            <h2>Beneficiary Address Details</h2>
            {beneficiaryFieldsConfig?.Individual.AddressStreet.visible && (
              <FormInput<IndividualBeneficiaryFormType>
                id="street"
                register={register}
                error={formState.errors?.street?.message}
                className="formInput"
                type="text"
                label="Address"
                maxLength={64}
                disabled={!canFormChange}
              />
            )}
            {beneficiaryFieldsConfig?.Individual.AddressCity.visible && (
              <FormInput<IndividualBeneficiaryFormType>
                id="city"
                register={register}
                error={formState.errors.city?.message}
                className="formInput"
                type="text"
                label="City"
                maxLength={32}
                disabled={!canFormChange}
              />
            )}
            {beneficiaryFieldsConfig?.Individual.AddressCountry.visible && (
              <SelectForm<IndividualBeneficiaryFormType>
                id="countryId"
                register={register}
                error={formState.errors.countryId?.message}
                options={addressSectionCountryOptions}
                className="select-form"
                defaultOption="Select Country"
                disabled={!canFormChange}
                watch={watch}
              />
            )}
            {beneficiaryFieldsConfig?.Individual.AddressPostCode.visible && (
              <FormInput<IndividualBeneficiaryFormType>
                id="postcode"
                register={register}
                error={formState.errors.postcode?.message}
                className="formInput"
                type="string"
                maxLength={16}
                label="Zip/Postcode"
                disabled={!canFormChange}
              />
            )}
          </div>
        )}

        {isVisibleWithdrawalFields && (
          <>
            <div className="warning-message">
              * Add Bank details only for transfers to USD Bank/ Mobile wallet Account
            </div>
            <div
              style={{
                display: 'flex',
                justifyContent: 'flex-end',
                marginTop: '8px',
                marginBottom: '8px',
              }}
            >
              <button
                className="editButtons-text"
                onClick={onToggleShowingBankDetails}
                type="button"
                style={{ textDecoration: 'underline' }}
              >
                <span className="fz-small" style={{display: 'flex', alignItems: 'center', gap: '5px'}}>
                  Add Bank details
                  {isShownBankDetails ? <Icon.ArrowUp /> : <Icon.ArrowDown />}
                </span>
              </button>
            </div>
            {isShownBankDetails && (
              <>
                <div className="details-section">
                  <h2>Foreign Currency Account/ Mobile Wallet Details</h2>
                  <SelectForm<IndividualBeneficiaryFormType>
                    // id="bankName"
                    id="bankId"
                    register={register}
                    options={providerListForWithdrawalOptions || []}
                    className="select-form"
                    defaultOption="Select a Bank"
                    disabled={!canFormChange}
                    watch={watch}
                  />
                  <FormInput<IndividualBeneficiaryFormType>
                    id="accountNumber"
                    register={register}
                    error={formState.errors.accountNumber?.message}
                    className="formInput"
                    type="string"
                    maxLength={32}
                    label="USD Bank or Wallet account No or Merchant Code"
                    disabled={!canFormChange}
                  />
                  {isShownWithdrawalInfo && (
                    <div className="withdrawal-account-number-info">
                      <div className="withdrawal-account-number-info-raw">
                        <div>Account Name:</div>
                        <div>{withdrawalAccountNumberInfo?.holderName}</div>
                      </div>
                      <div className="withdrawal-account-number-info-raw">
                        <div>Currency:</div>
                        <div>{withdrawalAccountNumberInfo?.currency}</div>
                      </div>
                    </div>
                  )}
                  <FormInput<IndividualBeneficiaryFormType>
                    id="reference"
                    register={register}
                    error={formState.errors.reference?.message}
                    className="formInput"
                    type="string"
                    maxLength={32}
                    label="Beneficiary Reference"
                    disabled={!canFormChange}
                  />
                  <FormInput<IndividualBeneficiaryFormType>
                    id="notificationEmail"
                    register={register}
                    error={formState.errors.notificationEmail?.message}
                    className="formInput"
                    type="string"
                    maxLength={32}
                    label="Beneficiary email (CBZ will send proof of payment)"
                    disabled={!canFormChange}
                  />
                </div>
                <div className="error-message">
                  <Icon.WarningExclamationMark />
                  <p>
                    Please ensure that account details entered are correct as error will lead to
                    delayed / failed transactions
                  </p>
                </div>
              </>
            )}
          </>
        )}
      </form>

      <div className="btn-wrapper mb-10">
        <button type="button" className="btn btn-cancel" onClick={onCancel}>
          Cancel
        </button>

        {(type === 'beneficiary' || (canFormChange && type === 'recipient')) && (
          <button
            form="beneficiary-details-form"
            type="submit"
            className="btn btn-next"
            disabled={isDisabledButton || isLoadingDisabledBtn}
          >
            {textConfirmButton}
          </button>
        )}
      </div>
    </>
  )
}

export default IndividualBeneficiaryForm
