import { useMemo } from 'react'
import { matchPath, Navigate, Outlet, useLocation } from 'react-router-dom'
import { authHelper } from '../helpers/authData'
import { useAppSelector } from '../hooks/dispatch'
import { AmlStatus, UserStatus } from '../interfaces/user'
import { getAvailableRoutes } from './configRouting'

const LoggedInPrivateRoute = () => {
  const token = authHelper.getAuthData()
  const userDetails = useAppSelector(state => state.user.userDetails)
  const userAml = useAppSelector(state => state.user.userAml)
  const userAmlList = useAppSelector(state => state.user.userAmlList)

  const location = useLocation()
  const matchByTransaction =
    location.pathname !== '/transaction/new' && matchPath('/transaction/:id', location.pathname)
  const matchByTransactionPayment = matchPath('/transaction/:id/payment-details', location.pathname)
  const matchByTransactionFailedPayment = matchPath(
    '/transaction/:id/payment-details/failed',
    location.pathname
  )
  const matchByTransactionAbortedPayment = matchPath(
    '/transaction/:id/payment-details/aborted',
    location.pathname
  )
  const matchByTransactionRecipient = matchPath('/transaction/:id/beneficiary', location.pathname)
  // const matchByEditingBeneficiary = matchPath('/beneficiary/:id/edit', location.pathname)
  const matchByBeneficiary =
    location.pathname !== '/beneficiary/new' &&
    matchPath('/beneficiary/:beneficiaryId', location.pathname)
  const isLoggedIn = token && userDetails

  const isAvailableRouteWithParams = useMemo(() => {
    if (userDetails?.status === UserStatus.Accepted) {
      if (userAml?.status === AmlStatus.Verified) {
        return (
          matchByTransaction ||
          matchByBeneficiary ||
          matchByTransactionPayment ||
          matchByTransactionRecipient ||
          matchByTransactionFailedPayment || 
          matchByTransactionAbortedPayment
        )
      } else {
        return (
          matchByTransaction ||
          matchByBeneficiary ||
          matchByTransactionRecipient ||
          matchByTransactionFailedPayment ||
          matchByTransactionAbortedPayment
        )
      }
    }

    return false
  }, [
    matchByBeneficiary,
    matchByTransaction,
    matchByTransactionFailedPayment,
    matchByTransactionPayment,
    matchByTransactionRecipient,
    userAml?.status,
    userDetails?.status,
  ])

  const hasAllowedRouteWithoutParams = useMemo(() => {
    const routeList = userDetails && getAvailableRoutes(userDetails, userAml)
    let hasAllowedRoute

    if (userDetails?.status === UserStatus.Accepted && userAmlList.length > 0) {
      hasAllowedRoute = routeList?.find(route => route.path === location?.pathname)
    }

    return Boolean(hasAllowedRoute)
  }, [location?.pathname, userAml, userAmlList.length, userDetails])

  const isAvailableRoute = useMemo(() => {
    if (isLoggedIn) {
      if (isAvailableRouteWithParams) {
        return true
      } else {
        return hasAllowedRouteWithoutParams
      }
    } else {
      return false
    }
  }, [hasAllowedRouteWithoutParams, isAvailableRouteWithParams, isLoggedIn])

  if (Boolean(isLoggedIn && userAmlList.length > 0)) {
    return isAvailableRoute ? <Outlet /> : <Navigate to="/" />
  } else if (!token) {
    return <Navigate to="/" />
  } else {
    return null
  }
}

export default LoggedInPrivateRoute
