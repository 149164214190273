export enum presetConstants {
  GET_PRESET_REQUEST = 'GET_PRESET_REQUEST',
  GET_PRESET_SUCCESS = 'GET_PRESET_SUCCESS',
  GET_PRESET_FAILURE = 'GET_PRESET_FAILURE',

  GET_AVAILABLE_FLOW_REQUEST = 'GET_AVAILABLE_FLOW_REQUEST',
  GET_AVAILABLE_FLOW_SUCCESS = 'GET_AVAILABLE_FLOW_SUCCESS',
  GET_AVAILABLE_FLOW_FAILURE = 'GET_AVAILABLE_FLOW_FAILURE',

  POST_EARLY_ACCESS_REQUEST = 'POST_EARLY_ACCESS_REQUEST',
  POST_EARLY_ACCESS_SUCCESS = 'POST_EARLY_ACCESS_SUCCESS',
  POST_EARLY_ACCESS_FAILURE = 'POST_EARLY_ACCESS_FAILURE',

}
