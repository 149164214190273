import { detectMobile } from '../helpers/utils'
import { Icon } from './icons'
import MobileMenu from './mobileMenu/MobileMenu'

type InnerHeaderProps = {
  title: string
  titlePosition?: string
  icon?: React.ReactNode
  onGoBack?: () => void | undefined
  marginLeft?: string
}

const isMobile = detectMobile()

const InnerHeader = ({ title, onGoBack, icon, titlePosition, marginLeft }: InnerHeaderProps) => {
  return (
    <>
      {isMobile ? (
        <>
          <div className="page-header">
            {onGoBack ? (
              <>
                <button className="btn btn-back" onClick={onGoBack}>
                  <Icon.ArrowLeftBack />
                </button>
                <h1
                  className={icon ? 'page-header__title-icon' : 'page-header__title-btn'}
                  onClick={onGoBack}
                >
                  {icon && <div className="show-mobile">{icon}</div>}
                  {title}
                </h1>
              </>
            ) : (
              <h1 className={icon ? 'page-header__title-icon' : 'page-header__title-btn'}>
                {icon && <div className="show-mobile">{icon}</div>}
                {title}
              </h1>
            )}
            <MobileMenu />
          </div>
        </>
      ) : (
        <div className="page-header">
          {onGoBack && (
            <button className="btn btn-back" onClick={onGoBack}>
              <Icon.ArrowLeftBack />
              <span>Back</span>
            </button>
          )}
          <h1
            className={
              icon
                ? `page-header__title-icon ${titlePosition}`
                : `page-header__title-btn ${titlePosition}`
            }
            style={{ marginLeft }}
          >
            {icon && <div className="show-mobile">{icon}</div>}
            {title}
          </h1>
        </div>
      )}
    </>
  )
}
export default InnerHeader
