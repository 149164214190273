import { ChangeEvent, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import useInfiniteScroll from 'react-infinite-scroll-hook'
import { Icon } from '../../components/icons'
import TransactionItem from '../../components/transactions/TransactionItem'
import { useAppDispatch, useAppSelector } from '../../hooks/dispatch'
import { remittanceActions } from '../../redux/action-creators/remittanceActions'
import debounce from 'lodash/debounce'
import { useLocation, useNavigate } from 'react-router-dom'
import { beneficiaryService } from '../../services/beneficiaryService'
import { PuffLoader } from 'react-spinners'
import Modal from '../../components/Modal'
import { detectMobile } from '../../helpers/utils'
import InnerHeader from '../../components/innerHeader'
import { CustomErrorResponse } from '../../interfaces/api/axiosTypes'
import { AmlStatus } from '../../interfaces/user'

const isMobile = detectMobile()

type LocationState = {
  state: {
    searchedTxValue: string
  }
}

const PastTransactionsPage = () => {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const location = useLocation() as LocationState
  const searchedData = location?.state?.searchedTxValue || undefined

  const { items, hasNextPage } = useAppSelector(state => state.remittance.transactions)
  const { isTransactionsLoading: loading, error } = useAppSelector(state => state.remittance)
  const userAmlStatus = useAppSelector(state => state.user.userAml?.status)

  const step = 10
  const offset = 0

  const [searchInputValue, setSearchInputValue] = useState('')
  const [showClearButton, setShowClearButton] = useState(false)
  const [isOpenModal, setIsOpenModal] = useState(false)
  const [isShown, setIsShown] = useState(false)
  const [isOpenedSomeTooltip, setIsOpenedSomeTooltip] = useState(false)
  const [isOpenVerificationModal, setIsOpenVerificationModal] = useState(false)

  const searchSectionRef = useRef<HTMLDivElement>(null)
  const inputRef = useRef<HTMLInputElement>(null)

  const onClickAway = useCallback(
    (event: any) => {
      if (
        !(
          event.target.closest('.circle-with-dots') ||
          searchSectionRef.current?.contains(event.target) ||
          isOpenedSomeTooltip
        )
      ) {
        setIsShown(false)
        setSearchInputValue('')
        setShowClearButton(false)
      }
    },
    [isOpenedSomeTooltip]
  )

  useEffect(() => {
    if (isShown && isMobile) {
      document.addEventListener('click', onClickAway, { capture: true })

      return () => {
        document.removeEventListener('click', onClickAway, { capture: true })
      }
    }
  }, [isShown, onClickAway])

  useEffect(() => {
    if (!isMobile || searchedData) {
      setIsShown(true)
    }
  }, [searchedData])

  useEffect(() => {
    if (searchedData) {
      setSearchInputValue(searchedData)
      setShowClearButton(true)
    }
  }, [searchedData])

  const showSearch = () => {
    setIsShown(true)
  }

  const searchForData = useCallback(async () => {
    const trimmedInputValue = searchInputValue.trim()

    if (trimmedInputValue) {
      await dispatch(remittanceActions.getTransactionsBySearch(offset, 15, trimmedInputValue))
    } else {
      await dispatch(remittanceActions.getPastTransactions(offset, 15))
    }
  }, [dispatch, searchInputValue])

  const debounceFunction = useMemo(() => debounce(searchForData, 700), [searchForData])

  useEffect(() => {
    debounceFunction()

    return debounceFunction.cancel
  }, [debounceFunction])

  useEffect(() => {
    return () => {
      dispatch(remittanceActions.clearPastTransactions())
    }
  }, [dispatch])

  const isCustomErrorResponse = (
    error: CustomErrorResponse | undefined | string
  ): error is CustomErrorResponse => {
    if (error && typeof error !== 'string') {
      return (error as CustomErrorResponse).errorCode !== undefined
    } else {
      return false
    }
  }

  useEffect(() => {
    if (isCustomErrorResponse(error)) {
      return setIsOpenModal(true)
    }
  }, [error])

  const onLoadMore = async () => {
    if (searchInputValue) {
      const trimmedInputValue = searchInputValue.trim()

      await dispatch(
        remittanceActions.getTransactionsBySearch(offset + items.length, step, trimmedInputValue)
      )
    } else {
      await dispatch(remittanceActions.getPastTransactions(offset + items.length, step))
    }
  }

  const [sentryRef, { rootRef }] = useInfiniteScroll({
    loading,
    hasNextPage,
    onLoadMore,
    disabled: !!error,
    rootMargin: '0px 0px 400px 0px',
  })

  const onInputChange = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value

    if (value) {
      setShowClearButton(true)
    } else {
      setShowClearButton(false)
    }

    setSearchInputValue(value)
  }, [])

  const clearInput = (event: any) => {
    setSearchInputValue('')
    setShowClearButton(false)
    inputRef.current?.focus()
  }

  const newTransaction = useCallback(async () => {
    // const offset = 0
    // const step = 1
    // const beneficiaries = await beneficiaryService.getBeneficiaries(offset, step)

    // if (!beneficiaries.items.length) {
    //   navigate('/beneficiary/new', { state: { from: 'continue-transaction' } })
    // } else {
    //   navigate('/beneficiaries/select-beneficiary')
    // }

    navigate('/')
  }, [navigate])

  const modalConfirmFunction = () => {
    setIsOpenModal(false)
  }

  const confirmVerificationModal = useCallback(() => {
    setIsOpenVerificationModal(false)

    if (userAmlStatus === AmlStatus.Pending) {
      navigate('/pending')
      return
    }

    navigate('/verification')
  }, [navigate, userAmlStatus])

  return (
    <>
      <section className="h-100-mb">
        <div className="container container-xxl container-full-mb container-100">
          <div className="past-transactions past-transactions--main">
            <InnerHeader title="Past Transactions" icon={<Icon.PastTransactions />} />
            <div className="past-transactions-search">
              {isShown && (
                <div className="search-block" ref={searchSectionRef}>
                  <input
                    className="search"
                    placeholder="Search Transactions"
                    maxLength={40}
                    value={searchInputValue}
                    onChange={onInputChange}
                    ref={inputRef}
                    {...(isMobile && { autoFocus: true })}
                  />
                  {showClearButton && (
                    <button onClick={clearInput}>
                      <span>
                        <Icon.Cross />
                      </span>
                    </button>
                  )}
                </div>
              )}
            </div>
            <div className="table">
              <div className="table-header">
                <div className="table-header__item transaction-item">Date</div>
                <div className="table-header__item transaction-item">Beneficiary</div>
                <div className="table-header__item transaction-item">Country</div>
                <div className="table-header__item transaction-item">Currency</div>
                <div className="table-header__item transaction-item">Amount</div>
                <div className="table-header__item transaction-item">Redeem Code</div>
                <div className="table-header__item transaction-item">Status</div>
                <div className="table-header__item transaction-item">
                  <button
                    className="btn btn-primary btn-xl btn-additional hide-mobile"
                    onClick={newTransaction}
                  >
                    + New Transaction
                  </button>
                  {!isShown && (
                    <div className="show-mobile" onClick={showSearch}>
                      <Icon.Search />
                    </div>
                  )}
                </div>
              </div>
              <div className="table-body" ref={rootRef}>
                {!Boolean(items.length) && loading ? (
                  <div className="spinner spinner-absolute">
                    <PuffLoader size={70} color="#3171D8" />
                  </div>
                ) : (
                  items.map(transaction => (
                    <TransactionItem
                      key={transaction.id}
                      reference={sentryRef}
                      transaction={transaction}
                      searchedTxValue={searchInputValue.trim()}
                      setIsOpenedSomeTooltip={setIsOpenedSomeTooltip}
                      setIsOpenVerificationModal={setIsOpenVerificationModal}
                    />
                  ))
                )}
              </div>
            </div>
            <div className="show-mobile t-center">
              <button
                className="btn btn-primary btn-xxl show btn-svg btnLogin-bottom"
                onClick={newTransaction}
              >
                <Icon.PastTransactions />+ New Transaction
              </button>
            </div>
          </div>
        </div>
        <Modal
          setIsOpenModal={setIsOpenModal}
          isOpenModal={isOpenModal}
          confirmFunction={modalConfirmFunction}
          modalHeader="Error"
          modalBody="Oops! Tiny issue with your transaction. Please contact our technical support."
          modalType="informationModal"
        />
      </section>
      <Modal
        setIsOpenModal={setIsOpenVerificationModal}
        isOpenModal={isOpenVerificationModal}
        modalHeader="Verification"
        modalBody="For security reasons, please, complete the Identity Verification."
        modalType="informationModal"
        confirmFunction={confirmVerificationModal}
      />
    </>
  )
}

export default PastTransactionsPage
