import React, { Dispatch, SetStateAction, useCallback, useEffect, useMemo } from 'react'
import { useAppDispatch, useAppSelector } from '../../../hooks/dispatch'
import { CardPaymentButtonVariant } from '../../../interfaces/cardPayment'
import { remittanceActions } from '../../../redux/action-creators/remittanceActions'
import { PuffLoader } from 'react-spinners'
import { PaymentMethod } from '../../../interfaces'

type DeviceDataFormProps = {
  setCardPaymentStatusForBtn: Dispatch<SetStateAction<CardPaymentButtonVariant>>
}

const DeviceDataForm = ({ setCardPaymentStatusForBtn }: DeviceDataFormProps) => {
  const dispatch = useAppDispatch()
  const initializedCardInformation = useAppSelector(
    state => state.remittance.initializedCardInformation
  )
  const withdrawalPaymentMethod = useAppSelector(
    state => state.remittance.transaction.withdrawalMethod
  )

  const isBankAccountWithdrawal = useMemo(
    () => withdrawalPaymentMethod === PaymentMethod.BankTransfer,
    [withdrawalPaymentMethod]
  )
  
  const authenticateCard = useCallback(
    (data: string) => {
      const parsedJson = JSON.parse(data)
      const userAgentHeader = window.navigator.userAgent
      const browserLanguage =
        window.navigator.language.length < 5 ? 'en-US' : window.navigator.language

      const body = {
        transactionReference: initializedCardInformation?.transactionReference || '',
        deviceData: {
          collectionReference: parsedJson.SessionId,
          acceptHeader: 'text/html',
          userAgentHeader,
          browserLanguage,
        },
      }
      dispatch(
        remittanceActions.authenticateCard(
          body,
          setCardPaymentStatusForBtn,
          isBankAccountWithdrawal
        )
      )
    },
    [
      dispatch,
      initializedCardInformation?.transactionReference,
      isBankAccountWithdrawal,
      setCardPaymentStatusForBtn,
    ]
  )

  const receiveMessage = useCallback(
    (event: MessageEvent) => {
      const specialUrl =
        initializedCardInformation?.url && new URL(initializedCardInformation?.url).origin

      if (event.origin === specialUrl) {
        authenticateCard(event.data)
      }
    },
    [authenticateCard, initializedCardInformation?.url]
  )

  useEffect(() => {
    window.addEventListener('message', receiveMessage)

    return () => {
      window.removeEventListener('message', receiveMessage)
    }
  }, [receiveMessage])

  const deviceDataForm = useMemo(() => {
    return (
      initializedCardInformation &&
      `<form id="collectionForm" name="devicedata" method="POST" action=${initializedCardInformation?.url}><input type="hidden" name="Bin" value=${initializedCardInformation?.bin} /><input type="hidden" name="JWT" value=${initializedCardInformation?.jwt} /></form><script>window.onload = function test () { document.getElementById('collectionForm').submit() }</script>`
    )
  }, [initializedCardInformation])

  return (
    <>
      <div className="portal"></div>
      <div className="device-data-form">
        <div className="spinner spinner-absolute spinner-withoutBg">
          <PuffLoader size={70} color="#3171d8" />
          <p>Loading...</p>
        </div>
        {deviceDataForm && (
          <iframe height="0" width="0" hidden title="Device Data Form" srcDoc={deviceDataForm} />
        )}
      </div>
    </>
  )
}

export default DeviceDataForm
