import { Dispatch, SetStateAction, useCallback, useEffect, useMemo } from 'react'
import { useAppDispatch, useAppSelector } from '../../../hooks/dispatch'
import { CardToDelete, PaymentSection } from '../../../interfaces/cardPayment'
import { remittanceActions } from '../../../redux/action-creators/remittanceActions'
import { Icon } from '../../icons'
import { PuffLoader } from 'react-spinners'

type CardListProps = {
  openDeleteCardModal: (card: CardToDelete) => void
  setPaymentSection: Dispatch<SetStateAction<PaymentSection>>
}
const CardList = ({ openDeleteCardModal, setPaymentSection }: CardListProps) => {
  const dispatch = useAppDispatch()
  const {
    savedCards: cards,
    isLoading,
    isCardPaymentLoaded,
  } = useAppSelector(state => state.remittance)
  const { topUpNumber: id } = useAppSelector(state => state.remittance.transaction)

  useEffect(() => {
    dispatch(remittanceActions.getSavedCards())
  }, [dispatch])

  const modifiedCardList = useMemo(
    () =>
      cards.map(card => {
        const ExpiredTokenDateToTimeStamp = new Date(card.tokenExpireDate).getTime()
        const ExpiredCardDateToTimeStamp = new Date(
          new Date(card.cardExpireYear, card.cardExpireMonth).toISOString()
        ).getTime()
        const currentTimeToTimeStamp = new Date(new Date().toISOString()).getTime()

        const isExpiredToken = ExpiredTokenDateToTimeStamp - currentTimeToTimeStamp <= 0
        const isExpiredCard = ExpiredCardDateToTimeStamp - currentTimeToTimeStamp <= 0

        const isExpired = isExpiredToken || isExpiredCard

        return {
          ...card,
          isExpired,
        }
      }),

    [cards]
  )

  const onSelect = useCallback(
    async (savedCardId: string) => {
      const body = { savedCardId, topUp: { id } }

      await dispatch(remittanceActions.initializeBySavedCard(body))

      dispatch(remittanceActions.setCardPaymentViewSection('device-data'))
    },
    [dispatch, id]
  )

  const onGoBack = useCallback(() => {
    setPaymentSection('payment-providers')
    // dispatch(remittanceActions.setCardPaymentViewSection('card-payment-variant'))
  }, [setPaymentSection])

  const onDelete = useCallback(
    (id: string, last4Digits: string) => {
      openDeleteCardModal({ id, last4Digits })
    },
    [openDeleteCardModal]
  )

  const onNewCard = useCallback(() => {
    dispatch(remittanceActions.setCardPaymentViewSection('new-card'))
  }, [dispatch])

  const getCardIcon = useCallback((brandName: string) => {
    const upperCaseBrandName = brandName.toUpperCase()
    switch (upperCaseBrandName) {
      case 'AMEX': {
        return <Icon.Amex />
      }
      case 'ECMC': {
        return <Icon.MasterCard />
      }
      case 'VISA': {
        return <Icon.Visa />
      }
      case 'MAESTRO': {
        return <Icon.Maestro />
      }
      default: {
        return null
      }
    }
  }, [])

  return (
    <>
      {isLoading && (
        <div className="spinner spinner-absolute spinner-withoutBg">
          <PuffLoader size={70} color="#3171d8" />
        </div>
      )}
      <div className="card-selection">
        <button className="card-selection__title" onClick={onGoBack}>
          Back To Payment Method
        </button>
        <div className="card-selection__rows">
          {cards.length > 0 &&
            modifiedCardList.map(card => (
              <div className="card-selection__row" key={card.extId}>
                <div className="card-selection__item">
                  {getCardIcon(card.brand)}
                  <p>{card.cardHolderName}</p>
                  <span>**** {card.last4Digits}</span>
                </div>
                {card.isExpired ? (
                  <span>Expired</span>
                ) : (
                  <button
                    onClick={() => onSelect(card.extId)}
                    className="btn card-selection__select"
                  >
                    Select
                  </button>
                )}
                <button
                  onClick={() => onDelete(card.extId, card.last4Digits)}
                  className="btn-icon bg-white"
                >
                  <Icon.DeleteIcon />
                </button>
              </div>
            ))}
          {isCardPaymentLoaded && cards.length === 0 ? (
            <p className="card-selection__no-cards">Add a card to pay</p>
          ) : null}
        </div>
        <div className="card-selection__action ">
          <button className="btn btn-primary" onClick={onNewCard}>
            + Pay with new card
          </button>
        </div>
      </div>
    </>
  )
}

export default CardList
