import { configureStore } from "@reduxjs/toolkit";
import thunk, { ThunkDispatch } from "redux-thunk";
import { rootReducer, RootState } from "./reducers";

export type AppDispatch = ThunkDispatch<RootState, any, any>;

export const store = configureStore({
  reducer: rootReducer,
  middleware: [thunk],
  devTools: process.env.REACT_APP_ENVIRONMENT !== 'production',
});

export default store;
