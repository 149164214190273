import { Dispatch, SetStateAction, useCallback, useState } from 'react'
import Tooltip from 'rc-tooltip'
import { Icon } from '../icons'
import { PaymentSection } from '../../interfaces/cardPayment'
import { TransactionDetails } from '../../interfaces/remittanceType'

type BankTransferSectionProps = {
  transaction: TransactionDetails
  setPaymentSection: Dispatch<SetStateAction<PaymentSection>>
}

const BankTransferSection = ({ transaction, setPaymentSection }: BankTransferSectionProps) => {
  const [showToolTip, setShowToolTip] = useState([false, false, false, false])

  const onCopyClick = (value?: string | null, indexField: number = 0) => {
    const newShowToolTipArray = [...showToolTip]
    newShowToolTipArray[indexField] = true
    const emptyArray = [false, false, false, false]

    setShowToolTip(newShowToolTipArray)

    navigator.clipboard.writeText(value ?? '')
    window.setTimeout(() => setShowToolTip(emptyArray), 1000)
  }

  const onGoBack = useCallback(() => {
    setPaymentSection('payment-providers')
  }, [setPaymentSection])
  return (
    <>
      <button className="card-selection__title" onClick={onGoBack}>
        Back To Payment Method
      </button>
      <div className="price-title">
        Make a bank payment to pay for your transfer to the following account
      </div>
      <div className="price-row">
        <p className="price-row__name">Account Name:</p>
        <p className="price-row__value">{transaction.accountName}</p>
        <div className="price-row__copy">
          <Tooltip
            visible={showToolTip[0]}
            placement="left"
            trigger={['click']}
            mouseLeaveDelay={0.2}
            overlay="Copied to clipboard"
          >
            <span className="copy-field" onClick={() => onCopyClick(transaction.accountName, 0)}>
              <Icon.CopyIcon />
            </span>
          </Tooltip>
        </div>
      </div>
      <div className="price-row">
        <p className="price-row__name">{transaction.iban ? 'IBAN:' : 'Account Number:'}</p>
        <p className="price-row__value">
          {transaction.iban ? transaction.iban : transaction.accountNumber}
        </p>
        <div className="price-row__copy">
          <Tooltip
            visible={showToolTip[1]}
            placement="left"
            trigger={['click']}
            mouseLeaveDelay={0.2}
            overlay="Copied to clipboard"
          >
            <span
              className="copy-field"
              onClick={() =>
                onCopyClick(transaction.iban ? transaction.iban : transaction.accountNumber, 1)
              }
            >
              <Icon.CopyIcon />
            </span>
          </Tooltip>
        </div>
      </div>
      {!transaction.iban && (
        <div className="price-row">
          <p className="price-row__name">Sort Code:</p>
          <p className="price-row__value">{transaction.sortCode}</p>
          <div className="price-row__copy">
            <Tooltip
              visible={showToolTip[2]}
              placement="left"
              trigger={['click']}
              mouseLeaveDelay={0.2}
              overlay="Copied to clipboard"
            >
              <span className="copy-field" onClick={() => onCopyClick(transaction.sortCode, 2)}>
                <Icon.CopyIcon />
              </span>
            </Tooltip>
          </div>
        </div>
      )}
      <div className="price-row">
        <p className="price-row__name">
          <Icon.ExclamationMark />
          Use payment reference:
        </p>
        <p className="price-row__value">{transaction.paymentReference}</p>
        <div className="price-row__copy">
          <Tooltip
            visible={showToolTip[3]}
            placement="left"
            trigger={['click']}
            mouseLeaveDelay={0.2}
            overlay="Copied to clipboard"
          >
            <span
              className="copy-field"
              onClick={() => onCopyClick(transaction.paymentReference, 3)}
            >
              <Icon.CopyIcon />
            </span>
          </Tooltip>
        </div>
      </div>
      <span className="price-info__note danger">
        <b>Important note</b> - when making payment using your bank, you must enter in the Payment
        Reference exactly as shown above, with no other text
      </span>
    </>
  )
}

export default BankTransferSection
