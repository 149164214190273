import { Country } from '../interfaces/auth'
import {
  BeneficiariesList,
  BeneficiaryLookUpServerResponse,
  CompanyRecipient,
  GetBeneficiaryFieldsConfigResponse,
  KWLRecipient,
  MobileWalletRecipient,
  ProviderNameForWithdrawal,
  ValidateWithdrawalAccountNumberRequestData,
  ValidateWithdrawalAccountNumberResponse,
} from '../interfaces/beneficiary'
import { anonimRequests } from '../utils/anonimusRequest'
import { authorizeRequests } from '../utils/authorizeRequest'

function getBeneficiaryCountryList() {
  const path = ['countries', 'receive']

  return anonimRequests.anonimusRequest<null, Array<Country>>(path)
}

function getCountryAllList() {
  const path = ['countries', 'all']

  return authorizeRequests.authorizeRequest<null, Array<Country>>(path)
}

function createRecipient(recipient: KWLRecipient | CompanyRecipient) {
  const path = ['beneficiaries']

  return authorizeRequests.postAuthorize<KWLRecipient | CompanyRecipient, KWLRecipient | CompanyRecipient>(path, recipient)
}

function createMobileWalletRecipient(recipient: MobileWalletRecipient) {
  const path = ['beneficiaries']

  return authorizeRequests.postAuthorize<MobileWalletRecipient, KWLRecipient>(path, recipient)
}

function getRecipient(recipientId: string) {
  const path = ['beneficiaries', recipientId]

  return authorizeRequests.authorizeRequest<null, KWLRecipient>(path)
}

function updateRecipient(recipientId: string, recipient: KWLRecipient | CompanyRecipient) {
  const path = ['beneficiaries', recipientId]

  return authorizeRequests.putAuthorize<KWLRecipient | CompanyRecipient, null>(path, recipient)
}

function deleteRecipient(recipientId: number) {
  const path = ['beneficiaries', String(recipientId)]

  return authorizeRequests.authorizeRequest<null, null>(path, 'DELETE')
}

function getBeneficiaries(offset: number, step: number, q?: string) {
  const path = ['beneficiaries']
  const isCancelledMode = true
  const query = {
    offset,
    step,
    ...(q && { q }),
  }

  return authorizeRequests.authorizeRequest<null, BeneficiariesList>(
    path,
    'GET',
    null,
    query,
    isCancelledMode
  )
}

function lookUpBeneficiary(nationalId: string) {
  const path = ['citizenship', 'validate', nationalId]
  // const query = { nationalId }

  return authorizeRequests.authorizeRequest<null, BeneficiaryLookUpServerResponse>(path, 'GET')
}

function getBeneficiaryFieldsConfig() {
  const path = ['beneficiaries', 'configuration']

  return authorizeRequests.authorizeRequest<null, GetBeneficiaryFieldsConfigResponse>(path, 'GET')
}

function getProviderListForWithdrawal() {
  const path = ['beneficiaries', 'banks']

  return authorizeRequests.authorizeRequest<null, Array<ProviderNameForWithdrawal>>(path, 'GET')
}

function validateWithdrawalAccountNumber(requestQuery: ValidateWithdrawalAccountNumberRequestData): Promise<ValidateWithdrawalAccountNumberResponse> {
  const path = ['beneficiaries', 'validate-bankaccount']

  const query = {
    accountNumber: requestQuery.accountNumber,
    bankId: requestQuery.bankId,
  }

  return authorizeRequests.authorizeRequest<null, ValidateWithdrawalAccountNumberResponse>(path, 'GET', null, query)
}

export const beneficiaryService = {
  getBeneficiaryCountryList,
  getCountryAllList,
  createRecipient,
  getRecipient,
  updateRecipient,
  deleteRecipient,
  getBeneficiaries,
  lookUpBeneficiary,
  getBeneficiaryFieldsConfig,
  getProviderListForWithdrawal,
  validateWithdrawalAccountNumber,
  createMobileWalletRecipient,
}
