import { AuthData } from "../interfaces/auth";
import { localStorageHelper } from "./useLocalStorage";

function setAuthData(authData: AuthData) {
  localStorage.setItem("data", JSON.stringify(authData));
}

function getAuthData(): AuthData {
  return localStorageHelper.getData("data");
}

function removeAuthData() {
  localStorageHelper.removeData("data");
}

function setTempAuthData(authData: AuthData) {
  localStorage.setItem("tempAuthData", JSON.stringify(authData));
}

function removeTempAuthData() {
  localStorageHelper.removeData("tempAuthData");
}

function getTempToken(): AuthData {
  return localStorageHelper.getData("tempAuthData");
}

export const authHelper = {
  getAuthData,
  setAuthData,
  removeAuthData,
  setTempAuthData,
  removeTempAuthData,
  getTempToken,
};
