import { CustomErrorResponse, QueryParams } from "../interfaces/api/axiosTypes"
import * as url from "../helpers/url"
import axios, { AxiosError, Method } from "axios"
import { history } from "../routing/history"

export const ownAnonymousAxios = axios.create()

ownAnonymousAxios.interceptors.response.use(
  async (response) => response,
  async function (error: AxiosError) {
    if (error.response?.status && error.response?.status === 503) {
      history.push("/maintenance")
    }
    return Promise.reject(error)
  }
)

// const dictionaryTyQueryString = (dict: QueryParams) => {
//   const params = new URLSearchParams(dict as any)
//   return params.toString()
// }

const postAnonimus = async <TRequest, TResponse>(
  path: string[],
  body?: TRequest,
  query?: QueryParams
): Promise<TResponse> => {
  return anonimusRequest<TRequest, TResponse>(path, "POST", body, query)
}

const putAnonimus = async <TRequest, TResponse>(
  path: string[],
  body?: TRequest,
  query?: QueryParams
): Promise<TResponse> => {
  return anonimusRequest<TRequest, TResponse>(path, "PUT", body, query)
}

const anonimusRequest = async <TRequest, TResponse>(
  path: string[],
  method: Method = "GET",
  body?: TRequest,
  query?: QueryParams
): Promise<TResponse> => {
  const apiUrl = process.env.REACT_APP_BASE_URL || "http://localhost:52191/"
  const defaultApiUrl = "api/kwl/v1"

  // const queryString = query != null ? "?" + dictionaryTyQueryString(query) : ""
  const requestUri = url.combine(apiUrl, defaultApiUrl, ...path)

  let response
  try {
    response = await ownAnonymousAxios({
      method: method,
      baseURL: requestUri,
      headers: {
        "Content-Type": "application/json",
      },
      data: body,
      // params: queryString,
      params: query,
      responseType: "json",
    })
    return response.data as TResponse
  } catch (error) {
    let e = error as AxiosError
    let customErrorResponse = JSON.parse(e.request.response) as CustomErrorResponse
    return Promise.reject(customErrorResponse)
  }
}

export const anonimRequests = {
  anonimusRequest,
  postAnonimus,
  putAnonimus,
}
