import axios, { AxiosError, Method } from 'axios'
import { authHelper } from '../helpers/authData'
import { CustomErrorResponse } from '../interfaces/api/axiosTypes'
import * as url from '../helpers/url'
import { authServices } from '../services/authServices'
import { history } from '../routing/history'
import { localStorageHelper } from '../helpers/useLocalStorage'
import { store } from '../redux/store'
import { authConstants } from '../redux/constants/authConstants'
import { errorConstants } from '../redux/constants/errorConstants'

export const ownAxios = axios.create()

ownAxios.interceptors.response.use(
  async response => response,
  async function (error: AxiosError) {
    const customError = error?.response?.data as CustomErrorResponse

    if (error.response?.status === 401) {
      try {
        const response = await authServices.refreshToken()
        authHelper.setAuthData(response)

        error.config.headers!['Authorization'] = `Bearer ${response?.accessToken}`
        return await ownAxios.request(error.config)
      } catch (error) {
        localStorageHelper.clearLocalStorage()
        store.dispatch({ type: authConstants.LOGOUT_SUCCESS })

        const urlParams = window.location.search
        const pathName = window.location.pathname
        
        if(urlParams && pathName === '/login') {
          history.push(`${pathName}${urlParams}`)
        }
        // history.push('/')
        return Promise.reject('session expired!')
      }
    }

    if (
      error.response?.status && error.response?.status === 503
      // error.response?.status >= 500 &&
      // error.response?.status < 600 &&
      // customError.errorCode !== '5000000148' &&
      // customError.errorCode !== '5000000158' &&
      // customError.errorCode !== '5000000194' && 
      // customError.errorCode !== '5000000198'
    ) {
      history.push('/maintenance')
    }

    if (error.response?.status === 403) {
      history.push('/')
      store.dispatch({ type: errorConstants.SET_ERROR, payload: { errorCode: '403' } })
    }

    return Promise.reject(error)
  }
)

const abortControllerObj: Record<string, AbortController> = {}

async function sendRequest<TRequest, TResponse>(
  url: string,
  method: Method = 'GET',
  body: TRequest,
  queryParams?: Record<string, string | number>,
  isCancelledMode?: boolean
): Promise<TResponse> {
  const token = authHelper.getAuthData()
  const tempToken = authHelper.getTempToken()
  const controller = new AbortController()

  if (isCancelledMode) {
    abortControllerObj[url]?.abort()
    abortControllerObj[url] = controller
  }

  const accessToken = tempToken ? tempToken.accessToken : token.accessToken

  let response
  try {
    response = await ownAxios({
      method,
      baseURL: url,
      headers: {
        Authorization: `Bearer ${accessToken}`,
        'Content-Type': 'application/json',
        'Accept-Language': 'EN',
      },
      ...(body && { data: body }),
      responseType: 'json',
      params: queryParams,
      ...(isCancelledMode && { signal: controller.signal }),
    })
    controller.abort()
    return response.data as TResponse
  } catch (error) {
    let e = error as AxiosError
    let customErrorResponse = JSON.parse(e.request.response) as CustomErrorResponse
    return Promise.reject(customErrorResponse)
  }
}

const authorizeRequest = async <TRequest, TResponse>(
  path: string[],
  method: Method = 'GET',
  body?: TRequest,
  queryParams?: Record<any, string | number>,
  isCancelledMode?: boolean
): Promise<TResponse> => {
  const apiUrl = process.env.REACT_APP_BASE_URL || 'http://localhost:52191/'
  const defaultApiUrl = 'api/kwl/v1'

  const defaultUrl = url.combine(apiUrl, defaultApiUrl, ...path)

  return await sendRequest(defaultUrl, method, body, queryParams, isCancelledMode)
}

const postAuthorize = <TRequest, TResponse>(
  url: string[],
  body?: TRequest,
  queryParams?: Record<string, string | number>
): Promise<TResponse> => {
  return authorizeRequest(url, 'POST', body, queryParams)
}
const putAuthorize = <TRequest, TResponse>(
  path: string[],
  body?: TRequest,
  queryParams?: Record<string, string | number>
): Promise<TResponse> => {
  return authorizeRequest(path, 'PUT', body, queryParams)
}

const deleteAuthorize = <TRequest, TResponse>(
  url: string[],
  body?: TRequest,
  queryParams?: Record<string, string | number>
): Promise<TResponse> => {
  return authorizeRequest(url, 'DELETE', body, queryParams)
}
export const authorizeRequests = {
  postAuthorize,
  putAuthorize,
  authorizeRequest,
  deleteAuthorize,
}
