import { PreRegistrationActions } from '../../interfaces/actions/preRegistrationActionTypes'
import * as preRegistrationConstants from '../constants/preRegistrationConstants'
import { UserDetails } from '../../interfaces/redux-types/authActionTypes'

export type preRegistrationState = {
  isLoaded: boolean
  isLoading: boolean
  error: string
  userDetails: UserDetails | null
  loggedIn: boolean
  startSentToWhiteList: boolean
  finishedSentToWhiteList: boolean
  isMobile: boolean
}

export const initialPreRegistrationState: preRegistrationState = {
  isLoading: false,
  isLoaded: false,
  error: '',
  userDetails: null,
  loggedIn: false,
  startSentToWhiteList: false,
  finishedSentToWhiteList: false,
  isMobile: false,
}

export const preRegistrationReducer = (
  state = initialPreRegistrationState,
  action: PreRegistrationActions
): preRegistrationState => {
  switch (action?.type) {
    case preRegistrationConstants.SEND_TO_WHITE_LIST_REQUEST:
      return {
        ...state,
        startSentToWhiteList: true,
        finishedSentToWhiteList: false,
        error: '',
      }
    case preRegistrationConstants.SEND_TO_WHITE_LIST_SUCCESS:
      return {
        ...state,
        startSentToWhiteList: false,
        finishedSentToWhiteList: true,
      }
    case preRegistrationConstants.SEND_TO_WHITE_LIST_FAILURE:
      return {
        ...state,
        error: action.payload.message || 'Something wrong.',
        startSentToWhiteList: false,
      }
    case preRegistrationConstants.preRegistrationConstants.SET_IS_MOBILE:
      return {
        ...state,
        isMobile: action.payload,
      }
    default:
      return state
  }
}
