import { combineReducers, ThunkDispatch, Reducer } from '@reduxjs/toolkit'
import { authReducer } from './authReducer'
import { RemittanceReducer } from './remmitanceReducer'
import { preRegistrationReducer, preRegistrationState } from './preRegistrationReducer'
import { calculationReducer } from './calculationReducer'
import { userReducer } from './userReducer'
import { authConstants } from '../constants/authConstants'
import { AuthActions, AuthState } from '../../interfaces/redux-types/authActionTypes'
import { CalculationActions, CalculationState } from '../../interfaces/redux-types/calculation'
import { UserActions, UserState } from '../../interfaces/redux-types/userReduxTypes'
import { PreRegistrationActions } from '../../interfaces/actions/preRegistrationActionTypes'
import {
  IRemittanceReducer,
  RemittanceAction,
} from '../../interfaces/remittance/remittanceActionTypes'
import { BeneficiaryReducer } from './beneficiaryReducer'
import { BeneficiaryActions, BeneficiaryState } from '../../interfaces/redux-types/beneficiary'
import { ErrorReducer } from './errorReducer'
import { ErrorActionTypes, ErrorState } from '../../interfaces/redux-types/errorActionTypes'
import { PresetActionTypes, PresetState } from '../../interfaces/redux-types/presetReduxTypes'
import { presetReducer } from './presetReducer'

export const initialStore = {
  preset: presetReducer,
  auth: authReducer,
  remittance: RemittanceReducer,
  preRegistration: preRegistrationReducer,
  calculation: calculationReducer,
  user: userReducer,
  beneficiary: BeneficiaryReducer,
  error: ErrorReducer,
}

const reducers = combineReducers(initialStore)
export default reducers

export type RootState = {
  preset: PresetState
  auth: AuthState
  remittance: IRemittanceReducer
  preRegistration: preRegistrationState
  calculation: CalculationState
  user: UserState
  beneficiary: BeneficiaryState
  error: ErrorState
}

type ActionsTypes =
  | PresetActionTypes
  | AuthActions
  | RemittanceAction
  | PreRegistrationActions
  | CalculationActions
  | UserActions
  | BeneficiaryActions
  | ErrorActionTypes

export type AppDispatch = ThunkDispatch<RootState, any, any>

export const getInitState = () => {
  const reducerNames = Object.keys(initialStore) as (keyof RootState)[]
  return reducerNames.reduce((acc: RootState, reducerName) => {
    return {
      ...acc,
      // @ts-ignore
      [reducerName]: initialStore[reducerName](),
    }
  }, {} as RootState)
}

export const rootReducer: Reducer = (state: RootState, actions: ActionsTypes) => {
  if (actions.type === authConstants.LOGOUT_SUCCESS) {
    const initState = getInitState()
    return reducers(initState, actions)
  }
  return reducers(state, actions)
}
