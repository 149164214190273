import { useCallback, useEffect, useMemo, useState } from 'react'
import { useNavigate, useLocation, useSearchParams } from 'react-router-dom'
import InnerHeader from '../../components/innerHeader'
import MobileVerification from '../../components/mobileVerification/MobileVerification'
import CodeVerification from '../../components/mobileVerification/CodeVerification'
import { localStorageHelper } from '../../helpers/useLocalStorage'
import { QueryParams } from '../../interfaces/api/axiosTypes'
import { remittanceActions } from '../../redux/action-creators/remittanceActions'
import { useAppDispatch, useAppSelector } from '../../hooks/dispatch'
import isEqual from 'lodash/isEqual'

type LocationState = {
  state: {
    from: string
  }
}

const MobileVerificationPage = () => {
  // const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const location = useLocation() as LocationState
  const from = location?.state?.from || 'sing-up'
  const [currentSection, setCurrentSection] = useState('mobile-verification')

  const [queryParams] = useSearchParams()

  // const withdrawalPaymentProviders = useAppSelector(
  //   state => state.remittance.withdrawalPaymentProvider,
  //   isEqual
  // )

  // const urlParams = useMemo((): QueryParams => {
  //   const params = { withdrawalMethod: '', withdrawalProviderId: '' }

  //   for (const item of queryParams.entries()) {
  //     const [param, value] = item

  //     params[param] = value
  //   }

  //   return params
  // }, [queryParams])

  // const setReceiveMethodToLocalStorage = useCallback(() => {
  //   if (withdrawalPaymentProviders.length > 0) {
  //     const currentReceiveMethod = withdrawalPaymentProviders.find(
  //       withdrawalPaymentProviderItem =>
  //         String(withdrawalPaymentProviderItem.paymentMethod) === urlParams.withdrawalMethod &&
  //         String(withdrawalPaymentProviderItem.paymentProvider.id) ===
  //           urlParams.withdrawalProviderId
  //     )

  //     const receiveMethodForLocalStorage = {
  //       value: currentReceiveMethod
  //         ? currentReceiveMethod.paymentProvider.id
  //         : withdrawalPaymentProviders[0].paymentProvider.id,
  //       paymentMethod: currentReceiveMethod
  //         ? currentReceiveMethod.paymentMethod
  //         : withdrawalPaymentProviders[0].paymentMethod,
  //     }

  //     localStorage.setItem('receiveMethod', JSON.stringify(receiveMethodForLocalStorage))
  //   }

  // }, [urlParams.withdrawalMethod, urlParams.withdrawalProviderId, withdrawalPaymentProviders])

  // const getWithdrawalPaymentProviderList = useCallback(async () => {
  //   await dispatch(remittanceActions.getWithdrawalPaymentProviderList('USD'))

  //   setReceiveMethodToLocalStorage()
  // }, [dispatch, setReceiveMethodToLocalStorage])

  // useEffect(() => {
  //   if (urlParams.withdrawalMethod && urlParams.withdrawalProviderId) {
  //     getWithdrawalPaymentProviderList()
  //   }
  // }, [getWithdrawalPaymentProviderList, urlParams.withdrawalMethod, urlParams.withdrawalProviderId, urlParams])

  useEffect(() => {
    const referralCode = queryParams.get('ref')?.trim()
    const isAvailableString = referralCode ? /^[a-zA-Z0-9-_]*$/.test(referralCode) : false

    if (referralCode && isAvailableString) {
      localStorageHelper.setData('referralCode', referralCode)
    }
  }, [queryParams])

  const onGoBack = () => {
    if (currentSection === 'mobile-verification') {
      navigate('/')
    } else {
      setCurrentSection('mobile-verification')
    }
  }

  return (
    <section className="h-100-mb">
      <div className="container container-md container-100">
        <InnerHeader title="Verify your mobile" onGoBack={onGoBack} />
        <div className="verification-section">
          {currentSection === 'mobile-verification' ? (
            <MobileVerification onChangeSection={setCurrentSection} from={from} />
          ) : (
            <CodeVerification from={from} />
          )}
        </div>
      </div>
    </section>
  )
}

export default MobileVerificationPage
