import format from 'date-fns/format'
import React, { useCallback } from 'react'
import { AgentsLocationItemResponse, ScheduleDay } from '../../interfaces'
import { Icon } from '../icons'

type AgentsLocationRowProps = {
  agentsLocationItem: AgentsLocationItemResponse
}

const AgentsLocationRow = ({ agentsLocationItem }: AgentsLocationRowProps) => {
  const getAvailableTime = useCallback((agentSchedule: ScheduleDay) => {
    const formattedStartTime = format(new Date(`2023-01-01T${agentSchedule.startTime}`), 'h:mmaaa')
    const formattedEndTime = format(new Date(`2023-01-01T${agentSchedule.endTime}`), 'h:mmaaa')

    if (agentSchedule.isAvailable) {
      return `${formattedStartTime} - ${formattedEndTime}`
    } else {
      return 'Closed'
    }
  }, [])

  return (
    <div className="agents-location-row">
      <div className="header-row">
        <div>{agentsLocationItem.title}</div>
        <div className='agent-address'>{agentsLocationItem.address}</div>
        <a className='coordinate-link'
          href={`https://www.google.com/maps/search/?api=1&query=${agentsLocationItem.latitude},${agentsLocationItem.longitude}`}
          target="_blank"
          rel="noopener noreferrer"
        >
          <Icon.ArrowRoute />
        </a>
      </div>
      <p>Opening Hours:</p>
      <div className="body-row">
        {agentsLocationItem.schedule.map(agentSchedule => (
          <div className="body-cell" key={agentSchedule.dayOfWeek}>
            <p className="day-of-week">{agentSchedule.dayOfWeek}</p>
            <p className={!agentSchedule.isAvailable ? 'closed-time' : 'work-hours'}>
              {getAvailableTime(agentSchedule)}
            </p>
          </div>
        ))}
      </div>
    </div>
  )
}

export default AgentsLocationRow
