import { useMemo } from 'react'
import { KWLRecipient } from '../../interfaces/beneficiary'
import { PhoneNumberUtil } from 'google-libphonenumber'

type IndividualBeneficiaryDetailsProps = {
  recipient: KWLRecipient | undefined
  isShownBeneficiaryAccount?: boolean
}

const IndividualBeneficiaryDetails = ({
  recipient,
  isShownBeneficiaryAccount,
}: IndividualBeneficiaryDetailsProps) => {
  const phoneNumber = useMemo(() => {
    if (recipient?.phoneNumber) {
      const phoneUtil = PhoneNumberUtil.getInstance()
      const number = phoneUtil.parseAndKeepRawInput(recipient?.phoneNumber)

      return phoneUtil.formatInOriginalFormat(number)
    } else {
      return ''
    }
  }, [recipient])

  return (
    <>
      <div className="user-info-block__row">
        <span>Beneficiary</span>
        <span
          title={`${recipient?.firstName} ${recipient?.lastName}`}
        >{`${recipient?.firstName} ${recipient?.lastName}`}</span>
      </div>
      <div className="user-info-block__row">
        <span> Mobile #</span>
        <span>{phoneNumber}</span>
      </div>
      {isShownBeneficiaryAccount && (
        <div className="user-info-block__row">
          <span>Bank Account #</span>
          <span>{recipient?.accountNumber}</span>
        </div>
      )}
    </>
  )
}

export default IndividualBeneficiaryDetails
