import React, { Dispatch, SetStateAction, useCallback } from 'react'
import { Option } from '../../interfaces/commonTypes'

type CustomNativeSelectorProps = {
  label?: string
  options: Array<Option>
  disabled?: boolean
  className?: string
  value: any
  onParentChange: Dispatch<SetStateAction<any>>
}

const CustomNativeSelector = ({
  label,
  options,
  disabled,
  className,
  value,
  onParentChange,
}: CustomNativeSelectorProps) => {
  const onChange = useCallback(
    (event: any) => {
      onParentChange(event.target.value)
    },
    [onParentChange]
  )
  return (
    <div className={className}>
      {label && <label className="form-select-label">{label}</label>}
      <select value={value} onChange={onChange} {...(disabled && { disabled })}>
        {options.map(optionItem => (
          <option key={optionItem.value} value={optionItem.value}>
            {optionItem.label}
          </option>
        ))}
      </select>
      {/* <p className="form-input-error">{error}</p> */}
    </div>
  )
}

export default CustomNativeSelector
