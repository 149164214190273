export enum authConstants {
  SEND_REFRESH_TOKEN_REQUEST = 'SEND_REFRESH_TOKEN_REQUEST',
  SEND_REFRESH_TOKEN_SUCCESS = 'SEND_REFRESH_TOKEN_SUCCESS',
  SEND_REFRESH_TOKEN_FAILURE = 'SEND_REFRESH_TOKEN_FAILURE',

  VERIFY_MOBILE_NUMBER_REQUEST = 'VERIFY_MOBILE_NUMBER_REQUEST',
  VERIFY_MOBILE_NUMBER_SUCCESS = 'VERIFY_MOBILE_NUMBER_SUCCESS',
  VERIFY_MOBILE_NUMBER_FAILURE = 'VERIFY_MOBILE_NUMBER_FAILURE',

  REMOVE_MOBILE_NUMBER_FROM_STATE = 'REMOVE_MOBILE_NUMBER_FROM_STATE',

  VERIFY_CODE_FROM_SMS_REQUEST = 'VERIFY_CODE_FROM_SMS_REQUEST',
  VERIFY_CODE_FROM_SMS_SUCCESS = 'VERIFY_CODE_FROM_SMS_SUCCESS',
  VERIFY_CODE_FROM_SMS_FAILURE = 'VERIFY_CODE_FROM_SMS_FAILURE',

  LOGOUT_REQUEST = 'LOGOUT_REQUEST',
  LOGOUT_SUCCESS = 'LOGOUT_SUCCESS',
  LOGOUT_FAILURE = 'LOGOUT_FAILURE',

  GET_SUM_SUB_TOKEN_REQUEST = 'GET_SUM_SUB_TOKEN_REQUEST',
  GET_SUM_SUB_TOKEN_SUCCESS = 'GET_SUM_SUB_TOKEN_SUCCESS',
  GET_SUM_SUB_TOKEN_FAILURE = 'GET_SUM_SUB_TOKEN_FAILURE',

  GET_USER_COUNTRY_LIST_REQUEST = 'GET_USER_COUNTRY_LIST_REQUEST',
  GET_USER_COUNTRY_LIST_SUCCESS = 'GET_USER_COUNTRY_LIST_SUCCESS',
  GET_USER_COUNTRY_LIST_FAILURE = 'GET_USER_COUNTRY_LIST_FAILURE',
}
