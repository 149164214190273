import { PaymentMethod } from '../interfaces'
import {
  PastPaymentIntentsServerResponse,
  PastTransactionsServerResponse,
  PaymentIntentDetails,
  PaymentIntentStatus,
  ShortPaymentIntentDetails,
  ShortTransactionDetails,
  TransactionDetails,
  TransactionPaymentProvider,
  TransactionStatus,
} from '../interfaces/remittanceType'

// export const getUpdatedTxDetails = (
//   paymentProviderList: Array<PaymentOption>,
//   transaction: TransactionDetails
// ) => {
//   let updatedTx = { ...transaction }
//   const minSendAmountForCurrentTx = paymentProviderList.sort(
//     (firstOperand, secondOperand) => firstOperand.totalFee - secondOperand.totalFee
//   )[0].sendAmount

//   const paymentProviderFee = transaction.amountToSend - minSendAmountForCurrentTx
//   const amountToSendWithoutFee = transaction.amountToSend - paymentProviderFee

//   updatedTx = {
//     ...updatedTx,
//     paymentProviderFee,
//     amountToSendWithoutFee,
//   }
//   return updatedTx
// }

export const paymentMethodEnumConfig = {
  [TransactionPaymentProvider.CardPayment]: PaymentMethod.CreditCard,
  [TransactionPaymentProvider.ClearJunctionBank]: PaymentMethod.BankTransfer,
  [TransactionPaymentProvider.CurrencyCloudBank]: PaymentMethod.BankTransfer,
}

const transactionStatusEnumConfig = {
  [PaymentIntentStatus.Initiated]: TransactionStatus.Ordered,
  [PaymentIntentStatus.PaymentMethodSelected]: TransactionStatus.Ordered,
  [PaymentIntentStatus.SourceFundsSent]: TransactionStatus.Transferred,
  [PaymentIntentStatus.Canceled]: TransactionStatus.Cancelled,
  [PaymentIntentStatus.UserCanceled]: TransactionStatus.Cancelled,
  [PaymentIntentStatus.Expired]: TransactionStatus.Expired,
  [PaymentIntentStatus.Completed]: TransactionStatus.Redeemed,
  [PaymentIntentStatus.SourceFundsReceived]: TransactionStatus.NotRedeemed,
  [PaymentIntentStatus.FundsCleared]: TransactionStatus.NotRedeemed,
  [PaymentIntentStatus.AvailableForPayout]: TransactionStatus.NotRedeemed,
  [PaymentIntentStatus.IncorrectSourceFundsReceived]: TransactionStatus.IncorrectPayin,
}

export const isPaymentDetailsType = (
  tx: PaymentIntentDetails | TransactionDetails
): tx is PaymentIntentDetails => {
  return (tx as PaymentIntentDetails).extId !== undefined
}

export const isShortPaymentIntentDetailsType = (
  tx: ShortPaymentIntentDetails | ShortTransactionDetails
): tx is ShortPaymentIntentDetails => {
  return (tx as ShortPaymentIntentDetails).extId !== undefined
}

export const isPastPaymentIntentsType = (
  pastTxList: PastTransactionsServerResponse | PastPaymentIntentsServerResponse
): pastTxList is PastPaymentIntentsServerResponse => {
  return (pastTxList as PastPaymentIntentsServerResponse).items[0].extId !== undefined
}

export const getModifiedPastTransactions = (
  pastTransactions: PastTransactionsServerResponse | PastPaymentIntentsServerResponse
) => {
  let modifiedTx: PastTransactionsServerResponse = {
    items: [],
    hasNextPage: pastTransactions.hasNextPage,
  }

  if (pastTransactions.items.length === 0) {
    return modifiedTx
  }

  if (isPastPaymentIntentsType(pastTransactions)) {
    modifiedTx.items = pastTransactions.items.map(pastTxItem => ({
      id: pastTxItem.extId,
      bookedDate: pastTxItem.createdDate,
      status: transactionStatusEnumConfig[pastTxItem.status],
      amountToReceive: pastTxItem.amount,
      receiveCurrency: pastTxItem.currency.id,
      recipient: pastTxItem.recipient,
      digitalReference: pastTxItem.digitalReference,
      receiveCurrencySign: '',
    }))
  } else {
    modifiedTx.items = [...pastTransactions.items]
  }

  return modifiedTx
}

export const getModifiedTransactionDetails = (tx: TransactionDetails | PaymentIntentDetails) => {
  let modifiedTxDetails: TransactionDetails | null = null

  if (isPaymentDetailsType(tx)) {
    modifiedTxDetails = {
      accountName: tx.bankDetails.accountName,
      accountNumber: tx.bankDetails.accountNumber,
      sortCode: tx.bankDetails.sortCode,
      iban: tx.bankDetails.iban,
      topUpRequestExtId: tx.extId,
      paymentReference: tx.reference,
      rate: tx.rate,
      receiveCurrency: tx.receiveCurrency.id,
      amountToReceive: tx.receiveAmount,
      srcCurrency: tx.sendCurrency.id,
      amountToSend: tx.sendAmount,
      amountToSendWithoutFee: tx.amountToSendWithoutFee,
      paymentProviderDiffFee: tx.paymentProviderDiffFee,
      fee: tx.fee,
      topUpNumber: tx.extId,
      digitalReference: tx.digitalReference,
      paymentProvider: tx.paymentProviders.cashInPaymentProvider,
      status: transactionStatusEnumConfig[tx.status],
      recipient: tx.recipient,
      sourceCountryId: tx.sourceCountryId,
      receiveCountryId: tx.receiveCountryId,
      receiveCurrencySign: '',
      srcCurrencySign: '',
    }
  } else {
    modifiedTxDetails = { ...tx }
  }

  return modifiedTxDetails
}
