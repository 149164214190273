import React, { useCallback } from 'react'
import { Icon } from '../../icons'
import NumberFormat, { NumberFormatValues } from 'react-number-format'
import { Control, FieldValues, Path } from 'react-hook-form'
import CountrySelector from './CountrySelector'
import MoneyInput from './MoneyInput'

type MoneyInputWithCountrySelectorProps = {
  selectorName: string
  selectorValue: {
    label: string
    value: number
    currency?: string
    isO2Code: string
    prefix: string
  }
  inputName: string
  inputValue: string | number
  options: Array<{
    label: string
    value: number
    currency?: string
    isO2Code: string
    prefix: string
  }>
  label: string
  decimalScale: number
  disabled: boolean
  prefix: string
  onChange: (value: any, name: string, focusCounter?: number) => void
  shouldCleanInputWhenFirstClick?: boolean
  errorVariant?: string
}

const MoneyInputWithCountrySelector = ({
  selectorName,
  selectorValue,
  inputName,
  inputValue,
  options,
  label,
  decimalScale,
  disabled,
  prefix,
  onChange,
  shouldCleanInputWhenFirstClick,
  errorVariant,
}: MoneyInputWithCountrySelectorProps) => {
  return (
    <div className="send-block-amount-wrapper">
      <label htmlFor="selector" style={{ padding: '0 16px' }}>
        {label}
      </label>
      {/* <div className={`send-block-amount`}> */}
      <div
        className={`send-block-amount${errorVariant ? ' error' : ''}`}
        style={disabled ? { background: '#F6F6F6' } : {}}
      >
        <CountrySelector
          name={selectorName}
          options={options}
          value={selectorValue}
          onChange={onChange}
          withCurrency={true}
        />
        {/* <div className="currency">
          <Icon.USAFlag />
          <strong>USD</strong>
  </div>*/}
        <MoneyInput
          name={inputName}
          prefix={prefix}
          decimalScale={decimalScale}
          disabled={disabled}
          value={inputValue}
          onChange={onChange}
          shouldCleanInputWhenFirstClick={shouldCleanInputWhenFirstClick}
        />
      </div>
      {/* {errorVariant ? (
        <span className="form-input-error">{errorText}</span>
      ) : (
        <p className="send-block-amount-text">
          {preset?.receiveAmountMultiplesOf
            ? `The amount must be a multiple of ${preset?.receiveAmountMultiplesOf}`
            : ''}
        </p>
      )} */}
    </div>
  )
}

export default MoneyInputWithCountrySelector
