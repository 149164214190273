import { useCallback, useEffect } from 'react'
import SumsubWebSdk from '@sumsub/websdk-react'
import { useNavigate } from 'react-router-dom'
import { useAppDispatch, useAppSelector } from '../../../hooks/dispatch'
import { authActions } from '../../../redux/action-creators/authActions'
import { userActions } from '../../../redux/action-creators/userActions'
import InnerHeader from '../../../components/innerHeader'
import { AmlStatus } from '../../../interfaces/user'
import { FlowState } from '../../../interfaces/preset'

const SumSubPage = () => {
  const navigate = useNavigate()
  const dispatch = useAppDispatch()

  const token = useAppSelector(state => state.auth.sumSubToken)
  const userAml = useAppSelector(state => state.user.userAml)
  const flowList = useAppSelector(state => state.preset.availableFlow)

  const getSumSubToken = useCallback(() => {
    let currentFlowType

    if (userAml?.status === AmlStatus.RejectedWithRetry) {
      currentFlowType = flowList.find(
        flow =>
          flow.minimalAmlCheckLevel &&
          flow.state === FlowState.On &&
          flow.minimalAmlCheckLevel.weight === userAml.level.weight
      )?.flowType
    } else {
      currentFlowType = flowList.find(
        flow => flow.minimalAmlCheckLevel && flow.state === FlowState.On
      )?.flowType
    }

    currentFlowType && dispatch(authActions.getSumSubTokenAction(currentFlowType))
  }, [dispatch, flowList, userAml?.level?.weight, userAml?.status])

  useEffect(() => {
    getSumSubToken()
    // dispatch(authActions.getSumSubTokenAction())
  }, [getSumSubToken])

  const goToPendingPage = async (type: any) => {
    if (type === 'idCheck.onApplicantSubmitted' || type === 'idCheck.onApplicantResubmitted') {
      await dispatch(userActions.getUserAmlList(flowList))
      navigate('/pending')
    }

    if (type === 'idCheck.applicantReviewComplete') {
      setTimeout(() => {
        dispatch(userActions.getUserAmlList(flowList))
      }, 3000)
    }
  }

  const onGoBack = () => {
    navigate('/')
  }

  return (
    <section className="h-100-mb doc-check-page">
      <div className="container container-md container-100">
        <InnerHeader title="Please confirm your identity" onGoBack={onGoBack} />
        <p className="additional-text">
          We also require you to agree to the processing of your personal data by our service
          provider, SumSub.
        </p>
        {token && (
          <SumsubWebSdk
            accessToken={token}
            expirationHandler={() => Promise.resolve(token)}
            onMessage={type => goToPendingPage(type)}
            config={{
              uiConf: {
                customCssStr:
                  ':root {\n  --black: #24E2A0;\n --grey-darker: #B2B2B2;\n  --border-color: #DBDBDB;\n --primary-color: #221772; \n --success-color: #3171D8; }\n body { font-family: Roboto, sans-serif;}\n #loader { background-color: #fff; padding: 60px 20px; } \n p {\n  \n  font-size: 16px !important;\n  line-height: 24px;\n}\n\nsection {\n  margin: 40px auto;\n}\n\ninput {\n  \n  font-weight: 600;\n  outline: none;\n}\n\nsection.content {\n  background-color: #fff;\n  color: var(--blue-color);\n  padding: 40px 40px 16px;\n  box-shadow: none;\n  border-radius: 6px;\n}\n\n .checkbox .checkmark { margin-top: 4px; } \n\nbutton.submit,\nbutton.back {\n  text-transform: capitalize;\n  border-radius: 6px;\n  height: 48px;\n  padding: 0 30px;\n  font-size: 16px;\n  background-image: none !important;\n  transform: none !important;\n  box-shadow: none !important;\n  transition: all 0.2s linear;\n}\n\nbutton.submit {\n  min-width: 132px;\n  background: none;\n  background-color: var(--primary-color);\n}\n\n.round-icon {\n  background-color: var(--primary-color) !important;\n  background-image: none !important;\n}',
              },
            }}
          />
        )}
      </div>
    </section>
  )
}

export default SumSubPage
