import { useState, useEffect } from "react";
import { Icon } from "./icons"

const CountDown = ({ countDownDate }: { countDownDate: Date }) => {
  const [startDate, setStartDate] = useState<Date | null>(null);
  const [timer, setTimer] = useState();

  useEffect(() => {
    let interval: any = null;

    if (countDownDate && +countDownDate > +new Date()) {
      interval = setInterval(() => {
        setStartDate(new Date(+countDownDate - +new Date()));
      }, 400)

      setTimer(interval);
    }

    return () => {
      if (interval) clearInterval(interval);
    }
  }, [])

  const dateFormat = (val: number) => val.toString().padStart(2, '0');

  const days = dateFormat(Math.floor(+startDate! / (1000 * 60 * 60 * 24)));
  const hours = dateFormat(Math.floor((+startDate! % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)));
  const minutes = dateFormat(Math.floor((+startDate! % (1000 * 60 * 60)) / (1000 * 60)));
  const seconds = dateFormat(Math.floor(((+startDate! % (1000 * 60)) / 1000)));

  if (+countDownDate - +new Date() < 500) {
    clearInterval(timer);

    setStartDate(null);

    setTimeout(() => window.location.reload(), 1000);
  }

  return (
    <>
      <div className='countdown-content'>
        <div className='data-wrap'>
          <div className='data-section'>
            <div className='data-block'><span className='time'>{startDate ? days : '--'}</span><span className='label'>days</span></div>
            <div className='separating'>:</div>
            <div className='data-block'><span className='time'>{startDate ? hours : '--'}</span><span className='label'>hours</span></div>
            <div className='separating'>:</div>
            <div className='data-block'><span className='time'>{startDate ? minutes : '--'}</span><span className='label'>minutes</span></div>
            <div className='separating'>:</div>
            <div className='data-block data-block-seconds'>
              <span className='time'>
                {startDate ? `${seconds}.${startDate.getMilliseconds().toString().substring(0, 1)}` : '--'}
              </span>
              <span className='label'>seconds</span>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default CountDown;
