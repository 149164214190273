import React, { useCallback, useEffect, useMemo } from 'react'
import { PuffLoader } from 'react-spinners'
import InnerHeader from '../../components/innerHeader'
import CalculationSection from '../../components/calculation/CalculationSection'
import { useAppDispatch, useAppSelector } from '../../hooks/dispatch'
import { Navigate, useNavigate, useParams } from 'react-router-dom'
import { remittanceActions } from '../../redux/action-creators/remittanceActions'
import { PaymentMethod } from '../../interfaces'
import { Icon } from '../../components/icons'

type FailedPaymentPageProps = {
  type: 'aborted' | 'failed'
}

const FailedPaymentPage = ({ type }: FailedPaymentPageProps) => {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const { id } = useParams()

  const { transaction: tx, getTransactionForPaymentLoadState } = useAppSelector(
    state => state.remittance
  )

  const isShownBeneficiaryAccount = useMemo(() => {
    return tx.withdrawalMethod === PaymentMethod.BankTransfer
  }, [tx.withdrawalMethod])

  const content = useMemo(() => {
    if (type === 'aborted') {
      return {
        title: 'Transaction Aborted',
        text: `Payment aborted. Contact your card issuer. Possible reasons: an error from the credit card bank, not enough funds, or any other.`,
      }
    } else {
      return {
        title: 'Transaction Failed',
        text: 'Payment failed. Contact your card issuer.',
      }
    }
  }, [type])

  useEffect(() => {
    if (id) {
      dispatch(remittanceActions.getTransaction(id, navigate))
    }
  }, [dispatch, id, navigate])

  const onGoBack = useCallback(() => {
    navigate('/past-transactions')
  }, [navigate])

  const onTryAgainClick = useCallback(() => {
    navigate(`/transaction/${id}/payment-details`)
  }, [id, navigate])

  if (!id) {
    return <Navigate to="/past-transactions" />
  }

  return (
    <section className="h-100-mb">
      {getTransactionForPaymentLoadState.isLoading && (
        <div className="spinner spinner-fixed">
          <PuffLoader size={70} color="#3171d8" />
        </div>
      )}

      <div className="container container-md container-100">
        <div>
          <>
            <InnerHeader title={content.title} onGoBack={onGoBack} />
            <CalculationSection
              mode="calculation-block-with-recipient"
              receiveAmount={tx.amountToReceive}
              recipient={tx.recipient}
              isShownBeneficiaryAccount={isShownBeneficiaryAccount}
              currency={tx.receiveCurrency}
              currencySign={tx.receiveCurrencySign}
            />
            <div className="price-info">
              <div className="failed-payment-section">
                <Icon.PaymentFailed />
                <p>{content.text}</p>
              </div>
            </div>
            <div className="btn-wrapper mb-10 mt-30 justify-end">
              {/* <button type="button" className="btn btn-cancel btn-50" onClick={onGoBack}>
                Cancel
              </button> */}
              <button onClick={onTryAgainClick} className="btn btn-next btn-50">
                Try Again
              </button>
            </div>
          </>
        </div>
      </div>
    </section>
  )
}

export default FailedPaymentPage
