import { Dispatch, SetStateAction, useCallback, useEffect, useRef, useState } from 'react'
import { useAppSelector } from '../../hooks/dispatch'

type TimerProps = {
  text: string
  setIsDisabledButton?: Dispatch<SetStateAction<boolean>>
}

const Timer = ({ text, setIsDisabledButton }: TimerProps) => {
  const emailResendTimer = useAppSelector(state => state.user.emailResendTimer)

  const initialTimer =
    Number(localStorage.getItem('emailResendTimer')) > Math.round(Date.now() / 1000)
      ? Number(localStorage.getItem('emailResendTimer')) - Math.round(Date.now() / 1000)
      : 0

  const [timer, setTimer] = useState<number>(initialTimer)

  const timeoutId: { current: NodeJS.Timeout | undefined } = useRef(undefined)

  useEffect(() => {
    const remainingTime = emailResendTimer - Math.round(Date.now() / 1000)

    if (remainingTime > 0) {
      setTimer(remainingTime)
    }
  }, [emailResendTimer])

  useEffect(() => {
    if (timer > 0) {
      setIsDisabledButton && setIsDisabledButton(true)
    }
  }, [setIsDisabledButton, timer])

  const countTimer = useCallback(() => {
    if (timer <= 0) {
      setIsDisabledButton && setIsDisabledButton(false)
      localStorage.removeItem('emailResendTimer')
    } else {
      setTimer(timer - 1)
    }
  }, [setIsDisabledButton, timer])

  useEffect(() => {
    timeoutId.current = setTimeout(countTimer, 1000)

    return () => {
      clearTimeout(timeoutId.current)

      if (timer > 0) {
        localStorage.setItem('emailResendTimer', String(Math.round(Date.now() / 1000) + timer))
      }
    }
  }, [timer, countTimer])

  return <>{timer > 0 && <div className="timer">{timer > 0 && `${text} ${timer} sec.`}</div>}</>
}

export default Timer
