import {
  AgentsLocationItemResponse,
  PaymentOption,
  SetPaymentProviderServerRequest,
  ShortWithdrawalPaymentProvider,
  WithdrawalPaymentOptionListByCountry,
  WithdrawalPaymentProvider,
} from '../interfaces'
import { CompanyBeneficiaryFormType, KWLRecipient } from '../interfaces/beneficiary'
import { PostCalculateServerRequest } from '../interfaces/calculation'
import {
  InitializeCardServerResponse,
  InitializeByNewCardServerRequest,
  AuthenticateCardServerRequest,
  AuthenticateCardServerResponse,
  VerifyCardPaymentServerRequest,
  VerifyCardPaymentServerResponse,
  SavedCard,
  InitializeBySavedCardServerRequest,
  InitializeByNewCardForPaymentIntentServerRequest,
  InitializeBySavedCardForPaymentIntentServerRequest,
} from '../interfaces/cardPayment'
import {
  CreatePaymentIntentServerRequest,
  CreateTransactionBySendAgainServerRequest,
  CreateTransactionServerRequest,
  PastPaymentIntentsServerResponse,
  PastTransactionsServerResponse,
  PaymentIntentDetails,
  TransactionDetails,
  TransactionsNumber,
} from '../interfaces/remittanceType'
import { anonimRequests } from '../utils/anonimusRequest'
import { authorizeRequests } from '../utils/authorizeRequest'

/* 
TO DO: Remove conditions, requests and paymentIntentSwitcher variable 
after full integration of payment intents
*/

const paymentIntentSwitcher = process.env.REACT_APP_PAYMENT_INTENT_SWITCHER

function getPastTransactions(offset: number, step: number, q?: string) {
  //NEW - Payment Intent
  const path = ['payment-intents']
  const isCancelledMode = true
  const query = {
    offset,
    step,
    ...(q && { q }),
    // ...(q && { query: q }),
  }

  //OLD - TopUp
  if (!paymentIntentSwitcher || paymentIntentSwitcher === 'false') {
    const path = ['top-ups']

    return authorizeRequests.authorizeRequest<null, PastTransactionsServerResponse>(
      path,
      'GET',
      null,
      query,
      isCancelledMode
    )
  }

  //NEW - Payment Intent
  return authorizeRequests.authorizeRequest<null, PastPaymentIntentsServerResponse>(
    path,
    'GET',
    null,
    query,
    isCancelledMode
  )
}

function getTransaction(transactionId: string) {
  if (paymentIntentSwitcher === 'true') {
    //NEW - Payment Intent
    const path = ['payment-intents', transactionId]

    return authorizeRequests.authorizeRequest<string, PaymentIntentDetails>(path)
  } else {
    //OLD - TopUp
    const path = ['top-ups', transactionId]

    return authorizeRequests.authorizeRequest<string, TransactionDetails>(path)
  }
}

function createTransaction(body: CreateTransactionServerRequest) {
  if (paymentIntentSwitcher === 'true') {
    //NEW - Payment Intent
    const path = ['payment-intents']

    const newBody = {
      sendCurrency: body.sendCurrency,
      receiveCurrency: body.receiveCurrency,
      receiveAmount: body.receiveAmount,
      beneficiaryId: body.beneficiaryId,
      transferDetails: { ...body.transactionDetails },
    }

    return authorizeRequests.postAuthorize<CreatePaymentIntentServerRequest, PaymentIntentDetails>(
      path,
      newBody
    )
  } else {
    //OLD - TopUp
    const path = ['top-ups']

    return authorizeRequests.postAuthorize<CreateTransactionServerRequest, TransactionDetails>(
      path,
      body
    )
  }
}

//NEW - Payment Intent
function createTransactionBySendAgain(body: CreateTransactionBySendAgainServerRequest) {
  const path = ['payment-intents']

  return authorizeRequests.postAuthorize<
    CreateTransactionBySendAgainServerRequest,
    PaymentIntentDetails
  >(path, body)
}

function setPaymentProvider(topUpId: string, body: SetPaymentProviderServerRequest) {
  //NEW - Payment Intent
  let path = ['payment-intents', topUpId, 'payment']

  //OLD - TopUp
  if (!paymentIntentSwitcher || paymentIntentSwitcher === 'false') {
    path = ['top-ups', topUpId, 'payment']
  }

  return authorizeRequests.putAuthorize<SetPaymentProviderServerRequest, null>(path, body)
}

function updateRecipientInTopUp(recipient: KWLRecipient | Omit<CompanyBeneficiaryFormType, "countryId">, topUpId: string) {
  //NEW - Payment Intent
  let path = ['payment-intents', topUpId, 'recipient']

  if (!paymentIntentSwitcher || paymentIntentSwitcher === 'false') {
    //OLD - TopUp
    path = ['top-ups', topUpId, 'recipient']

    return authorizeRequests.putAuthorize<KWLRecipient | Omit<CompanyBeneficiaryFormType, "countryId">, TransactionDetails>(path, recipient)
  }

  return authorizeRequests.putAuthorize<KWLRecipient | Omit<CompanyBeneficiaryFormType, "countryId">, TransactionDetails>(path, recipient)
}

function transfer(id: string) {
  const path = ['payment-intents', id, 'funds-sent']

  if (!paymentIntentSwitcher || paymentIntentSwitcher === 'false') {
    //OLD - TopUp
    return authorizeRequests.postAuthorize<{ id: string }, null>(['transfer'], { id })
  }

  return authorizeRequests.postAuthorize<null, null>(path)
}

function cancelTransaction(id: string) {
  //NEW - Payment Intent
  const path = ['payment-intents', id, 'cancel']

  if (!paymentIntentSwitcher || paymentIntentSwitcher === 'false') {
    //OLD - TopUp
    return authorizeRequests.postAuthorize<{ id: string }, null | string>(['top-ups', 'cancel'], {
      id,
    })
  }

  return authorizeRequests.putAuthorize<null, null>(path)
}

function getNotRedeemedTransactionsNumber() {
  //NEW - Payment Intent
  let path = ['payment-intents', 'info']

  //OLD - TopUp
  if (!paymentIntentSwitcher || paymentIntentSwitcher === 'false') {
    path = ['top-ups', 'info']

    return authorizeRequests.authorizeRequest<null, TransactionsNumber>(path)
  }

  return authorizeRequests.authorizeRequest<null, TransactionsNumber>(path)
}

function initializeByNewCard(body: InitializeByNewCardServerRequest) {
  const path = ['world-pay', 'payments', 'cards', 'new']

  //NEW - Payment Intent
  if (paymentIntentSwitcher === 'true') {
    const newBody = {
      cardHolderName: body.cardHolderName,
      sessionHref: body.sessionHref,
      saveCard: body.saveCard,
      paymentIntent: {
        extId: body.topUp.id,
      },
    }

    return authorizeRequests.postAuthorize<
      InitializeByNewCardForPaymentIntentServerRequest,
      InitializeCardServerResponse
    >(path, newBody)
  }

  //OLD - TopUp
  return authorizeRequests.postAuthorize<
    InitializeByNewCardServerRequest,
    InitializeCardServerResponse
  >(path, body)
}

function initializeBySavedCard(body: InitializeBySavedCardServerRequest) {
  const path = ['world-pay', 'payments', 'cards', 'saved']

  //NEW - Payment Intent
  if (paymentIntentSwitcher === 'true') {
    const newBody = {
      savedCardId: body.savedCardId,
      paymentIntent: {
        extId: body.topUp.id,
      },
    }

    return authorizeRequests.postAuthorize<
      InitializeBySavedCardForPaymentIntentServerRequest,
      InitializeCardServerResponse
    >(path, newBody)
  }

  //OLD - TopUp
  return authorizeRequests.postAuthorize<
    InitializeBySavedCardServerRequest,
    InitializeCardServerResponse
  >(path, body)
}

function authenticateCard(body: AuthenticateCardServerRequest) {
  const path = ['world-pay', 'payments', 'cards', 'authentication']

  return authorizeRequests.postAuthorize<
    AuthenticateCardServerRequest,
    AuthenticateCardServerResponse
  >(path, body)
}

function verifyCardPayment(body: VerifyCardPaymentServerRequest) {
  const path = ['world-pay', 'payments', 'cards', '3ds-verification']

  return authorizeRequests.postAuthorize<
    VerifyCardPaymentServerRequest,
    VerifyCardPaymentServerResponse
  >(path, body)
}

function getSavedCards() {
  const path = ['world-pay', 'saved-cards']

  return authorizeRequests.authorizeRequest<null, SavedCard[]>(path)
}

function deleteSavedCard(extId: string) {
  const path = ['world-pay', 'saved-cards']
  const body = {
    extId,
  }

  return authorizeRequests.deleteAuthorize<{ extId: string }, null>(path, body)
}

function getAgentsLocation() {
  const path = ['agent', 'locations']

  return anonimRequests.anonimusRequest<null, Array<AgentsLocationItemResponse>>(path)
}

function getPaymentProvider(body: PostCalculateServerRequest) {
  const path = ['instance-currencies', 'payment-options']

  return authorizeRequests.postAuthorize<PostCalculateServerRequest, Array<PaymentOption>>(
    path,
    body
  )
}

function getWithdrawalPaymentProviderList(currency: string) {
  const path = ['instance-currencies', 'withdrawal']
  const query = {
    currency
  }

  return anonimRequests.anonimusRequest<null, Array<WithdrawalPaymentProvider>>(path, 'GET', null, query)
}

function updateWithdrawalPaymentProvider(id: string, body: ShortWithdrawalPaymentProvider) {
  const path = ['top-ups', id, 'withdrawal']

  return authorizeRequests.putAuthorize<ShortWithdrawalPaymentProvider, TransactionDetails>(path, body)
}

function getWithdrawalPaymentOptionsByCountry() {
  const path = ['countries', 'receive', 'payment-options']


  return anonimRequests.anonimusRequest<null, Array<WithdrawalPaymentOptionListByCountry>>(path)
}

function createPaymentOrder(extId: string) {
  const path = ['thunes']

  const body = {
    extId,
  }

  return authorizeRequests.postAuthorize<{ extId: string }, { paymentPageUrl: string }>(path, body)
}

function getCashInCountryList() {
  const path = ['countries', 'source', 'payment-options']

  return anonimRequests.anonimusRequest<null, Array<WithdrawalPaymentOptionListByCountry>>(path)
}

export const remittanceService = {
  cancelTransaction,
  getPastTransactions,
  createTransaction,
  createTransactionBySendAgain,
  getTransaction,
  transfer,
  updateRecipientInTopUp,
  getNotRedeemedTransactionsNumber,
  initializeByNewCard,
  initializeBySavedCard,
  authenticateCard,
  verifyCardPayment,
  getSavedCards,
  deleteSavedCard,
  getAgentsLocation,
  getPaymentProvider,
  setPaymentProvider,
  getWithdrawalPaymentProviderList,
  updateWithdrawalPaymentProvider,

  getWithdrawalPaymentOptionsByCountry,
  createPaymentOrder,
  getCashInCountryList,
}
