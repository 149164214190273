import { useMemo } from 'react'

type BuildVersionProps = {
  className: string
}

const environment = process.env.REACT_APP_ENVIRONMENT || ''
const buildCommit = process.env.REACT_APP_BUILD_COMMIT
const buildVersion = process.env.REACT_APP_BUILD_VERSION

const BuildVersion = ({ className }: BuildVersionProps) => {
  const shownBuildVersion = useMemo(() => {
    if (environment.toLowerCase() === 'dev') {
      return `latest-vnext: ${buildCommit}`
    }
    if (environment.toLowerCase() === 'production' || environment.toLowerCase() === 'uat') {
      return `v.${buildVersion}`
    }
    return 'test'
  }, [])

  return <span className={className}>{shownBuildVersion}</span>
}

export default BuildVersion
