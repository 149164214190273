import { Icon } from '../../components/icons'
import { TransactionStatus } from '../../interfaces/remittanceType'

export const transactionStatusConfig = [
  {
    type: TransactionStatus.Ordered,
    label: 'Ordered',
    className: 'text-grey',
    icon: <Icon.SmallGreyCircle />,
  },
  {
    type: TransactionStatus.PaymentInProgress,
    label: 'Payment in progress',
    className: 'text-grey',
    icon: <Icon.SmallGreyCircle />,
  },
  {
    type: TransactionStatus.Transferred,
    label: 'Transferred',
    className: 'text-grey',
    icon: <Icon.SmallGreyCircle />,
  },
  {
    type: TransactionStatus.Cancelled,
    label: 'Cancelled',
    className: 'text-red',
    icon: <Icon.RedCircle />,
  },
  {
    type: TransactionStatus.Expired,
    label: 'Expired',
    className: 'text-green',
    icon: <Icon.SmallGreyCircle />,
  },
  {
    type: TransactionStatus.Redeemed,
    label: 'Redeemed',
    className: 'text-green',
    icon: <Icon.SuccessTick />,
  },
  {
    type: TransactionStatus.NotRedeemed,
    label: 'Not Redeemed',
    className: 'text-blue',
    icon: <Icon.SmallBlueCircle />,
  },
  {
    type: TransactionStatus.IncorrectPayin,
    label: 'Incorrect payment',
    className: 'text-red',
    icon: <Icon.SmallRedCircle />,
  },
  {
    type: TransactionStatus.TransferPending,
    label: 'Transfer Pending',
    className: 'text-red',
    icon: <Icon.RedCircle />,
  },
]
