import { Dispatch, SetStateAction } from 'react'
import { Icon } from '../icons'
import Timer from './Timer'

type CircleLoadingProps = {
  icon: boolean
  timer?: boolean
  loadingText?: string
  setIsDisabledButton?: Dispatch<SetStateAction<boolean>>
}

const CircleLoading = ({ loadingText, icon, setIsDisabledButton, timer }: CircleLoadingProps) => {
  return (
    <div className="confirmation-page">
      {loadingText && <div className="loading-text">{loadingText}</div>}
      <div className="pending-circle">
        <div className="loader"></div>
      </div>
      {timer && (
        <Timer
          text="Send email again in:"
          {...(setIsDisabledButton && { setIsDisabledButton: setIsDisabledButton })}
        />
      )}
      {icon && (
        <div className="btn-wrapper justify-between">
          <Icon.Rocket />
          <span className="text-blue">Verification in progress</span>
        </div>
      )}
    </div>
  )
}

export default CircleLoading
