import React, { useCallback, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import AgentsLocationList from '../../components/agentsLocation/AgentsLocationList'
import InnerHeader from '../../components/innerHeader'
import { useAppDispatch, useAppSelector } from '../../hooks/dispatch'
import { remittanceActions } from '../../redux/action-creators/remittanceActions'

const AgentsLocationPage = () => {
  const navigate = useNavigate()
  const dispatch = useAppDispatch()

  const { isLoading, isLoaded, agentsLocationList } = useAppSelector(state => state.remittance)

  useEffect(() => {
    dispatch(remittanceActions.getAgentsLocation())
  }, [dispatch])

  const onGoBack = useCallback(() => {
    navigate(-1)
  }, [navigate])


  return (
    <>
      {!isLoading && isLoaded && (
        <section className="agents-location-page">
          <div className="container container-xxl">
            <div>
              <InnerHeader title="Agent Locations" onGoBack={onGoBack} />
            </div>
            <AgentsLocationList agentsLocationList={agentsLocationList} />
          </div>
        </section>
      )}
    </>
  )
}

export default AgentsLocationPage
