import React, { useCallback } from 'react'
import Select, { MenuListProps, SingleValue } from 'react-select'
import { components, ControlProps, OnChangeValue, OptionProps } from 'react-select'
import { customSelectorStyles } from '../../../helpers/customSelectorStyle'
import * as Flags from 'country-flag-icons/react/3x2'

type CountrySelectorProps = {
  options: Array<{
    label: string
    value: number
    currency?: string
    isO2Code: string
    prefix: string
  }>
  value: {
    label: string
    value: number
    currency?: string
    isO2Code: string
    prefix: string
  }
  name: string
  onChange: (value: any, name: string) => void
  withCurrency: boolean
}

const CustomIndicator = (props: any) => {
  return (
    <div
      style={{
        backgroundColor: '#D3E6FF',
        borderRadius: '100%',
        width: 24,
        height: 24,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <components.IndicatorsContainer {...props} />
    </div>
  )
}

const CustomControl = (
  props: ControlProps<{
    label: string
    value: number
    currency?: string
    isO2Code: string
    prefix: string
  }>
) => {
  const { isO2Code } =
    (props.selectProps.value as {
      label: string
      value: number
      currency?: string
      isO2Code: string
    }) || ''
  const FlagComponent = Flags[`${isO2Code}`]


  return (
    <components.Control {...props}>
      <div style={{ width: '28px', marginRight: '5px' }}>{isO2Code && <FlagComponent />}</div>
      {props.children}
      {/* <CustomIndicator/> */}
    </components.Control>
  )
}

const CustomOption = (
  props: OptionProps<{
    label: string
    value: number
    currency?: string
    isO2Code: string
    prefix: string
  }>
) => {
  const { label } = props

  const FlagComponent = Flags[`${props.data.isO2Code}`]

  return (
    <>
    {/* <components.Input/> */}
    <components.Option {...props}>
      <div style={{ width: '28px', marginRight: '5px' }}>
        <FlagComponent />
      </div>
      <p style={{textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap', width: '100%'}} title={label}>{label}</p>
      {props.data.currency ? <p className='currency-code'>{props.data.currency}</p> : null}
    </components.Option>
    </>
    
  )
}

const CountrySelector = ({
  onChange,
  value,
  name,
  options,
  withCurrency,
}: CountrySelectorProps) => {
  const onSelectorChange = useCallback(
    (
      value: SingleValue<{
        label: string
        value: number
        currency?: string
        isO2Code: string
        prefix: string
      }>
    ) => {
      onChange(value, name)
    },
    [name, onChange]
  )

  return (
    <Select
      isSearchable={false}
      // styles={customSelectorStyles(withCurrency)}
      styles={customSelectorStyles}
      components={{
        Option: CustomOption,
        Control: CustomControl,
        IndicatorsContainer: CustomIndicator,
      }}
      isMulti={false}
      options={options}
      value={options.find(option => option.value === value.value)}
      onChange={onSelectorChange}
      // menuIsOpen
    />
  )
}

export default CountrySelector
