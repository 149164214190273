import { CalculationActions, CalculationState } from '../../interfaces/redux-types/calculation'
import { CalculationLimitPeriod } from '../../interfaces/user'
import { calculationConstants } from '../constants/calculationConstants'
import { userConstants } from '../constants/userConstants'

export const initialState: CalculationState = {
  isLoading: false,
  rate: 0,
  amountToSend: 0,
  amountToReceive: 0,
  fee: 0,
  totalAmountToSend: 0,
  minAmountToReceive: 0,
  maxAmountToReceive: 0,
  error: '',
  isLoaded: false,
  withdrawalFeeSrcCurr: null,
  paymentProviderLimits: [],
}

export const calculationReducer = (
  state = initialState,
  action: CalculationActions
): CalculationState => {
  switch (action?.type) {
    case calculationConstants.POST_CALCULATE_REQUEST:
      return {
        ...state,
        isLoading: true,
        isLoaded: false,
        totalAmountToSend: 0,
        withdrawalFeeSrcCurr: null,
      }
    case calculationConstants.POST_CALCULATE_SUCCESS:
      return {
        ...state,
        isLoaded: true,
        isLoading: false,
        rate: action.payload.rate,
        amountToSend: action.payload.amountToSend,
        amountToReceive: action.payload.amountToReceive,
        fee: action.payload.fee,
        totalAmountToSend: action.payload.totalAmountToSend,
        minAmountToReceive:
          action.payload.limits.find(
            limit => limit.period === CalculationLimitPeriod.MinPerTransaction
          )?.totalLimit || 0,
        maxAmountToReceive:
          action.payload.limits.find(
            limit => limit.period === CalculationLimitPeriod.MaxPerTransaction
          )?.totalLimit || 0,
        paymentProviderLimits: action.payload.paymentProviderLimits,
        withdrawalFeeSrcCurr: action.payload.withdrawalFeeSrcCurr,
      }
    case calculationConstants.POST_CALCULATE_FAILURE:
      return {
        ...state,
        isLoaded: false,
        isLoading: false,
        error: action.payload,
      }
    case userConstants.GET_USER_CALCULATION_LIMITS_SUCCESS:
      return {
        ...state,
        minAmountToReceive:
          action.payload.find(limit => limit.period === CalculationLimitPeriod.MinPerTransaction)
            ?.totalLimit || 0,
        maxAmountToReceive:
          action.payload.find(limit => limit.period === CalculationLimitPeriod.MaxPerTransaction)
            ?.totalLimit || 0,
      }
    case calculationConstants.CLEAR_CALCULATION_DATA_FROM_STATE:
      return {
        ...state,
        totalAmountToSend: 0,
      }
    default:
      return state
  }
}
