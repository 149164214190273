import * as yup from 'yup'
import { SourceOfFunds } from '../../interfaces/remittanceType'

const transactionDetailsScheme = yup.object({
  sourceOfFunds: yup
    .string()
    .typeError('Please, select source of funds')
    .required('Please, select source of funds'),
  sourceOfFundsDetails: yup
    .string()
    .typeError('')
    .nullable()
    .when('sourceOfFunds', (sourceOfFunds, field) =>
      sourceOfFunds === String(SourceOfFunds.Other)
        ? field
            .trim()
            .min(5, 'Please enter a complete source of funds.')
            .matches(
              /^[\s]*([a-zA-Z]{1,}[\s]{0,1}[,.]{0,1}[\s]{0,1})+[a-zA-Z.]*[\s]*$/,
              'Please, use only letters'
            )
            .required('Please, enter source of funds')
        : field
    ),
  reasonTransfer: yup
    .string()
    .typeError('Please, select reason for transfer')
    .required('Please, select reason for transfer'),
  reasonTransferDetails: yup
    .string()
    .typeError('')
    .nullable()
    .when('reasonTransfer', (reasonTransfer, field) =>
      reasonTransfer === String(SourceOfFunds.Other)
        ? field
            .trim()
            .min(2, 'Please enter a complete reason for transfer.')
            .matches(
              /^[\s]*([a-zA-Z]{1,}[\s]{0,1}[,.]{0,1}[\s]{0,1})+[a-zA-Z.]*[\s]*$/,
              'Please, use only letters'
            )
            .required('Please, enter reason for transfer')
        : field
    ),
})

const cardScheme = yup.object({
  // cardExpiryDate: yup.string().typeError('Required field'),
  // cvc: yup.string().typeError('Required field'),
  // cardNumber: yup
  //   .string()
  //   .typeError('Please, enter valid card number')
  //   .required('Please, enter valid card number')
  //   .test('validateCardNumber', 'Please, enter a valid card number', cardName => {
  //     const trimmedValue = cardName ? cardName.replace(/\s/g, '') : ''
  //     const masterCardPattern = /^(5[1-5]|2[2-7])\d*$/
  //     const visaPattern = /^(?!^493698\d*$)4\d*$/
  //     const maestroPattern =
  //       /^(493698|(50[0-5][0-9]{2}|506[0-5][0-9]|5066[0-9])|(5067[7-9]|506[89][0-9]|50[78][0-9]{2})|5[6-9]|63|67)\d*$/

  //     if (masterCardPattern.test(trimmedValue) && trimmedValue.length === 16) {
  //       return true
  //     }
  //     if (
  //       visaPattern.test(trimmedValue) &&
  //       (trimmedValue.length === 13 ||
  //         trimmedValue.length === 16 ||
  //         trimmedValue.length === 18 ||
  //         trimmedValue.length === 19)
  //     ) {
  //       return true
  //     }
  //     if (
  //       maestroPattern.test(trimmedValue) &&
  //       trimmedValue.length >= 12 &&
  //       trimmedValue.length <= 19
  //     ) {
  //       return true
  //     }

  //     return false
  //   }),
  cardHolderName: yup
    .string()
    .typeError('Please, enter valid card holder name')
    .matches(
      /^[\s]*([a-zA-Z0-9]{1,}[\s]{0,1}[-.`‘’']{0,1}[\s]{0,1})+[a-zA-Z0-9.]*[\s]*$/,
      'Please, use only letters'
    )
    .required('Please, enter valid card holder name'),

  // cardExpiryDate: yup
  //   .string()
  //   .typeError('Please, enter valid expiry date')
  //   .required('Please, enter valid expiry date'),
  // cvc: yup.string().typeError('Add CVV to continue').required('Add CVV to continue'),
})

export const transactionYupSchemes = {
  transactionDetailsScheme,
  cardScheme,
}
