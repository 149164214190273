export type UserDetails = {
  firstName?: string
  lastName?: string
  isEmailConfirmed?: boolean
  fullName?: string
  email?: string
  phoneNumber?: string
  status?: UserStatus
  dateOfBirth?: string
  countryId?: number
  city?: string
  street?: string
  postcode?: string
  isSubscribedToNewsAndUpdates?: boolean
}

export type UpdateUserSubscriptionServerRequest = {
  enabled: boolean
}

export enum UserStatus {
  Pending = 10,
  Accepted = 20,
  Rejected = 30,
  Blocked = 40,
  Deleted = 50,
  // Deprecated
  //Countdown = 60,
  HoldOn = 70,
  NotVerified = 80,
  InitiatedVerification = 90,
  PendingVerification = 100,
  FailedVerification = 110,
  FailedVerificationWithRetry = 120,
  Guest = 130,
}

export type ChangeEmailRequest = {
  email: string
}

export type UserDetailsForm = {
  firstName: string
  lastName: string
  dateOfBirth: string
  email: string
}

export type UserAddressForm = {
  countryId: number
  city: string
  street: string
  postcode: string
}

export enum CalculationLimitPeriod {
  Daily,
  Monthly,
  MaxPerTransaction,
  MinPerTransaction,
}

export type CalculationLimit = {
  period: CalculationLimitPeriod
  remainingAmount: number
  totalLimit: number
  limitPass: boolean
}

export enum AmlStatus {
  Unknown = 0,
  Initiated = 10,
  Pending = 15,
  Verified = 20,
  Rejected = 30,
  RejectedWithRetry = 40,
}

export type AmlDetail = {
  status: AmlStatus
  createdDate: string
  expirationDate: string
  level: { weight: number }
}
