function scriptAlreadyLoaded(src) {
  return document.querySelector(`script[src="${src}"]`)
}

export function loadCheckoutScript(src) {
  return new Promise((resolve, reject) => {
    if (scriptAlreadyLoaded(src)) {
      resolve()
      return
    }

    let checkoutScript = document.createElement('script')
    checkoutScript.src = src
    checkoutScript.onload = resolve
    checkoutScript.onerror = reject
    document.head.appendChild(checkoutScript)
  })
}

export function addWorldpayCheckoutToPage() {
  return new Promise((resolve, reject) => {
    ;(function () {
      window.Worldpay.checkout.init(
        {
          id: process.env.REACT_APP_WP_INITIALIZE_ID,
          form: '#new-card-form',
          fields: {
            pan: {
              selector: '#card-pan',
              placeholder: 'Card Number 0000-0000-0000-0000',
            },
            expiry: {
              selector: '#card-expiry',
            },
            cvv: {
              selector: '#card-cvv',
            },
          },
          styles: {
            input: {
              color: '#3171D8',
              'font-size': '18px',
            },
            'input#pan': {
              'font-size': '18px',
            },
            'input.is-valid': {
              color: '#3171D8',
            },
            'input.is-invalid': {
              color: '#3171D8',
            },
            'input.is-onfocus': {
              color: '#3171D8',
            },
          },
          enablePanFormatting: true,
          acceptedCardBrands: ['maestro', 'visa', 'mastercard', 'amex'],
        },
        function (error, checkout) {
          if (error) {
            reject(error)
          } else {
            resolve(checkout)
          }
        }
      )
    })()
  })
}
