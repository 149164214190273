import * as yup from 'yup'
import { checkForDateExistence } from '../utils'

const userDetailsScheme = yup.object({
  firstName: yup
    .string()
    .typeError('Please, enter first name')
    .matches(
      /^[\s]*([a-zA-Z]{1,}[\s]{0,1}[-.`‘’']{0,1}[\s]{0,1})+[a-zA-Z.]*[\s]*$/,
      'Please, use only letters'
    )
    .required('Please, enter first name'),
  lastName: yup
    .string()
    .typeError('Please, enter last name')
    .matches(
      /^[\s]*([a-zA-Z]{1,}[\s]{0,1}[-.`‘’']{0,1}[\s]{0,1})+[a-zA-Z.]*[\s]*$/,
      'Please, use only letters'
    )
    .required('Please, enter last name'),
  dateOfBirth: yup
    .string()
    .typeError('Please, enter date of birth')
    .test('validateDate', 'Please, enter a valid date of birth', checkForDateExistence)
    .required('Please, enter date of birth'),
  email: yup
    .string()
    .typeError('Please, enter email')
    .matches(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
      'Email Invalid'
    )
    .required('Please, enter email'),
})

const userAddressScheme = yup.object({
  city: yup
    .string()
    .typeError('Please, enter city')
    .matches(
      /^[\s]*([a-zA-Z]{1,}[\s]{0,1}[-]{0,1}[\s]{0,1}[a-zA-Z]{1,})+[\s]*$/,
      'Please, use only letters'
    )
    .required('Please, enter city'),
  street: yup
    .string()
    .typeError('Please, enter street')
    .matches(
      /^[\s]*([a-zA-Z0-9]{1,}[\s]{0,1}[-,./]{0,2}[\s]{0,1})+[\s]*$/,
      "Please, don't use special characters"
    )
    .required('Please, enter street'),
  countryId: yup.string().typeError('Please, select country').required('Please, select country'),
})

export const userYupSchemes = {
  userDetailsScheme,
  userAddressScheme,
}
