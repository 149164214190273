import { useCallback, useEffect, useMemo } from 'react'
import { authHelper } from '../helpers/authData'
import { setAttributes } from '../helpers/utils'
import { useAppDispatch, useAppSelector } from '../hooks/dispatch'
import { GetAvailableFlowResponse, GetPresetResponse } from '../interfaces/preset'
import { presetActions } from '../redux/action-creators/presetActions'
import { userActions } from '../redux/action-creators/userActions'
import RouteList from './RouteList'

const AppRouter = () => {
  const dispatch = useAppDispatch()
  const { preset, availableFlow } = useAppSelector(state => state.preset)
  const token = useMemo(() => authHelper.getAuthData(), [])
  const userDetails = useAppSelector(state => state.user.userDetails)
  const flowList = useAppSelector(state => state.preset.availableFlow)

  useEffect(() => {
    if (
      window.location.hostname === 'kwl.newlinetechno.net' ||
      window.location.hostname === 'expat.kuvacash.com'
    ) {
      const root = document.getElementById('root')

      if (root) {
        root.classList.add('bg-image')
      }
    }
  }, [])

  const setUpPreset = useCallback(async (preset: GetPresetResponse) => {
    const linkIcon = document.createElement('link')
    const linkXsIcon = document.createElement('link')
    const linkSmIcon = document.createElement('link')
    const linkAppleIcon = document.createElement('link')
    const linkLgIcon = document.createElement('link')
    const linkMsApp = document.createElement('link')
    const metaDescription = document.createElement('meta')
    const metaOgTitle = document.createElement('meta')
    const metaOgUrl = document.createElement('meta')
    const metaOgImage = document.createElement('meta')
    const metaOgDescription = document.createElement('meta')
    const title = document.createElement('title')

    setAttributes(linkIcon, { rel: 'icon', href: preset?.faviconSm })
    setAttributes(linkXsIcon, { rel: 'icon', href: preset?.faviconXs, sizes: '16x16' })
    setAttributes(linkSmIcon, { rel: 'icon', href: preset?.faviconSm, sizes: '32x32' })
    setAttributes(linkAppleIcon, { rel: 'apple-touch-icon', href: preset?.faviconMd })
    setAttributes(linkLgIcon, { rel: 'icon', href: preset?.faviconLg, sizes: '150x150' })
    setAttributes(linkMsApp, { name: 'msapplication-TileImage', content: preset?.faviconSm })
    setAttributes(metaDescription, { name: 'description', content: preset?.metaDescription })
    setAttributes(metaOgTitle, { property: 'og:title', content: preset?.title })
    setAttributes(metaOgUrl, {
      property: 'og:url',
      content: `https://${window.location.hostname}/`,
    })
    setAttributes(metaOgImage, { property: 'og:image', content: preset?.faviconMd })
    setAttributes(metaOgDescription, {
      property: 'og:description',
      content: preset?.metaDescription,
    })
    title.innerHTML = preset?.title || ''

    document.head.append(
      linkIcon,
      linkXsIcon,
      linkSmIcon,
      linkAppleIcon,
      linkLgIcon,
      linkMsApp,
      metaDescription,
      metaOgTitle,
      metaOgUrl,
      metaOgImage,
      metaOgDescription,
      title
    )
  }, [])

  const getPreset = useCallback(async () => {
    const res = await dispatch(presetActions.getPreset())

    setUpPreset(res as GetPresetResponse)
  }, [dispatch, setUpPreset])

  const getAvailableFlows = useCallback(async () => {
    await dispatch(presetActions.getAvailableFlow())
  }, [dispatch])

  const getUserDetails = useCallback(() => {
    dispatch(userActions.getUserDetails())
  }, [dispatch])

  const getUserAmlList = useCallback(
    (flowList: GetAvailableFlowResponse) => {
      dispatch(userActions.getUserAmlList(flowList))
    },
    [dispatch]
  )

  useEffect(() => {
    if (!preset) {
      getPreset()
    }
  }, [getPreset, preset])

  useEffect(() => {
    if (availableFlow.length === 0) {
      getAvailableFlows()
    }
  }, [availableFlow.length, getAvailableFlows])

  useEffect(() => {
    if (token && !userDetails) {
      getUserDetails()
    }
  }, [getUserDetails, token, userDetails])

  useEffect(() => {
    if (userDetails?.status && flowList.length > 0) {
      getUserAmlList(flowList)
    }
  }, [flowList, getUserAmlList, userDetails?.status])

  // return availableFlow.length > 0 && preset ? <RouteList /> : null
  return preset ? <RouteList /> : null
}

export default AppRouter
