import { TopUpKWLInfo } from '../../helpers/initialModel'
// import { getUpdatedTxDetails } from '../../helpers/remittance'
import { PaymentResultType } from '../../interfaces/cardPayment'
import {
  IRemittanceReducer,
  RemittanceAction,
} from '../../interfaces/remittance/remittanceActionTypes'
import { TransactionStatus } from '../../interfaces/remittanceType'
import { calculationConstants } from '../constants/calculationConstants'
import { remittanceConstants } from '../constants/remittanceConstants'

export const initialState: IRemittanceReducer = {
  error: undefined,
  cardPaymentError: undefined,
  isLoaded: false,
  isCardPaymentLoaded: false,
  isLoading: false,
  isTransactionsLoading: false,
  isTransactionsLoaded: false,
  transactions: { items: [], hasNextPage: false },
  transaction: new TopUpKWLInfo(),
  notRedeemedCount: 0,

  cardPaymentViewSection: 'card-selection',
  initializedCardInformation: null,
  successCardPayment: false,
  authCardInfo: null,
  savedCards: [],
  cardPaymentInfoForVerification: null,
  agentsLocationList: [],

  paymentProviderList: [],
  getPaymentProvidersLoadState: {
    isLoaded: false,
    isLoading: false,
  },

  getTransactionForPaymentLoadState: {
    isLoaded: false,
    isLoading: false,
  },

  setTxStatusLoadState: {
    isLoaded: false,
    isLoading: false,
  },

  withdrawalPaymentProvider: [],
  getWithdrawalPaymentProviderLoadState: {
    isLoaded: false,
    isLoading: false,
  },

  updateWithdrawalPaymentProviderLoadState: {
    isLoaded: false,
    isLoading: false,
  },

  updateRecipientInTopUpLoadState: {
    isLoaded: false,
    isLoading: false,
  },

  withdrawalPaymentOptionsByCountry: [],
  getWithdrawalPaymentOptionsByCountryLoadState: {
    isLoaded: false,
    isLoading: false,
  },

  paymentPageUrl: '',
  createPaymentOrderLoadState: {
    isLoaded: false,
    isLoading: false,
  },

  cashInCountryList: []
}

export const RemittanceReducer = (
  state = initialState,
  action: RemittanceAction
): IRemittanceReducer => {
  switch (action?.type) {
    case remittanceConstants.GET_TRANSACTIONS_REQUEST:
      return {
        ...state,
        isTransactionsLoaded: false,
        isTransactionsLoading: true,
        error: undefined,
        transactions: {
          hasNextPage: state.transactions.hasNextPage,
          items: action.offset === 0 ? [] : state.transactions.items,
        },
      }
    case remittanceConstants.GET_TRANSACTIONS_SUCCESS:
      return {
        ...state,
        isTransactionsLoaded: true,
        isTransactionsLoading: false,
        transactions: {
          hasNextPage: action.payload.hasNextPage,
          items: [...state.transactions.items, ...action.payload.items],
        },
      }
    case remittanceConstants.GET_TRANSACTIONS_FAILURE:
      return {
        ...state,
        isTransactionsLoaded: false,
        isTransactionsLoading: false,
        error: action.payload,
      }
    case remittanceConstants.GET_TRANSACTIONS_BY_SEARCH_REQUEST:
      return {
        ...state,
        isTransactionsLoaded: false,
        isTransactionsLoading: true,
        error: undefined,
        transactions: {
          hasNextPage: state.transactions.hasNextPage,
          items: action.offset === 0 ? [] : state.transactions.items,
        },
      }
    case remittanceConstants.GET_TRANSACTIONS_BY_SEARCH_SUCCESS:
      return {
        ...state,
        isTransactionsLoaded: true,
        isTransactionsLoading: false,
        transactions: {
          hasNextPage: action.payload.hasNextPage,
          items: [...state.transactions.items, ...action.payload.items],
        },
      }
    case remittanceConstants.GET_TRANSACTIONS_BY_SEARCH_FAILURE:
      return {
        ...state,
        isTransactionsLoaded: false,
        isTransactionsLoading: false,
        error: action.payload,
      }
    case remittanceConstants.CANCEL_TRANSACTION_REQUEST:
      return {
        ...state,
        // isLoaded: false,
        // isLoading: true,
      }
    case remittanceConstants.CANCEL_TRANSACTION_SUCCESS:
      return {
        ...state,
        transaction: { ...state.transaction, status: TransactionStatus.Cancelled },
        // isLoading: false,
        // isLoaded: true,
      }
    case remittanceConstants.CLEAR_PAST_TRANSACTIONS:
      return {
        ...state,
        transactions: { items: [], hasNextPage: false },
      }
    case remittanceConstants.GET_TRANSACTION_REQUEST:
      return {
        ...state,
        getTransactionForPaymentLoadState: { isLoaded: false, isLoading: true },
        setTxStatusLoadState: {
          isLoading: false,
          isLoaded: false,
        },
        error: undefined,
      }
    case remittanceConstants.GET_TRANSACTION_SUCCESS:
      return {
        ...state,
        getTransactionForPaymentLoadState: { isLoaded: true, isLoading: false },
        transaction: action.payload,
      }
    case remittanceConstants.GET_TRANSACTION_FAILURE:
      return {
        ...state,
        getTransactionForPaymentLoadState: { isLoaded: false, isLoading: false },
        error: action.payload,
      }
    case remittanceConstants.TRANSFER_REQUEST:
      return {
        ...state,
        setTxStatusLoadState: {
          isLoading: true,
          isLoaded: false,
        },
      }
    case remittanceConstants.TRANSFER_SUCCESS:
      return {
        ...state,
        setTxStatusLoadState: {
          isLoading: false,
          isLoaded: true,
        },
        transaction: { ...state.transaction, status: TransactionStatus.Transferred },
      }
    case remittanceConstants.TRANSFER_FAILURE:
      return {
        ...state,
        setTxStatusLoadState: {
          isLoading: false,
          isLoaded: false,
        },
        error: action.payload,
      }
    case remittanceConstants.CREATE_TRANSACTION_REQUEST:
      return {
        ...state,
        isLoaded: false,
        isLoading: true,
      }
    case remittanceConstants.CREATE_TRANSACTION_SUCCESS:
      return {
        ...state,
        isLoaded: true,
        isLoading: false,
        transaction: action.payload,
      }
    case remittanceConstants.CREATE_TRANSACTION_FAILURE:
      return {
        ...state,
        isLoaded: false,
        isLoading: false,
      }
    case remittanceConstants.UPDATE_RECIPIENT_IN_TOP_UP_REQUEST:
      return {
        ...state,
        error: undefined,
        updateRecipientInTopUpLoadState: {
          isLoaded: false,
          isLoading: true,
        }

      }
    case remittanceConstants.UPDATE_RECIPIENT_IN_TOP_UP_SUCCESS:
      return {
        ...state,
        updateRecipientInTopUpLoadState: {
          isLoaded: true,
          isLoading: false,
        }
        // transactions: {
        //   hasNextPage: state.transactions.hasNextPage,
        //   items: state.transactions.items.map(tx =>
        //     tx.id === action.payload.txId ? { ...tx, recipient: action.payload.tx.recipient } : tx
        //   ),
        // },
      }
    case remittanceConstants.UPDATE_RECIPIENT_IN_TOP_UP_FAILURE:
      return {
        ...state,
        updateRecipientInTopUpLoadState: {
          isLoaded: false,
          isLoading: false,
        },
        error: action.payload,
      }
    case remittanceConstants.GET_NOT_REDEEMED_TRANSACTIONS_NUMBER_REQUEST:
      return {
        ...state,
        error: undefined,
        isLoaded: false,
        isLoading: true,
      }
    case remittanceConstants.GET_NOT_REDEEMED_TRANSACTIONS_NUMBER_SUCCESS:
      return {
        ...state,
        isLoaded: true,
        isLoading: false,
        notRedeemedCount: action.payload.notRedeemedCount,
      }
    case remittanceConstants.GET_NOT_REDEEMED_TRANSACTIONS_NUMBER_FAILURE:
      return {
        ...state,
        isLoaded: false,
        isLoading: false,
        error: action.payload,
      }

    case remittanceConstants.INITIALIZE_BY_NEW_CARD_REQUEST:
      return {
        ...state,
        successCardPayment: false,
        cardPaymentError: undefined,
        isLoaded: false,
        isLoading: true,
      }
    case remittanceConstants.INITIALIZE_BY_NEW_CARD_SUCCESS:
      return {
        ...state,
        initializedCardInformation: action.payload,
        isLoading: false,
        cardPaymentViewSection: action.sectionName,
      }
    case remittanceConstants.INITIALIZE_BY_NEW_CARD_FAILURE:
      return {
        ...state,
        isLoaded: false,
        isLoading: false,
        cardPaymentError: action.payload,
        cardPaymentViewSection: action.sectionName,
      }
    case remittanceConstants.INITIALIZE_BY_SAVED_CARD_REQUEST:
      return {
        ...state,
        successCardPayment: false,
        cardPaymentError: undefined,
        isLoaded: false,
        isLoading: true,
      }
    case remittanceConstants.INITIALIZE_BY_SAVED_CARD_SUCCESS:
      return {
        ...state,
        initializedCardInformation: action.payload,
        isLoading: false,
      }
    case remittanceConstants.INITIALIZE_BY_SAVED_CARD_FAILURE:
      return {
        ...state,
        isLoaded: false,
        isLoading: false,
        cardPaymentError: action.payload,
      }
    case remittanceConstants.AUTHENTICATE_CARD_REQUEST:
      return {
        ...state,
        isLoaded: false,
        isLoading: true,
        cardPaymentError: undefined,
        successCardPayment: false,
      }
    case remittanceConstants.AUTHENTICATE_CARD_SUCCESS:
      return {
        ...state,
        isLoaded: true,
        isLoading: false,
        cardPaymentError: action.cardPaymentError,
        successCardPayment: action.payload.outcome === PaymentResultType.Authorized,
        authCardInfo: action.payload,
        cardPaymentInfoForVerification:
          action.payload.outcome === PaymentResultType.Challenged
            ? {
              transactionReference: action.payload.transactionReference,
              challengeReference: action.payload.challenge.reference,
            }
            : null,
        cardPaymentViewSection: action.sectionName,
      }
    case remittanceConstants.AUTHENTICATE_CARD_FAILURE:
      return {
        ...state,
        isLoaded: false,
        isLoading: false,
        cardPaymentError: action.payload,
        cardPaymentViewSection: action.sectionName,
      }
    case remittanceConstants.GET_SAVED_CARD_REQUEST:
      return {
        ...state,
        cardPaymentError: undefined,
        isCardPaymentLoaded: false,
        isLoading: true,
      }
    case remittanceConstants.GET_SAVED_CARD_SUCCESS:
      return {
        ...state,
        isCardPaymentLoaded: true,
        isLoading: false,
        savedCards: action.payload,
      }
    case remittanceConstants.GET_SAVED_CARD_FAILURE:
      return {
        ...state,
        isCardPaymentLoaded: false,
        isLoading: false,
        // cardPaymentError: action.payload
      }
    case remittanceConstants.VERIFY_CARD_PAYMENT_REQUEST:
      return {
        ...state,
        cardPaymentError: undefined,
        isLoaded: false,
        isLoading: true,
      }
    case remittanceConstants.VERIFY_CARD_PAYMENT_SUCCESS:
      return {
        ...state,
        isLoaded: true,
        isLoading: false,
        successCardPayment: action.payload.outcome === PaymentResultType.Authorized,
        cardPaymentViewSection: action.sectionName,
      }
    case remittanceConstants.VERIFY_CARD_PAYMENT_FAILURE:
      return {
        ...state,
        isLoaded: false,
        isLoading: false,
        cardPaymentError: action.payload,
        cardPaymentViewSection: action.sectionName,
      }
    case remittanceConstants.DELETE_SAVED_CARD_SUCCESS:
      return {
        ...state,
        savedCards: state.savedCards.filter(card => card.extId !== action.payload),
      }
    case remittanceConstants.SET_CARD_PAYMENT_FAILURE:
      return {
        ...state,
        cardPaymentError: action.error,
        cardPaymentViewSection: action.sectionName,
      }
    case remittanceConstants.CLEAR_PAST_TRANSACTIONS_ERROR:
      return {
        ...state,
        error: undefined,
      }
    case remittanceConstants.SET_CARD_PAYMENT_VIEW_SECTION_SUCCESS:
      return {
        ...state,
        cardPaymentViewSection: action.payload,
      }
    case remittanceConstants.GET_AGENTS_LOCATION_REQUEST:
      return {
        ...state,
        error: undefined,
        isLoaded: false,
        isLoading: true,
      }
    case remittanceConstants.GET_AGENTS_LOCATION_SUCCESS:
      return {
        ...state,
        isLoaded: true,
        isLoading: false,
        agentsLocationList: action.payload,
      }
    case remittanceConstants.GET_AGENTS_LOCATION_FAILURE:
      return {
        ...state,
        isLoaded: false,
        isLoading: false,
      }
    case remittanceConstants.GET_PAYMENT_PROVIDER_REQUEST:
      return {
        ...state,
        error: undefined,
        getPaymentProvidersLoadState: { isLoaded: false, isLoading: true },
      }
    case remittanceConstants.GET_PAYMENT_PROVIDER_SUCCESS:
      return {
        ...state,
        paymentProviderList: action.payload,
        getPaymentProvidersLoadState: { isLoaded: true, isLoading: false },
      }

    case remittanceConstants.GET_PAYMENT_PROVIDER_FAILURE:
      return {
        ...state,
        getPaymentProvidersLoadState: { isLoaded: false, isLoading: false },
      }
    case remittanceConstants.GET_WITHDRAWAL_PAYMENT_PROVIDER_REQUEST:
      return {
        ...state,
        getWithdrawalPaymentProviderLoadState: { isLoaded: false, isLoading: true },
      }
    case remittanceConstants.GET_WITHDRAWAL_PAYMENT_PROVIDER_SUCCESS:
      return {
        ...state,
        getWithdrawalPaymentProviderLoadState: { isLoaded: true, isLoading: false },
        withdrawalPaymentProvider: action.payload
      }
    case remittanceConstants.GET_WITHDRAWAL_PAYMENT_PROVIDER_FAILURE:
      return {
        ...state,
        getWithdrawalPaymentProviderLoadState: { isLoaded: false, isLoading: false },
      }

    case remittanceConstants.GET_WITHDRAWAL_PAYMENT_OPTIONS_BY_COUNTRY_REQUEST:
      return {
        ...state,
        getWithdrawalPaymentOptionsByCountryLoadState: { isLoaded: false, isLoading: true },
      }
    case remittanceConstants.GET_WITHDRAWAL_PAYMENT_OPTIONS_BY_COUNTRY_SUCCESS:
      return {
        ...state,
        getWithdrawalPaymentOptionsByCountryLoadState: { isLoaded: true, isLoading: false },
        withdrawalPaymentOptionsByCountry: action.payload
      }
    case remittanceConstants.GET_WITHDRAWAL_PAYMENT_OPTIONS_BY_COUNTRY_FAILURE:
      return {
        ...state,
        getWithdrawalPaymentOptionsByCountryLoadState: { isLoaded: false, isLoading: false },
      }


    case remittanceConstants.GET_CASH_IN_COUNTRY_LIST_SUCCESS:
      return {
        ...state,
        // getWithdrawalPaymentOptionsByCountryLoadState: { isLoaded: true, isLoading: false },
        cashInCountryList: action.payload
      }


    case calculationConstants.CLEAR_CALCULATION_DATA_FROM_STATE:
      return {
        ...state,
        getWithdrawalPaymentProviderLoadState: { isLoaded: false, isLoading: false }
      }
    case remittanceConstants.UPDATE_WITHDRAWAL_PAYMENT_PROVIDER_REQUEST:
      return {
        ...state,
        updateWithdrawalPaymentProviderLoadState: {
          isLoaded: false,
          isLoading: true,
        },
      }
    case remittanceConstants.UPDATE_WITHDRAWAL_PAYMENT_PROVIDER_SUCCESS:
      return {
        ...state,
        updateWithdrawalPaymentProviderLoadState: {
          isLoaded: true,
          isLoading: false,
        },
        transaction: action.payload,
      }
    case remittanceConstants.UPDATE_WITHDRAWAL_PAYMENT_PROVIDER_FAILURE:
      return {
        ...state,
        updateWithdrawalPaymentProviderLoadState: {
          isLoaded: false,
          isLoading: false,
        },
      }
    case remittanceConstants.CREATE_PAYMENT_ORDER_REQUEST:
      return {
        ...state,
        createPaymentOrderLoadState: {
          isLoaded: false,
          isLoading: true,
        },
      }
    case remittanceConstants.CREATE_PAYMENT_ORDER_SUCCESS:
      return {
        ...state,
        paymentPageUrl: action.payload.paymentPageUrl,
        createPaymentOrderLoadState: {
          isLoaded: true,
          isLoading: false,
        },
      }
    case remittanceConstants.CREATE_PAYMENT_ORDER_FAILURE:
      return {
        ...state,
        createPaymentOrderLoadState: {
          isLoaded: false,
          isLoading: false,
        },
      }
    default:
      return state
  }
}
