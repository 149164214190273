import { Dispatch } from 'redux'
import {
  PreRegistrationActions,
  SetIsMobile,
} from '../../interfaces/actions/preRegistrationActionTypes'
import { preRegistrationServices } from '../../services/preRegistrationServices'
import * as preRegistrationConstants from '../../redux/constants/preRegistrationConstants'
import { NavigateFunction } from 'react-router-dom'
import { ChangeEmailRequest } from '../../interfaces/user'
import { userServices } from '../../services/userServices'

function setUserEmail(email: string) {
  return async (dispatch: Dispatch<PreRegistrationActions>) => {
    dispatch({
      type: preRegistrationConstants.userConstants.CHANGE_USER_EMAIL,
      payload: email,
    })
  }
}

function ChangeUserEmail(navigate: NavigateFunction, email: ChangeEmailRequest) {
  return async (dispatch: Dispatch<PreRegistrationActions>) => {
    dispatch({
      type: preRegistrationConstants.userConstants.CHANGE_USER_EMAIL_REQUEST,
    })
    userServices.changeUserEmail(email).then(
      () => {
        dispatch({
          type: preRegistrationConstants.userConstants.CHANGE_USER_EMAIL_SUCCESS,
          payload: email,
        })
      },
      error => {
        dispatch({
          type: preRegistrationConstants.userConstants.CHANGE_USER_EMAIL_FAILURE,
          payload: error,
        })
        // if (error.code === 500) {
        //   navigate('/maintenance')
        // }
      }
    )
  }
}

const sendToWhiteList = (data: {}) => async (dispatch: Dispatch<PreRegistrationActions>) => {
  dispatch({ type: preRegistrationConstants.SEND_TO_WHITE_LIST_REQUEST })

  await preRegistrationServices.sendToWhiteList(data).then(
    () => {
      dispatch({
        type: preRegistrationConstants.SEND_TO_WHITE_LIST_SUCCESS,
      })
    },
    error => {
      dispatch({
        type: preRegistrationConstants.SEND_TO_WHITE_LIST_FAILURE,
        payload: error,
      })
    }
  )
}

const setIsMobile = (isMobile: boolean) => (dispatch: Dispatch<SetIsMobile>) => {
  dispatch({
    type: preRegistrationConstants.preRegistrationConstants.SET_IS_MOBILE,
    payload: isMobile,
  })
}

export const preRegistrationActions = {
  setUserEmail,
  ChangeUserEmail,
  sendToWhiteList,
  setIsMobile,
}
