import { SyntheticEvent, useCallback, useEffect, useMemo, useState } from 'react'
import Tooltip from 'rc-tooltip'
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom'
import CalculationSection from '../../components/calculation/CalculationSection'
import { Icon } from '../../components/icons'
import InnerHeader from '../../components/innerHeader'
import Modal from '../../components/Modal'
import { transactionStatusConfig } from '../../helpers/configs/transactionConfig'
import { recipientDetailsInLocalStorage } from '../../helpers/recipientData'
import { localStorageHelper } from '../../helpers/useLocalStorage'
import { useAppDispatch, useAppSelector } from '../../hooks/dispatch'
import { remittanceActions } from '../../redux/action-creators/remittanceActions'
import { detectMobileApp } from '../../helpers/utils'
// import SharingButton from '../../components/interfaceComponents/SharingButton'
import { PuffLoader } from 'react-spinners'
import isNull from 'lodash/isNull'
import { AmlStatus } from '../../interfaces/user'
import { userActions } from '../../redux/action-creators/userActions'
import {
  PaymentIntentDetails,
  TransactionPaymentProvider,
  TransactionStatus,
} from '../../interfaces/remittanceType'
import { remittanceService } from '../../services/remittanceService'
import { calculationHelper } from '../../helpers/calculationData'
import { PaymentMethod } from '../../interfaces'

const getPaymentMethodText = (paymentMethod: PaymentMethod | undefined) => {
  const paymentMethodList = {
    [PaymentMethod.CreditCard]: 'Paid by Card Payment',
    [PaymentMethod.BankTransfer]: 'Paid by Bank Transfer',
    [PaymentMethod.OpenBanking]: 'Paid by Banking',
  }

  return paymentMethod ? paymentMethodList[paymentMethod] : ''
}
/* 
TO DO: Remove conditions, requests and paymentIntentSwitcher variable 
after full integration of payment intents
*/

const paymentIntentSwitcher = process.env.REACT_APP_PAYMENT_INTENT_SWITCHER

type LocationState = {
  state: {
    searchedTxValue: string
  }
  pathname: string
}

//for android flutter app
const isMobileApp = detectMobileApp()

const TransactionDetailsPage = () => {
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const location = useLocation() as LocationState
  const searchedTxValue = location?.state?.searchedTxValue || undefined
  const { id } = useParams()

  const { transaction, getTransactionForPaymentLoadState } = useAppSelector(
    state => state.remittance
  )
  const userAmlStatus = useAppSelector(state => state.user.userAml?.status)
  const preset = useAppSelector(state => state.preset.preset)
  const flowList = useAppSelector(state => state.preset.availableFlow)

  const [isOpenModal, setIsOpenModal] = useState(false)
  // const [isOpenShareModal, setIsOpenShareModal] = useState(false)
  const [isOpenInfoModal, setIsOpenInfoModal] = useState(false)
  const [showToolTipForCode, setShowToolTipForCode] = useState(false)
  const [isOpenVerificationModal, setIsOpenVerificationModal] = useState(false)

  const isShownBeneficiaryAccount = useMemo(() => {
    return transaction.withdrawalMethod === PaymentMethod.BankTransfer
  }, [transaction.withdrawalMethod])

  useEffect(() => {
    if (id) {
      dispatch(remittanceActions.getTransaction(id, navigate))
    }
  }, [dispatch, id, navigate])

  const onCancel = useCallback(() => {
    navigate('/past-transactions', { state: { searchedTxValue } })
  }, [navigate, searchedTxValue])

  const onCancelTransaction = useCallback(() => {
    dispatch(remittanceActions.cancelTransaction(transaction.topUpRequestExtId))
  }, [dispatch, transaction.topUpRequestExtId])

  const moveFromContinueBtnAfterAmlCheck = useCallback(
    (userAmlStatus: AmlStatus | undefined) => {
      if (userAmlStatus === AmlStatus.Verified) {
        const from = location.pathname

        navigate(`/transaction/${id}/payment-details`, { state: { from, searchedTxValue } })
      } else {
        setIsOpenVerificationModal(true)
      }
    },
    [id, location.pathname, navigate, searchedTxValue]
  )

  const onContinue = useCallback(() => {
    dispatch(userActions.getUserAmlList(flowList, moveFromContinueBtnAfterAmlCheck))
  }, [dispatch, flowList, moveFromContinueBtnAfterAmlCheck])

  const moveFromSendAgainBtnAfterAmlCheck = useCallback(
    async (userAmlStatus: AmlStatus | undefined) => {
      const from = location.pathname

      if (userAmlStatus === AmlStatus.Verified) {
        if (paymentIntentSwitcher === 'true') {
          const tx = (await remittanceService.getTransaction(
            transaction.topUpRequestExtId
          )) as PaymentIntentDetails

          recipientDetailsInLocalStorage.saveRecipientDetails(tx.recipient)
          calculationHelper.setCalculationData(transaction.amountToReceive)
          navigate('/transaction/new', { state: { searchedTxValue, from } })
        } else {
          if (transaction.recipient.isDeleted) {
            setIsOpenModal(true)
          } else {
            recipientDetailsInLocalStorage.saveRecipientDetails(transaction.recipient)
            localStorageHelper.setData('receivedAmount', String(transaction.amountToReceive))
            navigate('/transaction/new', { state: { from, searchedTxValue } })
          }
        }
      } else {
        setIsOpenVerificationModal(true)
      }
    },
    [
      location.pathname,
      navigate,
      searchedTxValue,
      transaction.amountToReceive,
      transaction.recipient,
      transaction.topUpRequestExtId,
    ]
  )

  const sendAgain = useCallback(() => {
    dispatch(userActions.getUserAmlList(flowList, moveFromSendAgainBtnAfterAmlCheck))
  }, [dispatch, flowList, moveFromSendAgainBtnAfterAmlCheck])

  const confirmModalFunction = useCallback(() => {
    setIsOpenVerificationModal(false)

    if (userAmlStatus === AmlStatus.Pending) {
      navigate('/pending')
      return
    }

    navigate('/verification')
  }, [navigate, userAmlStatus])

  const onOpenInfo = (event: SyntheticEvent) => {
    event.stopPropagation()

    setIsOpenInfoModal(true)
  }

  const modalConfirmFunction = () => {
    setIsOpenModal(false)
    navigate('/beneficiaries')
    localStorageHelper.setData('receivedAmount', String(transaction.amountToReceive))
  }

  const onCopyCodeClick = () => {
    setShowToolTipForCode(true)

    navigator.clipboard.writeText(transaction.digitalReference)

    setTimeout(() => setShowToolTipForCode(false), 1000)
  }

  const onSharingButtonClick = () => {
    onCopyCodeClick()
    // if (isMobile) {
    //   setIsOpenShareModal(true)
    // } else {
    //   onCopyCodeClick()
    // }
  }

  //for android flutter app

  const onClickLinkForCopyToClipboard = useCallback(() => {
    setShowToolTipForCode(true)

    setTimeout(() => setShowToolTipForCode(false), 1000)
  }, [])

  const currentTransactionStatus = useMemo(() => {
    return transactionStatusConfig.find(
      transactionStatus => transactionStatus.type === transaction.status
    )
  }, [transaction.status])

  const paymentStatusText = useMemo(() => {
    if (
      transaction.status === TransactionStatus.Ordered ||
      transaction.status === TransactionStatus.Transferred
    ) {
      return 'Pending payment'
    }

    if (
      transaction.status === TransactionStatus.NotRedeemed ||
      transaction.status === TransactionStatus.Redeemed ||
      transaction.status === TransactionStatus.PaymentInProgress
    ) {
      return getPaymentMethodText(transaction.cashInPaymentMethod)
    }

    return ''
  }, [transaction.cashInPaymentMethod, transaction.status])

  const buttonConfig = useMemo(() => {
    switch (transaction.status) {
      case TransactionStatus.Ordered:
        return {
          leftButtonText: 'Cancel Transaction',
          rightButtonText: 'Continue',
          leftButton: onCancelTransaction,
          rightButton: onContinue,
        }
      case TransactionStatus.PaymentInProgress:
        return {
          leftButtonText: 'Back',
          rightButtonText: '',
          leftButton: onCancel,
          rightButton: () => {},
        }
      case TransactionStatus.Transferred:
        return {
          leftButtonText: 'Back',
          rightButtonText: 'Continue',
          leftButton: onCancel,
          rightButton: onContinue,
        }
      case TransactionStatus.Cancelled:
        return {
          leftButtonText: 'Back',
          // rightButtonText: 'Send Again',
          rightButtonText: '',
          leftButton: onCancel,
          rightButton: () => {},
          // rightButton: sendAgain,
        }
      case TransactionStatus.Expired:
        return {
          leftButtonText: 'Back',
          rightButtonText: '',
          leftButton: onCancel,
          rightButton: () => {},
        }
      case TransactionStatus.Redeemed:
        return {
          leftButtonText: 'Back',
          // rightButtonText: 'Send Again',
          rightButtonText: '',
          leftButton: onCancel,
          rightButton: () => {},
          // rightButton: sendAgain,
        }
      case TransactionStatus.NotRedeemed:
        return {
          leftButtonText: 'Back',
          rightButtonText: '',
          leftButton: onCancel,
          rightButton: () => {},
        }
      case TransactionStatus.IncorrectPayin:
        return {
          leftButtonText: 'Back',
          rightButtonText: '',
          leftButton: onCancel,
          rightButton: () => {},
        }
      case TransactionStatus.Unknown:
        return {
          leftButtonText: 'Back',
          rightButtonText: '',
          leftButton: onCancel,
          rightButton: () => {},
        }
      default:
        return {
          leftButtonText: 'Back',
          rightButtonText: '',
          leftButton: onCancel,
          rightButton: () => {},
        }
    }
  }, [onCancel, onCancelTransaction, onContinue, transaction.status])

  const referenceCode = useMemo(() => {
    if (
      isNull(transaction.digitalReference) ||
      transaction.status === TransactionStatus.Ordered ||
      transaction.status === TransactionStatus.Transferred ||
      transaction.status === TransactionStatus.Cancelled ||
      transaction.status === TransactionStatus.Expired
    ) {
      return { showShareButton: false, code: '--- ---- ----' }
    } else {
      return { showShareButton: true, code: transaction.digitalReference }
    }
  }, [transaction.digitalReference, transaction.status])

  return (
    <>
      {getTransactionForPaymentLoadState.isLoading && (
        <div className="spinner spinner-fixed">
          <PuffLoader size={70} color="#3171d8" />
        </div>
      )}
      <section className="h-100-mb">
        <div className="container container-md container-100">
          {!getTransactionForPaymentLoadState.isLoading &&
            getTransactionForPaymentLoadState.isLoaded && (
              <div className="past-transactions">
                <InnerHeader title="Transaction Details" onGoBack={onCancel} />
                <CalculationSection
                  mode="calculation-block-with-recipient"
                  receiveAmount={transaction.amountToReceive}
                  recipient={transaction.recipient}
                  isShownBeneficiaryAccount={isShownBeneficiaryAccount}
                  currency={transaction.receiveCurrency}
                  currencySign={transaction.receiveCurrencySign}
                />
                <div className="payment-block">
                  <div className="payment-block__count">
                    {transaction.status === TransactionStatus.Ordered ||
                    transaction.status === TransactionStatus.Transferred
                      ? 'Payment Due:'
                      : 'Payment:'}
                    <span>
                      {transaction.srcCurrencySign}
                      {transaction.amountToSend.toFixed(2)}
                    </span>
                  </div>
                  <div className="payment-block__info" onClick={onOpenInfo}>
                    <Icon.Info />
                  </div>
                </div>
                {paymentStatusText && (
                  <div className="past-transactions__checkbox">
                    <Icon.CheckBox />
                    <span>{paymentStatusText}</span>
                  </div>
                )}
                <div className="redeem-code-block">
                  <div className="redeem-code-block__info">
                    <div className="redeem-code-block__title">Redeem Code</div>
                    <div
                      className={`redeem-code-block__status ${currentTransactionStatus?.className}`}
                    >
                      {currentTransactionStatus?.icon}
                      {currentTransactionStatus?.label}
                    </div>
                  </div>
                  <div className="redeem-code-block__code">{referenceCode.code}</div>
                  {referenceCode.showShareButton && (
                    <div className="redeem-code-block__icon">
                      {isMobileApp ? (
                        //for android flutter app
                        <div className="copy-to-clipboard">
                          {showToolTipForCode && (
                            <div className="rc-tooltip rc-tooltip-placement-left">
                              <div className="rc-tooltip-content">
                                <div className="rc-tooltip-arrow"></div>
                                <div className="rc-tooltip-inner">Copied to clipboard</div>
                              </div>
                            </div>
                          )}
                          <a
                            href={`copyToClipboard/${transaction.digitalReference}`}
                            onClick={onClickLinkForCopyToClipboard}
                          >
                            <Icon.ShareBox />
                          </a>
                        </div>
                      ) : (
                        <>
                          <Tooltip
                            visible={showToolTipForCode}
                            placement="left"
                            trigger={['click']}
                            mouseLeaveDelay={0.2}
                            overlay="Copied to clipboard"
                          >
                            <button onClick={onSharingButtonClick}>
                              <Icon.ShareBox />
                            </button>
                          </Tooltip>
                        </>
                      )}
                    </div>
                  )}
                </div>
                {(transaction.status === TransactionStatus.Ordered ||
                  transaction.status === TransactionStatus.Transferred) && (
                  <div className="price-info">
                    <p className="price-info__text">
                      Press "Continue" button to select the payment method, please
                    </p>
                  </div>
                )}
                <div className="past-transactions__additional-block">
                  <Icon.LightningChargeFill />
                  <p>Instant cash pick up at {preset?.infoForHomePage}.</p>
                </div>

                {transaction.withdrawalMethod === PaymentMethod.Cash && (
                  <Link to="/agents-location" className="past-transactions__location">
                    View Cashout Locations
                    <Icon.ArrowRightBack />
                  </Link>
                )}
                <div className="btn-wrapper btn-noTransition mt-20">
                  <button
                    className={`btn btn-cancel${
                      transaction.status === TransactionStatus.Ordered ? ' btn-50 btn-error' : ''
                    }`}
                    onClick={buttonConfig.leftButton}
                  >
                    {buttonConfig.leftButtonText}
                  </button>
                  {buttonConfig.rightButtonText && (
                    <button
                      className={`btn btn-next${
                        transaction.status === TransactionStatus.Ordered ? ' btn-50' : ''
                      }`}
                      //disabled={Boolean(errorField)}
                      onClick={buttonConfig.rightButton}
                    >
                      {buttonConfig.rightButtonText}
                    </button>
                  )}
                </div>
              </div>
            )}
        </div>
        <Modal
          setIsOpenModal={setIsOpenModal}
          isOpenModal={isOpenModal}
          confirmFunction={modalConfirmFunction}
          modalHeader="Transaction Details"
          modalBody='The beneficiary has been deleted, so you can select another one and click the "Send Money" button.'
          modalType="informationModal"
        />
      </section>
      {/* <ReactModal
        onRequestClose={() => setIsOpenShareModal(false)}
        shouldCloseOnOverlayClick={true}
        className="modal-share"
        overlayClassName="modal-overlay"
        isOpen={isOpenShareModal}
        ariaHideApp={false}
      >
        <SharingButton redeemCode={transaction.digitalReference} />
      </ReactModal> */}
      <Modal
        setIsOpenModal={setIsOpenVerificationModal}
        isOpenModal={isOpenVerificationModal}
        modalHeader="Verification"
        modalBody="For security reasons, please, complete the Identity Verification."
        modalType="informationModal"
        confirmFunction={confirmModalFunction}
      />
      <Modal
        modalType="transactionDetails"
        setIsOpenModal={setIsOpenInfoModal}
        isOpenModal={isOpenInfoModal}
        modalHeader="Transaction Details"
        modalBody={transaction}
      />
    </>
  )
}

export default TransactionDetailsPage
