import { Dispatch, SetStateAction, useCallback, useEffect, useMemo, useState } from 'react'
import {
  CardPaymentButtonVariant,
  CardToDelete,
  PaymentSection,
} from '../../interfaces/cardPayment'
import DeviceDataForm from './cardPayment/DeviceDataForm'
import CardPaymentResult from './cardPayment/CardPaymentResult'
import ChallengeForm from './cardPayment/ChallengeForm'
import CardList from './cardPayment/CardList'
// import CardPaymentVariantMenu from './cardPayment/CardPaymentVariantMenu'
import { useAppDispatch, useAppSelector } from '../../hooks/dispatch'
import { remittanceActions } from '../../redux/action-creators/remittanceActions'
import Modal from '../Modal'
import NewCard from './cardPayment/NewCard'

type CardPaymentSectionProps = {
  setDisabledButton: Dispatch<SetStateAction<boolean>>
  // setShowTabMenu: Dispatch<SetStateAction<boolean>>
  setCardPaymentStatusForBtn: Dispatch<SetStateAction<CardPaymentButtonVariant>>
  setPaymentSection: Dispatch<SetStateAction<PaymentSection>>
}

const CardPaymentSection = ({
  setDisabledButton,
  // setShowTabMenu,
  setCardPaymentStatusForBtn,
  setPaymentSection,
}: CardPaymentSectionProps) => {
  const dispatch = useAppDispatch()
  const cardPaymentViewSection = useAppSelector(state => state.remittance.cardPaymentViewSection)

  const [isOpenDeleteCardModal, setIsOpenDeleteCardModal] = useState(false)
  const [cardToDelete, setCardToDelete] = useState<CardToDelete | null>(null)

  useEffect(() => {
    // if (cardPaymentViewSection === 'card-payment-variant') {
    //   setShowTabMenu(true)
    // } else {
    //   setShowTabMenu(false)
    // }

    if (cardPaymentViewSection === 'new-card') {
      setCardPaymentStatusForBtn('processing')
    }
    if (cardPaymentViewSection !== 'new-card' && cardPaymentViewSection !== 'result') {
      setCardPaymentStatusForBtn('')
    }
  }, [cardPaymentViewSection, dispatch, setCardPaymentStatusForBtn])

  useEffect(() => {
    return () => {
      dispatch(remittanceActions.setCardPaymentViewSection('card-selection'))
    }
  }, [dispatch])

  const openDeleteCardModal = useCallback((card: CardToDelete) => {
    setCardToDelete(prevState => card)
    setIsOpenDeleteCardModal(true)
  }, [])

  const deleteCard = useCallback(() => {
    dispatch(remittanceActions.deleteSavedCard(cardToDelete!.id))
    setIsOpenDeleteCardModal(false)
  }, [cardToDelete, dispatch])

  const currentCardPaymentSection = useMemo(() => {
    switch (cardPaymentViewSection) {
      // case 'card-payment-variant':
      //   return <CardPaymentVariantMenu />
      case 'card-selection':
        return (
          <CardList
            openDeleteCardModal={openDeleteCardModal}
            setPaymentSection={setPaymentSection}
          />
        )
      case 'new-card':
        return <NewCard setDisabledButton={setDisabledButton} />
      case 'device-data':
        return <DeviceDataForm setCardPaymentStatusForBtn={setCardPaymentStatusForBtn} />
      case 'challenge':
        return <ChallengeForm setCardPaymentStatusForBtn={setCardPaymentStatusForBtn} />
      case 'result':
        return <CardPaymentResult />
      // default:
      //   return (
      //     <CardList
      //       openDeleteCardModal={openDeleteCardModal}
      //       setPaymentSection={setPaymentSection}
      //     />
      //   )
    }
  }, [
    cardPaymentViewSection,
    openDeleteCardModal,
    setCardPaymentStatusForBtn,
    setDisabledButton,
    setPaymentSection,
  ])

  return (
    <>
      <div className="card-payment">{currentCardPaymentSection}</div>
      <Modal
        setIsOpenModal={setIsOpenDeleteCardModal}
        isOpenModal={isOpenDeleteCardModal}
        modalHeader="Remove Payment Details?"
        modalBody={`You are about to remove the card ending in: **** ${cardToDelete?.last4Digits}`}
        modalType="deleteCardModal"
        confirmFunction={deleteCard}
      />
    </>
  )
}

export default CardPaymentSection
