import { PaymentMethod } from '.'
import { CompanyRecipient, KWLRecipient } from './beneficiary'
import { WithdrawalOption } from './calculation'
import { Currency } from './commonTypes'

/*
TO DO: Remove old types after full integration of payment intents  
*/

export enum PaymentType {
  Regular = 10,
  Priority = 20,
}

export enum SourceOfFunds {
  Other,
  Salary,
  Savings,
  Investments,
  Gift,
  Loan,
  Winnings,
  PropertySale,
  BusinessOwnership,
}

export enum ReasonForTransfer {
  Other,
  Personal,
  Gift,
  Schooling,
  ExpenseReimbursement,
  InvoicePayment,
  MedicalCosts,
  Transportation,
}

export enum TransactionStatus {
  Unknown = 0,
  Ordered = 10,
  PaymentInProgress = 15,
  Transferred = 20,
  Cancelled = 30,
  Expired = 40,
  Redeemed = 50,
  NotRedeemed = 60,
  IncorrectPayin = 70,
  TransferPending = 80,
}

export type TransactionDataStateForNewTx = {
  sendCurrency: string
  receiveCurrency: string
  receiveAmount: number | undefined
  beneficiaryId: number | undefined
  receiveMethod?: WithdrawalOption
  transactionDetails: TransactionDetailsRequest
  // receiveCountryId: number,
  // sourceCountryId: number,
}

export type TransactionDetailsRequest = {
  sourceOfFunds: SourceOfFunds | string
  reasonTransfer: ReasonForTransfer | string
  sourceOfFundsDetails?: string | null
  reasonTransferDetails?: string | null
}

export type OtherTransactionDetailsForm = {
  sourceOfFunds: SourceOfFunds | string
  reasonTransfer: ReasonForTransfer | string
  sourceOfFundsDetails?: string | null
  reasonTransferDetails?: string | null
  receiveMethod?: WithdrawalOption
}

export enum PaymentIntentStatus {
  Initiated = 0,
  PaymentMethodSelected = 10,
  SourceFundsSent = 20,
  SourceFundsSendingFailed = 30,
  IncorrectSourceFundsReceived = 40,
  SourceFundsReceived = 50,
  SourceFundsReceivingFailed = 60,
  FundsCleared = 70,
  AvailableForPayout = 80,
  DestFundsSent = 90,
  DestFundsSendingFailed = 100,
  IncorrectDestFundsReceived = 110,
  DestFundsReceivingFailed = 120,
  DestFundsReceived = 130,
  Completed = 140,
  Expired = 150,
  Canceled = 160,
  UserCanceled = 170,
}

export enum TransactionPaymentProvider {
  ClearJunctionBank,
  CurrencyCloudBank,
  CardPayment,
  Thunes,
}

//OLD - TopUp
export interface ShortTransactionDetails {
  id: string
  bookedDate: string
  // expirationDate: string
  // redeemedDate: string
  // redeemCode: string
  status: TransactionStatus
  // amountToSend: number
  // totalAmountToSend: number
  amountToReceive: number
  // fee: number
  // rate: number
  // sendCurrency: string
  receiveCurrency: string
  recipient: KWLRecipient & { companyName?: string | null }
  // transactionDetails: OtherTransactionDetailsForm
  digitalReference: string
  receiveCurrencySign: string
}

//OLD - TopUp
export type TransactionDetails = {
  accountName: string
  accountNumber: string | null
  sortCode: string | null
  iban: string | null

  topUpRequestExtId: string
  paymentReference: string

  rate: number
  reverseRate?: number
  receiveCurrency: string
  amountToReceive: number
  srcCurrency: string
  amountToSend: number
  amountToSendWithoutFee: number
  paymentProviderDiffFee: number
  fee: number

  receiveCurrencySign: string
  srcCurrencySign: string

  topUpNumber: number | string
  digitalReference: string
  withdrawalMethod?: PaymentMethod
  withdrawalProviderId?: number
  withdrawalFee?: number
  receiveCountryId: number,
  sourceCountryId: number,

  paymentProvider: TransactionPaymentProvider | null
  cashInPaymentMethod?: PaymentMethod

  status: TransactionStatus
  recipient: KWLRecipient
  // recipientPhoneNumber: string
  // bookedDate: string
  // redeemedDate: string
  // reverseRate: number
  // transactionDetails: OtherTransactionDetailsForm
}

//NEW - Payment Intent
export interface ShortPaymentIntentDetails {
  extId: string
  status: PaymentIntentStatus
  currency: Currency
  amount: number
  createdDate: string
  recipient: KWLRecipient
  digitalReference: string
}

//NEW - Payment Intent
export type PaymentIntentDetails = {
  extId: string
  reference: string
  digitalReference: string

  bankDetails: {
    accountName: string
    accountNumber: string | null
    sortCode: string | null
    iban: string | null
  }
  // bookedDate: string
  // redeemedDate: string
  amountToSendWithoutFee: number
  fee: number
  paymentProviderDiffFee: number
  status: PaymentIntentStatus
  recipient: KWLRecipient

  sendCurrency: Currency
  receiveCurrency: Currency
  sendAmount: number
  receiveAmount: number
  receiveCountryId: number
  sourceCountryId: number
  rate: number
  // reverseRate: number


  // paymentType?: PaymentType
  // details: OtherTransactionDetailsForm
  paymentProviders: {
    cashInPaymentProvider: TransactionPaymentProvider | null
  }
}

export type TransactionsNumber = {
  notRedeemedCount: number
}

//OLD - TopUp
export type PastTransactionsServerResponse = {
  items: ShortTransactionDetails[]
  hasNextPage: boolean
}

//NEW - Payment intent
export type PastPaymentIntentsServerResponse = {
  items: ShortPaymentIntentDetails[]
  hasNextPage: boolean
}

export type CreateTransactionServerRequest = {
  sendCurrency: string
  receiveCurrency: string
  receiveAmount: number | undefined
  beneficiaryId: number | undefined
  withdrawalProviderId?: number
  withdrawalMethod?: PaymentMethod
  transactionDetails: TransactionDetailsRequest
}

//NEW - Payment intent
export type CreateTransactionBySendAgainServerRequest = {
  sendCurrency: string
  receiveCurrency: string
  receiveAmount: number | undefined
  recipient: KWLRecipient | CompanyRecipient
  transferDetails: OtherTransactionDetailsForm
}

export type CreatePaymentIntentServerRequest = {
  sendCurrency: string
  receiveCurrency: string
  receiveAmount: number | undefined
  beneficiaryId: number | undefined
  transferDetails: OtherTransactionDetailsForm
}
