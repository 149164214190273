import { Dispatch, SetStateAction, useEffect, useMemo, useState } from 'react'
import isEmpty from 'lodash/isEmpty'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { beneficiaryYupSchemes } from '../../helpers/yupSchemes/beneficiaryYupSchemes'
import { BeneficiaryLookUpForm, BeneficiaryType } from '../../interfaces/beneficiary'
import FormInput from '../interfaceComponents/FormInput'
import { useAppDispatch, useAppSelector } from '../../hooks/dispatch'
import { beneficiaryActions } from '../../redux/action-creators/beneficiaryActions'
import { trimObjectFields } from '../../helpers/utils'
import { Icon } from '../icons'
import SelectForm from '../interfaceComponents/SelectForm'
import { getCurrentBeneficiaryTypeOptions } from '../../helpers/configs/optionConfigs'
import isEqual from 'lodash/isEqual'

type BeneficiaryLookUpProps = {
  onCancel: () => void
  setIsLookUpPage: Dispatch<SetStateAction<boolean>>
}

const BeneficiaryLookUp = ({ onCancel, setIsLookUpPage }: BeneficiaryLookUpProps) => {
  const dispatch = useAppDispatch()
  const beneficiaryFieldsConfig = useAppSelector(
    state => state.beneficiary.beneficiaryFieldsConfig,
    isEqual
  )

  const [isDisabledButton, setIsDisabledButton] = useState(false)

  const beneficiaryTypeOptions = useMemo(
    () =>
      beneficiaryFieldsConfig ? getCurrentBeneficiaryTypeOptions(beneficiaryFieldsConfig) : [],
    [beneficiaryFieldsConfig]
  )

  const { register, handleSubmit, watch, clearErrors, formState, setValue } =
    useForm<BeneficiaryLookUpForm>({
      resolver: yupResolver(beneficiaryYupSchemes.beneficiaryLookUpScheme),
    })

  const watchedBeneficiaryType = watch('type')

  useEffect(() => {
    beneficiaryFieldsConfig && setValue('type', beneficiaryTypeOptions[0].value)
  }, [beneficiaryFieldsConfig, beneficiaryTypeOptions, setValue])

  useEffect(() => {
    if (watchedBeneficiaryType === String(BeneficiaryType.Company)) {
      setValue('nationalId', '')
      clearErrors('nationalId')
    }
  }, [clearErrors, setValue, watchedBeneficiaryType])

  useEffect(() => {
    if (isEmpty(formState.errors)) {
      setIsDisabledButton(false)
    } else {
      setIsDisabledButton(true)
    }
  }, [formState, setIsDisabledButton])

  const onVerify = (data: BeneficiaryLookUpForm) => {
    const nationalId = data.nationalId.toUpperCase()
    const lookUpData = trimObjectFields<BeneficiaryLookUpForm>({ ...data, nationalId })

    if (String(watchedBeneficiaryType) === '0') {
      dispatch(beneficiaryActions.lookUpBeneficiary(lookUpData, setIsLookUpPage))
    } else {
      dispatch(
        beneficiaryActions.setBeneficiaryType(Number(watchedBeneficiaryType), setIsLookUpPage)
      )
    }
  }

  return beneficiaryFieldsConfig ? (
    <>
      <form id="beneficiary-lookup-form" onSubmit={handleSubmit(onVerify)}>
        <div className="details-section">
          <div className="details-section__title">
            <h2>Beneficiary Type</h2>
          </div>
          <SelectForm<BeneficiaryLookUpForm>
            options={beneficiaryTypeOptions}
            id="type"
            register={register}
            className="select-form"
            watch={watch}
          />
          {String(watchedBeneficiaryType) === '0' && (
            <>
              <div className="details-section__title">
                <h2>Beneficiary ID/ Passport No</h2>
              </div>
              <FormInput<BeneficiaryLookUpForm>
                id="nationalId"
                register={register}
                error={formState.errors.nationalId?.message}
                className="formInput"
                maxLength={18}
                type="text"
                label="National ID Number"
                example="e.g. 00-000000A00 for Citizens or Passport No. for Non-Citizens"
              />
            </>
          )}
        </div>
      </form>
      <div className="btn-wrapper mb-10">
        <button type="button" className="btn btn-cancel" onClick={onCancel}>
          Cancel
        </button>

        {String(watchedBeneficiaryType) === '0' ? (
          <button
            type="submit"
            form="beneficiary-lookup-form"
            className="btn btn-next btn-svg"
            disabled={isDisabledButton}
          >
            <Icon.Search />
            Proceed
          </button>
        ) : (
          <button type="submit" form="beneficiary-lookup-form" className="btn btn-next btn-svg">
            Next
          </button>
        )}
      </div>
    </>
  ) : null
}

export default BeneficiaryLookUp
