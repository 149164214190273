export const SEND_MOBILE_REQUEST = 'SEND_MOBILE_REQUEST'
export const SEND_MOBILE_SUCCESS = 'SEND_MOBILE_SUCCESS'
export const SEND_MOBILE_FAILURE = 'SEND_MOBILE_FAILURE'

export const SEND_PIN_CODE_REQUEST = 'SEND_PIN_CODE_REQUEST'
export const SEND_PIN_CODE_SUCCESS = 'SEND_PIN_CODE_SUCCESS'
export const SEND_PIN_CODE_FAILURE = 'SEND_PIN_CODE_FAILURE'



export enum userConstants {
  CHANGE_USER_EMAIL_REQUEST = 'CHANGE_USER_EMAIL_REQUEST',
  CHANGE_USER_EMAIL_SUCCESS = 'CHANGE_USER_EMAIL_SUCCESS',
  CHANGE_USER_EMAIL_FAILURE = 'CHANGE_USER_EMAIL_FAILURE',

  CHANGE_USER_EMAIL = 'CHANGE_USER_EMAIL',
}

export const SEND_TO_WHITE_LIST_REQUEST = 'SEND_TO_WHITE_LIST_REQUEST'
export const SEND_TO_WHITE_LIST_SUCCESS = 'SEND_TO_WHITE_LIST_SUCCESS'
export const SEND_TO_WHITE_LIST_FAILURE = 'SEND_TO_WHITE_LIST_FAILURE'

export enum preRegistrationConstants {
  SET_IS_MOBILE = 'SET_IS_MOBILE',
}
