import { isUserDataExist } from '../helpers/userHelper'
import { AmlDetail, AmlStatus, UserDetails } from '../interfaces/user'

export const getAvailableRoutes = (userDetails: UserDetails, userAml: AmlDetail | null) => {
  if (isUserDataExist(userDetails)) {
    if (userDetails.isEmailConfirmed) {
      if (!userAml) {
        return [
          { elementName: 'ProfilePage', path: '/profile' },
          { elementName: 'PastTransactionPage', path: '/past-transactions' },
          { elementName: 'BeneficiariesPage', path: '/beneficiaries' },
          { elementName: 'BeneficiaryPage', path: '/beneficiary/new' },
          { elementName: 'BeneficiariesPage', path: '/beneficiaries/select-beneficiary' },
          { elementName: 'EmailVerificationPendingPage', path: '/email-verification-pending' },
          { elementName: 'SumSubPage', path: '/verification' },
          { elementName: 'AgentsLocationPage', path: '/agents-location' },
        ]
      }
      if (userAml?.status === AmlStatus.RejectedWithRetry) {
        return [
          { elementName: 'ProfilePage', path: '/profile' },
          { elementName: 'PastTransactionPage', path: '/past-transactions' },
          { elementName: 'BeneficiariesPage', path: '/beneficiaries' },
          { elementName: 'BeneficiaryPage', path: '/beneficiary/new' },
          { elementName: 'BeneficiariesPage', path: '/beneficiaries/select-beneficiary' },
          { elementName: 'EmailVerificationPendingPage', path: '/email-verification-pending' },
          { elementName: 'SumSubPage', path: '/verification' },
          { elementName: 'PendingVerificationPage', path: '/pending' },
          { elementName: 'AgentsLocationPage', path: '/agents-location' },
        ]
      }

      if (userAml?.status === AmlStatus.Verified) {
        return [
          { elementName: 'ProfilePage', path: '/profile' },
          { elementName: 'PastTransactionPage', path: '/past-transactions' },
          { elementName: 'NewTransactionPage', path: '/transaction/new' },
          { elementName: 'BeneficiaryPage', path: '/beneficiary/new' },
          { elementName: 'PendingVerificationPage', path: '/pending' },
          { elementName: 'BeneficiariesPage', path: '/beneficiaries' },
          { elementName: 'BeneficiariesPage', path: '/beneficiaries/select-beneficiary' },
          { elementName: 'EmailVerificationPendingPage', path: '/email-verification-pending' },
          { elementName: 'AgentsLocationPage', path: '/agents-location' },
        ]
      }

      if (userAml?.status === AmlStatus.Pending) {
        return [
          { elementName: 'ProfilePage', path: '/profile' },
          { elementName: 'PastTransactionPage', path: '/past-transactions' },
          { elementName: 'BeneficiariesPage', path: '/beneficiaries' },
          { elementName: 'BeneficiaryPage', path: '/beneficiary/new' },
          { elementName: 'BeneficiariesPage', path: '/beneficiaries/select-beneficiary' },
          { elementName: 'EmailVerificationPendingPage', path: '/email-verification-pending' },
          { elementName: 'AgentsLocationPage', path: '/agents-location' },
          { elementName: 'PendingVerificationPage', path: '/pending' },
        ]
      }
    } else {
      return [
        { elementName: 'ProfilePage', path: '/profile' },
        { elementName: 'EmailVerificationPendingPage', path: '/email-verification-pending' },
      ]
    }
  } else {
    return [
      { elementName: 'ProfilePage', path: '/profile' },
      { elementName: 'UserDetailsPage', path: '/sing-up/user-details' },
      { elementName: 'UserDetailsPage', path: '/sing-up/user-address' },
      { elementName: 'EmailVerificationPendingPage', path: '/email-verification-pending' },
    ]
  }
}
