import { useEffect } from 'react'
import { PuffLoader } from 'react-spinners'

const VerificationCardChallenge = () => {
  useEffect(() => {
    window.parent.postMessage('Challenge completed', window.location.origin)
  }, [])

  return (
    <section className="verification-card-payment-page">
      <div className="spinner spinner-fixed">
        <PuffLoader size={70} color="#3171d8" />
        <p>Loading...</p>
      </div>
    </section>
  )
}

export default VerificationCardChallenge
