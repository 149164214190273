import { PresetActionTypes, PresetState } from '../../interfaces/redux-types/presetReduxTypes'
import { presetConstants } from '../constants/presetConstants'

export const initialState: PresetState = {
  isLoaded: false,
  isLoading: false,
  error: '',
  preset: null,
  availableFlow: [],
  earlyAccess: undefined,
}

export const presetReducer = (state = initialState, action: PresetActionTypes): PresetState => {
  switch (action?.type) {
    case presetConstants.GET_PRESET_REQUEST:
      return {
        ...state,
        isLoaded: false,
        isLoading: true,
        error: '',
      }
    case presetConstants.GET_PRESET_SUCCESS:
      return {
        ...state,
        isLoaded: true,
        isLoading: false,
        preset: action.payload,
      }
    case presetConstants.GET_AVAILABLE_FLOW_REQUEST:
      return {
        ...state,
        isLoaded: false,
        isLoading: true,
        error: '',
      }
    case presetConstants.GET_AVAILABLE_FLOW_SUCCESS:
      return {
        ...state,
        isLoaded: true,
        isLoading: false,
        availableFlow: action.payload,
      }
    case presetConstants.POST_EARLY_ACCESS_REQUEST:
      return {
        ...state,
        isLoaded: false,
        isLoading: true,
        error: '',
      }
    case presetConstants.POST_EARLY_ACCESS_SUCCESS:
      return {
        ...state,
        isLoaded: true,
        isLoading: false,
        earlyAccess: action.payload,
      }
    case presetConstants.POST_EARLY_ACCESS_FAILURE:
      return {
        ...state,
        isLoaded: false,
        isLoading: false,
      }
    default:
      return state
  }
}
