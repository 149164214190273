import { useState, useRef, ChangeEvent, useMemo } from 'react'
import ReCAPTCHA from 'react-google-recaptcha'
import InnerHeader from '../../components/innerHeader'
import { useAppSelector } from './../../hooks/dispatch'
import { createTicketZendesk } from '../../services/zendesk'
import { Icon } from '../../components/icons'
import { isUserDataExist } from '../../helpers/userHelper'
import { UserStatus } from '../../interfaces/user'

const captchaKey = process.env.REACT_APP_RECAPTCHA_KEY
const captchaDevKey = process.env.REACT_APP_RECAPTCHA_DEV_KEY

const HelpSupportPage = () => {
  const { userDetails, isLoading, isLoaded } = useAppSelector(state => state.user)

  const hasFullUserData = userDetails && isUserDataExist(userDetails)
  const isEmailConfirmed = userDetails && userDetails?.isEmailConfirmed

  const [formData, setFormData] = useState({ message: '', email: '' })
  const [formDataError, setFormDataError] = useState({ messageError: '', emailError: '' })
  const [isSuccessSection, setIsSuccessSection] = useState(false)
  const [captcha, setCaptcha] = useState<string>('')

  const captchaRef = useRef<ReCAPTCHA>(null)

  const emailBody = useMemo(() => {
    if (userDetails) {
      return `<div>
                <div>
                  ${userDetails.email ? `<p>Email for feedback: ${userDetails.email}</p>` : ''}
                  ${userDetails.firstName ? `<p>First name: ${userDetails.firstName}</p>` : ''}
                  ${userDetails.lastName ? `<p>Last name: ${userDetails.lastName}</p>` : ''}
                  ${userDetails.city ? `<p>City: ${userDetails.city}</p>` : ''}
                  ${userDetails.street ? `<p>Street: ${userDetails.street}</p>` : ''}
                  ${userDetails?.postcode ? `<p>Postcode: ${userDetails?.postcode}</p>` : ''}
                  ${userDetails?.phoneNumber ? `<p>Phone: ${userDetails?.phoneNumber}</p>` : ''}
                </div>
                <br />
                <p>Message: ${formData?.message}</p>
              </div>`
    } else {
      return `<div>
                <div><p>Email for feedback: ${formData.email}</p></div>
                <br />
                <p>Message: ${formData?.message}</p>
              </div>`
    }
  }, [formData.email, formData?.message, userDetails])

  const checkEmail = (email: string) => {
    const emailPattern =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    return emailPattern.test(email)
  }

  const onChangeInput = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { value, name } = event.target

    setFormData({ ...formData, [name]: value })

    if (name === 'message') {
      setFormDataError({ ...formDataError, messageError: '' })
    }

    if (name === 'email') {
      setFormDataError({ ...formDataError, emailError: '' })
    }
  }

  const validateForm = () => {
    let isValidForm = true
    if (!checkEmail(formData.email) && !userDetails) {
      setFormDataError(prevState => ({
        ...prevState,
        emailError: 'Please enter a valid email address',
      }))
      isValidForm = false
    }
    if (!formData.message.length) {
      setFormDataError(prevState => ({ ...prevState, messageError: 'Please enter a message' }))
      isValidForm = false
    }

    return isValidForm
  }

  const handleSubmit = () => {
    if (validateForm()) {
      try {
        createTicketZendesk(emailBody)

        captchaRef.current?.reset()
        setFormData({ ...formData, message: '', email: '' })
        setCaptcha('')
        setIsSuccessSection(true)
      } catch (error) {}
    }
    // createTicketZendesk(emailBody)

    // captchaRef.current?.reset()
    // setFormData({ ...formData, message: '' })
    // setCaptcha('')
  }

  const loginClassName = useMemo(() => {
    return userDetails?.status === UserStatus.Accepted && hasFullUserData && isEmailConfirmed
      ? ' login'
      : ''
  }, [hasFullUserData, isEmailConfirmed, userDetails?.status])

  return (
    <>
      {(isLoaded && !isLoading) || !userDetails ? (
        <section className="support-page h-100-mb">
          <div className="container container-md container-100">
            {isSuccessSection ? (
              <>
                <InnerHeader
                  title="Help And Support"
                  icon={<Icon.HelpSupport />}
                  titlePosition="center"
                />
                <div className={`support-page-thanks${loginClassName}`}>
                  <Icon.SuccessTick />
                  <p>
                    Thank you for contacting us -<br />
                    our team will look into your query
                    <br />
                    and reply to you within 2h
                  </p>
                  <div className="btn-wrapper btn-wrapper-sm center m-b10">
                    <button
                      className="btn btn-xxl btn-svg"
                      onClick={() => setIsSuccessSection(false)}
                    >
                      <Icon.HelpSupport />
                      OK
                    </button>
                  </div>
                </div>
              </>
            ) : (
              <>
                <InnerHeader title="Help And Support" icon={<Icon.HelpSupport />} />
                <div className="support-page-container">
                  <p className="support-page-text">
                    Please get in touch with us here if you have any issues, and we will endeavour
                    to assist you. Note that we will be in touch over email to resolve your query
                    within 2 hours, so please ensure you verify your email address is correct, prior
                    to contacting us here.
                  </p>
                  {!userDetails ? (
                    <div className="form-input">
                      <input
                        placeholder="Your Email"
                        name="email"
                        onChange={onChangeInput}
                        value={formData.email}
                        {...(formDataError.emailError && { className: 'error' })}
                      />
                      <p className="form-input-error">{formDataError.emailError}</p>
                    </div>
                  ) : null}
                  <div className="area-block">
                    <textarea
                      placeholder="Write your query here"
                      name="message"
                      onChange={onChangeInput}
                      value={formData.message}
                      {...(formDataError.messageError && { className: 'error' })}
                    />
                    <p className="form-input-error">{formDataError.messageError}</p>
                    <div className="captha">
                      <ReCAPTCHA
                        sitekey={(captchaKey as string) || (captchaDevKey as string)}
                        ref={captchaRef}
                        onChange={value => setCaptcha(value!)}
                      />
                    </div>
                    <div className="btn-wrapper justify-end hide-mobile">
                      <button
                        className="btn btn-secondary btn-xs"
                        onClick={handleSubmit}
                        disabled={!captcha}
                      >
                        Submit
                      </button>
                    </div>
                  </div>
                </div>
                <div className="show-mobile t-center mb-10">
                  <button
                    className="btn btn-primary btn-xxl show btn-svg"
                    onClick={handleSubmit}
                    disabled={!captcha}
                  >
                    <Icon.HelpSupport />
                    Request Help
                  </button>
                </div>
              </>
            )}
          </div>
        </section>
      ) : null}
    </>
  )
}

export default HelpSupportPage
