import { Currency } from "./commonTypes"

export interface Recipient {
  firstName: string
  lastName: string
  phoneNumber: string
}

export interface CreateTopUpResponse {
  topUpRequestId: number
  accountName: string
  accountNumber: string
  sortCode: string
  paymentReference: string
  srcCurrency: string
  amountToSend: string
}

export type ScheduleDay = {
  dayOfWeek: string
  startTime: string
  endTime: string
  isAvailable: boolean
}

export type AgentsLocationItemResponse = {
  agentId: string
  title: string
  address: string
  latitude: number
  longitude: number
  schedule: Array<ScheduleDay>
}

export enum PaymentMethod {
  KuvaCash = 1,
  CreditCard = 2, //KWL
  BankAccount = 3,
  Cash = 4,
  InstantCredit = 5,
  ApplePay = 6, //KWL
  GooglePay = 7, //KWL
  CashOnDelivery = 8,
  CreditWalletOnly = 9,
  CreditVendor = 10,
  MPesa = 11,
  BankTransfer = 100, //KWL
  OpenBanking = 105,
  MobileWallet = 106,
}

export enum FeeUnitType {
  FixedAmout = 0,
  GradualAmout = 1,
  Percentage = 2,
  PercentAndFixed = 3,
  PercentOrMinFixed = 4,
}

export enum FeePayerType {
  Kuva = 0,
  User = 1,
  Instance = 2,
}

export type PaymentOption = {
  paymentProviderMethod: {
    id: number
    paymentProvider: {
      id: number
      name: string
    }
    paymentMethod: PaymentMethod
  }
  sendAmount: number
  totalFee: number
}

export type WithdrawalPaymentProvider = {
  id: number,
  paymentProvider: {
    id: number,
    name: string,
    // type: 170
  },
  paymentMethod: PaymentMethod,
  // paymentOptionType: 1
}

export type WithdrawalPaymentOption = {
  paymentProvider: {
    id: number,
    name: string,
    // type: 170
  },
  paymentMethod: PaymentMethod,
  currency: Currency,
  currencySign: string
  title: string
  longTitle: string
  description: string
  id: number
  order: number
}

export type WithdrawalPaymentOptionListByCountry = {
  paymentOptions: Array<WithdrawalPaymentOption>
  name: string,
  isO2Code: string,
  id: number,
  currency: Currency | null
  phoneCode: string
}

export type ShortWithdrawalPaymentProvider = {
  withdrawalProviderId: number,
  withdrawalMethod: PaymentMethod
}

export type SetPaymentProviderServerRequest = {
  paymentProviderId: number
  paymentMethod: PaymentMethod
}
