import { Dispatch, LegacyRef, SetStateAction, useEffect } from 'react'
import { useForm } from 'react-hook-form'
import ReactModal from 'react-modal'
import { localStorageHelper } from '../helpers/useLocalStorage'
import { useAppDispatch, useAppSelector } from '../hooks/dispatch'
import { EarlyAccessForm } from '../interfaces/preset'
import { presetActions } from '../redux/action-creators/presetActions'
import { Icon } from './icons'
import FormInput from './interfaceComponents/FormInput'

// const password = process.env.REACT_APP_PASS_FOR_EARLY_ACCESS

type EarlyAccessModalProps = {
  setIsModalOpen: Dispatch<SetStateAction<boolean>>
  isModalOpen: boolean
  forwardedRef: LegacyRef<ReactModal> | null
}

const EarlyAccessModal = ({ setIsModalOpen, isModalOpen, forwardedRef }: EarlyAccessModalProps) => {
  const dispatch = useAppDispatch()
  const { isLoading } = useAppSelector(state => state.preset)
  const {
    register,
    handleSubmit,
    setError,
    setValue,
    formState: { errors },
  } = useForm<EarlyAccessForm>()

  useEffect(() => {
    if (isModalOpen) {
      document.getElementById('root')?.classList.add('blur')
      return () => {
        document.getElementById('root')?.classList.remove('blur')
      }
    }
  }, [isModalOpen])

  const onClose = () => {
    setIsModalOpen(false)
    setValue('password', '')
    setError('password', { message: '' })
  }

  const onVerify = async (data: EarlyAccessForm) => {
    const pass = data.password.trim()

    const res = await dispatch(presetActions.postEarlyAccess({ password: pass }))

    if (typeof res === 'boolean') {
      if (res) {
        localStorageHelper.setData('isVerified', 'true')
        setIsModalOpen(false)
        setValue('password', '')
      } else {
        setError('password', { message: 'The invite code is not valid' })
      }
    }
  }
  return (
    <ReactModal
      className="modal-early-access"
      overlayClassName="modal-early-access-overlay"
      isOpen={isModalOpen}
      ariaHideApp={false}
      ref={forwardedRef}
    >
      <>
        <div className="modal-early-access-header">
          <button onClick={onClose}>
            <Icon.Cross />
          </button>
        </div>
        <div className="modal-early-access-body">
          <form onSubmit={handleSubmit(onVerify)}>
            <h3>Please enter your password to get early access</h3>
            <FormInput<EarlyAccessForm>
              id="password"
              register={register}
              error={errors.password?.message}
              className="formInput"
              type="password"
              label="Password"
              maxLength={20}
              autoFocus={true}
            />
            <div className="btn-wrapper center">
              <button className="btn btn-additional" disabled={isLoading}>
                Verify
              </button>
            </div>
          </form>
        </div>
      </>
    </ReactModal>
  )
}

export default EarlyAccessModal
