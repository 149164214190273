import { Dispatch } from 'redux'
import { PostCalculateServerRequest } from '../../interfaces/calculation'
import {
  PostCalculation,
  RemoveTotalAmountToSendFromState,
} from '../../interfaces/redux-types/calculation'
import { GetUserCalculationLimitSuccess } from '../../interfaces/redux-types/userReduxTypes'
import { calculationServices } from '../../services/calculationServices'
import { calculationConstants } from '../constants/calculationConstants'
import { userConstants } from '../constants/userConstants'
import { CalculationLimit } from '../../interfaces/user'

function postAuthorizeCalculationAction(
  body: PostCalculateServerRequest,
  checkReceiveField?: (value: number, limits: Array<CalculationLimit>) => void
) {
  return async (dispatch: Dispatch<PostCalculation | GetUserCalculationLimitSuccess>) => {
    dispatch({ type: calculationConstants.POST_CALCULATE_REQUEST })
    await calculationServices.postAuthorizedCalculation(body).then(
      res => {
        dispatch({
          type: calculationConstants.POST_CALCULATE_SUCCESS,
          payload: res,
        })
        dispatch({
          type: userConstants.GET_USER_CALCULATION_LIMITS_SUCCESS,
          payload: res.limits,
        })

        //For Calculation Page
        checkReceiveField && checkReceiveField(res.amountToReceive, res.limits)
      },
      err => {
        dispatch({
          type: calculationConstants.POST_CALCULATE_FAILURE,
          payload: err,
        })
      }
    )
  }
}

function postAnonymousCalculationAction(
  body: PostCalculateServerRequest,
  checkReceiveField?: (value: number, limits: Array<CalculationLimit>) => void
) {
  return async (dispatch: Dispatch<PostCalculation>) => {
    dispatch({ type: calculationConstants.POST_CALCULATE_REQUEST })
    await calculationServices.postAnonymousCalculation(body).then(
      res => {
        dispatch({
          type: calculationConstants.POST_CALCULATE_SUCCESS,
          payload: res,
        })
        //For Calculation Page
        checkReceiveField && checkReceiveField(res.amountToReceive, res.limits)
        
      },
      err => {
        dispatch({
          type: calculationConstants.POST_CALCULATE_FAILURE,
          payload: err,
        })
      }
    )
  }
}

function removeTotalAmountToSendFromState() {
  return (dispatch: Dispatch<RemoveTotalAmountToSendFromState>) => {
    dispatch({ type: calculationConstants.CLEAR_CALCULATION_DATA_FROM_STATE })
  }
}

export const calculationActions = {
  postAuthorizeCalculationAction,
  postAnonymousCalculationAction,
  removeTotalAmountToSendFromState,
}
