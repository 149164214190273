import { useCallback } from 'react'
import Select, { SingleValue } from 'react-select'

import { WithdrawalOption } from '../../../interfaces/calculation'

type SelectorProps = {
  options: Array<WithdrawalOption>
  onChange: (value: any, name: string) => void
  styles: any
  labelName?: string
  value: WithdrawalOption | null
  name: string
  error: string
}

const CustomSelector = ({
  options,
  styles,
  labelName,
  onChange,
  name,
  value,
  error,
}: SelectorProps) => {
  const onSelectorChange = useCallback(
    (value: SingleValue<WithdrawalOption> | null) => {
      onChange(value, name)
    },
    [name, onChange]
  )
  return (
    <div className="custom-selector">
      {labelName && <label htmlFor="selector">{labelName}</label>}
      <Select
        className={error ? 'error' : ''}
        isSearchable={false}
        styles={styles}
        isMulti={false}
        options={options}
        placeholder="Select..."
        value={value}
        onChange={onSelectorChange}
      />
      <p className="form-input-error">{error}</p>
    </div>
  )
}

export default CustomSelector
