import React, {
  ChangeEvent,
  FormEvent,
  useEffect,
  useMemo,
  useState,
  SyntheticEvent,
  useCallback,
} from 'react'
import { useNavigate } from 'react-router-dom'
import { Icon } from '../../components/icons'
import { useAppDispatch, useAppSelector } from '../../hooks/dispatch'
import { userActions } from '../../redux/action-creators/userActions'
import { userServices } from '../../services/userServices'
import Timer from '../interfaceComponents/Timer'
import Modal from '../Modal'

const ProfileDetails: React.FC = () => {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()

  const [email, setEmail] = useState('')
  const [IsEmailChanged, setIsEmailChanged] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')
  const apiEmail = useAppSelector(state => state.user.userDetails?.email) || ''
  const isEmailConfirmed = useAppSelector(state => state.user.userDetails?.isEmailConfirmed)
  const resendEmailDelayTime =
    useAppSelector(state => state.preset.preset?.emailDelayTimeInSeconds) || 60

  // const [isOpenModal, setIsOpenModal] = useState(false)
  // const [showToolTip, setShowToolTip] = useState(false)
  const [isDisabledButton, setIsDisabledButton] = useState(false)

  useEffect(() => {
    setEmail(apiEmail)
  }, [apiEmail])

  const checkEmail = (email: string) => {
    const emailPattern =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

    return emailPattern.test(email)
  }

  const showEmailChangedSuccessfully = () => {
    setIsEmailChanged(true)
    setTimeout(() => setIsEmailChanged(false), 3000)
  }

  const onChange = (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value

    setEmail(value)
    setErrorMessage('')
  }

  const handleError = (error?: any) => {
    if (error?.errorCode === '4000000048') {
      setErrorMessage(error?.message)
    }
    if (error?.errorCode === '4000000161') {
    }
    if (!error) {
      setErrorMessage('Please enter a valid email address')
    }
  }

  const makeRedirect = useCallback(async () => {
    await dispatch(userActions.getUserDetails())

    localStorage.setItem(
      'emailResendTimer',
      String(Math.round(Date.now() / 1000) + resendEmailDelayTime)
    )
    navigate('/email-verification-pending', { state: { from: '/profile' } })
  }, [dispatch, navigate, resendEmailDelayTime])

  const onSubmit = async (e: FormEvent) => {
    e.preventDefault()

    if (checkEmail(email)) {
      const emailForRequest = { email }

      try {
        await userServices.changeUserEmail(emailForRequest)
        dispatch(userActions.changeUserEmailSuccess(emailForRequest, makeRedirect))
        showEmailChangedSuccessfully()
      } catch (error) {
        handleError(error)
      }
    } else {
      handleError()
    }
  }

  // const onDelete = (event: SyntheticEvent) => {
  //   event.stopPropagation()

  //   setShowToolTip(false)
  //   setIsOpenModal(true)
  // }

  const verifiedSection = useMemo(() => {
    if (apiEmail && isEmailConfirmed) {
      return (
        <div className="fz-xSmall verified-success t-white">
          <Icon.SuccessTick /> Verified
        </div>
      )
    }

    if (apiEmail && !isEmailConfirmed) {
      return <div className="fz-xSmall verified-error">Unverified Email - Check your inbox</div>
    }
  }, [apiEmail, isEmailConfirmed])

  const errorSection = useMemo(() => {
    if (errorMessage) {
      return <p className="form-input-error">{errorMessage}</p>
    }

    if (!errorMessage && IsEmailChanged) {
      return <p className="fz-xSmall form-input-success t-white">Email was changed successfully</p>
    }
  }, [IsEmailChanged, errorMessage])

  return (
    <div>
      <form className="profile-form" onSubmit={onSubmit}>
        <div className="profile-form-row">
          <div>
            <label htmlFor="email">Email</label>
            <input onChange={onChange} value={email} name="email" type="email" />
          </div>
          {apiEmail ? (
            <button
              className="btn btn-secondary btn-xs"
              disabled={Boolean(errorMessage) || email === apiEmail || isDisabledButton}
              type="submit"
            >
              Change
            </button>
          ) : (
            <button
              disabled={Boolean(errorMessage)}
              className="btn btn-secondary btn-xs"
              type="submit"
            >
              Add email
            </button>
          )}
        </div>
        {errorSection}
        <Timer text="Change email in:" setIsDisabledButton={setIsDisabledButton} />
        <div className="verified mb-10">{verifiedSection}</div>
        {/* <button type="button" className="btn btn-secondary btn-xs" onClick={onDelete}>
          Delete Account
        </button> */}
      </form>

      {/* <Modal
        setIsOpenModal={setIsOpenModal}
        isOpenModal={isOpenModal}
        modalHeader="Account Deletion "
        modalMainWord="Warning"
        modalBody=" - this action will delete your account and you will no longer be able to access your acount, your past transactions or any of your account activity. Please type “I Understand” in the box below to confirm account deletion."
        modalType="deleteProfileModal"
        confirmFunction={() => {}}
      /> */}
    </div>
  )
}
export default ProfileDetails
