import { CompanyRecipient } from '../../interfaces/beneficiary'

type CompanyBeneficiaryDetailsProps = {
  recipient: CompanyRecipient | undefined
  isShownBeneficiaryAccount?: boolean
}

const CompanyBeneficiaryDetails = ({
  recipient,
  isShownBeneficiaryAccount,
}: CompanyBeneficiaryDetailsProps) => {
  return (
    <>
      <div className="user-info-block__row">
        <span>Company</span>
        <span title={`${recipient?.companyName}`}>{`${recipient?.companyName}`}</span>
      </div>
      {isShownBeneficiaryAccount && (
        <div className="user-info-block__row">
          <span>Bank Account #</span>
          <span>{recipient?.accountNumber}</span>
        </div>
      )}
    </>
  )
}

export default CompanyBeneficiaryDetails
