import { Dispatch, SetStateAction, useCallback, useMemo } from 'react'
import { Link } from 'react-router-dom'
import { useAppDispatch } from '../../../hooks/dispatch'
import { CardPaymentButtonVariant } from '../../../interfaces/cardPayment'
import { remittanceActions } from '../../../redux/action-creators/remittanceActions'

type CardPaymentButtonProps = {
  isDisabledButton: boolean
  buttonType: CardPaymentButtonVariant
  setCardPaymentStatusForBtn: Dispatch<SetStateAction<CardPaymentButtonVariant>>
}

const CardPaymentButton = ({
  isDisabledButton,
  buttonType,
  setCardPaymentStatusForBtn,
}: CardPaymentButtonProps) => {
  const dispatch = useAppDispatch()

  const onTryAgain = useCallback(() => {
    dispatch(remittanceActions.setCardPaymentViewSection('card-selection'))
    setCardPaymentStatusForBtn('processing')
  }, [dispatch, setCardPaymentStatusForBtn])

  const successBtn = useMemo(
    () => (
      <button type="button" className="btn btn-next btn-50">
        <Link to="/past-transactions">View Transactions</Link>
      </button>
    ),
    []
  )

  const failureBtn = useMemo(
    () => (
      <button type="button" className="btn btn-next btn-50" onClick={onTryAgain}>
        Try Again
      </button>
    ),
    [onTryAgain]
  )
  const processingBtn = useMemo(
    () => (
      <button
        type="submit"
        form="new-card-form"
        disabled={isDisabledButton}
        className="btn btn-next btn-50"
      >
        Pay
      </button>
    ),
    [isDisabledButton]
  )

  const currentButton = useMemo(() => {
    switch (buttonType) {
      case '':
        return null
      case 'processing':
        return processingBtn
      case 'success':
        return successBtn
      case 'failure':
        return failureBtn
      case 'withdrawal-failure':
        return successBtn
      default:
        return processingBtn
    }
  }, [buttonType, failureBtn, processingBtn, successBtn])

  return <>{currentButton}</>
}

export default CardPaymentButton
