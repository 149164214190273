import { useCallback, useEffect, useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import OtherTransactionDetails from '../../components/transactions/OtherTransactionDetails'
import { useAppDispatch, useAppSelector } from '../../hooks/dispatch'
import { recipientDetailsInLocalStorage } from '../../helpers/recipientData'
import { Recipient, TransactionCreationRequest } from '../../helpers/initialModel'
import { remittanceActions } from '../../redux/action-creators/remittanceActions'
import { BeneficiaryType, CompanyRecipient, KWLRecipient } from '../../interfaces/beneficiary'
import { transactionDataInLocalStorage } from '../../helpers/localStorage/transactionData'
import TermsAndConditions from '../../components/transactions/TermsAndConditions'
import { TransactionDataStateForNewTx } from '../../interfaces/remittanceType'
import isEqual from 'lodash/isEqual'
import {
  getWithdrawalPaymentOptionsForCalcSection,
  getWithdrawalPaymentOptionsForCalcSectionV2,
} from '../../helpers/configs/optionConfigs'
import omit from 'lodash/omit'
import { PaymentMethod } from '../../interfaces'
import { calculationHelper } from '../../helpers/calculationData'
import { localStorageHelper } from '../../helpers/useLocalStorage'
import { getWithdrawalPaymentOptionsByCountry } from '../../helpers/calcHelper'

/* 
TO DO: Remove conditions, requests and paymentIntentSwitcher variable 
after full integration of payment intents
*/

const paymentIntentSwitcher = process.env.REACT_APP_PAYMENT_INTENT_SWITCHER

const NewTransactionPage = () => {
  const navigate = useNavigate()
  const dispatch = useAppDispatch()

  const withdrawalPaymentOptionsByCountry = useAppSelector(
    state => state.remittance.withdrawalPaymentOptionsByCountry
  )
  const getWithdrawalPaymentOptionsByCountryLoadState = useAppSelector(
    state => state.remittance.getWithdrawalPaymentOptionsByCountryLoadState
  )

  // const withdrawalPaymentProviders = useAppSelector(
  //   state => state.remittance.withdrawalPaymentProvider,
  //   isEqual
  // )
  // const getWithdrawalPaymentProviderLoadState = useAppSelector(
  //   state => state.remittance.getWithdrawalPaymentProviderLoadState
  // )

  const [currentSection, setCurrentSection] = useState('other-transaction-details')
  const [transactionData, setTransactionData] = useState<TransactionDataStateForNewTx>(
    new TransactionCreationRequest()
  )
  const [recipientData, setRecipient] = useState<KWLRecipient | CompanyRecipient>(new Recipient())

  useEffect(() => {
    const recipient = recipientDetailsInLocalStorage.getRecipientDetails()

    if (recipient) {
      setRecipient(recipient)
      // dispatch(remittanceActions.getWithdrawalPaymentProviderList('USD'))
      dispatch(remittanceActions.getWithdrawalPaymentOptionsByCountry())
    } else {
      navigate('/')
    }

    return () => {
      recipientDetailsInLocalStorage.removeRecipientDetails()
    }
  }, [dispatch, navigate])

  const withdrawalPaymentProviderOptions = useMemo(() => {
    const recipient = recipientDetailsInLocalStorage.getRecipientDetails()
    const calcData = localStorageHelper.getData('calcData')

    const withdrawalPaymentOptions = getWithdrawalPaymentOptionsByCountry(
      withdrawalPaymentOptionsByCountry,
      Number(calcData.receiveCountryId)
    )

    if (recipient?.type === BeneficiaryType.Company) {
      const currentWithdrawalPaymentProvider = withdrawalPaymentOptions.filter(
        withdrawalPaymentProviderItem =>
          withdrawalPaymentProviderItem.paymentMethod === PaymentMethod.BankTransfer
      )

      return getWithdrawalPaymentOptionsForCalcSectionV2(currentWithdrawalPaymentProvider)
    } else {
      return getWithdrawalPaymentOptionsForCalcSectionV2(withdrawalPaymentOptions)
    }
  }, [withdrawalPaymentOptionsByCountry])

  useEffect(() => {
    if (
      withdrawalPaymentProviderOptions.length <= 0 &&
      !getWithdrawalPaymentOptionsByCountryLoadState.isLoaded
    ) {
      return
    }

    const transactionData = transactionDataInLocalStorage.getTransactionData()

    if (transactionData) {
      const receiveMethodFromLocalStorage = localStorage.getItem('receiveMethod')
      if (receiveMethodFromLocalStorage) {
        const receiveMethod = JSON.parse(receiveMethodFromLocalStorage)
        const newTransactionData = { ...transactionData, receiveMethod }

        setTransactionData(newTransactionData)
      } else {
        setTransactionData(transactionData)
      }
    }

    if (!transactionData) {
      const receiveMethodFromLocalStorage = localStorage.getItem('receiveMethod')
      const receiveMethod =
        receiveMethodFromLocalStorage !== null
          ? JSON.parse(receiveMethodFromLocalStorage)
          : withdrawalPaymentProviderOptions[0]
      const recipient = recipientDetailsInLocalStorage.getRecipientDetails()
      const newTransactionData = new TransactionCreationRequest(
        recipient.type === BeneficiaryType.Company
          ? withdrawalPaymentProviderOptions[0]
          : receiveMethod
      )

      setTransactionData(newTransactionData)
      transactionDataInLocalStorage.setTransactionData(newTransactionData)
    }

    return () => {
      transactionDataInLocalStorage.removeTransactionData()
    }
  }, [getWithdrawalPaymentOptionsByCountryLoadState.isLoaded, withdrawalPaymentProviderOptions])

  const onCreationTransaction = useCallback(
    (receiveAmount: number | undefined) => {
      const calcData = localStorageHelper.getData('calcData')

      const calculationData = {
        // sendCurrency: srcCurrency,
        sendCurrency: calcData.sendCurrency,
        receiveCurrency: calcData.receiveCurrency,
        receiveAmount,
        receiveCountryId: calcData.receiveCountryId,
        sourceCountryId: calcData.sourceCountryId,
      }

      const omittedTransactionDetails = omit(transactionData, 'receiveMethod')

      //NEW - PAYMENT INTENT
      if (paymentIntentSwitcher === 'true' && !recipientData.id) {
        const transaction = {
          recipient: recipientData,
          ...calculationData,
          transferDetails: transactionData.transactionDetails,
        }

        dispatch(remittanceActions.createTransactionBySendAgain(transaction!, navigate))
        return
      }

      const transaction = {
        ...omittedTransactionDetails,
        ...{ beneficiaryId: recipientData.id },
        ...calculationData,
        ...(withdrawalPaymentProviderOptions.length > 0
          ? {
              withdrawalMethod: transactionData.receiveMethod?.paymentMethod,
              withdrawalProviderId: transactionData.receiveMethod?.paymentProviderId,
            }
          : {}),
      }
      dispatch(remittanceActions.createTransaction(transaction!, navigate))
    },
    [dispatch, navigate, recipientData, transactionData, withdrawalPaymentProviderOptions.length]
  )

  return (
    <>
      {currentSection === 'other-transaction-details' ? (
        <OtherTransactionDetails
          setCurrentSection={setCurrentSection}
          transactionData={transactionData}
          setTransactionData={setTransactionData}
          recipientData={recipientData}
          withdrawalPaymentProviderOptions={withdrawalPaymentProviderOptions}
        />
      ) : (
        <TermsAndConditions
          recipientData={recipientData}
          createTransaction={onCreationTransaction}
          transactionData={transactionData}
          setCurrentSection={setCurrentSection}
        />
      )}
    </>
  )
}

export default NewTransactionPage
