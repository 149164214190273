type FakeInputProps = {
  value?: string | number
  label: string
}

const FakeInput = ({ value, label }: FakeInputProps) => {
  return (
    <div className="fake-input">
      <div className="fake-input-label">{label}</div>
      <div className="fake-input-value">{value}</div>
    </div>
  )
}

export default FakeInput
