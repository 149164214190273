import { EarlyAccessForm, GetAvailableFlowResponse, GetPresetResponse } from '../interfaces/preset'
import { anonimRequests } from '../utils/anonimusRequest'

const getPreset = () => {
  const path = ['info']

  return anonimRequests.anonimusRequest<null, GetPresetResponse>(path)
}

const getAvailableFlow = () => {
  const path = ['flows']

  return anonimRequests.anonimusRequest<null, GetAvailableFlowResponse>(path)
}

const postEarlyAccess = (body: EarlyAccessForm) => {
  const path = ['early-access']

  return anonimRequests.postAnonimus<EarlyAccessForm, boolean>(path, body)
}

export const presetServices = {
  getPreset,
  getAvailableFlow,
  postEarlyAccess,
}
