import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import MainHeader from '../components/Header'
import Footer from '../components/Footer'
import { Outlet, useLocation } from 'react-router-dom'
import { useAppDispatch, useAppSelector } from '../hooks/dispatch'
import { UserStatus } from '../interfaces/user'
import { preRegistrationActions } from '../redux/action-creators/preRegistrationActions'
import EarlyAccessModal from '../components/EarlyAccessModal'
import { localStorageHelper } from '../helpers/useLocalStorage'
import CalculationPageV2 from '../pages/CalculationPage'
import ErrorModal from '../components/modal/ErrorModal'
import { detectMobile } from '../helpers/utils'
import { FlowType } from '../interfaces/preset'

// const isEarlyAccess = process.env.REACT_APP_IS_EARLY_ACCESS || true

const Layout = () => {
  const dispatch = useAppDispatch()
  const { pathname } = useLocation()
  const isMobileHeader = pathname === '/' ? 'main' : ''
  const userDetails = useAppSelector(state => state.user.userDetails)
  const isMobileState = useAppSelector(state => state.preRegistration.isMobile)
  const flows = useAppSelector(state => state.preset.availableFlow)
  const isVerifiedLocalStorage = localStorageHelper.getData('isVerified')
  const isDetectedMobile = useMemo(() => detectMobile(), [])

  const [isModalOpen, setIsModalOpen] = useState(false)
  const [isVerified, setIsVerified] = useState(false)

  const modalRef = useRef<ReactModal>(null)

  const isEarlyAccess = useMemo(() => {
    const earlyAccessFlow = flows.find(flow => flow.flowType === FlowType.EarlyAccess)

    return Boolean(earlyAccessFlow?.state)
  }, [flows])

  const onClickAway = useCallback((event: any) => {
    if (!modalRef.current?.portal?.content?.contains(event.target)) {
      event.stopPropagation()
      event.preventDefault()
      setIsModalOpen(true)
    }
  }, [])

  useEffect(() => {
    if (isEarlyAccess && !isVerified) {
      document.addEventListener('click', onClickAway, { capture: true })

      return () => {
        document.removeEventListener('click', onClickAway, { capture: true })
      }
    }
  }, [isEarlyAccess, isVerified, onClickAway])

  useEffect(() => {
    if (isEarlyAccess && !isVerifiedLocalStorage) {
      setIsModalOpen(true)
    }
  }, [isEarlyAccess, isVerifiedLocalStorage])

  useEffect(() => {
    if (isEarlyAccess) {
      setIsVerified(isVerifiedLocalStorage)
    }
  }, [isEarlyAccess, isVerifiedLocalStorage])

  useEffect(() => {
    if (!isMobileState && isDetectedMobile) {
      dispatch(preRegistrationActions.setIsMobile(isDetectedMobile))
    }
  }, [dispatch, isDetectedMobile, isMobileState])

  return (
    <>
      <MainHeader />
      <main
        className={`${isMobileHeader}${
          userDetails !== null && userDetails.status === UserStatus.Accepted ? '' : ' main-user'
        }`}
      >
        {isEarlyAccess && !isVerified ? <CalculationPageV2 /> : <Outlet />}
      </main>
      <Footer />
      {flows.length > 0 && (
        <EarlyAccessModal
          forwardedRef={modalRef}
          setIsModalOpen={setIsModalOpen}
          isModalOpen={isModalOpen}
        />
      )}
      <ErrorModal />
    </>
  )
}

export default Layout
