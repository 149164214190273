import { SyntheticEvent, useMemo } from 'react'
import { CompanyRecipient, SanctionStatus } from '../../interfaces/beneficiary'
import Tooltip from 'rc-tooltip'
import { Icon } from '../icons'
import * as Flags from 'country-flag-icons/react/3x2'

type CompanyTypeListItemContentProps = {
  beneficiary: CompanyRecipient
  hover: boolean
  showToolTip: boolean
  isDisabledButton: boolean
  isShownSendCash: boolean
  sendCash: (event: SyntheticEvent) => void
  onBeneficiaryDetails: (event: SyntheticEvent) => void
  onDelete: (event: SyntheticEvent) => void
  openTooltip: (event: SyntheticEvent) => void
  onEdit: (event: SyntheticEvent) => void
}

const CompanyTypeListItemContent = ({
  beneficiary,
  hover,
  isDisabledButton,
  isShownSendCash,
  sendCash,
  showToolTip,
  onBeneficiaryDetails,
  onDelete,
  openTooltip,
  onEdit,
}: CompanyTypeListItemContentProps) => {
  const isMobile = useMemo(() => {
    const toMatch = [
      /Android/i,
      /webOS/i,
      /iPhone/i,
      /iPad/i,
      /iPod/i,
      /BlackBerry/i,
      /Windows Phone/i,
    ]

    return toMatch.some(toMatchItem => navigator.userAgent.match(toMatchItem))
  }, [])

  const FlagComponent = Flags['ZW']

  const isSanctionedBeneficiary = useMemo(() => {
    return beneficiary.sanctionStatus === SanctionStatus.OnSanctionsListManualConfirmed
  }, [beneficiary.sanctionStatus])

  return (
    <>
      <div className="table-item beneficiary-item medium">
        <span className="name" title={`${beneficiary.companyName} | ''`}>
          {beneficiary.companyName || 'TEST'}
        </span>
      </div>
      <div className="table-item beneficiary-item row">
        <>
          <div style={{ width: '28px', paddingRight: '5px' }}>
            <FlagComponent />
          </div>
          <div>ZW</div>
        </>
      </div>
      <div className="table-item beneficiary-item"></div>
      <div className="table-item beneficiary-item row beneficiary-type">
        <Icon.CompanyBeneficiary />
        <div>Company</div>
      </div>
      {(hover || isMobile) && (
        <div className="table-item show-more">
          <div>
            {!isDisabledButton && !isSanctionedBeneficiary && isShownSendCash && (
              <button className="btn btn-primary btn-additional hide-mobile" onClick={sendCash}>
                Send Money
              </button>
            )}
          </div>
          <Tooltip
            visible={showToolTip}
            placement="left"
            trigger={['click']}
            mouseLeaveDelay={0}
            getTooltipContainer={elem => elem}
            showArrow={false}
            overlay={
              <div>
                {!isDisabledButton && !isSanctionedBeneficiary && isShownSendCash && (
                  <>
                    <button className="show-mobile" onClick={sendCash} disabled={isDisabledButton}>
                      Send Money
                    </button>
                    <button onClick={onBeneficiaryDetails}>View Details</button>
                  </>
                )}
                <button onClick={onDelete}>Delete</button>
                <button onClick={onEdit}>Edit</button>
              </div>
            }
          >
            <button className="circle-with-dots" onClick={openTooltip}>
              <Icon.CircleWithDots />
            </button>
          </Tooltip>
        </div>
      )}
    </>
  )
}

export default CompanyTypeListItemContent
