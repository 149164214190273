import React, { useCallback, useState } from 'react'
import { FieldValues, UseControllerProps, useController } from 'react-hook-form'
import NumberFormat, { NumberFormatValues } from 'react-number-format'

type MoneyInputProps = {
  prefix: string
  decimalScale: number
  disabled: boolean
  name: string
  value: string | number
  onChange: (value: any, name: string, focusCounter?: number) => void
  shouldCleanInputWhenFirstClick?: boolean
}

const MoneyInput = ({
  prefix = '$',
  decimalScale,
  name,
  disabled,
  onChange,
  value,
  shouldCleanInputWhenFirstClick,
}: MoneyInputProps) => {
  const [inputCounter, setInputCounter] = useState(0)
  const [amountFocus, setAmountFocus] = useState('')

  const onInputChange = useCallback(
    (value: NumberFormatValues) => {
      onChange(value.floatValue, name)
    },
    [name, onChange]
  )
  const onFocus = () => {
    setAmountFocus(' focus')
    if (inputCounter === 0) {
      onChange('', name, inputCounter)
      setInputCounter(inputCounter + 1)
    }
  }

  const onBlur = () => {
    setAmountFocus('')
  }

  return (
    <NumberFormat
      {...(shouldCleanInputWhenFirstClick && { onFocus })}
      // onFocus={onFocus}
      // onBlur={onBlur}
      value={value}
      prefix={prefix}
      decimalScale={decimalScale}
      onValueChange={onInputChange}
      fixedDecimalScale={true}
      allowNegative={false}
      isNumericString={true}
      allowLeadingZeros={false}
      type="tel"
      maxLength={9}
      autoComplete="off"
      disabled={disabled}
    />
  )
}

export default MoneyInput
