import { Dispatch, SetStateAction, useCallback } from 'react'
import { useAppDispatch } from '../../../hooks/dispatch'
import { remittanceActions } from '../../../redux/action-creators/remittanceActions'
import NewCardForm from './NewCardForm'

type NewCardProps = {
  setDisabledButton: Dispatch<SetStateAction<boolean>>
}

const NewCard = ({ setDisabledButton }: NewCardProps) => {
  const dispatch = useAppDispatch()

  const onGoBack = useCallback(() => {
    dispatch(remittanceActions.setCardPaymentViewSection('card-selection'))
  }, [dispatch])

  return (
    <div className="card-selection">
      <button className="card-selection__title" onClick={onGoBack}>
        Back To Card Selection
      </button>
      <NewCardForm setDisabledButton={setDisabledButton} />
    </div>
  )
}

export default NewCard
