import { Country } from '../interfaces/auth'
import {
  CalculationLimit,
  ChangeEmailRequest,
  UpdateUserSubscriptionServerRequest,
  UserAddressForm,
  // UserDetailRequest,
  UserDetails,
  UserDetailsForm,
} from '../interfaces/user'
import { anonimRequests } from '../utils/anonimusRequest'
import { authorizeRequests } from '../utils/authorizeRequest'

function updateUserDetails(userDetail: UserDetailsForm) {
  const path = ['profile']
  const body = {
    firstName: userDetail.firstName,
    lastName: userDetail.lastName,
    dateOfBirth: userDetail.dateOfBirth,
    email: userDetail.email,
  }

  return authorizeRequests.putAuthorize<UserDetailsForm, null>(path, body)
}

function updateUserAddress(userAddress: UserAddressForm) {
  const path = ['profile', 'address']

  return authorizeRequests.putAuthorize<UserAddressForm, UserDetails>(path, userAddress)
}

function changeUserEmail(email: ChangeEmailRequest) {
  return authorizeRequests.putAuthorize(['profile', 'email'], email)
}

function resendEmail() {
  const path = ['profile', 'email', 'resend']

  return authorizeRequests.putAuthorize<null, null>(path)
}

function confirmEmail(token: string) {
  const path = ['profile', 'email', 'confirm', token]

  return anonimRequests.putAnonimus(path)
}

function getUserDetails() {
  return authorizeRequests.authorizeRequest<null, UserDetails>(['profile'])
}

function getUserCountryList() {
  const path = ['countries', 'source']

  return anonimRequests.anonimusRequest<null, Array<Country>>(path)
}

function removeUser() {
  return authorizeRequests.deleteAuthorize(['profile'])
}

function getUserCalculationLimit() {
  const path = ['flow-limits']

  return authorizeRequests.authorizeRequest<null, CalculationLimit[]>(path)
}

function getAnonymousCalculationLimit() {
  const path = ['flow-limits', 'common']

  return anonimRequests.anonimusRequest<null, CalculationLimit[]>(path)
}

function getUserAmlList() {
  const path = ['aml-checks']

  return authorizeRequests.authorizeRequest<null, any>(path)
}

function updateUserSubscription(checkBoxValue: boolean) {
  const path = ['profile', 'news-and-updates']
  const body = { enabled: checkBoxValue }

  return authorizeRequests.putAuthorize<UpdateUserSubscriptionServerRequest, null>(path, body)
}

export const userServices = {
  updateUserDetails,
  getUserDetails,
  updateUserAddress,
  changeUserEmail,
  resendEmail,
  getUserCountryList,
  removeUser,
  confirmEmail,
  getUserCalculationLimit,
  getAnonymousCalculationLimit,
  getUserAmlList,
  updateUserSubscription,
}
