import {
  PostCalculateServerRequest,
  PostCalculationServerResponse,
} from '../interfaces/calculation'
import { anonimRequests } from '../utils/anonimusRequest'
import { authorizeRequests } from '../utils/authorizeRequest'

/* 
TO DO: Remove conditions, requests and paymentIntentSwitcher variable 
after full integration of payment intents
*/

const paymentIntentSwitcher = process.env.REACT_APP_PAYMENT_INTENT_SWITCHER

function postAuthorizedCalculation(body: PostCalculateServerRequest) {
  //NEW - Payment Intent
  let path = ['payment-intents', 'calc']

  if (!paymentIntentSwitcher || paymentIntentSwitcher === 'false') {
    //OLD - TopUp
    path = ['top-ups', 'calc']
  }

  return authorizeRequests.postAuthorize<PostCalculateServerRequest, PostCalculationServerResponse>(
    path,
    body
  )
}

function postAnonymousCalculation(body: PostCalculateServerRequest) {
  //NEW - Payment Intent
  let path = ['payment-intents', 'calc']

  if (!paymentIntentSwitcher || paymentIntentSwitcher === 'false') {
    //OLD - TopUp
    path = ['top-ups', 'calc']
  }

  return anonimRequests.postAnonimus<PostCalculateServerRequest, PostCalculationServerResponse>(
    path,
    body
  )
}

export const calculationServices = {
  postAuthorizedCalculation,
  postAnonymousCalculation,
}
