import { AuthActions, AuthState } from '../../interfaces/redux-types/authActionTypes'
import { authConstants } from '../constants/authConstants'

export const initialState: AuthState = {
  phoneNumber: '',
  userDetails: null,
  countryList: [],
  error: null,
  errorCode: '',
  isLoaded: false,
  isLoading: false,
  loggedIn: false,
  sumSubToken: '',
}

export const authReducer = (state = initialState, action: AuthActions): AuthState => {
  switch (action?.type) {
    case authConstants.VERIFY_MOBILE_NUMBER_REQUEST: {
      return {
        ...state,
        isLoaded: false,
        isLoading: true,
        errorCode: '',
      }
    }
    case authConstants.VERIFY_MOBILE_NUMBER_SUCCESS:
      return {
        ...state,
        isLoaded: true,
        isLoading: false,
        phoneNumber: action.payload,
      }
    case authConstants.VERIFY_MOBILE_NUMBER_FAILURE:
      return {
        ...state,
        isLoaded: false,
        isLoading: false,
        errorCode: action.payload.errorCode,
      }
    case authConstants.VERIFY_CODE_FROM_SMS_REQUEST:
      return {
        ...state,
        isLoaded: false,
        isLoading: true,
        errorCode: '',
      }
    case authConstants.VERIFY_CODE_FROM_SMS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isLoaded: true,
        loggedIn: true,
      }
    case authConstants.VERIFY_CODE_FROM_SMS_FAILURE:
      return {
        ...state,
        isLoaded: false,
        isLoading: false,
        errorCode: action.payload.errorCode,
      }
    case authConstants.GET_USER_COUNTRY_LIST_SUCCESS:
      return {
        ...state,
        countryList: action.payload,
      }
    case authConstants.LOGOUT_FAILURE:
      return {
        ...state,
        error: action.payload,
      }
    case authConstants.GET_SUM_SUB_TOKEN_SUCCESS:
      return {
        ...state,
        sumSubToken: action.payload.token,
      }
    case authConstants.GET_SUM_SUB_TOKEN_FAILURE:
      return {
        ...state,
        error: action.payload,
      }
    case authConstants.REMOVE_MOBILE_NUMBER_FROM_STATE:
      return {
        ...state,
        phoneNumber: '',
      }
    default:
      return state
  }
}
