import { StylesConfig } from "react-select"
import { WithdrawalOption } from "../../../../interfaces/calculation"

export const selectorStyles: StylesConfig<WithdrawalOption, false> = {
  container: (provided: any) => ({
    ...provided,
    backgroundColor: '#EFF6FF',
    width: '100%',
    fontSize: 18,
    borderRadius: 10,
  }),
  singleValue: (provided: any) => ({
    ...provided,
    color: '#3171D8',
    margin: 0,
  }),
  option: (provided: any, state: any) => ({
    ...provided,
    display: 'flex',
    alignItems: 'center',
    fontSize: 16,
    padding: '10px 16px',
    margin: 0,
    color: '#3171D8',
    borderRadius: '5px',
    '&:active': {
      backgroundColor: '#2D82F5',
      color: '#FFFFFF',
    },
    backgroundColor: state.isFocused ? '#D3E6FF' : '#FFFFFF',
    ...(state.isSelected && { fontWeight: 600 }),
    cursor: 'pointer',
  }),
  control: (provided: any) => ({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '10px 16px',
  }),
  valueContainer: (provided: any) => ({
    ...provided,
    padding: 0,
    marginRight: '5px',
  }),
  indicatorSeparator: () => ({}),
  indicatorsContainer: (provided: any) => ({
    ...provided,
  }),
  dropdownIndicator: (provided: any, state: any) => ({
    ...provided,
    padding: 0,
    cursor: 'pointer',
    color: '#3171D8',

    transform: state.selectProps.menuIsOpen && 'rotate(180deg)',
    '&:hover': {
      color: '#3171D8',
    },
  }),
  menu: (provided: any) => ({
    ...provided,
    width: '100%',
    margin: 0,
  }),
  menuList: (provided: any) => ({
    ...provided,
    fontSize: 14,
    padding: 0,
    overflowY: 'auto',
  }),
}

export const receiveMethodSelectorConfig = {
  selectorStyles,
}
