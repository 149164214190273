import { anonimRequests } from '../utils/anonimusRequest'



const sendToWhiteList = (body: {}) => {
  const path = ['users']

  return anonimRequests.postAnonimus(path, body)
}

export const preRegistrationServices = {
  sendToWhiteList,
}
