import { CountryOption } from "../../interfaces/beneficiary"

function setRecipientCountry(countryData: CountryOption) {
  localStorage.setItem('recipientCountry', JSON.stringify(countryData))
}

function getRecipientCountry(): CountryOption {
  const unParsed = localStorage.getItem('recipientCountry')

  return unParsed !== null ? JSON.parse(unParsed) : null
}

export const recipientCountryInLocalStorage = {
  setRecipientCountry,
  getRecipientCountry,
}