import { format } from 'date-fns'

export const getDateFormatWithTime = (date: string) => {
  const ms = Date.parse(date)

  return new Date(ms).toLocaleString('en-US')
}

export const getDateFormatWithoutTime = (date: string) => {
  const defaultDate = '0001-01-01T00:00:00'
  const ms = Date.parse(date)

  return defaultDate === date
    ? ''
    : new Date(ms).toLocaleString('en-US', { month: '2-digit', day: '2-digit', year: 'numeric' })
}

export const getShortDate = (date: string) => {
  const defaultDate = '0001-01-01T00:00:00'
  const ms = Date.parse(date)

  return defaultDate === date
    ? ''
    : new Date(ms).toLocaleString('en-US', { month: '2-digit', day: '2-digit', year: '2-digit' })
}

export const getDateFormatWithoutTimeForInput = (date: string | undefined) => {
  return date ? format(new Date(date), 'yyyy-MM-dd') : ''
}

export const getDateFormatForDateInput = (date: string) => {
  return date ? format(new Date(date), 'dd-MM-yyyy') : ''
}
