import { ErrorActionTypes, ErrorState } from '../../interfaces/redux-types/errorActionTypes'
import { errorConstants } from '../constants/errorConstants'

const initialState: ErrorState = {
  errorMessage: '',
  errorCode: '',
}

export const ErrorReducer = (state = initialState, action: ErrorActionTypes): ErrorState => {
  switch (action?.type) {
    case errorConstants.SET_ERROR: {
      return {
        ...state,
        errorCode: action.payload.errorCode,
        id: action.payload.id
      }
    }
    case errorConstants.CLEAR_ERROR: {
      return {
        ...state,
        errorMessage: '',
        errorCode: '',
      }
    }
    default:
      return state
  }
}
