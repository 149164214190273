import { PaymentMethod } from '.'

export type Card = {
  savedCard: boolean
  cardHolderName: string
}
export type SavedCard = {
  extId: string
  last4Digits: string
  cardHolderName: string
  cardExpireMonth: number
  cardExpireYear: number
  brand: string
  tokenExpireDate: string
}

export type CardToDelete = {
  id: string
  last4Digits: string
}

export type InitializeByNewCardServerRequest = {
  cardHolderName: string
  sessionHref: string
  saveCard: boolean
  topUp: {
    id: number | string
  }
}
export type InitializeBySavedCardServerRequest = {
  savedCardId: string
  topUp: {
    id: number | string
  }
}

export type InitializeByNewCardForPaymentIntentServerRequest = {
  cardHolderName: string
  sessionHref: string
  saveCard: boolean
  paymentIntent: {
    extId: number | string
  }
}

export type InitializeBySavedCardForPaymentIntentServerRequest = {
  savedCardId: string
  paymentIntent: {
    extId: number | string
  }
}

export type InitializeCardServerResponse = {
  transactionReference: string
  jwt: string
  bin: string
  url: string
}

export type AuthenticateCardServerRequest = {
  transactionReference: string
  deviceData: {
    collectionReference: string
    acceptHeader: string
    userAgentHeader: string
    browserLanguage: string
    // ipAddress: "192.0.0.0"
  }
}

export enum PaymentResultType {
  Authorized = 10,
  Challenged = 20,
  Failed = 30,
  Unavailable = 40,
}

type Challenge = {
  reference: string
  url: string
  jwt: string
  payload: string
}

export type AuthenticateCardServerResponse = {
  outcome: PaymentResultType
  transactionReference: string
  challenge: Challenge
}

export type SectionName =
  | ''
  // | 'card-payment-variant'
  | 'card-selection'
  | 'new-card'
  | 'device-data'
  | 'result'
  | 'challenge'

export type CardPaymentButtonVariant = '' | 'processing' | 'success' | 'failure' | 'withdrawal-failure'

export type PaymentSection = 'payment-providers' | 'payment-summary' | PaymentMethod | ''

export type VerifyCardPaymentServerRequest = {
  transactionReference: string
  challengeReference: string
}

export type VerifyCardPaymentServerResponse = {
  outcome: PaymentResultType
}
