import { useCallback, useEffect, useMemo, useRef } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { authHelper } from '../helpers/authData'
import { calculationHelper } from '../helpers/calculationData'
import { useAppSelector } from '../hooks/dispatch'
import { isUserDataExist } from '../helpers/userHelper'
import { beneficiaryService } from '../services/beneficiaryService'
import { isEmpty } from 'lodash'

type QueryParams = {
  receiveAmount: string
  receiveCountryId: string
  receiveCurrency: string
  sendCurrency: string
  sourceCountryId: string
  withdrawalMethod: string
  withdrawalProviderId: string
  receiveSign: string
}
const NonLoggedInPrivateRoute = ({ children }: { children: JSX.Element }) => {
  const navigate = useNavigate()
  const [queryParams] = useSearchParams()
  const { userDetails } = useAppSelector(state => state.user)

  const token = useRef(authHelper.getAuthData())

  const urlParams = useMemo((): QueryParams => {
    const params = {
      receiveAmount: '',
      receiveCountryId: '',
      receiveCurrency: '',
      sendCurrency: '',
      sourceCountryId: '',
      withdrawalMethod: '',
      withdrawalProviderId: '',
      receiveSign: '',
    }

    for (const item of queryParams.entries()) {
      const [param, value] = item

      params[param] = value
    }

    return params
  }, [queryParams])

  useEffect(() => {
    if (urlParams.receiveAmount) {
      calculationHelper.setCalculationData(urlParams.receiveAmount)

      const receiveMethod = {
        currency: urlParams.receiveCurrency,
        currencySign: urlParams.receiveSign,
        paymentMethod: Number(urlParams.withdrawalMethod),
        paymentProviderId: Number(urlParams.withdrawalProviderId),
      }
      localStorage.setItem('receiveMethod', JSON.stringify(receiveMethod))

      calculationHelper.setCalculationDataV2(urlParams)
    }
  }, [urlParams])

  const makeRedirect = useCallback(async () => {
    const isFullUserData = userDetails && isUserDataExist(userDetails)
    const isEmailConfirmed = userDetails && userDetails.isEmailConfirmed

    const offset = 0
    const step = 1
    const beneficiaries = await beneficiaryService.getBeneficiaries(offset, step)

    if (isFullUserData && isEmailConfirmed) {
      if (isEmpty(beneficiaries.items)) {
        navigate('/beneficiary/new', { state: { from: 'continue-transaction' } })
        return
      } else {
        navigate('/beneficiaries/select-beneficiary', { state: { from: 'continue-transaction' } })
        return
      }
    }

    if (isFullUserData && !isEmailConfirmed) {
      navigate('/email-verification-pending', { state: { from: 'continue-transaction' } })
      return
    }

    if (!isFullUserData) {
      navigate('/sing-up/user-details', { state: { from: 'continue-transaction' } })
      return
    }

    navigate('/')
  }, [navigate, userDetails])

  useEffect(() => {
    if (token.current && userDetails) {
      makeRedirect()
    }
  }, [makeRedirect, userDetails])

  return <>{token.current ? null : children}</>
}

export default NonLoggedInPrivateRoute
