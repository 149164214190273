import { SyntheticEvent, useCallback, useEffect, useMemo, useState } from 'react'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { useAppDispatch, useAppSelector } from '../../hooks/dispatch'
import { Icon } from '../../components/icons'
import InnerHeader from '../../components/innerHeader'
import { PaymentMethod } from '../../interfaces'
import { remittanceActions } from '../../redux/action-creators/remittanceActions'
import 'rc-tooltip/assets/bootstrap_white.css'
import CalculationSection from '../../components/calculation/CalculationSection'
import PaymentDetailsSection from '../../components/transactions/PaymentDetailsSection'
import CardPaymentButton from '../../components/transactions/cardPayment/CardPaymentButton'
import { CardPaymentButtonVariant, PaymentSection } from '../../interfaces/cardPayment'
import Modal from '../../components/Modal'
import { TransactionPaymentProvider, TransactionStatus } from '../../interfaces/remittanceType'
import {
  getWithdrawalPaymentOptionsForSummary,
  getWithdrawalPaymentOptionsForSummaryV2,
} from '../../helpers/configs/optionConfigs'
import { getWithdrawalPaymentOptionsByCountry } from '../../helpers/calcHelper'
import { PuffLoader } from 'react-spinners'


type LocationState = {
  state: {
    searchedTxValue: string
    from: string
  }
}

const PayForYourTransferPage = () => {
  const navigate = useNavigate()
  const dispatch = useAppDispatch()

  const { id } = useParams()
  const location = useLocation() as LocationState
  const searchedTxValue = location?.state?.searchedTxValue || undefined
  const from = location?.state?.from || '/past-transactions'

  const {
    transaction: topUpKWLinfo,
    getTransactionForPaymentLoadState,
    cardPaymentViewSection,
    setTxStatusLoadState,
    // withdrawalPaymentProvider,
    withdrawalPaymentOptionsByCountry,
    createPaymentOrderLoadState,
  } = useAppSelector(state => state.remittance)

  const [paymentSection, setPaymentSection] = useState<PaymentSection>('payment-providers')
  const [cardPaymentStatusForBtn, setCardPaymentStatusForBtn] =
    useState<CardPaymentButtonVariant>('')
  const [isDisabledButton, setDisabledButton] = useState(true)
  const [isOpenModal, setIsOpenModal] = useState(false)

  const withdrawalPaymentProviderOptions = useMemo(() => {
    const withdrawalPaymentOptions = getWithdrawalPaymentOptionsByCountry(
      withdrawalPaymentOptionsByCountry,
      topUpKWLinfo.receiveCountryId
    )

    return getWithdrawalPaymentOptionsForSummaryV2(withdrawalPaymentOptions)
  }, [topUpKWLinfo.receiveCountryId, withdrawalPaymentOptionsByCountry])

  const isShownBeneficiaryAccount = useMemo(() => {
    return topUpKWLinfo.withdrawalMethod === PaymentMethod.BankTransfer
  }, [topUpKWLinfo.withdrawalMethod])

  useEffect(() => {
    if (id) {
      dispatch(remittanceActions.getTransaction(id, navigate))
      dispatch(remittanceActions.getWithdrawalPaymentOptionsByCountry())
      // dispatch(remittanceActions.getWithdrawalPaymentProviderList('USD'))
    }
  }, [dispatch, id, navigate])

  useEffect(() => {
    if (
      (topUpKWLinfo.status === TransactionStatus.Ordered ||
        topUpKWLinfo.status === TransactionStatus.Transferred) &&
      !setTxStatusLoadState.isLoaded
    ) {
      if (topUpKWLinfo.paymentProvider === null) {
        setPaymentSection('payment-providers')
      } else {
        setPaymentSection('payment-summary')
      }
    }
  }, [setTxStatusLoadState.isLoaded, topUpKWLinfo.paymentProvider, topUpKWLinfo.status])

  const onGoTransactions = () => {
    navigate('/past-transactions', { state: { searchedTxValue } })
  }

  const onGoBack = useCallback(() => {
    if (
      (paymentSection === PaymentMethod.BankTransfer ||
        paymentSection === PaymentMethod.CreditCard) &&
      cardPaymentViewSection !== 'result'
    ) {
      setPaymentSection('payment-summary')
      return
    }

    navigate(from, { state: { searchedTxValue } })
  }, [cardPaymentViewSection, from, navigate, paymentSection, searchedTxValue])

  const onContinue = () => {
    if (paymentSection === PaymentMethod.BankTransfer) {
      id && dispatch(remittanceActions.transferTX(id, navigate))
    }
  }

  const onInfo = (event: SyntheticEvent) => {
    setIsOpenModal(true)
  }

  const onPayClick = useCallback(() => {
    if (topUpKWLinfo.paymentProvider === null) {
      return
    }
    if (
      TransactionPaymentProvider.Thunes === topUpKWLinfo.paymentProvider
      // &&
      // topUpKWLinfo.cashInPaymentMethod === PaymentMethod.CreditCard
    ) {
      dispatch(remittanceActions.createPaymentOrder(topUpKWLinfo.topUpRequestExtId))

      return
    }

    topUpKWLinfo.cashInPaymentMethod && setPaymentSection(topUpKWLinfo.cashInPaymentMethod)
  }, [
    dispatch,
    topUpKWLinfo.cashInPaymentMethod,
    topUpKWLinfo.paymentProvider,
    topUpKWLinfo.topUpRequestExtId,
  ])

  const displayedAmountBeforePayment = useMemo(() => {
    if (
      topUpKWLinfo.status === TransactionStatus.Ordered ||
      topUpKWLinfo.status === TransactionStatus.Transferred
    ) {
      if (paymentSection === 'payment-providers')
        return (topUpKWLinfo.amountToSend - topUpKWLinfo.paymentProviderDiffFee).toFixed(2)
    }

    return topUpKWLinfo.amountToSend.toFixed(2)
  }, [
    paymentSection,
    topUpKWLinfo.amountToSend,
    topUpKWLinfo.paymentProviderDiffFee,
    topUpKWLinfo.status,
  ])

  return (
    <section className="h-100-mb">
      {createPaymentOrderLoadState.isLoading && (
        <div className="spinner spinner-fixed">
          <PuffLoader size={70} color="#3171d8" />
        </div>
      )}
      <div className="container container-md container-100">
        <>
          <InnerHeader title="Pay For Your Transfer" onGoBack={onGoBack} />
          <CalculationSection
            mode="calculation-block-with-recipient"
            receiveAmount={topUpKWLinfo.amountToReceive}
            recipient={topUpKWLinfo.recipient}
            isShownBeneficiaryAccount={isShownBeneficiaryAccount}
            currency={topUpKWLinfo.receiveCurrency}
            currencySign={topUpKWLinfo.receiveCurrencySign}
          />

          <div className="payment-block">
            {!getTransactionForPaymentLoadState.isLoading && (
              <>
                <div className="payment-block__count">
                  {topUpKWLinfo.status === TransactionStatus.Ordered ||
                  topUpKWLinfo.status === TransactionStatus.Transferred
                    ? 'Payment Due:'
                    : 'Payment:'}
                  <span>
                    {topUpKWLinfo.srcCurrencySign}
                    {displayedAmountBeforePayment}
                  </span>
                </div>
                {(paymentSection === PaymentMethod.BankTransfer ||
                  paymentSection === PaymentMethod.CreditCard) && (
                  <div className="payment-block__info" onClick={onInfo}>
                    <Icon.Info />
                  </div>
                )}
              </>
            )}
          </div>

          {(topUpKWLinfo.status === TransactionStatus.Ordered ||
            topUpKWLinfo.status === TransactionStatus.Transferred) && (
            <PaymentDetailsSection
              transaction={topUpKWLinfo}
              setPaymentSection={setPaymentSection}
              paymentSection={paymentSection}
              setDisabledButton={setDisabledButton}
              setCardPaymentStatusForBtn={setCardPaymentStatusForBtn}
              withdrawalPaymentProviderOptions={withdrawalPaymentProviderOptions}
            />
          )}

          {(topUpKWLinfo.status === TransactionStatus.NotRedeemed ||
            topUpKWLinfo.status === TransactionStatus.Redeemed) && (
            <>
              <div className="btn-wrapper mb-10 justify-between">
                <div className="checkbox-icon checkbox-icon-white cursor-default checkbox-icon-50">
                  <Icon.WhiteCheckbox />
                  <p>Funds Transfer Confirmed</p>
                </div>
                <button onClick={onGoTransactions} className="btn btn-next btn-50">
                  View Transactions
                </button>
              </div>
              {/* <div className="mainContent-footer">
                <div className="container container-md">
                  <div className="btn-wrapper">
                    <button
                      onClick={print}
                      className='btn btn-primary'>
                      Print Page
                    </button>
                  </div>
                </div>
              </div> */}
            </>
          )}
          {topUpKWLinfo.status === TransactionStatus.Transferred && (
            <>
              {paymentSection === PaymentMethod.BankTransfer && (
                <div className="btn-wrapper mb-10 justify-between">
                  <div className="checkbox-icon checkbox-icon-white cursor-default checkbox-icon-50">
                    <Icon.WhiteCheckbox />
                    <p>Funds Transfer Confirmed</p>
                  </div>
                  <button onClick={onGoTransactions} className="btn btn-next btn-50">
                    View Transactions
                  </button>
                </div>
              )}
              {paymentSection === PaymentMethod.CreditCard && (
                <div className="btn-wrapper mb-10">
                  {cardPaymentStatusForBtn && (
                    <button type="button" className="btn btn-cancel btn-50" onClick={onGoBack}>
                      Back
                    </button>
                  )}
                  <CardPaymentButton
                    isDisabledButton={isDisabledButton}
                    setCardPaymentStatusForBtn={setCardPaymentStatusForBtn}
                    buttonType={cardPaymentStatusForBtn}
                  />
                </div>
              )}
            </>
          )}
          {topUpKWLinfo.status === TransactionStatus.Ordered && (
            <div className="btn-wrapper mb-10">
              {(cardPaymentStatusForBtn || paymentSection === PaymentMethod.BankTransfer) && (
                <button type="button" className="btn btn-cancel btn-50" onClick={onGoBack}>
                  Back
                </button>
              )}
              {paymentSection === PaymentMethod.BankTransfer && (
                <button onClick={onContinue} className="btn btn-next btn-50">
                  I’ve Transferred
                </button>
              )}
              {paymentSection === PaymentMethod.CreditCard && (
                <CardPaymentButton
                  isDisabledButton={isDisabledButton}
                  setCardPaymentStatusForBtn={setCardPaymentStatusForBtn}
                  buttonType={cardPaymentStatusForBtn}
                />
              )}
            </div>
          )}
          {paymentSection === 'payment-summary' && (
            <div className="btn-wrapper mb-10">
              <button type="button" className="btn btn-cancel btn-50" onClick={onGoBack}>
                Cancel
              </button>
              <button onClick={onPayClick} className="btn btn-next btn-50">
                Pay
              </button>
            </div>
          )}
        </>
        <Modal
          modalType="transactionDetails"
          setIsOpenModal={setIsOpenModal}
          isOpenModal={isOpenModal}
          modalHeader="Transaction Details"
          modalBody={topUpKWLinfo}
        />
      </div>
    </section>
  )
}
export default PayForYourTransferPage
