export enum remittanceConstants {
  CHANGE_SEND_AMOUNT = 'CHANGE_SEND_AMOUNT',

  VERIFY_MOBILE_REQUEST = 'VERIFY_MOBILE_REQUEST',
  VERIFY_MOBILE_SUCCESS = 'VERIFY_MOBILE_SUCCESS',
  VERIFY_MOBILE_FAILURE = 'VERIFY_MOBILE_FAILURE',

  CANCEL_TRANSACTION_REQUEST = 'CANCEL_TRANSACTION_REQUEST',
  CANCEL_TRANSACTION_SUCCESS = 'CANCEL_TRANSACTION_SUCCESS',
  CANCEL_TRANSACTION_FAILURE = 'CANCEL_TRANSACTION_FAILURE',

  GET_TRANSACTIONS_REQUEST = 'GET_TRANSACTIONS_REQUEST',
  GET_TRANSACTIONS_SUCCESS = 'GET_TRANSACTIONS_SUCCESS',
  GET_TRANSACTIONS_FAILURE = 'GET_TRANSACTIONS_FAILURE',

  GET_TRANSACTIONS_BY_SEARCH_REQUEST = 'GET_TRANSACTIONS_BY_SEARCH_REQUEST',
  GET_TRANSACTIONS_BY_SEARCH_SUCCESS = 'GET_TRANSACTIONS_BY_SEARCH_SUCCESS',
  GET_TRANSACTIONS_BY_SEARCH_FAILURE = 'GET_TRANSACTIONS_BY_SEARCH_FAILURE',

  CREATE_TRANSACTION_REQUEST = 'CREATE_TRANSACTION_REQUEST',
  CREATE_TRANSACTION_SUCCESS = 'CREATE_TRANSACTION_SUCCESS',
  CREATE_TRANSACTION_FAILURE = 'CREATE_TRANSACTION_FAILURE',

  CREATE_TRANSACTION_BY_SEND_AGAIN_REQUEST = 'CREATE_TRANSACTION_BY_SEND_AGAIN_REQUEST',
  CREATE_TRANSACTION_BY_SEND_AGAIN_SUCCESS = 'CREATE_TRANSACTION_BY_SEND_AGAIN_SUCCESS',
  CREATE_TRANSACTION_BY_SEND_AGAIN_FAILURE = 'CREATE_TRANSACTION_BY_SEND_AGAIN_FAILURE',

  UPDATE_RECIPIENT_IN_TOP_UP_REQUEST = 'UPDATE_RECIPIENT_IN_TOP_UP_REQUEST',
  UPDATE_RECIPIENT_IN_TOP_UP_SUCCESS = 'UPDATE_RECIPIENT_IN_TOP_UP_SUCCESS',
  UPDATE_RECIPIENT_IN_TOP_UP_FAILURE = 'UPDATE_RECIPIENT_IN_TOP_UP_FAILURE',

  CLEAR_PAST_TRANSACTIONS = 'CLEAR_PAST_TRANSACTIONS',

  CLEAR_PAST_TRANSACTIONS_ERROR = 'CLEAR_PAST_TRANSACTIONS_ERROR',

  SAVE_TRANSACTION_SEARCHED_DATA = 'SAVE_TRANSACTION_SEARCHED_DATA',

  CLEAR_TRANSACTION_SEARCHED_DATA = 'CLEAR_TRANSACTION_SEARCHED_DATA',

  GET_TRANSACTION_REQUEST = 'GET_TRANSACTION_REQUEST',
  GET_TRANSACTION_SUCCESS = 'GET_TRANSACTION_SUCCESS',
  GET_TRANSACTION_FAILURE = 'GET_TRANSACTION_FAILURE',

  TRANSFER_REQUEST = 'TRANSFER_REQUEST',
  TRANSFER_SUCCESS = 'TRANSFER_SUCCESS',
  TRANSFER_FAILURE = 'TRANSFER_FAILURE',

  GET_NOT_REDEEMED_TRANSACTIONS_NUMBER_REQUEST = 'GET_NOT_REDEEMED_TRANSACTIONS_NUMBER_REQUEST',
  GET_NOT_REDEEMED_TRANSACTIONS_NUMBER_SUCCESS = 'GET_NOT_REDEEMED_TRANSACTIONS_NUMBER_SUCCESS',
  GET_NOT_REDEEMED_TRANSACTIONS_NUMBER_FAILURE = 'GET_NOT_REDEEMED_TRANSACTIONS_NUMBER_FAILURE',

  INITIALIZE_BY_NEW_CARD_REQUEST = 'INITIALIZE_BY_NEW_CARD_REQUEST',
  INITIALIZE_BY_NEW_CARD_SUCCESS = 'INITIALIZE_BY_NEW_CARD_SUCCESS',
  INITIALIZE_BY_NEW_CARD_FAILURE = 'INITIALIZE_BY_NEW_CARD_FAILURE',

  AUTHENTICATE_CARD_REQUEST = 'AUTHENTICATE_CARD_REQUEST',
  AUTHENTICATE_CARD_SUCCESS = 'AUTHENTICATE_CARD_SUCCESS',
  AUTHENTICATE_CARD_FAILURE = 'AUTHENTICATE_CARD_FAILURE',

  VERIFY_CARD_PAYMENT_REQUEST = 'VERIFY_CARD_PAYMENT_REQUEST',
  VERIFY_CARD_PAYMENT_SUCCESS = 'VERIFY_CARD_PAYMENT_SUCCESS',
  VERIFY_CARD_PAYMENT_FAILURE = 'VERIFY_CARD_PAYMENT_FAILURE',

  GET_SAVED_CARD_REQUEST = 'GET_SAVED_CARD_REQUEST',
  GET_SAVED_CARD_SUCCESS = 'GET_SAVED_CARD_SUCCESS',
  GET_SAVED_CARD_FAILURE = 'GET_SAVED_CARD_FAILURE',

  INITIALIZE_BY_SAVED_CARD_REQUEST = 'INITIALIZE_BY_SAVED_CARD_REQUEST',
  INITIALIZE_BY_SAVED_CARD_SUCCESS = 'INITIALIZE_BY_SAVED_CARD_SUCCESS',
  INITIALIZE_BY_SAVED_CARD_FAILURE = 'INITIALIZE_BY_SAVED_CARD_FAILURE',

  DELETE_SAVED_CARD_REQUEST = 'DELETE_SAVED_CARD_REQUEST',
  DELETE_SAVED_CARD_SUCCESS = 'DELETE_SAVED_CARD_SUCCESS',
  DELETE_SAVED_CARD_FAILURE = 'DELETE_SAVED_CARD_FAILURE',

  SET_CARD_PAYMENT_FAILURE = 'SET_CARD_PAYMENT_FAILURE',

  SET_CARD_PAYMENT_VIEW_SECTION_SUCCESS = 'SET_CARD_PAYMENT_VIEW_SECTION_SUCCESS',

  GET_AGENTS_LOCATION_REQUEST = 'GET_AGENTS_LOCATION_REQUEST',
  GET_AGENTS_LOCATION_SUCCESS = 'GET_AGENTS_LOCATION_SUCCESS',
  GET_AGENTS_LOCATION_FAILURE = 'GET_AGENTS_LOCATION_FAILURE',

  GET_PAYMENT_PROVIDER_REQUEST = 'GET_PAYMENT_PROVIDER_REQUEST',
  GET_PAYMENT_PROVIDER_SUCCESS = 'GET_PAYMENT_PROVIDER_SUCCESS',
  GET_PAYMENT_PROVIDER_FAILURE = 'GET_PAYMENT_PROVIDER_FAILURE',

  GET_WITHDRAWAL_PAYMENT_PROVIDER_REQUEST = 'GET_WITHDRAWAL_PAYMENT_PROVIDER_REQUEST',
  GET_WITHDRAWAL_PAYMENT_PROVIDER_SUCCESS = 'GET_WITHDRAWAL_PAYMENT_PROVIDER_SUCCESS',
  GET_WITHDRAWAL_PAYMENT_PROVIDER_FAILURE = 'GET_WITHDRAWAL_PAYMENT_PROVIDER_FAILURE',

  GET_WITHDRAWAL_PAYMENT_OPTIONS_BY_COUNTRY_REQUEST = 'GET_WITHDRAWAL_PAYMENT_OPTIONS_BY_COUNTRY_REQUEST',
  GET_WITHDRAWAL_PAYMENT_OPTIONS_BY_COUNTRY_SUCCESS = 'GET_WITHDRAWAL_PAYMENT_OPTIONS_BY_COUNTRY_SUCCESS',
  GET_WITHDRAWAL_PAYMENT_OPTIONS_BY_COUNTRY_FAILURE = 'GET_WITHDRAWAL_PAYMENT_OPTIONS_BY_COUNTRY_FAILURE',

  GET_CASH_IN_COUNTRY_LIST_REQUEST = 'GET_CASH_IN_COUNTRY_LIST_REQUEST',
  GET_CASH_IN_COUNTRY_LIST_SUCCESS = 'GET_CASH_IN_COUNTRY_LIST_SUCCESS',
  GET_CASH_IN_COUNTRY_LIST_FAILURE = 'GET_CASH_IN_COUNTRY_LIST_FAILURE',

  SET_PAYMENT_PROVIDER_REQUEST = 'SET_PAYMENT_PROVIDER_REQUEST',
  SET_PAYMENT_PROVIDER_SUCCESS = 'SET_PAYMENT_PROVIDER_SUCCESS',
  SET_PAYMENT_PROVIDER_FAILURE = 'SET_PAYMENT_PROVIDER_FAILURE',

  UPDATE_WITHDRAWAL_PAYMENT_PROVIDER_REQUEST = 'UPDATE_WITHDRAWAL_PAYMENT_PROVIDER_REQUEST',
  UPDATE_WITHDRAWAL_PAYMENT_PROVIDER_SUCCESS = 'UPDATE_WITHDRAWAL_PAYMENT_PROVIDER_SUCCESS',
  UPDATE_WITHDRAWAL_PAYMENT_PROVIDER_FAILURE = 'UPDATE_WITHDRAWAL_PAYMENT_PROVIDER_REQUEST_FAILURE',

  CREATE_PAYMENT_ORDER_REQUEST = 'CREATE_PAYMENT_ORDER_REQUEST',
  CREATE_PAYMENT_ORDER_SUCCESS = 'CREATE_PAYMENT_ORDER_SUCCESS',
  CREATE_PAYMENT_ORDER_FAILURE = 'CREATE_PAYMENT_ORDER_FAILURE',

}
