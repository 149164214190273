import { useEffect, useMemo } from 'react'
import { useLocation, Link, NavLink } from 'react-router-dom'
import { Icon } from './icons'
import { useAppDispatch, useAppSelector } from '../hooks/dispatch'
import { remittanceActions } from '../redux/action-creators/remittanceActions'
import { UserStatus } from '../interfaces/user'
import { isUserDataExist } from '../helpers/userHelper'
import BuildVersion from './BuildVersion'

const SendMoneyPathes = ['/', '/beneficiaries/select-beneficiary', '/transaction/new']

const Footer = () => {
  const dispatch = useAppDispatch()
  const { pathname } = useLocation()
  const { notRedeemedCount } = useAppSelector(state => state.remittance)
  const userDetails = useAppSelector(state => state.user.userDetails)
  const isMobile = useAppSelector(state => state.preRegistration.isMobile)
  const preset = useAppSelector(state => state.preset.preset)

  const hasFullUserData = userDetails && isUserDataExist(userDetails)
  const isEmailConfirmed = userDetails && userDetails?.isEmailConfirmed

  useEffect(() => {
    if (isMobile && userDetails?.status === UserStatus.Accepted) {
      dispatch(remittanceActions.getNotRedeemedTransactionsNumber())
    }
  }, [dispatch, isMobile, pathname, userDetails?.status])

  const currentNotRedeemedCount = useMemo(() => {
    return notRedeemedCount >= 9 ? '9+' : notRedeemedCount
  }, [notRedeemedCount])

  const isSendMoneyFlow = useMemo(
    () => SendMoneyPathes.find(path => path === pathname),

    [pathname]
  )

  const helpUrl = useMemo(() => {
    // if (window.location.hostname === 'localhost') {
    //   return 'https://wpdev04.newlinetechno.net/contact/'
    // }
    if (window.location.hostname === 'uat.zikicash.com') {
      return 'https://wpdev04.newlinetechno.net/contact/'
    }
    if (window.location.hostname === 'send.zikicash.com') {
      return 'https://zikicash.com/contact'
    }

    return '/help'
  }, [])

  return (
    <>
      {pathname === '/profile' ? null : (
        <footer
          className={`footer ${
            userDetails !== null &&
            userDetails.status === UserStatus.Accepted &&
            hasFullUserData &&
            isEmailConfirmed
              ? 'h-90'
              : ''
          }`}
        >
          <div className="container container-xxl">
            <div className={`footer-links ${userDetails !== null ? 'hide-mobile' : ''}`}>
              <a href={preset?.termAndConditionsPdf} target="_blank" rel="noreferrer">
                Terms and Conditions
              </a>
              <strong> - </strong>
              <a href={preset?.privacyPolicyPdf} target="_blank" rel="noreferrer">
                Privacy Policy
              </a>
              <strong> - </strong>
              <a href={preset?.websiteTermsOfUsePdf} target="_blank" rel="noreferrer">
                Website Terms of Use
              </a>
              <BuildVersion className="build-version hide-mobile" />
            </div>
          </div>
          {userDetails?.status === UserStatus.Accepted && hasFullUserData && isEmailConfirmed && (
            <div className="mobile-nav show-mobile">
              <nav>
                <NavLink to="/">
                  {Boolean(isSendMoneyFlow) ? (
                    <Icon.SendMoneyMobileActive />
                  ) : (
                    <Icon.SendMoneyMobile />
                  )}
                  Send Money
                </NavLink>
                <NavLink to="/past-transactions">
                  <span className="transactions-icon">
                    {notRedeemedCount > 0 && (
                      <span className="transaction-count-icon">{currentNotRedeemedCount}</span>
                    )}
                    {pathname === '/past-transactions' ? (
                      <Icon.TransactionsMobileActive />
                    ) : (
                      <Icon.TransactionsMobile />
                    )}
                  </span>
                  My Transactions
                </NavLink>
                <Link
                  to={'/beneficiaries'}
                  className={
                    pathname !== '/beneficiaries/select-beneficiary' &&
                    pathname === '/beneficiaries'
                      ? 'active'
                      : ''
                  }
                >
                  {pathname === '/beneficiaries' ? (
                    <Icon.BeneficiariesMobileActive />
                  ) : (
                    <Icon.BeneficiariesMobile />
                  )}
                  My Beneficiaries
                </Link>
                {helpUrl === '/help' ? (
                  <NavLink to="/help">
                    {pathname === '/help' ? <Icon.SupportMobileActive /> : <Icon.SupportMobile />}
                    Support
                  </NavLink>
                ) : (
                  <a href={helpUrl} rel="noreferrer">
                    <Icon.SupportMobile />
                    Support
                  </a>
                )}
                {/* <NavLink to="/help">
                  {pathname === '/help' ? <Icon.SupportMobileActive /> : <Icon.SupportMobile />}
                  Support
                </NavLink> */}
              </nav>
            </div>
          )}
        </footer>
      )}
    </>
  )
}

export default Footer
