import {
  GetUserDetails,
  UpdateUserAddress,
  UpdateUserDetails,
  ChangeEmail,
  GetUserCountryList,
  DeleteProfile,
  ResendEmail,
  ConfirmChangedEmail,
  GetUserCalculationLimit,
  GetUserAmlList,
  UpdateUserSubscription,
} from '../../interfaces/redux-types/userReduxTypes'
import { userConstants } from '../constants/userConstants'
import { Dispatch } from 'redux'
import { userServices } from '../../services/userServices'
import {
  AmlStatus,
  ChangeEmailRequest,
  UserAddressForm,
  UserDetails,
  UserDetailsForm,
} from '../../interfaces/user'
import { authActions } from './authActions'
import { NavigateFunction } from 'react-router-dom'
import { UseFormSetError } from 'react-hook-form'
import { GetAvailableFlowResponse } from '../../interfaces/preset'
import { getCurrentUserAml } from '../../helpers/userHelper'

function getUserDetails(makeRedirect?: (userDetails: UserDetails) => void) {
  return async (dispatch: Dispatch<GetUserDetails>) => {
    dispatch({ type: userConstants.GET_USER_DETAILS_REQUEST })
    await userServices.getUserDetails().then(
      res => {
        dispatch({
          type: userConstants.GET_USER_DETAILS_SUCCESS,
          payload: res,
        })

        if (makeRedirect && res) {
          makeRedirect(res)
        }
      },
      error => {
        dispatch({
          type: userConstants.GET_USER_DETAILS_FAILURE,
          payload: error,
        })
      }
    )
  }
}

function getUserDetailsSuccess(userDetails: UserDetails) {
  return async (dispatch: Dispatch<GetUserDetails>) => {
    dispatch({ type: userConstants.GET_USER_DETAILS_SUCCESS, payload: userDetails })
  }
}

function updateUserDetailsAction(
  userDetails: UserDetailsForm,
  navigate: NavigateFunction,
  from: string,
  setError: UseFormSetError<UserDetailsForm>
) {
  return async (dispatch: Dispatch<UpdateUserDetails>) => {
    dispatch({ type: userConstants.UPDATE_USER_DETAIL_REQUEST })
    userServices.updateUserDetails(userDetails).then(
      () => {
        dispatch({
          type: userConstants.UPDATE_USER_DETAIL_SUCCESS,
          payload: userDetails,
        })
        navigate('/sing-up/user-address', { state: { from } })
      },
      error => {
        dispatch({
          type: userConstants.UPDATE_USER_DETAIL_FAILURE,
          payload: error,
        })
        if (error.errorCode === '4000000048') {
          setError('email', { message: error.message })
        }
      }
    )
  }
}

function updateUserAddressAction(userAddress: UserAddressForm) {
  return async (dispatch: Dispatch<UpdateUserAddress>) => {
    dispatch({ type: userConstants.UPDATE_USER_ADDRESS_REQUEST })
    await userServices.updateUserAddress(userAddress).then(
      () => {
        dispatch({
          type: userConstants.UPDATE_USER_ADDRESS_SUCCESS,
          payload: userAddress,
        })
      },
      err => {
        dispatch({
          type: userConstants.UPDATE_USER_ADDRESS_SUCCESS,
          payload: err,
        })
      }
    )
  }
}

function changeUserEmailSuccess(email: ChangeEmailRequest, makeRedirect: () => void) {
  return async (dispatch: Dispatch<ChangeEmail>) => {
    dispatch({
      type: userConstants.CHANGE_USER_EMAIL_SUCCESS,
      payload: email,
    })
    makeRedirect()
  }
}

function resendEmail(resendEmailDelayTime: number) {
  return (dispatch: Dispatch<ResendEmail>) => {
    dispatch({ type: userConstants.RESEND_EMAIL_REQUEST })
    userServices.resendEmail().then(
      () => {
        dispatch({
          type: userConstants.RESEND_EMAIL_SUCCESS,
          payload: Math.round(Date.now() / 1000) + resendEmailDelayTime,
        })
        localStorage.setItem(
          'emailResendTimer',
          String(Math.round(Date.now() / 1000) + resendEmailDelayTime)
        )
      },
      err => {
        dispatch({ type: userConstants.RESEND_EMAIL_FAILURE })
      }
    )
  }
}

function confirmChangedEmail(token: string) {
  return async (dispatch: Dispatch<ConfirmChangedEmail>) => {
    dispatch({ type: userConstants.CONFIRM_CHANGED_EMAIL_REQUEST })
    await userServices.confirmEmail(token).then(
      () => {
        dispatch({
          type: userConstants.CONFIRM_CHANGED_EMAIL_SUCCESS,
        })
      },
      error => {
        dispatch({
          type: userConstants.CONFIRM_CHANGED_EMAIL_FAILURE,
          payload: error,
        })
      }
    )
  }
}

function getUserCountryList() {
  return async (dispatch: Dispatch<GetUserCountryList>) => {
    dispatch({ type: userConstants.GET_USER_COUNTRY_LIST_REQUEST })
    await userServices.getUserCountryList().then(
      res => {
        dispatch({ type: userConstants.GET_USER_COUNTRY_LIST_SUCCESS, payload: res })
      },
      err => {
        dispatch({ type: userConstants.GET_USER_COUNTRY_LIST_FAILURE })
      }
    )
  }
}

function deleteUser(navigate: NavigateFunction) {
  return async (dispatch: Dispatch<DeleteProfile>) => {
    dispatch({ type: userConstants.DELETE_PROFILE_REQUEST })
    await userServices.removeUser().then(
      () => {
        dispatch({ type: userConstants.DELETE_PROFILE_SUCCESS })
        authActions.logOutAction()
        navigate('/')
      },
      () => {
        dispatch({ type: userConstants.DELETE_PROFILE_FAILURE })
      }
    )
  }
}

function getUserCalculationLimit() {
  return async (dispatch: Dispatch<GetUserCalculationLimit>) => {
    dispatch({ type: userConstants.GET_USER_CALCULATION_LIMITS_REQUEST })
    await userServices.getUserCalculationLimit().then(
      res => {
        dispatch({ type: userConstants.GET_USER_CALCULATION_LIMITS_SUCCESS, payload: res })
      },
      () => {
        dispatch({ type: userConstants.GET_USER_CALCULATION_LIMITS_FAILURE })
      }
    )
  }
}

function getAnonymousCalculationLimit() {
  return async (dispatch: Dispatch<GetUserCalculationLimit>) => {
    dispatch({ type: userConstants.GET_USER_CALCULATION_LIMITS_REQUEST })
    await userServices.getAnonymousCalculationLimit().then(
      res => {
        dispatch({ type: userConstants.GET_USER_CALCULATION_LIMITS_SUCCESS, payload: res })
      },
      () => {
        dispatch({ type: userConstants.GET_USER_CALCULATION_LIMITS_FAILURE })
      }
    )
  }
}

function getUserAmlList(
  flowList: GetAvailableFlowResponse,
  makeRedirect?: (userAmlStatus: AmlStatus | undefined) => void
) {
  return async (dispatch: Dispatch<GetUserAmlList>) => {
    dispatch({ type: userConstants.GET_USER_AML_LIST_REQUEST })
    await userServices.getUserAmlList().then(
      res => {
        const userLevelModel = getCurrentUserAml(res, flowList)

        if (makeRedirect) {
          makeRedirect(userLevelModel?.status)
        }

        dispatch({
          type: userConstants.GET_USER_AML_LIST_SUCCESS,
          payload: { userAml: userLevelModel, userAmlList: res },
        })
      },
      err => {
        dispatch({ type: userConstants.GET_USER_AML_LIST_FAILURE })
      }
    )
  }
}

function updateUserSubscription(checkBoxValue: boolean) {
  return async (dispatch: Dispatch<UpdateUserSubscription>) => {
    dispatch({ type: userConstants.UPDATE_USER_SUBSCRIPTION_REQUEST })
    await userServices.updateUserSubscription(checkBoxValue).then(
      () => {
        dispatch({ type: userConstants.UPDATE_USER_SUBSCRIPTION_SUCCESS, payload: checkBoxValue })
      },
      () => {
        dispatch({ type: userConstants.UPDATE_USER_SUBSCRIPTION_FAILURE })
      }
    )
  }
}

export const userActions = {
  updateUserDetailsAction,
  getUserDetails,
  updateUserAddressAction,
  getUserDetailsSuccess,
  changeUserEmailSuccess,
  resendEmail,
  getUserCountryList,
  deleteUser,
  confirmChangedEmail,
  getUserCalculationLimit,
  getAnonymousCalculationLimit,
  getUserAmlList,
  updateUserSubscription,
}
