import { Country } from "../../interfaces/auth";
import {
  BeneficiaryType,
  CompanyBeneficiaryFormType,
  IndividualBeneficiaryFormType,
  ProviderNameForWithdrawal
} from "../../interfaces/beneficiary";


export const getCountryPhoneCodeOptions = (countryList: Array<Country>) => countryList.map((country) => ({
  value: country.phoneCode,
  label: country.phoneCode,
  iso2Code: country.isO2Code,
}))

export const getDocumentCountryOptions = (countryList: Array<Country>) => countryList.map((country) => ({
  value: country.isO2Code,
  label: country.name,
}))

export const getAddressSectionCountryOptions = (countryList: Array<Country>) => countryList.map((country) => ({
  value: country.id,
  label: country.name,
}))

export const getProviderForWithdrawalOptions = (providerList: Array<ProviderNameForWithdrawal>, beneficiaryType: BeneficiaryType) => {
  const providerListOptions = providerList.map(provider => ({ value: provider.id, label: provider.displayName, }))

  return beneficiaryType === BeneficiaryType.Company
    ? providerListOptions
    : [...providerListOptions, ...[{ value: '-', label: '-', }]]
}

export const isIndividualBeneficiaryFormType = (
  beneficiary: IndividualBeneficiaryFormType | Omit<CompanyBeneficiaryFormType, 'countryId'>
): beneficiary is IndividualBeneficiaryFormType => {
  return (beneficiary as IndividualBeneficiaryFormType).firstName !== undefined
}

