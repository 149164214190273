import { useController, UseControllerProps, FieldValues } from 'react-hook-form'

import { useCallback, useEffect, useMemo, useState } from 'react'
import { Option } from '../../interfaces/commonTypes'

type ControlledSelectorFormProps<T extends FieldValues> = UseControllerProps<T> & {
  className?: string
  disabled?: boolean
  label: string
  options: Array<Option>
}

const ControlledSelectorForm = <T extends FieldValues>({
  name,
  control,
  label,
  options,
  className,
  disabled,
}: ControlledSelectorFormProps<T>) => {
  const {
    field: { onChange, value },
    fieldState: { error: fieldError },
  } = useController({
    name,
    control,
  })
  const [valueSelector, setValueSelector] = useState()

  useEffect(() => {
    if (value) {
      value.value ? setValueSelector(value.value) : setValueSelector(value)
    }
  }, [value])
  const isError = fieldError ? 'error' : ''

  const selectTextColor = useMemo(() => {
    if (disabled) {
      return { color: '#9D9D9D' }
    } else {
      if (value || value === 0) {
        return { color: '#3171D8' }
      } else {
        return { color: '#7B7B7B' }
      }
    }
  }, [disabled, value])

  const onChangeControlledSelector = useCallback(
    (event: any) => {
      const value = event.target.value

      const selectedOption = options.find(option => String(option.value) === value)
      onChange(selectedOption)
    },
    [onChange, options]
  )

  return (
    <div className={className}>
      {label && <label className="form-select-label">{label}</label>}
      <select
        {...(disabled && { disabled })}
        className={isError}
        style={selectTextColor}
        onChange={onChangeControlledSelector}
        value={valueSelector}
      >
        {/* {defaultOption && (
            <option value="" disabled>
              {defaultOption}
            </option>
          )} */}
        {options.map(optionItem => (
          <option key={optionItem.value} value={optionItem.value}>
            {optionItem.label}
          </option>
        ))}
      </select>
      {/* <p className="form-input-error">{fieldError}</p> */}
    </div>
  )
}

export default ControlledSelectorForm
