import { ChangeEvent, SyntheticEvent, useState, useRef } from 'react'
import PhoneInput from 'react-phone-input-2'
import { useDispatch } from 'react-redux'
import { isValidPhoneNumber } from 'libphonenumber-js'
import ReCAPTCHA from 'react-google-recaptcha'
import { Icon } from '../../components/icons'
import CountDown from '../../components/CountDown'
import { preRegistrationActions } from '../../redux/action-creators/preRegistrationActions'
import { useAppSelector } from '../../hooks/dispatch'
import { Link } from 'react-router-dom'

const countDownDate =
  (process.env.REACT_APP_COUNTDOWN_DATE as string) || new Date('2023-05-20 20:42:00.000 GMT+0300')

const captchaKey = process.env.REACT_APP_RECAPTCHA_KEY
const captchaDevKey = process.env.REACT_APP_RECAPTCHA_DEV_KEY

const checkEmail = (email: string) =>
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
    email
  )

const StartPage = () => {
  const preset = useAppSelector(state => state.preset.preset)

  const [formData, setFormData] = useState<{ name: string; email: string; phoneNumber: string }>({
    name: '',
    email: '',
    phoneNumber: '',
  })
  const [err, setErr] = useState<{ email: boolean; phoneNumber: boolean }>({
    email: false,
    phoneNumber: false,
  })
  const [captcha, setCaptcha] = useState<string>('')
  const preRegistration = useAppSelector(state => state.preRegistration)
  const dispatch = useDispatch()
  const captchaRef = useRef<ReCAPTCHA>(null)

  const onChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { target } = e

    setFormData({ ...formData, [target.name]: target.value })

    if (target.name === 'email') {
      const res = checkEmail(target.value)
      setErr({ ...err, email: !res })

      if (target.value.length < 3) {
        setErr({ ...err, email: false })
      }
    }
  }

  const onChangePhone = (value: string) => {
    setErr({ ...err, phoneNumber: false })

    setFormData({ ...formData, phoneNumber: '+' + value })
  }

  const onSubmit = async (e: SyntheticEvent) => {
    e.preventDefault()

    if (!isValidPhoneNumber(formData.phoneNumber)) {
      setErr({ ...err, phoneNumber: true })

      return false
    }

    await preRegistrationActions.sendToWhiteList(formData)(dispatch)

    captchaRef.current?.reset()
    setCaptcha('')
  }

  const { name, email, phoneNumber } = formData

  return (
    <>
      <header className="header">
        <div className="container container-xxl">
          <div>
            <Link to="/" className="logo">
              <img alt="Logo" src={preset?.mainLogoSvg} />
            </Link>
          </div>
        </div>
      </header>
      <main className="main main-user">
        <div className="page-header">
          <div className="container container-md">
            <h1 className="page-title center">Zikicash International Remit</h1>
            <h2 className="page-subtitle">
              Send USD cash to your <Icon.SmallHeart /> ones, instantly
            </h2>
          </div>
        </div>
        <CountDown countDownDate={new Date(countDownDate)} />

        <div className="container container-md">
          <p className="launchTitle">
            <Icon.whiteRocket />
            Preparing for launch
          </p>

          {!preRegistration.finishedSentToWhiteList ? (
            <div className="form">
              <form className="launchForm" onSubmit={onSubmit}>
                <div className="form-row">
                  <Icon.user />
                  <input
                    type="text"
                    name="name"
                    onChange={onChange}
                    value={name}
                    placeholder="Your Name"
                  />
                </div>
                <div className="form-row">
                  <Icon.email />
                  <div>
                    <input
                      type="text"
                      name="email"
                      onChange={onChange}
                      value={email}
                      placeholder="Your Email"
                    />
                    {err.email && (
                      <span className="form-input-error">Please enter a valid email address</span>
                    )}
                  </div>
                </div>
                <div className="form-row">
                  <Icon.whatApp />
                  <div>
                    <PhoneInput
                      onChange={onChangePhone}
                      value={phoneNumber}
                      masks={{ gb: '(...) ...-....' }}
                      country="gb"
                    />
                    {err.phoneNumber && (
                      <span className="form-input-error">Please enter a valid phone number</span>
                    )}
                    {preRegistration.error && (
                      <span className="form-input-error">{preRegistration.error}</span>
                    )}
                  </div>
                </div>
                <div className="captha">
                  <ReCAPTCHA
                    sitekey={(captchaKey as string) || (captchaDevKey as string)}
                    ref={captchaRef}
                    onChange={value => setCaptcha(value!)}
                  />
                </div>
                <div className="btn-wrapper justify-center">
                  <input
                    className="submit btn btn-primary "
                    type="submit"
                    value="Join Waitlist"
                    disabled={
                      !email || !name || !phoneNumber || err.email || err.phoneNumber || !captcha
                    }
                  />
                </div>
              </form>
            </div>
          ) : (
            <div className="thanks">
              <p>Thank you!</p>
              <p>Subscribed to waitlist - we'll be in touch with you just before we launch!</p>
            </div>
          )}
        </div>
      </main>
      <footer className="footer">
        <div className="container container-xxl">
          <div className="footer-links">
            <a href={preset?.termAndConditionsPdf} target="_blank" rel="noreferrer">
              Terms and Conditions
            </a>
            <strong> - </strong>
            <a href={preset?.privacyPolicyPdf} target="_blank" rel="noreferrer">
              Privacy Policy
            </a>
            <strong> - </strong>
            <a href={preset?.websiteTermsOfUsePdf} target="_blank" rel="noreferrer">
              Website Terms of Use
            </a>
          </div>
          {preset?.additionalLogo && (
            <div className="poweredBy mb-20">
              <img className="additional-logo" alt="test" src={preset?.additionalLogo} />
            </div>
          )}
        </div>
      </footer>
    </>
  )
}

export default StartPage
