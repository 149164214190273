import { NavigateFunction } from 'react-router-dom'
import { Dispatch } from 'redux'
import { Dispatch as DispatchReact, SetStateAction } from 'react'
import {
  ClearPastTransactions,
  ClearPastTransactionsError,
  InitializeByNewCard,
  CreateTransaction,
  GetNotRedeemedTransactionsNumber,
  GetTransactionsBySearch,
  RemittanceAction,
  UpdateRecipientInTopUp,
  AuthenticateCard,
  VerifyCardPayment,
  GetSavedCards,
  InitializeBySavedCard,
  DeleteSavedCard,
  SetCardPaymentFailure,
  SetCardPaymentSectionSuccess,
  GetAgentsLocation,
  GetPaymentProvider,
  SetPaymentProvider,
  GetTransaction,
  CreateTransactionBySendAgain,
  GetWithdrawalPaymentProvider,
  UpdateWithdrawalPaymentProvider,
  GetWithdrawalPaymentOptionsByCountry,
  CreatePaymentOrder,
  GetCashInCountryList,
} from '../../interfaces/remittance/remittanceActionTypes'
import { remittanceService } from '../../services/remittanceService'
import { remittanceConstants } from '../constants/remittanceConstants'
import { CompanyBeneficiaryFormType, KWLRecipient } from '../../interfaces/beneficiary'
import { calculationHelper } from '../../helpers/calculationData'
import {
  AuthenticateCardServerRequest,
  CardPaymentButtonVariant,
  InitializeByNewCardServerRequest,
  InitializeBySavedCardServerRequest,
  PaymentResultType,
  SectionName,
  VerifyCardPaymentServerRequest,
} from '../../interfaces/cardPayment'
import { SetError } from '../../interfaces/redux-types/errorActionTypes'
import { errorConstants } from '../constants/errorConstants'
import { SetPaymentProviderServerRequest, ShortWithdrawalPaymentProvider } from '../../interfaces'
import { PostCalculateServerRequest } from '../../interfaces/calculation'
import {
  CreateTransactionBySendAgainServerRequest,
  CreateTransactionServerRequest,
} from '../../interfaces/remittanceType'
import {
  getModifiedPastTransactions,
  getModifiedTransactionDetails,
} from '../../helpers/remittanceUtils'
import { localStorageHelper } from '../../helpers/useLocalStorage'

function cancelTransaction(txId: string) {
  return async (dispatch: Dispatch<RemittanceAction>) => {
    dispatch({ type: remittanceConstants.CANCEL_TRANSACTION_REQUEST })
    remittanceService.cancelTransaction(txId).then(
      () => {
        dispatch({ type: remittanceConstants.CANCEL_TRANSACTION_SUCCESS, payload: txId })
      },
      err => {
        dispatch({ type: remittanceConstants.CANCEL_TRANSACTION_FAILURE, payload: err })
      }
    )
  }
}

function getPastTransactions(offset: number, elementsCount: number) {
  return async (dispatch: Dispatch<RemittanceAction>) => {
    dispatch({ type: remittanceConstants.GET_TRANSACTIONS_REQUEST, offset })
    await remittanceService.getPastTransactions(offset, elementsCount).then(
      res => {
        const modifiedPastTransactions = getModifiedPastTransactions(res)

        dispatch({
          type: remittanceConstants.GET_TRANSACTIONS_SUCCESS,
          payload: modifiedPastTransactions,
        })
      },
      err => {
        dispatch({ type: remittanceConstants.GET_TRANSACTIONS_FAILURE, payload: err })
      }
    )
  }
}

function getTransactionsBySearch(offset: number, elementsCount: number, searchedData: string) {
  return async (dispatch: Dispatch<GetTransactionsBySearch>) => {
    dispatch({ type: remittanceConstants.GET_TRANSACTIONS_BY_SEARCH_REQUEST, offset })
    await remittanceService.getPastTransactions(offset, elementsCount, searchedData).then(
      res => {
        const modifiedPastTransactions = getModifiedPastTransactions(res)

        dispatch({
          type: remittanceConstants.GET_TRANSACTIONS_BY_SEARCH_SUCCESS,
          payload: modifiedPastTransactions,
        })
      },
      err => {
        dispatch({
          type: remittanceConstants.GET_TRANSACTIONS_BY_SEARCH_FAILURE,
          payload: err,
        })
      }
    )
  }
}

function createTransaction(body: CreateTransactionServerRequest, navigate: NavigateFunction) {
  return async (dispatch: Dispatch<CreateTransaction | SetError>) => {
    dispatch({ type: remittanceConstants.CREATE_TRANSACTION_REQUEST })
    await remittanceService.createTransaction(body).then(
      res => {
        const modifiedTransactionDetails = getModifiedTransactionDetails(res)

        dispatch({
          type: remittanceConstants.CREATE_TRANSACTION_SUCCESS,
          payload: modifiedTransactionDetails,
        })
        calculationHelper.removeCalculationData()
        localStorageHelper.removeData('receiveMethod')
        localStorageHelper.removeData('calcData')
        navigate(`/transaction/${modifiedTransactionDetails.topUpRequestExtId}/payment-details`, {
          state: { from: `/transaction/${modifiedTransactionDetails.topUpRequestExtId}` },
        })
      },
      err => {
        const errorCode =
          err.errorCode === '40400000067' ||
            err.errorCode === '4000000211' ||
            err.errorCode === '4000000199' ||
            err.errorCode === '4001000035' ||
            (err.errorCode <= '4001000071' && err.errorCode >= '4001000063') ||
            err.errorCode === '4001000076' ||
            err.errorCode === '4001000077' ||
            err.errorCode === '4000000241' ||
            err.errorCode === '5000000194' ||
            err.errorCode === '5000000198' ||
            err.errorCode === '4000000253' ||
            err.errorCode === '4000000254' ||
            err.errorCode === '4000000264' ||
            err.errorCode === '4000000265'
            ? err.errorCode
            : 'creation-tx'

        if (
          err.errorCode === '4000000253' ||
          err.errorCode === '5000000194' ||
          err.errorCode === '5000000198' ||
          err.errorCode === '4000000254' ||
          err.errorCode === '4000000264' ||
          err.errorCode === '4000000265'
        ) {
          dispatch({ type: remittanceConstants.CREATE_TRANSACTION_FAILURE })
          dispatch({ type: errorConstants.SET_ERROR, payload: { errorCode } })
          return
        }

        if (errorCode === '4000000211') {
          dispatch({ type: errorConstants.SET_ERROR, payload: { errorCode } })
          return
        }

        dispatch({
          type: errorConstants.SET_ERROR,
          payload: { errorCode, id: body.beneficiaryId },
        })
        dispatch({ type: remittanceConstants.CREATE_TRANSACTION_FAILURE })

      }
    )
  }
}

function createTransactionBySendAgain(
  body: CreateTransactionBySendAgainServerRequest,
  navigate: NavigateFunction
) {
  return async (dispatch: Dispatch<CreateTransactionBySendAgain | SetError>) => {
    dispatch({ type: remittanceConstants.CREATE_TRANSACTION_BY_SEND_AGAIN_REQUEST })
    await remittanceService.createTransactionBySendAgain(body).then(
      res => {
        const modifiedTransactionDetails = getModifiedTransactionDetails(res)

        dispatch({
          type: remittanceConstants.CREATE_TRANSACTION_BY_SEND_AGAIN_SUCCESS,
          payload: modifiedTransactionDetails,
        })
        calculationHelper.removeCalculationData()
        navigate(`/transaction/${modifiedTransactionDetails.topUpRequestExtId}/payment-details`, {
          state: { from: `/transaction/${modifiedTransactionDetails.topUpRequestExtId}` },
        })
      },
      err => {
        const errorCode =
          err.errorCode === '40400000067' ||
            err.errorCode === '4000000211' ||
            err.errorCode === '4000000199' ||
            err.errorCode === '4001000035' ||
            (err.errorCode <= '4001000071' && err.errorCode >= '4001000063') ||
            err.errorCode === '4001000076' ||
            err.errorCode === '4001000077'
            ? err.errorCode
            : 'creation-tx'

        dispatch({ type: errorConstants.SET_ERROR, payload: { errorCode } })
        dispatch({ type: remittanceConstants.CREATE_TRANSACTION_BY_SEND_AGAIN_FAILURE })
      }
    )
  }
}

function updateRecipientInTopUp(
  recipient: KWLRecipient | Omit<CompanyBeneficiaryFormType, "countryId">,
  topUpId: string,
  navigate: NavigateFunction,
  searchedTxValue: string | undefined
) {
  return async (dispatch: Dispatch<UpdateRecipientInTopUp>) => {
    dispatch({ type: remittanceConstants.UPDATE_RECIPIENT_IN_TOP_UP_REQUEST })
    await remittanceService.updateRecipientInTopUp(recipient, topUpId).then(
      res => {
        dispatch({
          type: remittanceConstants.UPDATE_RECIPIENT_IN_TOP_UP_SUCCESS,
          payload: { tx: res, txId: topUpId },
        })
        navigate('/past-transactions', { state: { searchedTxValue } })
      },
      err => {
        if (err.errorCode === '4000000241') {
          dispatch({
            type: remittanceConstants.UPDATE_RECIPIENT_IN_TOP_UP_FAILURE,
            payload: 'Please, enter a valid account number'
          })
          return
        }
        if (
          err.errorCode === '4000000253' ||
          err.errorCode === '5000000194' ||
          err.errorCode === '5000000198'
        ) {
          dispatch({ type: remittanceConstants.UPDATE_RECIPIENT_IN_TOP_UP_FAILURE })
          dispatch({ type: errorConstants.SET_ERROR, payload: { errorCode: err.errorCode } })
          return
        }

        dispatch({ type: remittanceConstants.UPDATE_RECIPIENT_IN_TOP_UP_FAILURE })
      }
    )
  }
}

function clearPastTransactions() {
  return (dispatch: Dispatch<ClearPastTransactions>) => {
    dispatch({ type: remittanceConstants.CLEAR_PAST_TRANSACTIONS })
  }
}

function clearPastTransactionsError() {
  return (dispatch: Dispatch<ClearPastTransactionsError>) => {
    dispatch({ type: remittanceConstants.CLEAR_PAST_TRANSACTIONS_ERROR })
  }
}

function getTransaction(id: string, navigate: NavigateFunction) {
  return (dispatch: Dispatch<GetTransaction>) => {
    dispatch({ type: remittanceConstants.GET_TRANSACTION_REQUEST })
    remittanceService.getTransaction(id).then(
      res => {
        const modifiedTransactionDetails = getModifiedTransactionDetails(res)

        dispatch({
          type: remittanceConstants.GET_TRANSACTION_SUCCESS,
          payload: modifiedTransactionDetails,
        })
      },
      err => {
        dispatch({
          type: remittanceConstants.GET_TRANSACTION_FAILURE,
          payload: err.message,
        })
        if (err.code === 400 || err.code === 404 || err.status === 400) {
          navigate && navigate('/past-transactions')
        }
      }
    )
  }
}

function transferTX(id: string, navigate: NavigateFunction) {
  return (dispatch: Dispatch<RemittanceAction>) => {
    dispatch({ type: remittanceConstants.TRANSFER_REQUEST })
    remittanceService.transfer(id).then(
      () => {
        dispatch({ type: remittanceConstants.TRANSFER_SUCCESS })
      },
      err => {
        dispatch({ type: remittanceConstants.TRANSFER_FAILURE, payload: err })
        if (err.errorCode === '4000000156') {
          navigate(`/transaction/${id}`)
        }
      }
    )
  }
}

function getNotRedeemedTransactionsNumber() {
  return (dispatch: Dispatch<GetNotRedeemedTransactionsNumber>) => {
    dispatch({ type: remittanceConstants.GET_NOT_REDEEMED_TRANSACTIONS_NUMBER_REQUEST })
    remittanceService.getNotRedeemedTransactionsNumber().then(
      res => {
        dispatch({
          type: remittanceConstants.GET_NOT_REDEEMED_TRANSACTIONS_NUMBER_SUCCESS,
          payload: res,
        })
      },
      err => {
        dispatch({
          type: remittanceConstants.GET_NOT_REDEEMED_TRANSACTIONS_NUMBER_FAILURE,
          payload: err.message,
        })
      }
    )
  }
}

function initializeByNewCard(body: InitializeByNewCardServerRequest) {
  return (dispatch: Dispatch<InitializeByNewCard>) => {
    dispatch({ type: remittanceConstants.INITIALIZE_BY_NEW_CARD_REQUEST, payload: body })
    remittanceService.initializeByNewCard(body).then(
      res => {
        dispatch({
          type: remittanceConstants.INITIALIZE_BY_NEW_CARD_SUCCESS,
          payload: res,
          sectionName: 'device-data',
        })
      },
      err => {
        dispatch({
          type: remittanceConstants.INITIALIZE_BY_NEW_CARD_FAILURE,
          payload: err,
          sectionName: 'result',
        })
      }
    )
  }
}

function initializeBySavedCard(
  body: InitializeBySavedCardServerRequest,
  setPaymentSection?: DispatchReact<SetStateAction<SectionName>>
) {
  return async (dispatch: Dispatch<InitializeBySavedCard>) => {
    dispatch({ type: remittanceConstants.INITIALIZE_BY_SAVED_CARD_REQUEST })
    await remittanceService.initializeBySavedCard(body).then(
      res => {
        dispatch({ type: remittanceConstants.INITIALIZE_BY_SAVED_CARD_SUCCESS, payload: res })
      },
      err => {
        dispatch({
          type: remittanceConstants.INITIALIZE_BY_SAVED_CARD_FAILURE,
          payload: err,
        })
      }
    )
  }
}

function authenticateCard(
  body: AuthenticateCardServerRequest,
  setCardPaymentStatusForBtn: DispatchReact<SetStateAction<CardPaymentButtonVariant>>,
  isBankAccountWithdrawal: boolean
) {
  return async (dispatch: Dispatch<AuthenticateCard>) => {
    dispatch({ type: remittanceConstants.AUTHENTICATE_CARD_REQUEST })
    await remittanceService.authenticateCard(body).then(
      res => {
        let currentSectionName: SectionName = ''
        let paymentAuthError: string | undefined

        if (res.outcome === PaymentResultType.Authorized) {
          currentSectionName = 'result'
          setCardPaymentStatusForBtn('success')
        }
        if (res.outcome === PaymentResultType.Challenged) {
          currentSectionName = 'challenge'
        }
        if (res.outcome === PaymentResultType.Failed) {
          currentSectionName = 'result'
          setCardPaymentStatusForBtn('failure')
          paymentAuthError = 'Your card payment failed'
        }
        if (res.outcome === PaymentResultType.Unavailable) {
          currentSectionName = 'result'
          setCardPaymentStatusForBtn('failure')
          paymentAuthError = 'Your card payment failed'
        }

        dispatch({
          type: remittanceConstants.AUTHENTICATE_CARD_SUCCESS,
          payload: res,
          sectionName: currentSectionName,
          cardPaymentError: paymentAuthError,
        })
      },
      err => {
        dispatch({
          type: remittanceConstants.AUTHENTICATE_CARD_FAILURE,
          payload: err,
          sectionName: 'result',
        })

        if (isBankAccountWithdrawal) {
          setCardPaymentStatusForBtn('withdrawal-failure')
        } else {
          setCardPaymentStatusForBtn('failure')
        }
      }

    )
  }
}

function verifyCardPayment(
  body: VerifyCardPaymentServerRequest,
  setCardPaymentStatusForBtn: DispatchReact<SetStateAction<CardPaymentButtonVariant>>,
  isBankAccountWithdrawal: boolean
) {
  return async (dispatch: Dispatch<VerifyCardPayment>) => {
    dispatch({ type: remittanceConstants.VERIFY_CARD_PAYMENT_REQUEST })
    await remittanceService.verifyCardPayment(body).then(
      res => {
        dispatch({
          type: remittanceConstants.VERIFY_CARD_PAYMENT_SUCCESS,
          payload: res,
          sectionName: 'result',
        })
        setCardPaymentStatusForBtn('success')
      },
      err => {
        dispatch({
          type: remittanceConstants.VERIFY_CARD_PAYMENT_FAILURE,
          payload: err,
          sectionName: 'result',
        })

        if (isBankAccountWithdrawal) {
          setCardPaymentStatusForBtn('withdrawal-failure')
        } else {
          setCardPaymentStatusForBtn('failure')
        }
      }
    )
  }
}

function getSavedCards() {
  return async (dispatch: Dispatch<GetSavedCards>) => {
    dispatch({ type: remittanceConstants.GET_SAVED_CARD_REQUEST })
    await remittanceService.getSavedCards().then(
      res => {
        dispatch({
          type: remittanceConstants.GET_SAVED_CARD_SUCCESS,
          payload: res,
        })
      },
      err => {
        dispatch({ type: remittanceConstants.GET_SAVED_CARD_FAILURE, payload: err })
      }
    )
  }
}

function deleteSavedCard(extId: string) {
  return async (dispatch: Dispatch<DeleteSavedCard>) => {
    dispatch({ type: remittanceConstants.DELETE_SAVED_CARD_REQUEST })
    await remittanceService.deleteSavedCard(extId).then(
      () => {
        dispatch({
          type: remittanceConstants.DELETE_SAVED_CARD_SUCCESS,
          payload: extId,
        })
      },
      err => {
        dispatch({ type: remittanceConstants.DELETE_SAVED_CARD_FAILURE })
      }
    )
  }
}

function setCardPaymentFailure(error: string) {
  return (dispatch: Dispatch<SetCardPaymentFailure>) => {
    dispatch({
      type: remittanceConstants.SET_CARD_PAYMENT_FAILURE,
      error: error,
      sectionName: 'result',
    })
  }
}

function setCardPaymentViewSection(sectionName: SectionName) {
  return (dispatch: Dispatch<SetCardPaymentSectionSuccess>) => {
    dispatch({
      type: remittanceConstants.SET_CARD_PAYMENT_VIEW_SECTION_SUCCESS,
      payload: sectionName,
    })
  }
}

function getAgentsLocation() {
  return async (dispatch: Dispatch<GetAgentsLocation>) => {
    dispatch({ type: remittanceConstants.GET_AGENTS_LOCATION_REQUEST })

    await remittanceService.getAgentsLocation().then(
      res => {
        dispatch({ type: remittanceConstants.GET_AGENTS_LOCATION_SUCCESS, payload: res })
      },
      err => {
        dispatch({ type: remittanceConstants.GET_AGENTS_LOCATION_FAILURE })
      }
    )
  }
}

function getPaymentProvider(body: PostCalculateServerRequest) {
  return async (dispatch: Dispatch<GetPaymentProvider>) => {
    dispatch({ type: remittanceConstants.GET_PAYMENT_PROVIDER_REQUEST })

    await remittanceService.getPaymentProvider(body).then(
      res => {
        dispatch({ type: remittanceConstants.GET_PAYMENT_PROVIDER_SUCCESS, payload: res })
      },
      err => {
        dispatch({ type: remittanceConstants.GET_PAYMENT_PROVIDER_FAILURE })
      }
    )
  }
}

function setPaymentProvider(topUpId: string, body: SetPaymentProviderServerRequest) {
  return async (dispatch: Dispatch<SetPaymentProvider>) => {
    dispatch({ type: remittanceConstants.SET_PAYMENT_PROVIDER_REQUEST })

    await remittanceService.setPaymentProvider(topUpId, body).then(
      res => {
        dispatch({ type: remittanceConstants.SET_PAYMENT_PROVIDER_SUCCESS })
      },
      err => {
        dispatch({ type: remittanceConstants.SET_PAYMENT_PROVIDER_FAILURE })
      }
    )
  }
}

function getWithdrawalPaymentProviderList(currency: string) {
  return async (dispatch: Dispatch<GetWithdrawalPaymentProvider>) => {
    dispatch({ type: remittanceConstants.GET_WITHDRAWAL_PAYMENT_PROVIDER_REQUEST })

    await remittanceService.getWithdrawalPaymentProviderList(currency).then(
      res => {
        dispatch({ type: remittanceConstants.GET_WITHDRAWAL_PAYMENT_PROVIDER_SUCCESS, payload: res })
      },
      err => {
        dispatch({ type: remittanceConstants.GET_WITHDRAWAL_PAYMENT_PROVIDER_FAILURE })
      }
    )
  }
}

function getWithdrawalPaymentOptionsByCountry() {
  return async (dispatch: Dispatch<GetWithdrawalPaymentOptionsByCountry>) => {
    dispatch({ type: remittanceConstants.GET_WITHDRAWAL_PAYMENT_OPTIONS_BY_COUNTRY_REQUEST })

    await remittanceService.getWithdrawalPaymentOptionsByCountry().then(
      res => {
        dispatch({ type: remittanceConstants.GET_WITHDRAWAL_PAYMENT_OPTIONS_BY_COUNTRY_SUCCESS, payload: res })
      },
      err => {
        dispatch({ type: remittanceConstants.GET_WITHDRAWAL_PAYMENT_OPTIONS_BY_COUNTRY_FAILURE })
      }
    )
  }
}

function updateWithdrawalPaymentProvider(id: string, body: ShortWithdrawalPaymentProvider) {
  return async (dispatch: Dispatch<UpdateWithdrawalPaymentProvider>) => {
    dispatch({ type: remittanceConstants.UPDATE_WITHDRAWAL_PAYMENT_PROVIDER_REQUEST })

    await remittanceService.updateWithdrawalPaymentProvider(id, body).then(
      res => {
        dispatch({ type: remittanceConstants.UPDATE_WITHDRAWAL_PAYMENT_PROVIDER_SUCCESS, payload: res })
      },
      err => {
        dispatch({ type: remittanceConstants.UPDATE_WITHDRAWAL_PAYMENT_PROVIDER_FAILURE })
      }
    )
  }
}

function getCashInCountryList() {
  return async (dispatch: Dispatch<GetCashInCountryList>) => {
    dispatch({ type: remittanceConstants.GET_CASH_IN_COUNTRY_LIST_REQUEST })

    await remittanceService.getCashInCountryList().then(
      res => {
        dispatch({ type: remittanceConstants.GET_CASH_IN_COUNTRY_LIST_SUCCESS, payload: res })
      },
      err => {
        dispatch({ type: remittanceConstants.GET_CASH_IN_COUNTRY_LIST_FAILURE })
      }
    )
  }
}

function createPaymentOrder(extId: string) {
  return async (dispatch: Dispatch<CreatePaymentOrder>) => {
    dispatch({ type: remittanceConstants.CREATE_PAYMENT_ORDER_REQUEST })

    await remittanceService.createPaymentOrder(extId).then(
      res => {
        dispatch({ type: remittanceConstants.CREATE_PAYMENT_ORDER_SUCCESS, payload: res })
        window.open(res.paymentPageUrl, "_self")
      },
      err => {
        dispatch({ type: remittanceConstants.CREATE_PAYMENT_ORDER_FAILURE })
      }
    )
  }
}

export const remittanceActions = {
  cancelTransaction,
  getPastTransactions,
  createTransaction,
  createTransactionBySendAgain,
  clearPastTransactions,
  getTransaction,
  transferTX,
  updateRecipientInTopUp,
  getTransactionsBySearch,
  getNotRedeemedTransactionsNumber,
  clearPastTransactionsError,
  initializeByNewCard,
  initializeBySavedCard,
  authenticateCard,
  verifyCardPayment,
  getSavedCards,
  deleteSavedCard,
  setCardPaymentFailure,
  setCardPaymentViewSection,
  getAgentsLocation,
  getPaymentProvider,
  setPaymentProvider,
  getWithdrawalPaymentProviderList,
  updateWithdrawalPaymentProvider,
  getWithdrawalPaymentOptionsByCountry,
  createPaymentOrder,
  getCashInCountryList,
}
