import { useMemo } from 'react'
import { UseFormRegister, Path, UseFormWatch } from 'react-hook-form'
import { Option } from '../../interfaces/commonTypes'

type SelectFormType<T> = {
  options: Array<Option>
  register: UseFormRegister<T>
  label?: string
  defaultValue?: string
  id: Path<T>
  className?: string
  // value?: string | undefined
  defaultOption?: string
  error?: string
  disabled?: boolean
  watch: UseFormWatch<T>
  hidden?: boolean
}

const SelectForm = <T,>({
  options,
  register,
  label,
  id,
  className,
  defaultOption,
  error,
  disabled,
  watch,
  hidden,
}: SelectFormType<T>) => {
  const isError = error ? 'error' : ''
  const inputRegister = useMemo(() => register(id), [id, register])
  const value = watch(id)

  const selectTextColor = useMemo(() => {
    if (disabled) {
      return { color: '#9D9D9D' }
    } else {
      if (value || value === 0) {
        return { color: '#3171D8' }
      } else {
        return { color: '#7B7B7B' }
      }
    }
  }, [disabled, value])

  return (
    <div className={className} hidden={hidden}>
      {label && <label className="form-select-label">{label}</label>}
      <select
        {...inputRegister}
        id={id}
        {...(disabled && { disabled })}
        className={isError}
        style={selectTextColor}
      >
        {defaultOption && (
          <option value="" disabled>
            {defaultOption}
          </option>
        )}
        {options.map(optionItem => (
          <option key={optionItem.value} value={optionItem.value}>
            {optionItem.label}
          </option>
        ))}
      </select>
      <p className="form-input-error">{error}</p>
    </div>
  )
}

export default SelectForm
