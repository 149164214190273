import { Dispatch, SetStateAction, useEffect, useMemo, useState } from 'react'
import { PaymentOption } from '../../interfaces'
import { PaymentSection } from '../../interfaces/cardPayment'
import PaymentProviderBtn from './PaymentProviderBtn'
import { useAppDispatch, useAppSelector } from '../../hooks/dispatch'
import { PuffLoader } from 'react-spinners'
import { remittanceActions } from '../../redux/action-creators/remittanceActions'
import { calculationActions } from '../../redux/action-creators/calculationActions'
import { CalculationLimitPeriod } from '../../interfaces/user'
import Modal from '../Modal'
import { WithdrawalItemForSummary } from '../../interfaces/calculation'

type PaymentProviderSectionProps = {
  paymentProviderList: Array<PaymentOption>
  setPaymentSection: Dispatch<SetStateAction<PaymentSection>>
  withdrawalPaymentProviderOptions: Array<WithdrawalItemForSummary>
}

const PaymentProviderSection = ({
  paymentProviderList,
  setPaymentSection,
  withdrawalPaymentProviderOptions,
}: PaymentProviderSectionProps) => {
  const dispatch = useAppDispatch()

  const {
    receiveCurrency,
    srcCurrency: sendCurrency,
    amountToReceive: receiveAmount,
    receiveCountryId,
    sourceCountryId,
    withdrawalMethod,
    withdrawalProviderId,
  } = useAppSelector(state => state.remittance.transaction)
  const { isLoading } = useAppSelector(state => state.remittance.getPaymentProvidersLoadState)
  const { paymentProviderLimits } = useAppSelector(state => state.calculation)
  // const getWithdrawalPaymentProviderLoadState = useAppSelector(
  //   state => state.remittance.getWithdrawalPaymentProviderLoadState
  // )

  const [isOpenModal, setIsOpenModal] = useState(false)

  useEffect(() => {
    // let withdrawalObj = {}

    // if (withdrawalProviderId) {
    //   withdrawalObj = { withdrawalMethod, withdrawalProviderId }
    // }
    // if (withdrawalPaymentProviderOptions.length > 0 && !withdrawalProviderId) {
    //   withdrawalObj = {
    //     withdrawalMethod: withdrawalPaymentProviderOptions[0].withdrawalMethod,
    //     withdrawalProviderId: withdrawalPaymentProviderOptions[0].withdrawalProviderId,
    //   }
    // }

    const body = {
      sendCurrency,
      receiveAmount,
      receiveCurrency,
      receiveCountryId,
      sourceCountryId,
      ...(withdrawalProviderId
        ? {
            withdrawalProviderId,
            withdrawalMethod,
          }
        : {}),
    }

    dispatch(remittanceActions.getPaymentProvider(body))
    dispatch(calculationActions.postAuthorizeCalculationAction(body))
  }, [
    dispatch,
    receiveAmount,
    receiveCountryId,
    receiveCurrency,
    sendCurrency,
    sourceCountryId,
    withdrawalMethod,
    withdrawalProviderId,
  ])

  const modifiedPaymentProviderList = useMemo(() => {
    const sortedPaymentProviderList = paymentProviderList.sort(
      (firstOperand, secondOperand) => firstOperand.totalFee - secondOperand.totalFee
    )
    const modifiedPaymentProviderList = sortedPaymentProviderList.map(
      (paymentProvider, index, array) => {
        const minPaymentProviderLimit = paymentProviderLimits.find(
          paymentProviderLimit =>
            paymentProviderLimit.paymentMethod ===
              paymentProvider.paymentProviderMethod.paymentMethod &&
            paymentProviderLimit.range === CalculationLimitPeriod.MinPerTransaction
        )
        const maxPaymentProviderLimit = paymentProviderLimits.find(
          paymentProviderLimit =>
            paymentProviderLimit.paymentMethod ===
              paymentProvider.paymentProviderMethod.paymentMethod &&
            paymentProviderLimit.range === CalculationLimitPeriod.MaxPerTransaction
        )
        const percentageDifference = index
          ? (paymentProvider.sendAmount / array[0].sendAmount - 1) * 100
          : 0

        return {
          ...paymentProvider,
          percentageDifference,
          minPaymentProviderLimit,
          maxPaymentProviderLimit,
        }
      }
    )

    return modifiedPaymentProviderList
  }, [paymentProviderLimits, paymentProviderList])

  const modalConfirmFunction = () => {
    setIsOpenModal(false)
  }

  return isLoading ? (
    <div className="spinner spinner-withoutBg">
      <PuffLoader size={70} color="#3171d8" />
    </div>
  ) : (
    <>
      {modifiedPaymentProviderList.map(paymentProvider => (
        <PaymentProviderBtn
          paymentProvider={paymentProvider}
          setPaymentMethod={setPaymentSection}
          key={paymentProvider.paymentProviderMethod.id}
          setIsOpenModal={setIsOpenModal}
        />
      ))}
      <Modal
        setIsOpenModal={setIsOpenModal}
        isOpenModal={isOpenModal}
        confirmFunction={modalConfirmFunction}
        modalHeader="Payment Method Restrictions"
        modalBody="The amount does not match the limits for this payment method. Please use a different payment method."
        modalType="informationModal"
        modalConfirmationBtnTest="Close"
      />
    </>
  )
}

export default PaymentProviderSection
