import { FlowState, GetAvailableFlowResponse } from '../interfaces/preset'
import { AmlDetail, AmlStatus, UserDetails } from '../interfaces/user'

export const isUserDataExist = (userData: UserDetails) => {
  return userData.email &&
    userData.firstName &&
    userData.lastName &&
    userData.dateOfBirth &&
    userData.countryId &&
    userData.city &&
    userData.street
}

export const getCurrentUserAml = (
  userAmlList: Array<AmlDetail>,
  flowList: GetAvailableFlowResponse
) => {
  const currentEnabledFlowWeight =
    flowList.find(flow => flow.minimalAmlCheckLevel && flow.state === FlowState.On)
      ?.minimalAmlCheckLevel?.weight || 0

  const verifiedLevel = userAmlList.find(
    item => item.status === AmlStatus.Verified && item.level.weight >= currentEnabledFlowWeight
  )
  const pendingLevel = userAmlList.find(
    item => item.status === AmlStatus.Pending && item.level.weight >= currentEnabledFlowWeight
  )
  const rejectedWithRetryLevel = userAmlList.find(
    item =>
      item.status === AmlStatus.RejectedWithRetry && item.level.weight >= currentEnabledFlowWeight
  )

  if (verifiedLevel) {
    return verifiedLevel
  }

  if (pendingLevel) {
    return pendingLevel
  }

  if (rejectedWithRetryLevel) {
    return rejectedWithRetryLevel
  }

  return null
}
