import { useCallback, useEffect, useMemo, useState } from 'react'
import { useAppDispatch, useAppSelector } from '../../hooks/dispatch'
import isEmpty from 'lodash/isEmpty'
import { beneficiaryActions } from '../../redux/action-creators/beneficiaryActions'
import { CompanyBeneficiaryFormType, KWLRecipient } from '../../interfaces/beneficiary'
import { remittanceActions } from '../../redux/action-creators/remittanceActions'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import InnerHeader from '../../components/innerHeader'
import BeneficiaryForm from '../../components/beneficiaries/BeneficiaryForm'
import { TransactionStatus } from '../../interfaces/remittanceType'

type LocationState = {
  state: {
    searchedTxValue: string
  }
}

const TransactionRecipientDetailsPage = () => {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const { id } = useParams()
  const location = useLocation() as LocationState
  const searchedTxValue = location?.state?.searchedTxValue || undefined
  const { recipientCountryList, beneficiaryFieldsConfig } = useAppSelector(
    state => state.beneficiary
  )
  const { transaction, getTransactionForPaymentLoadState } = useAppSelector(
    state => state.remittance
  )

  const [canFormChange, setCanFormChange] = useState(false)

  const showSaveButton = useMemo(
    () =>
      transaction.status === TransactionStatus.Ordered ||
      transaction.status === TransactionStatus.NotRedeemed ||
      transaction.status === TransactionStatus.Transferred,
    [transaction.status]
  )

  useEffect(() => {
    if (showSaveButton) {
      setCanFormChange(true)
    } else {
      setCanFormChange(false)
    }
  }, [showSaveButton])

  useEffect(() => {
    if (
      beneficiaryFieldsConfig?.Company.BankName.visible ||
      beneficiaryFieldsConfig?.Individual.BankName.visible
    ) {
      dispatch(beneficiaryActions.getProviderListForWithdrawal())
    }
  }, [
    beneficiaryFieldsConfig?.Company.BankName.visible,
    beneficiaryFieldsConfig?.Individual.BankName.visible,
    dispatch,
  ])

  const getTransactionData = useCallback(() => {
    if (id) {
      dispatch(beneficiaryActions.getBeneficiaryFieldsConfig())
      dispatch(remittanceActions.getTransaction(id, navigate))
    }
  }, [dispatch, id, navigate])

  useEffect(() => {
    getTransactionData()
  }, [getTransactionData])

  useEffect(() => {
    if (isEmpty(recipientCountryList)) {
      dispatch(beneficiaryActions.getBeneficiaryCountryList())
    }
  }, [dispatch, recipientCountryList])

  const sendRequest = (recipient: KWLRecipient | Omit<CompanyBeneficiaryFormType, "countryId">) => {
    dispatch(
      remittanceActions.updateRecipientInTopUp(
        recipient,
        transaction.topUpRequestExtId,
        navigate,
        searchedTxValue
      )
    )
  }

  const onGoBack = () => {
    navigate('/past-transactions', { state: { searchedTxValue } })
  }

  return (
    <section>
      {!getTransactionForPaymentLoadState.isLoading &&
      getTransactionForPaymentLoadState.isLoaded ? (
        <section className="h-100-mb">
          <div className="container container-md container-100">
            <InnerHeader
              title={canFormChange ? 'Editing Beneficiary Details' : 'Beneficiary Details'}
              onGoBack={onGoBack}
            />
            <div className="beneficiary-details-page">
              <BeneficiaryForm
                recipient={transaction.recipient}
                sendRequest={sendRequest}
                setCanFormChange={setCanFormChange}
                onCancel={onGoBack}
                textButton="Save"
                canFormChange={canFormChange}
                isShownEditBtn={false}
                type='recipient'
                id={id}
              />
            </div>
          </div>
        </section>
      ) : null}
    </section>
  )
}

export default TransactionRecipientDetailsPage
