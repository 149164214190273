import { useEffect, useMemo, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { Icon } from '../../components/icons'
import ProfileDetails from '../../components/profile/ProfileDetails'
import Preferences from '../../components/profile/Preferences'
import Limits from '../../components/profile/Limits'
import { useAppSelector } from '../../hooks/dispatch'
import { AmlStatus } from '../../interfaces/user'

const tabConfig = [{ tabName: 'my-details' }, { tabName: 'preferences' }, { tabName: 'limits' }]

type LocationState = {
  state: {
    pathname: string
    tab: string
  }
}

const ProfilePage = () => {
  const navigate = useNavigate()
  const location = useLocation() as LocationState
  const userAmlStatus = useAppSelector(state => state.user.userAml?.status)

  const [activeTab, setActiveTab] = useState('my-details')

  const prevPathName = location?.state?.pathname || '/'
  const isProfilePage = prevPathName.includes('profile')

  const tabFromStateLocation = location?.state?.tab || ''

  useEffect(() => {
    if (tabFromStateLocation) {
      setActiveTab(tabFromStateLocation)
    }
  }, [tabFromStateLocation])

  const futurePathName = useMemo(
    () => (isProfilePage ? '/' : prevPathName),
    [isProfilePage, prevPathName]
  )

  const onClose = () => {
    navigate(futurePathName)
  }

  const onClick = (e: any) => {
    const idElement = e.target.id
    const activeTab = tabConfig.find(tab => idElement === tab.tabName)

    setActiveTab(activeTab?.tabName || 'my-details')
  }

  return (
    <section>
      <div className="profile-page">
        <div className="container container-xxl">
          <div className="profile-header">
            <h1 className="page-header__title-icon">
              <div className="show-mobile">
                <Icon.MyProfile />
              </div>
              My Profile
            </h1>
            <div className="close" onClick={onClose}>
              <span>Close</span>
              <Icon.WhiteCloseCircle />
            </div>
          </div>

          <div className="tab-links">
            <button
              id="my-details"
              className={activeTab === 'my-details' ? 'active' : ''}
              onClick={onClick}
            >
              My Details
            </button>
            <button
              id="preferences"
              className={activeTab === 'preferences' ? 'active' : ''}
              onClick={onClick}
            >
              Preferences
            </button>
            {userAmlStatus === AmlStatus.Verified && (
              <button
                id="limits"
                className={activeTab === 'limits' ? 'active' : ''}
                onClick={onClick}
              >
                Limits
              </button>
            )}
          </div>
          <div className="tab-content">
            {activeTab === 'my-details' && <ProfileDetails />}
            {activeTab === 'preferences' && <Preferences />}
            {activeTab === 'limits' && userAmlStatus === AmlStatus.Verified && <Limits />}
          </div>
        </div>
      </div>
    </section>
  )
}
export default ProfilePage
