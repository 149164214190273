import { UseFormRegister, Path } from 'react-hook-form'
import { memo, useMemo } from 'react'

export type LoginInputProps<T> = {
  placeholder?: string
  id: Path<T>
  className: string
  type: string
  register: UseFormRegister<T>
  error?: string
  label: string
  inputValue?: string
  disabled?: boolean
  autocomplete?: 'on' | 'off'
  maxLength?: number
  example?: string
  autoFocus?: boolean
  hidden?: boolean
  additionalLabel?: string 
}

const FormInput = <T,>({
  placeholder,
  id,
  type,
  register,
  error,
  label,
  disabled,
  autocomplete,
  autoFocus,
  maxLength,
  example,
  hidden,
  additionalLabel,
}: LoginInputProps<T>) => {
  const inputRegister = useMemo(() => register(id), [id, register])

  const isError = useMemo(() => (error ? 'error' : ''), [error])

  return (
    <div className={disabled ? 'form-input cursor-notAllowed' : 'form-input'} hidden={hidden}>
      {/* <label htmlFor={id}>{label}</label> */}
      <input
        {...inputRegister}
        placeholder={label}
        id={id}
        type={type}
        disabled={disabled}
        maxLength={maxLength}
        className={isError}
        {...(autoFocus && { autoFocus })}
        {...(autocomplete && { autoComplete: autocomplete })}
      />
      {example && <p className="form-input-example">{example}</p>}
      <p className="form-input-error">{error}</p>
      {additionalLabel && <p className="form-input-additional-text italic">{additionalLabel}</p>}
    </div>
  )
}

export default memo(FormInput) as typeof FormInput
