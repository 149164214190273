import React, { Dispatch, SetStateAction, useCallback, useEffect, useMemo, useState } from 'react'
import ReactModal from 'react-modal'
import { useNavigate } from 'react-router-dom'
import { useAppDispatch } from '../hooks/dispatch'
import { authActions } from '../redux/action-creators/authActions'
import { userActions } from '../redux/action-creators/userActions'
import { Icon } from './icons'
import { TransactionDetails } from '../interfaces/remittanceType'
import { WithdrawalItemForSummary } from '../interfaces/calculation'

type ModalProps = {
  modalHeader: string
  modalBody?: string | TransactionDetails
  confirmFunction?: (value?: any) => void
  modalMainWord?: string
  setIsOpenModal?: Dispatch<SetStateAction<boolean>>
  btnValuesForSelection?: Array<WithdrawalItemForSummary>
  selectedBtnValue?: WithdrawalItemForSummary
}

const TransactionDetailsModal = ({ modalHeader, modalBody, setIsOpenModal }: ModalProps) => {
  const topUpInfo = modalBody as TransactionDetails

  const isShowWithdrawalFee = useMemo(
    () => topUpInfo.withdrawalProviderId && topUpInfo.withdrawalMethod,
    [topUpInfo.withdrawalMethod, topUpInfo.withdrawalProviderId]
  )

  const isGroupedFees = useMemo(() => {
    return (
      window.location.hostname === 'remittance.dev.kuvacash.net' ||
      window.location.hostname === 'uat.zikicash.com' ||
      window.location.hostname === 'send.zikicash.com'
    )
  }, [])

  return (
    <>
      <div className="modal-header">
        <Icon.Info />
        <h2>{modalHeader}</h2>
      </div>
      <div className="modal-body">
        <div className="modal-content">
          <div className="modal-content__row">
            <div className="modal-content__name">Amount Sent - {topUpInfo.receiveCurrency}</div>
            <div className="modal-content__value">
              {' '}
              {topUpInfo.receiveCurrencySign}
              {topUpInfo.amountToReceive.toFixed(2)}
            </div>
          </div>
          <div className="modal-content__row">
            <div className="modal-content__name">Transaction Cost -</div>
            <div className="modal-content__value">
              {topUpInfo.srcCurrencySign}
              {topUpInfo.amountToSendWithoutFee.toFixed(2)}
            </div>
          </div>
          {isGroupedFees ? (
            <div className="modal-content__row">
              <div className="modal-content__name">Total Fee -</div>
              <div className="modal-content__value">
                {topUpInfo.srcCurrencySign}
                {(
                  topUpInfo.fee +
                  topUpInfo.paymentProviderDiffFee +
                  (topUpInfo?.withdrawalFee || 0)
                ).toFixed(2)}
              </div>
            </div>
          ) : (
            <>
              <div className="modal-content__row">
                <div className="modal-content__name">Transaction Fee -</div>
                <div className="modal-content__value">
                  {topUpInfo.srcCurrencySign}
                  {topUpInfo.fee.toFixed(2)}
                </div>
              </div>
              <div className="modal-content__row">
                <div className="modal-content__name">Payment Method Fee -</div>
                <div className="modal-content__value">
                  {topUpInfo.paymentProviderDiffFee > 0 ? (
                    <>
                      {topUpInfo.srcCurrencySign}
                      {topUpInfo.paymentProviderDiffFee.toFixed(2)}
                    </>
                  ) : (
                    'Free'
                  )}
                </div>
              </div>
              {isShowWithdrawalFee && (
                <div className="modal-content__row">
                  <div className="modal-content__name">Withdrawal Fee -</div>
                  <div className="modal-content__value">
                    {topUpInfo.srcCurrencySign}
                    {topUpInfo?.withdrawalFee && topUpInfo?.withdrawalFee.toFixed(2)}
                  </div>
                </div>
              )}
            </>
          )}
          <div className="modal-content__row total">
            <div className="modal-content__name">Total Cost -</div>
            <div className="modal-content__value">
              {topUpInfo.srcCurrencySign}
              {topUpInfo.amountToSend.toFixed(2)}
            </div>
          </div>
        </div>
        <div className="btn-wrapper center">
          <button
            className="btn btn-primary btn-sm"
            onClick={() => setIsOpenModal && setIsOpenModal(false)}
          >
            Close
          </button>
        </div>
      </div>
    </>
  )
}

const DialogModal = ({ modalHeader, modalBody, setIsOpenModal, confirmFunction }: ModalProps) => {
  return (
    <>
      <div className="modal-header">
        <h2>{modalHeader}</h2>
      </div>
      <div className="modal-body md">
        <h5 className="t-center mt-20 mb-20">{modalBody as string}</h5>
        <div className="btn-wrapper">
          <button
            className="btn btn-cancel"
            onClick={() => setIsOpenModal && setIsOpenModal(false)}
          >
            Cancel
          </button>
          <button className="btn btn-next" onClick={confirmFunction}>
            Yes
          </button>
        </div>
      </div>
    </>
  )
}

type InformationModalProps = {
  modalHeader: string
  modalBody?: string | TransactionDetails
  modalConfirmationBtnTest: string
  confirmFunction?: () => void
  modalMainWord?: string
  setIsOpenModal?: Dispatch<SetStateAction<boolean>>
}

const InformationModal = ({
  modalHeader,
  modalBody,
  confirmFunction,
  modalConfirmationBtnTest,
}: InformationModalProps) => {
  return (
    <>
      <div className="modal-header">
        <Icon.Info />
        <h2>{modalHeader}</h2>
      </div>
      <div className="modal-body md">
        <h5 className="t-center mt-20 mb-20">{modalBody as string}</h5>
        <div className="btn-wrapper center">
          <button className="btn btn-primary btn-sm" onClick={confirmFunction}>
            {modalConfirmationBtnTest}
          </button>
        </div>
      </div>
    </>
  )
}

const DeleteCardModal = ({
  modalHeader,
  modalBody,
  setIsOpenModal,
  confirmFunction,
}: ModalProps) => {
  return (
    <>
      <div className="modal-header modal-header--big">
        <h2 className="t-center">
          <Icon.Warning />
          {modalHeader}
        </h2>
      </div>
      <div className="modal-body md">
        <h4 className="mb-40 t-center">{modalBody as string}</h4>
        <div className="btn-wrapper btn-wrapper__delete-card">
          <button className="btn btn-delete" onClick={confirmFunction}>
            Remove Card
          </button>
          <button
            className="btn btn-cancel"
            onClick={() => setIsOpenModal && setIsOpenModal(false)}
          >
            Cancel
          </button>
        </div>
      </div>
    </>
  )
}

const DeleteProfileModal = ({
  modalHeader,
  modalBody,
  modalMainWord,
  setIsOpenModal,
}: ModalProps) => {
  const [deletionConfirmation, setDeletionConfirmation] = useState('')
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const [canDelete, setCanDelete] = useState(false)

  const deleteProfile = () => {
    if (canDelete) {
      dispatch!(userActions.deleteUser(navigate))
      dispatch!(authActions.logOutAction())
    }
  }

  const onDeletionConfirmationChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const {
      target: { value },
    } = e

    setDeletionConfirmation(value)
    let lowerWithoutSpaces = value.replace(' ', '').toLowerCase().trim()

    if (lowerWithoutSpaces === 'iunderstand' && lowerWithoutSpaces.length === 11) {
      setCanDelete(true)
    } else if (lowerWithoutSpaces.length > 10) {
      setCanDelete(false)
      return
    }
  }

  return (
    <>
      <div className="modal-header">
        <h2>
          <Icon.Warning />
          {modalHeader}
        </h2>
      </div>
      <div className="modal-body md">
        <p className="mb-20">
          <b>{modalMainWord}</b>
          {modalBody as string}
        </p>
        <input
          onChange={onDeletionConfirmationChange}
          type="text"
          value={deletionConfirmation}
          placeholder="Type confirmation here"
        />
        <div className="btn-wrapper center">
          <button
            className="btn btn-cancel"
            onClick={() => setIsOpenModal && setIsOpenModal(false)}
          >
            Cancel
          </button>
          <button disabled={!canDelete} className="btn btn-next" onClick={deleteProfile}>
            Delete Account
          </button>
        </div>
      </div>
    </>
  )
}

const ChangingReceiveMethodDialog = ({
  modalHeader,
  setIsOpenModal,
  confirmFunction,
  btnValuesForSelection,
  selectedBtnValue,
}: ModalProps) => {
  const [selectedReceiveMethod, setSelectedReceiveMethod] = useState<any>()

  useEffect(() => {
    if (selectedBtnValue) {
      setSelectedReceiveMethod(selectedBtnValue)
    }
  }, [selectedBtnValue])

  const onClickBtn = useCallback((value: any) => {
    setSelectedReceiveMethod(value)
  }, [])

  const onClose = useCallback(() => {
    confirmFunction && confirmFunction(selectedReceiveMethod)

    setIsOpenModal && setIsOpenModal(false)
  }, [confirmFunction, selectedReceiveMethod, setIsOpenModal])
  return (
    <>
      <div className="modal-header">
        <h2>{modalHeader}</h2>
      </div>
      <div className="modal-body md">
        <div className="btn-wrapper-method">
          {btnValuesForSelection?.map(btnValue => (
            <React.Fragment key={btnValue.withdrawalProviderId}>
              <div
                className={`btn-method-main${
                  btnValue.withdrawalProviderId === selectedReceiveMethod?.withdrawalProviderId &&
                  btnValue.currency === selectedReceiveMethod?.currency
                    ? ' active-btn'
                    : ''
                }`}
                onClick={() => onClickBtn(btnValue)}
              >
                <span>{btnValue.title}</span>
                <span>{btnValue.text}</span>
              </div>
            </React.Fragment>
          ))}
        </div>
        <div className="btn-wrapper center">
          <button className="btn btn-primary btn-sm" onClick={onClose}>
            OK
          </button>
        </div>
      </div>
    </>
  )
}

const ConfirmationBeforeCreatingTxDialog = ({
  modalHeader,
  setIsOpenModal,
  confirmFunction,
}: ModalProps) => {
  const [isConfirmedChecked, setIsConfirmedChecked] = useState<boolean>(false)

  const onChangeCheckBox = useCallback(() => {
    setIsConfirmedChecked(prev => !prev)
  }, [])

  const onConfirm = useCallback(() => {
    confirmFunction && confirmFunction()

    setIsOpenModal && setIsOpenModal(false)
  }, [confirmFunction, setIsOpenModal])

  const onCancel = useCallback(() => {
    setIsOpenModal && setIsOpenModal(false)
  }, [setIsOpenModal])
  return (
    <>
      <div className="modal-header">
        <h2>{modalHeader}</h2>
      </div>
      <div className="modal-body md">
        <p style={{ textIndent: '20px', textAlign: 'justify', marginBottom: '16px' }}>
          Scammers may ask you to make a payment using this service. You are solely responsible for
          ensuring that you intend to make this payment to the beneficiary you have selected. The
          payment you are currently trying to make may be an Authorised Push Payment (APP) scam, and
          we advise additional caution as to ensuring that the beneficiary is known to you, and that
          you intend to make this payment.
        </p>
        <label className="checkbox">
          <input
            type="checkbox"
            checked={isConfirmedChecked}
            onChange={onChangeCheckBox}
          />
          <div className="checkbox__body">
            I confirm that I am making this payment on my own behalf to a beneficiary known to me. I
            have not been instructed by any third party to make this payment. I understand and
            confirm that the payments I instruct on this platform are final, and once the funds I
            have ordered have been redeemed by the beneficiary, the transaction is no longer
            refundable
          </div>
          <div className="checkbox__checkmark"></div>
        </label>
        <div className="btn-wrapper center">
          <button className="btn btn-cancel" onClick={onCancel}>
            Cancel
          </button>
          <button className="btn btn-primary" onClick={onConfirm} disabled={!isConfirmedChecked}>
            I confirm
          </button>
        </div>
      </div>
    </>
  )
}

type MainModalProps = {
  setIsOpenModal: Dispatch<SetStateAction<boolean>>
  isOpenModal: boolean
  modalHeader: string
  modalBody?: string | TransactionDetails
  modalMainWord?: string
  modalConfirmationBtnTest?: string
  modalType:
    | 'informationModal'
    | 'dialogModal'
    | 'deleteProfileModal'
    | 'deleteCardModal'
    | 'transactionDetails'
    | 'changingReceiveMethod'
    | 'confirmationBeforeCreatingTx'
  confirmFunction?: (value?: any) => void
  btnValuesForSelection?: Array<WithdrawalItemForSummary>
  selectedBtnValue?: WithdrawalItemForSummary
}
const Modal = ({
  setIsOpenModal,
  isOpenModal,
  modalHeader,
  modalBody,
  modalType,
  modalMainWord,
  modalConfirmationBtnTest,
  confirmFunction,
  btnValuesForSelection,
  selectedBtnValue,
}: MainModalProps) => {
  const contentModal = useMemo(() => {
    switch (modalType) {
      case 'transactionDetails':
        return (
          <TransactionDetailsModal
            modalHeader={modalHeader}
            modalBody={modalBody as TransactionDetails}
            setIsOpenModal={setIsOpenModal}
          />
        )
      case 'dialogModal':
        return (
          <DialogModal
            modalHeader={modalHeader}
            modalBody={modalBody}
            setIsOpenModal={setIsOpenModal}
            confirmFunction={confirmFunction}
          />
        )
      case 'informationModal':
        return (
          <InformationModal
            modalHeader={modalHeader}
            modalBody={modalBody}
            confirmFunction={confirmFunction}
            modalConfirmationBtnTest={modalConfirmationBtnTest || 'OK'}
          />
        )
      case 'deleteCardModal':
        return (
          <DeleteCardModal
            modalHeader={modalHeader}
            modalBody={modalBody}
            setIsOpenModal={setIsOpenModal}
            confirmFunction={confirmFunction}
          />
        )

      case 'deleteProfileModal':
        return (
          <DeleteProfileModal
            modalHeader={modalHeader}
            modalBody={modalBody}
            modalMainWord={modalMainWord}
            setIsOpenModal={setIsOpenModal}
          />
        )
      case 'changingReceiveMethod':
        return (
          <ChangingReceiveMethodDialog
            modalHeader={modalHeader}
            setIsOpenModal={setIsOpenModal}
            confirmFunction={confirmFunction}
            btnValuesForSelection={btnValuesForSelection}
            selectedBtnValue={selectedBtnValue}
          />
        )
      case 'confirmationBeforeCreatingTx':
        return (
          <ConfirmationBeforeCreatingTxDialog
            modalHeader={modalHeader}
            setIsOpenModal={setIsOpenModal}
            confirmFunction={confirmFunction}
          />
        )
    }
  }, [
    btnValuesForSelection,
    confirmFunction,
    modalBody,
    modalConfirmationBtnTest,
    modalHeader,
    modalMainWord,
    modalType,
    selectedBtnValue,
    setIsOpenModal,
  ])

  return (
    <ReactModal
      onRequestClose={() => setIsOpenModal(false)}
      shouldCloseOnOverlayClick={true}
      className={`modal-window${modalType === 'transactionDetails' ? ' tx-details' : ''} `}
      overlayClassName="modal-overlay"
      isOpen={isOpenModal}
      ariaHideApp={false}
    >
      {contentModal}
    </ReactModal>
  )
}

export default Modal
