import { Dispatch } from 'redux'
import { Dispatch as ReactDispatch, SetStateAction } from 'react'
import { authHelper } from '../../helpers/authData'
import { localStorageHelper } from '../../helpers/useLocalStorage'
import {
  AuthActions,
  GetSumSubToken,
  GetUserCountryList,
  VerifyCodeFromSMS,
  VerifyMobileNumber,
  RemoveMobileNumberFromState,
} from '../../interfaces/redux-types/authActionTypes'
import { authServices } from '../../services/authServices'
import { authConstants } from '../constants/authConstants'
import { calculationHelper } from '../../helpers/calculationData'
import { AppDispatch } from '../reducers'
import { userActions } from './userActions'
import { UserDetails } from '../../interfaces/user'
import { FlowType } from '../../interfaces/preset'
import { errorConstants } from '../constants/errorConstants'
import { SetError } from '../../interfaces/redux-types/errorActionTypes'

function getUserCountryList() {
  return async (dispatch: Dispatch<GetUserCountryList>) => {
    dispatch({ type: authConstants.GET_USER_COUNTRY_LIST_REQUEST })
    authServices.getUserCountryList().then(
      res => {
        dispatch({
          type: authConstants.GET_USER_COUNTRY_LIST_SUCCESS,
          payload: res,
        })
      },
      err => {
        dispatch({
          type: authConstants.GET_USER_COUNTRY_LIST_FAILURE,
          payload: err,
        })
      }
    )
  }
}

function logOutAction() {
  return async (dispatch: Dispatch<AuthActions>) => {
    await authServices.logOut().then(
      () => {
        dispatch({ type: authConstants.LOGOUT_SUCCESS })
        localStorageHelper.clearLocalStorage()
      },
      err => {
        dispatch({ type: authConstants.LOGOUT_FAILURE, payload: err })
      }
    )
  }
}

function getSumSubTokenAction(flowType: FlowType) {
  return async (dispatch: Dispatch<GetSumSubToken>) => {
    dispatch({ type: authConstants.GET_SUM_SUB_TOKEN_REQUEST })
    await authServices.getSumSubToken(flowType).then(
      res => {
        dispatch({
          type: authConstants.GET_SUM_SUB_TOKEN_SUCCESS,
          payload: res,
        })
      },
      err => {
        dispatch({
          type: authConstants.GET_SUM_SUB_TOKEN_FAILURE,
          payload: err,
        })
      }
    )
  }
}

function verifyMobileNumber(
  phoneNumber: string,
  token: string,
  onChangeSection: ReactDispatch<SetStateAction<string>>,
  receiveAmount?: number
) {
  return async (dispatch: Dispatch<VerifyMobileNumber>) => {
    dispatch({ type: authConstants.VERIFY_MOBILE_NUMBER_REQUEST })
    await authServices.verifyMobileNumber(phoneNumber, token).then(
      res => {
        dispatch({ type: authConstants.VERIFY_MOBILE_NUMBER_SUCCESS, payload: phoneNumber })
        authHelper.setTempAuthData(res)
        onChangeSection('code-verification')
        receiveAmount && calculationHelper.setCalculationData(receiveAmount)
      },
      err => {
        dispatch({ type: authConstants.VERIFY_MOBILE_NUMBER_FAILURE, payload: err })
      }
    )
  }
}
function removeMobileNumberFromState() {
  return (dispatch: Dispatch<RemoveMobileNumberFromState>) => {
    dispatch({ type: authConstants.REMOVE_MOBILE_NUMBER_FROM_STATE })
  }
}

function verifyCodeFromSMS(
  code: string,
  appDispatch: AppDispatch,
  makeRedirect: (userDetails: UserDetails) => void,
  receiveAmount?: number
) {
  return async (dispatch: Dispatch<VerifyCodeFromSMS | SetError>) => {
    dispatch({ type: authConstants.VERIFY_CODE_FROM_SMS_REQUEST })
    await authServices.verifyCodeFromSMS(code).then(
      res => {
        dispatch({ type: authConstants.VERIFY_CODE_FROM_SMS_SUCCESS })
        authHelper.setAuthData(res)
        authHelper.removeTempAuthData()
        receiveAmount && calculationHelper.setCalculationData(receiveAmount)
        appDispatch(userActions.getUserDetails(makeRedirect))
        localStorageHelper.removeData('referralCode')
      },
      err => {
        if (err.errorCode === '4000000258') {
          dispatch({ type: errorConstants.SET_ERROR, payload: { errorCode: err.errorCode } })
        } else {
          dispatch({ type: authConstants.VERIFY_CODE_FROM_SMS_FAILURE, payload: err })
        }

      }
    )
  }
}

export const authActions = {
  getUserCountryList,
  logOutAction,
  getSumSubTokenAction,
  verifyMobileNumber,
  verifyCodeFromSMS,
  removeMobileNumberFromState,
}
