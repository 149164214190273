function setData(key: string, value: string) {
  localStorage.setItem(key, value)
}

function getData(key: string) {
  let unParsed = localStorage.getItem(key)

  try {
    if ((key === 'data' || key === 'tempAuthData' || key === 'calcData' || 'receiveMethod') && unParsed !== null) {
      return JSON.parse(unParsed)
    }
    return unParsed
  } catch (error) {}
}

function removeData(key: string) {
  localStorage.removeItem(key)
}

function clearLocalStorage() {
  Object.keys(localStorage).forEach(key => {
    if (key !== 'privacy' && key !== 'preset' && key !== 'emailResendTimer') {
      localStorage.removeItem(key)
    }
  })
}

export const localStorageHelper = { getData, setData, removeData, clearLocalStorage }
