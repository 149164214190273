export enum calculationConstants {
  GET_RATE_REQUEST = 'GET_RATE_REQUEST',
  GET_RATE_SUCCESS = 'GET_RATE_SUCCESS',
  GET_RATE_FAILURE = 'GET_RATE_FAILURE',

  POST_CALCULATE_REQUEST = 'POST_CALCULATE_REQUEST',
  POST_CALCULATE_SUCCESS = 'POST_CALCULATE_SUCCESS',
  POST_CALCULATE_FAILURE = 'POST_CALCULATE_FAILURE',

  CLEAR_CALCULATION_DATA_FROM_STATE = 'CLEAR_CALCULATION_DATA_FROM_STATE'
}
