import { Dispatch } from 'redux'
import { ClearError } from '../../interfaces/redux-types/errorActionTypes'
import { errorConstants } from '../constants/errorConstants'

function clearError() {
  return async (dispatch: Dispatch<ClearError>) => {
    dispatch({ type: errorConstants.CLEAR_ERROR })
  }
}

export const errorActions = {
  clearError,
}
