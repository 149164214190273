import { useMemo } from 'react'
import { Link } from 'react-router-dom'
import { isCustomErrorResponse } from '../../../helpers/utils'
import { useAppSelector } from '../../../hooks/dispatch'
import { Icon } from '../../icons'

const CardPaymentResult = () => {
  const successCardPayment = useAppSelector(state => state.remittance.successCardPayment)
  const cardPaymentError = useAppSelector(state => state.remittance.cardPaymentError)

  const cardPaymentSuccess = useMemo(
    () => (
      <>
        <Icon.PaymentSuccess />
        <p>Thank you for your payment</p>
      </>
    ),
    []
  )

  const helpUrl = useMemo(() => {
    // if (window.location.hostname === 'localhost') {
    //   return 'https://wpdev04.newlinetechno.net/contact/'
    // }
    if (window.location.hostname === 'uat.zikicash.com') {
      return 'https://wpdev04.newlinetechno.net/contact/'
    }
    if (window.location.hostname === 'send.zikicash.com') {
      return 'https://zikicash.com/contact'
    }

    return '/help'
  }, [])

  const cardPaymentFailure = useMemo(() => {
    return (
      <>
        <Icon.PaymentFailed />
        {isCustomErrorResponse(cardPaymentError) ? (
          <>
            <p>{cardPaymentError.errorCode}</p>
            <p>
              <>
                Something went wrong. Please, try again later or contact{' '}
                {helpUrl === '/help' ? (
                  <Link to="/help">our support</Link>
                ) : (
                  <a href={helpUrl} rel="noopener noreferrer">
                    our support
                  </a>
                )}
                {/* <Link to="/help">our support</Link> */}
              </>
            </p>
          </>
        ) : (
          <p>
            <>
              Something went wrong. Please, try again later or contact{' '}
              {helpUrl === '/help' ? (
                <Link to="/help">our support</Link>
              ) : (
                <a href={helpUrl} rel="noreferrer">
                  our support
                </a>
              )}
              {/* <Link to="/help">our support</Link> */}
            </>
          </p>
        )}
      </>
    )
  }, [cardPaymentError, helpUrl])

  const contentCardPaymentResult = useMemo(() => {
    if (successCardPayment && !cardPaymentError) {
      return cardPaymentSuccess
    } else {
      return cardPaymentFailure
    }
  }, [cardPaymentError, cardPaymentFailure, cardPaymentSuccess, successCardPayment])

  return <div className="price-info__result">{contentCardPaymentResult}</div>
}

export default CardPaymentResult
