export enum userConstants {
  GET_USER_DETAILS_REQUEST = 'GET_USER_DETAILS_REQUEST',
  GET_USER_DETAILS_SUCCESS = 'GET_USER_DETAILS_SUCCESS',
  GET_USER_DETAILS_FAILURE = 'GET_USER_DETAILS_FAILURE',

  UPDATE_USER_DETAIL_REQUEST = 'UPDATE_USER_DETAIL_REQUEST',
  UPDATE_USER_DETAIL_SUCCESS = 'UPDATE_USER_DETAIL_SUCCESS',
  UPDATE_USER_DETAIL_FAILURE = 'UPDATE_USER_DETAIL_FAILURE',

  UPDATE_USER_ADDRESS_REQUEST = 'UPDATE_USER_ADDRESS_REQUEST',
  UPDATE_USER_ADDRESS_SUCCESS = 'UPDATE_USER_ADDRESS_SUCCESS',
  UPDATE_USER_ADDRESS_FAILURE = 'UPDATE_USER_ADDRESS_FAILURE',

  CHANGE_USER_EMAIL_REQUEST = 'CHANGE_USER_EMAIL_REQUEST',
  CHANGE_USER_EMAIL_SUCCESS = 'CHANGE_USER_EMAIL_SUCCESS',
  CHANGE_USER_EMAIL_FAILURE = 'CHANGE_USER_EMAIL_FAILURE',

  CONFIRM_CHANGED_EMAIL_REQUEST = 'CONFIRM_CHANGED_EMAIL_REQUEST',
  CONFIRM_CHANGED_EMAIL_SUCCESS = 'CONFIRM_CHANGED_EMAIL_SUCCESS',
  CONFIRM_CHANGED_EMAIL_FAILURE = 'CONFIRM_CHANGED_EMAIL_FAILURE',

  RESEND_EMAIL_REQUEST = 'RESEND_EMAIL_REQUEST',
  RESEND_EMAIL_SUCCESS = 'RESEND_EMAIL_SUCCESS',
  RESEND_EMAIL_FAILURE = 'RESEND_EMAIL_FAILURE',

  GET_USER_COUNTRY_LIST_REQUEST = 'GET_USER_COUNTRY_LIST_REQUEST',
  GET_USER_COUNTRY_LIST_SUCCESS = 'GET_USER_COUNTRY_LIST_SUCCESS',
  GET_USER_COUNTRY_LIST_FAILURE = 'GET_USER_COUNTRY_LIST_FAILURE',

  DELETE_PROFILE_REQUEST = 'DELETE_PROFILE_REQUEST',
  DELETE_PROFILE_SUCCESS = 'DELETE_PROFILE_SUCCESS',
  DELETE_PROFILE_FAILURE = 'DELETE_PROFILE_FAILURE',

  GET_USER_CALCULATION_LIMITS_REQUEST = 'GET_USER_CALCULATION_LIMITS_REQUEST',
  GET_USER_CALCULATION_LIMITS_SUCCESS = 'GET_USER_CALCULATION_LIMITS_SUCCESS',
  GET_USER_CALCULATION_LIMITS_FAILURE = 'GET_USER_CALCULATION_LIMITS_FAILURE',

  GET_USER_AML_LIST_REQUEST = 'GET_USER_AML_LIST_REQUEST',
  GET_USER_AML_LIST_SUCCESS = 'GET_USER_AML_LIST_SUCCESS',
  GET_USER_AML_LIST_FAILURE = 'GET_USER_AML_LIST_FAILURE',

  UPDATE_USER_SUBSCRIPTION_REQUEST = 'UPDATE_USER_SUBSCRIPTION_REQUEST',
  UPDATE_USER_SUBSCRIPTION_SUCCESS = 'UPDATE_USER_SUBSCRIPTION_SUCCESS',
  UPDATE_USER_SUBSCRIPTION_FAILURE = 'UPDATE_USER_SUBSCRIPTION_FAILURE',
}
