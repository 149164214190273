import ReactDOM from "react-dom/client"
import "./assets/styles/index.scss"
import reportWebVitals from "./reportWebVitals"
import { Provider } from "react-redux"
import store from "./redux/store"
import { unstable_HistoryRouter as HistoryRouter } from "react-router-dom"
import { history } from "./routing/history"
import AppRouter from "./routing/AppRouter"

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement)

root.render(
  <Provider store={store}>
    <HistoryRouter history={history}>
      <AppRouter/>
    </HistoryRouter>
  </Provider>
)

reportWebVitals()
