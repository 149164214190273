import { OtherTransactionDetailsForm, TransactionPaymentProvider } from "../interfaces/remittanceType"
import { BeneficiaryType } from '../interfaces/beneficiary'
import { WithdrawalOption } from "../interfaces/calculation"

export class Recipient {
  id = 0
  firstName = ''
  lastName = ''
  phoneNumber = ''
  dateOfBirth = ''
  documentType = ''
  documentNumber = ''
  countryId = ''
  type = BeneficiaryType.Individual
  // countryIso = 'zw'
  documentCountryIso = ''
  gender = ''
  city = ''
  street = ''
  postcode = ''
}

export class TransactionCreationRequest {
  constructor(receiveMethod?: WithdrawalOption) {
    if (receiveMethod) {
      this.receiveMethod = receiveMethod
    }
  }

  sendCurrency = ''
  receiveCurrency = ''
  receiveAmount = 0
  beneficiaryId = 0
  receiveMethod: undefined | WithdrawalOption
  transactionDetails: OtherTransactionDetailsForm = {
    sourceOfFunds: '',
    reasonTransfer: '',
    sourceOfFundsDetails: null,
    reasonTransferDetails: null,
  }
}

export class TopUpKWLInfo {
  accountName = ''
  accountNumber = null
  sortCode = null
  iban = null
  topUpRequestExtId = ''
  paymentReference = ''
  digitalReference = ''
  srcCurrency = ''
  amountToSend = 0
  amountToSendWithoutFee = 0
  fee = 0
  paymentProviderDiffFee = 0
  status = 0
  recipient = new Recipient()
  paymentProvider = TransactionPaymentProvider.ClearJunctionBank
  receiveCurrency = ''
  amountToReceive = 0
  sourceCountryId = 0
  receiveCountryId = 0
  // bookedDate = ''
  // redeemedDate = ''

  receiveCurrencySign = ''
  srcCurrencySign = ''

  rate = 0
  // reverseRate = 0
  topUpNumber = 0
  // paymentProviderFee = 0
  transactionDetails = {
    sourceOfFunds: '',
    reasonTransfer: '',
    sourceOfFundsDetails: null,
    reasonTransferDetails: null,
  }
}
