import React, { Dispatch, SetStateAction, useCallback, useMemo } from 'react'
import { Icon } from '../icons'
import { PaymentMethod, PaymentOption } from '../../interfaces'
import { PaymentSection } from '../../interfaces/cardPayment'
import { useAppDispatch, useAppSelector } from '../../hooks/dispatch'
import { remittanceActions } from '../../redux/action-creators/remittanceActions'
import { PaymentProviderLimits } from '../../interfaces/calculation'
import { useNavigate } from 'react-router-dom'

const getPaymentMethod = (paymentMethod: PaymentMethod) => {
  const methods = {
    [PaymentMethod.KuvaCash]: 'Kuva Cash',
    [PaymentMethod.CreditCard]: 'Card Payment',
    [PaymentMethod.BankAccount]: 'Bank Account',
    [PaymentMethod.Cash]: 'Cash',
    [PaymentMethod.InstantCredit]: 'Instant Credit',
    [PaymentMethod.ApplePay]: 'Apple Pay',
    [PaymentMethod.GooglePay]: 'Google Pay',
    [PaymentMethod.CashOnDelivery]: 'Cash On Delivery',
    [PaymentMethod.CreditWalletOnly]: 'Credit Wallet Only',
    [PaymentMethod.CreditVendor]: 'Credit Vendor',
    [PaymentMethod.MPesa]: 'MPesa',
    [PaymentMethod.BankTransfer]: 'Bank Transfer',
    [PaymentMethod.OpenBanking]: 'Payment By Banking'
  }
  return methods !== undefined ? methods[paymentMethod] : ''
}

const paymentProviderIcons = {
  [PaymentMethod.BankTransfer]: <Icon.BankTransfer />,
  [PaymentMethod.OpenBanking]: <Icon.Banking />,
  [PaymentMethod.CreditCard]: <Icon.CreditCard />,
}

type PaymentProviderBtnProps = {
  paymentProvider: PaymentOption & {
    percentageDifference: number
    minPaymentProviderLimit: PaymentProviderLimits | undefined
    maxPaymentProviderLimit: PaymentProviderLimits | undefined
  }
  setPaymentMethod: Dispatch<SetStateAction<PaymentSection>>
  setIsOpenModal: Dispatch<SetStateAction<boolean>>
}

const PaymentProviderBtn = ({
  paymentProvider,
  setPaymentMethod,
  setIsOpenModal,
}: PaymentProviderBtnProps) => {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const {
    topUpRequestExtId: topUpId,
    withdrawalMethod,
    withdrawalProviderId,
  } = useAppSelector(state => state.remittance.transaction)

  const paymentProviderInfo = useMemo(() => {
    return {
      text: getPaymentMethod(paymentProvider.paymentProviderMethod.paymentMethod),
      icon: paymentProviderIcons[paymentProvider.paymentProviderMethod.paymentMethod],
      limitPass:
        paymentProvider.maxPaymentProviderLimit?.limitPass &&
        paymentProvider.minPaymentProviderLimit?.limitPass,
    }
  }, [
    paymentProvider.maxPaymentProviderLimit?.limitPass,
    paymentProvider.minPaymentProviderLimit?.limitPass,
    paymentProvider.paymentProviderMethod.paymentMethod,
  ])

  const onClick = useCallback(async () => {
    const body = {
      paymentProviderId: paymentProvider.paymentProviderMethod.paymentProvider.id,
      paymentMethod: paymentProvider.paymentProviderMethod.paymentMethod,
      ...(withdrawalProviderId
        ? {
            withdrawalProviderId,
            withdrawalMethod,
          }
        : {}),
    }

    await dispatch(remittanceActions.setPaymentProvider(topUpId, body))
    await dispatch(remittanceActions.getTransaction(topUpId, navigate))

    setPaymentMethod('payment-summary')
  }, [
    dispatch,
    navigate,
    paymentProvider.paymentProviderMethod.paymentMethod,
    paymentProvider.paymentProviderMethod.paymentProvider.id,
    setPaymentMethod,
    topUpId,
    withdrawalMethod,
    withdrawalProviderId,
  ])

  const onOpenInfoModal = useCallback(() => {
    setIsOpenModal(true)
  }, [setIsOpenModal])

  return (
    <div className="card-payment__item" {...(paymentProviderInfo.limitPass && { onClick })}>
      {!paymentProviderInfo.limitPass && (
        <div className="card-payment__item-disabled">
          <span className="icon-info" onClick={onOpenInfoModal}>
            <Icon.Info />
          </span>
        </div>
      )}
      <div className="card-payment__icon-block">
        {paymentProviderInfo.icon}
        <div>
          <span>{paymentProviderInfo.text}</span>
          {paymentProvider.paymentProviderMethod.paymentMethod === PaymentMethod.CreditCard && (
            <div className="card-payment__icons">
              <Icon.MasterCard />
              <Icon.Amex />
              {/* <Icon.Maestro /> */}
              <Icon.Visa />
            </div>
          )}
          <div className="payment-percent show-mobile">
            {paymentProvider.percentageDifference > 0 &&
              `(+${paymentProvider.percentageDifference.toFixed(2)}%)`}
          </div>
        </div>
      </div>
      <div className="payment-percent hide-mobile">
        {paymentProvider.percentageDifference > 0 &&
          `(+${paymentProvider.percentageDifference.toFixed(2)}%)`}
      </div>
      <div className="payment-btn-block">
        <div className="payment-btn">
          <span>Next</span>
          <Icon.ArrowRightBack />
        </div>
      </div>
    </div>
  )
}

export default PaymentProviderBtn
