export type GetPresetResponse = {
  mainLogoSvg: string
  additionalLogo: string
  enableLookUp: boolean
  gtmCode: string
  payButtonLogo: string
  companyName: string
  title: string
  metaDescription: string
  metaKeywords: string
  faviconXs: string
  faviconSm: string
  faviconMd: string
  faviconLg: string
  infoForHomePage: string
  infoForBankTransferDetailsPage: string
  termAndConditionsHtml: string
  termAndConditionsPdf: string
  privacyPolicyPdf: string
  websiteTermsOfUsePdf: string
  emailDelayTimeInSeconds: number
  sendMoneySectionLink: string
  sendMoneySectionText: string
  selectPaymentSectionText: string
  receiveAmountMultiplesOf: number
}

export enum FlowType {
  TopUp = 0,
  Cashout = 1,
  KuvaLocalOrder = 2,
  KuvaWhileLabel = 3,
  MaintenanceMode = 4,
  EarlyAccess = 5,
  CreditCardPayment = 6,
  CurrencyCloudBankPayment = 7,
  ClearJunctionBankPayment = 8,
  KuvaPayerFee = 9,
}

export enum FlowState {
  Off = 0,
  On = 1,
}

export type GetAvailableFlowResponse = Array<{
  state: FlowState
  flowType: FlowType
  minimalAmlCheckLevel: { weight: number } | null
}>

export type EarlyAccessForm = {
  password: string
}
