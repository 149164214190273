import { memo, useCallback, useEffect, useMemo } from 'react'
import InnerHeader from '../../../components/innerHeader'
import CircleLoading from '../../../components/interfaceComponents/CircleLoading'
import { useAppDispatch, useAppSelector } from '../../../hooks/dispatch'
import { AmlStatus } from '../../../interfaces/user'
import { Icon } from '../../../components/icons'
import { userActions } from '../../../redux/action-creators/userActions'
import { useNavigate } from 'react-router-dom'
import { calculationHelper } from '../../../helpers/calculationData'
import { beneficiaryService } from '../../../services/beneficiaryService'

const PendingPage = () => {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const userAmlStatus = useAppSelector(state => state.user.userAml?.status)
  const flowList = useAppSelector(state => state.preset.availableFlow)

  useEffect(() => {
    if (userAmlStatus === AmlStatus.RejectedWithRetry) {
      navigate('/verification')
    }
  }, [navigate, userAmlStatus])

  useEffect(() => {
    let interval: ReturnType<typeof setInterval>
    if (userAmlStatus === AmlStatus.Pending) {
      interval = setInterval(() => {
        dispatch(userActions.getUserAmlList(flowList))
      }, 5000)

      return () => clearInterval(interval)
    }
  }, [dispatch, flowList, userAmlStatus])

  const onGoBack = useCallback(() => {
    navigate('/')
  }, [navigate])

  const onClick = useCallback(async () => {
    const receivedAmountFromLocalStorage = Number(calculationHelper.getCalculationData())

    if (!receivedAmountFromLocalStorage) {
      navigate('/')
      return
    }

    const offset = 0
    const step = 1
    const beneficiaries = await beneficiaryService.getBeneficiaries(offset, step)

    if (beneficiaries.items.length > 0) {
      navigate('/beneficiaries/select-beneficiary')
    } else {
      navigate('/beneficiary/new', { state: { from: 'continue-transaction' } })
    }
  }, [navigate])

  const confirmationSection = useMemo(
    () => (
      <>
        <InnerHeader title="Success - is fully verified" onGoBack={onGoBack} />
        <div className="confirmation-page">
          <Icon.ConfirmTick />
          <span className="verified-success">Verified</span>
        </div>
      </>
    ),
    [onGoBack]
  )

  // const failedSection = useMemo(
  //   () => (
  //     <>
  //       <InnerHeader title="" onGoBack={onGoBack} />
  //       <div className="identity-error">
  //         <Icon.Error />
  //         <span>
  //           Unfortunately we couldn't verify your identity. Please contact our&nbsp;
  //           <Link to="/help" className="">
  //             support team.
  //           </Link>
  //         </span>
  //       </div>
  //     </>
  //   ),
  //   [onGoBack]
  // )

  const pendingSection = useMemo(
    () => (
      <>
        <InnerHeader title="Please wait, we are checking your documents" onGoBack={onGoBack} />
        <CircleLoading icon />
      </>
    ),
    [onGoBack]
  )

  const renderScreen = useMemo(() => {
    switch (userAmlStatus) {
      case 20:
        return confirmationSection
      // case 110:
      //   return failedSection
      default:
        return pendingSection
    }
  }, [confirmationSection, pendingSection, userAmlStatus])

  return (
    <section className="h-100-mb">
      <div className="container container-md container-100">
        {renderScreen}
        {userAmlStatus === AmlStatus.Verified && (
          <div className="btn-wrapper btn-wrapper-sm center m-b10">
            <button onClick={onClick} className="btn btn-primary ">
              Next
            </button>
          </div>
        )}
      </div>
    </section>
  )
}

export default memo(PendingPage)
