import {
  BeneficiaryType,
  DocumentType,
  DocumentTypeOptions,
  Gender,
  GetBeneficiaryFieldsConfigResponse,
} from '../../interfaces/beneficiary'
import { ReasonForTransfer, SourceOfFunds } from '../../interfaces/remittanceType'
import { Option } from '../../interfaces/commonTypes'
import { PaymentMethod, WithdrawalPaymentOption, WithdrawalPaymentOptionListByCountry, WithdrawalPaymentProvider } from '../../interfaces'

export const getCurrentBeneficiaryTypeOptions = (config: GetBeneficiaryFieldsConfigResponse) => {
  let options = [] as Array<Option>

  for (const key in config) {
    if (config[key].Enabled.visible) {
      options = [
        ...options,
        { label: BeneficiaryType[BeneficiaryType[key]], value: BeneficiaryType[key] },
      ]
    }
  }

  return options
}

export const getBeneficiaryTypeOptions = (config: GetBeneficiaryFieldsConfigResponse) => {
  let options = [] as Array<Option>

  for (const key in config) {
    options = [
      ...options,
      { label: BeneficiaryType[BeneficiaryType[key]], value: BeneficiaryType[key] },
    ]

  }

  return options
}

export const documentTypeOptionList = (config: GetBeneficiaryFieldsConfigResponse | null) => {
  const documentTypes = [
    { value: DocumentType.NationalId, label: 'National ID' },
    { value: DocumentType.DriversLicense, label: 'Drivers license' },
    { value: DocumentType.Passport, label: 'Passport' },
  ]
  let documentTypeList: Array<DocumentTypeOptions> = []

  if (config) {
    if (config.Individual.NationalId.visible) {
      documentTypeList = [...documentTypeList, documentTypes[0]]
    }
    if (config.Individual.DriversLicense.visible) {
      documentTypeList = [...documentTypeList, documentTypes[1]]
    }
    if (config.Individual.Passport.visible) {
      documentTypeList = [...documentTypeList, documentTypes[2]]
    }
  }

  return documentTypeList
}

export const genderOptions = [
  { value: Gender.Male, label: 'Male' },
  { value: Gender.Female, label: 'Female' },
]

export const reasonForTransferOptions = [
  { value: ReasonForTransfer.Other, label: 'Other' },
  { value: ReasonForTransfer.Personal, label: 'Personal/Family' },
  { value: ReasonForTransfer.Gift, label: 'Gift' },
  { value: ReasonForTransfer.Schooling, label: 'Schooling' },
  { value: ReasonForTransfer.ExpenseReimbursement, label: 'Expense Reimbursement' },
  { value: ReasonForTransfer.InvoicePayment, label: 'Invoice Payment' },
  { value: ReasonForTransfer.MedicalCosts, label: 'Medical Costs' },
  { value: ReasonForTransfer.Transportation, label: 'Transportation' },
]

export const sourceOfFundsOptions = [
  { value: SourceOfFunds.Other, label: 'Other' },
  { value: SourceOfFunds.Salary, label: 'Salary' },
  { value: SourceOfFunds.Savings, label: 'Savings' },
  { value: SourceOfFunds.Investments, label: 'Investments' },
  { value: SourceOfFunds.Gift, label: 'Gift' },
  { value: SourceOfFunds.Loan, label: 'Loan' },
  { value: SourceOfFunds.Winnings, label: 'Winnings' },
  { value: SourceOfFunds.PropertySale, label: 'PropertySale' },
  { value: SourceOfFunds.BusinessOwnership, label: 'BusinessOwnership' },
]

export const getWithdrawalPaymentOptionsForCalcSection = (withdrawalPaymentProvider: Array<WithdrawalPaymentProvider>) =>
  withdrawalPaymentProvider
    .map(withdrawalItem =>
    ({
      label: withdrawalItem.paymentMethod === PaymentMethod.Cash
        ? 'CBZ USD Cash Pickup (CBZ Touch, CBZ ATM, Agency Banking or CBZ Branch)'
        : 'USD Bank or Wallet Account',
      value: withdrawalItem.paymentProvider.id,
      paymentMethod: withdrawalItem.paymentMethod
    }))
    .sort((firstOperand, secondOperand) => firstOperand.paymentMethod - secondOperand.paymentMethod)


export const getWithdrawalPaymentOptionsForSummary = (withdrawalPaymentProvider: Array<WithdrawalPaymentProvider>) =>
  withdrawalPaymentProvider
    .map(withdrawalItem =>
    ({
      text: withdrawalItem.paymentMethod === PaymentMethod.Cash
        ? '98% of cash pickup transfers are available to collect in a minute via CBZ Touch, CBZ ATM, Agency Banking or CBZ Branch'
        : 'Get your money directly to your bank account.',
      title: withdrawalItem.paymentMethod === PaymentMethod.Cash ? 'Cash pickup' : 'Bank account',
      withdrawalMethod: withdrawalItem.paymentMethod,
      withdrawalProviderId: withdrawalItem.paymentProvider.id,


    }))
    .sort((firstOperand, secondOperand) => firstOperand.withdrawalMethod - secondOperand.withdrawalMethod)

export const getWithdrawalPaymentOptionsForCalcSectionV2 = (withdrawalPaymentProvider: Array<WithdrawalPaymentOption>) =>
  withdrawalPaymentProvider.sort((firstOperand, secondOperand) => firstOperand.order - secondOperand.order)
    .map(withdrawalItem =>
    ({
      label: `${withdrawalItem.currency.id} ${withdrawalItem.longTitle}`,
      value: withdrawalItem.id,
      paymentProviderId: withdrawalItem.paymentProvider.id,
      paymentMethod: withdrawalItem.paymentMethod,
      currency: withdrawalItem.currency.id,
    }))

export const getWithdrawalPaymentOptionsForSummaryV2 = (withdrawalPaymentProvider: Array<WithdrawalPaymentOption>) => {
  return withdrawalPaymentProvider
    .sort((firstOperand, secondOperand) => firstOperand.order - secondOperand.order)
    .map(withdrawalItem =>
    ({
      title: `${withdrawalItem.title} (${withdrawalItem.currency.id})`,
      text: withdrawalItem.description,
      withdrawalMethod: withdrawalItem.paymentMethod,
      withdrawalProviderId: withdrawalItem.paymentProvider.id,
      currency: withdrawalItem.currency.id,
    }))
}