import { PaymentMethod, WithdrawalPaymentOptionListByCountry, WithdrawalPaymentProvider } from "../interfaces";

export const getWithdrawalPaymentOptionsForHomePage = (withdrawalPaymentProvider: Array<WithdrawalPaymentProvider>) =>
  withdrawalPaymentProvider.map(withdrawalItem =>
  ({
    label: withdrawalItem.paymentMethod === PaymentMethod.Cash ? 'Cash Pickup' : 'Bank Account',
    value: withdrawalItem.paymentProvider.id,
    paymentMethod: withdrawalItem.paymentMethod
  }))
    .sort((firstOperand, secondOperand) => firstOperand.paymentMethod - secondOperand.paymentMethod)

export const getWithdrawalPaymentOptionsByCountryForHomePage = (withdrawalPaymentOptions: Array<WithdrawalPaymentOptionListByCountry>, countryId: number) => {
  const currentPaymentOptionsByCountry = withdrawalPaymentOptions.filter(item => item.id === countryId)[0]?.paymentOptions

  return currentPaymentOptionsByCountry?.sort((firstOperand, secondOperand) => firstOperand.order - secondOperand.order)
    .map(withdrawalItem =>
    ({
      label: `${withdrawalItem.title} (${withdrawalItem.currency.id})`,
      // value: withdrawalItem.paymentProvider.id,
      value: withdrawalItem.id,
      paymentMethod: withdrawalItem.paymentMethod,
      paymentProviderId: withdrawalItem.paymentProvider.id,
      currency: withdrawalItem.currency.id,
      currencySign: withdrawalItem.currency.sign,
    })) || []
}

export const getWithdrawalPaymentOptionsByCountry = (withdrawalPaymentOptions: Array<WithdrawalPaymentOptionListByCountry>, countryId: number) => {
  const paymentOptions = withdrawalPaymentOptions.filter(item => item.id === countryId)[0]?.paymentOptions || []


  return paymentOptions
}


export const getCountryOptions = (countryList: Array<WithdrawalPaymentOptionListByCountry>) => {
  return countryList.map(country => ({
    label: country.name,
    value: country.id,
    currency: country.currency?.id || '',
    prefix: country.currency?.sign || '',
    isO2Code: country.isO2Code
  }))
}



