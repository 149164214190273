import { ChangeEvent, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import useInfiniteScroll from 'react-infinite-scroll-hook'
import { useLocation, useNavigate } from 'react-router-dom'
import BeneficiaryListItem from '../../components/beneficiaries/BeneficiaryListItem'
import { Icon } from '../../components/icons'
import { useAppDispatch, useAppSelector } from '../../hooks/dispatch'
import { beneficiaryActions } from '../../redux/action-creators/beneficiaryActions'
import debounce from 'lodash/debounce'
import { PuffLoader } from 'react-spinners'
import { detectMobile } from '../../helpers/utils'
import InnerHeader from '../../components/innerHeader'
import CalculationSection from '../../components/calculation/CalculationSection'
import { calculationHelper } from '../../helpers/calculationData'
import { AmlStatus } from '../../interfaces/user'
import Modal from '../../components/Modal'
import { localStorageHelper } from '../../helpers/useLocalStorage'

const isMobile = detectMobile()

type LocationState = {
  state: {
    searchedValue: string
  }
  pathname: string
}

const BeneficiariesPage = () => {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const location = useLocation() as LocationState
  const searchedData = location?.state?.searchedValue || undefined
  const { items, hasNextPage } = useAppSelector(state => state.beneficiary.beneficiariesList)
  const { isLoading, error } = useAppSelector(state => state.beneficiary)
  const userAmlStatus = useAppSelector(state => state.user.userAml?.status)

  const step = 10
  const offset = 0

  const [searchInputValue, setSearchInputValue] = useState('')
  const [showClearButton, setShowClearButton] = useState(false)
  const [isShown, setIsShown] = useState(false)
  const [isOpenedSomeTooltip, setIsOpenedSomeTooltip] = useState(false)
  const [isDisabledButton, setIsDisabledButton] = useState(false)
  const [receive, setReceive] = useState<number | undefined | string>()

  const [isOpenVerificationModal, setIsOpenVerificationModal] = useState(false)
  const [isOpenDeletingBeneficiaryModal, setIsOpenDeletingBeneficiaryModal] = useState(false)
  const [isOpenCompanyBeneficiaryWarning, setIsOpenCompanyBeneficiaryWarning] = useState(false)

  const [deletingBeneficiaryId, setDeletingBeneficiaryId] = useState<number>(0)
  const receivePaymentData = localStorageHelper.getData('receiveMethod')
  const calcData = localStorageHelper.getData('calcData')

  const inputRef = useRef<HTMLInputElement>(null)
  const searchSectionRef = useRef<HTMLDivElement>(null)

  const onClickAway = useCallback(
    (event: any) => {
      if (
        !(
          isOpenedSomeTooltip ||
          event.target.closest('.circle-with-dots') ||
          searchSectionRef.current?.contains(event.target)
        )
      ) {
        setIsShown(false)
        setSearchInputValue('')
        setShowClearButton(false)
      }
    },
    [isOpenedSomeTooltip]
  )

  useEffect(() => {
    const receivePaymentData = localStorageHelper.getData('receiveMethod')
    const calcData = localStorageHelper.getData('calcData')

    if (
      (!receivePaymentData || !calcData) &&
      location?.pathname === '/beneficiaries/select-beneficiary'
    ) {
      navigate('/')
    }
  }, [location?.pathname, navigate])

  useEffect(() => {
    if (isShown && isMobile) {
      document.addEventListener('click', onClickAway, { capture: true })

      return () => {
        document.removeEventListener('click', onClickAway, { capture: true })
      }
    }
  }, [isShown, onClickAway])

  useEffect(() => {
    if (!isMobile || searchedData) {
      setIsShown(true)
    }
  }, [searchedData])

  useEffect(() => {
    if (searchedData) {
      setSearchInputValue(searchedData)
      setShowClearButton(true)
    }
  }, [searchedData])

  const showSearch = () => {
    setIsShown(true)
  }

  const searchForData = useCallback(async () => {
    const trimmedInputValue = searchInputValue.trim()

    if (trimmedInputValue) {
      await dispatch(beneficiaryActions.getBeneficiariesBySearch(offset, 15, trimmedInputValue))
    } else {
      await dispatch(beneficiaryActions.getBeneficiaries(offset, 15))
    }
  }, [dispatch, searchInputValue])

  const debounceFunction = useMemo(() => debounce(searchForData, 700), [searchForData])

  useEffect(() => {
    debounceFunction()

    return debounceFunction.cancel
  }, [debounceFunction])

  useEffect(() => {
    return () => {
      dispatch(beneficiaryActions.clearBeneficiaries())
      setIsDisabledButton(false)
    }
  }, [dispatch])

  const onLoadMore = async () => {
    if (searchInputValue) {
      const trimmedInputValue = searchInputValue.trim()

      await dispatch(
        beneficiaryActions.getBeneficiariesBySearch(offset + items.length, step, trimmedInputValue)
      )
    } else {
      await dispatch(beneficiaryActions.getBeneficiaries(offset + items.length, step))
    }
  }

  const [sentryRef, { rootRef }] = useInfiniteScroll({
    loading: isLoading,
    hasNextPage,
    onLoadMore,
    disabled: !!error,
    rootMargin: '0px 0px 400px 0px',
  })

  const onInputChange = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value

    if (value) {
      setShowClearButton(true)
    } else {
      setShowClearButton(false)
    }

    setSearchInputValue(value)
  }, [])

  const clearInput = () => {
    setSearchInputValue('')
    setShowClearButton(false)
    inputRef.current?.focus()
  }

  const addBeneficiary = () => {
    if (location.pathname === '/beneficiaries/select-beneficiary') {
      calculationHelper.setCalculationData(receive)
      navigate('/beneficiary/new', {
        state: {
          from: '/beneficiaries/select-beneficiary',
          searchedValue: searchInputValue.trim(),
        },
      })
    } else {
      navigate('/beneficiary/new', {
        state: { from: '/beneficiaries', searchedValue: searchInputValue.trim() },
      })
    }
  }

  const deleteBeneficiary = () => {
    setIsOpenDeletingBeneficiaryModal(false)

    dispatch(beneficiaryActions.deleteRecipient(deletingBeneficiaryId))
  }

  const confirmModalFunction = useCallback(() => {
    setIsOpenVerificationModal(false)

    if (userAmlStatus === AmlStatus.Pending) {
      navigate('/pending')
      return
    }

    navigate('/verification')
  }, [navigate, userAmlStatus])

  const confirmCompanyBeneficiaryWarningModal = useCallback(() => {
    setIsOpenCompanyBeneficiaryWarning(false)
  }, [])

  return (
    <>
      <section className="beneficiariesV2 h-100-mb">
        <div className="container container-xxl container-full-mb container-100">
          <div
            className={
              location?.pathname === '/beneficiaries/select-beneficiary'
                ? 'beneficiaries beneficiaries--main beneficiaries--search'
                : 'beneficiaries beneficiaries--main'
            }
          >
            <InnerHeader
              title={
                location.pathname === '/beneficiaries/select-beneficiary'
                  ? 'Select/Add Beneficiary'
                  : 'My Beneficiaries'
              }
              icon={<Icon.Beneficiaries />}
            />
            {location?.pathname === '/beneficiaries/select-beneficiary' &&
              receivePaymentData &&
              calcData && (
                <CalculationSection
                  mode="calculation-input"
                  receiveAmount={receive}
                  setReceiveAmount={setReceive}
                  setIsDisabledButton={setIsDisabledButton}
                  currency={receivePaymentData.currency}
                  currencySign={receivePaymentData.currencySign}
                />
              )}
            <div className="beneficiaries-search">
              {isShown && (
                <div className="search-block" ref={searchSectionRef}>
                  <input
                    className=""
                    placeholder="Search Beneficiaries"
                    maxLength={40}
                    value={searchInputValue}
                    onChange={onInputChange}
                    ref={inputRef}
                    {...(isMobile && { autoFocus: true })}
                  ></input>
                  {showClearButton && (
                    <button onClick={clearInput}>
                      <span>
                        <Icon.Cross />
                      </span>
                    </button>
                  )}
                </div>
              )}
            </div>

            <div className="table">
              <div className="table-header">
                <div className="table-header__item beneficiary-item">Name</div>
                <div className="table-header__item beneficiary-item">Country</div>
                <div className="table-header__item beneficiary-item">Phone Number</div>
                <div className="table-header__item beneficiary-item">Type</div>
                <div className="table-header__item beneficiary-item">
                  <button
                    className="btn btn-primary btn-xl btn-additional hide-mobile"
                    onClick={addBeneficiary}
                  >
                    + Add Beneficiary
                  </button>
                  {!isShown && (
                    <div className="show-mobile" onClick={showSearch}>
                      <Icon.Search />
                    </div>
                  )}
                </div>
              </div>
              <div className="table-body" ref={rootRef}>
                {!Boolean(items.length) && isLoading ? (
                  <div className="spinner spinner-absolute">
                    <PuffLoader size={70} color="#3171D8" />
                  </div>
                ) : (
                  items.map(beneficiary => (
                    <BeneficiaryListItem
                      setIsOpenVerificationModal={setIsOpenVerificationModal}
                      setIsOpenDeletingBeneficiaryModal={setIsOpenDeletingBeneficiaryModal}
                      setIsOpenCompanyBeneficiaryWarning={setIsOpenCompanyBeneficiaryWarning}
                      setDeletingBeneficiaryId={setDeletingBeneficiaryId}
                      isDisabledButton={isDisabledButton}
                      receiveAmount={receive}
                      key={beneficiary.id}
                      reference={sentryRef}
                      beneficiary={beneficiary}
                      searchedValue={searchInputValue.trim()}
                      setIsOpenedSomeTooltip={setIsOpenedSomeTooltip}
                    />
                  ))
                )}
              </div>
            </div>
            <div className="show-mobile t-center">
              <button className="btn btn-primary btn-xxl btn-svg" onClick={addBeneficiary}>
                <Icon.Beneficiaries />+ Add Beneficiary
              </button>
            </div>
          </div>
        </div>
      </section>
      <Modal
        setIsOpenModal={setIsOpenVerificationModal}
        isOpenModal={isOpenVerificationModal}
        modalHeader="Verification"
        modalBody="For security reasons, please, complete the Identity Verification."
        modalType="informationModal"
        confirmFunction={confirmModalFunction}
      />
      <Modal
        setIsOpenModal={setIsOpenDeletingBeneficiaryModal}
        isOpenModal={isOpenDeletingBeneficiaryModal}
        modalHeader="Deleting The Beneficiary"
        modalBody="Are you sure you want to remove this beneficiary?"
        modalType="dialogModal"
        confirmFunction={deleteBeneficiary}
      />
      <Modal
        setIsOpenModal={setIsOpenCompanyBeneficiaryWarning}
        isOpenModal={isOpenCompanyBeneficiaryWarning}
        modalHeader="Not Available"
        modalBody="Company beneficiaries may only receive a payment to their bank account.
                  Please, change the receiving method and you will be able to send to this beneficiary"
        modalType="informationModal"
        confirmFunction={confirmCompanyBeneficiaryWarningModal}
      />
    </>
  )
}

export default BeneficiariesPage
