import { useCallback, useEffect, useMemo } from 'react'
import { useAppDispatch, useAppSelector } from '../../hooks/dispatch'
import { CalculationLimitPeriod } from '../../interfaces/user'
import { userActions } from '../../redux/action-creators/userActions'
import isEmpty from 'lodash/isEmpty'

const Limits = () => {
  const dispatch = useAppDispatch()
  const userLimits = useAppSelector(state => state.user.userLimits)

  useEffect(() => {
    dispatch(userActions.getUserCalculationLimit())
  }, [dispatch])

  const modifiedLimits = useMemo(() => {
    const currentUserLimits = userLimits.reduce((obj, item) => {
      obj[item.period] = {
        remainingAmount: item.remainingAmount,
        totalLimit: item.totalLimit,
        noLimits: item.totalLimit === -1,
      }

      return obj
    }, {})

    if (
      currentUserLimits[CalculationLimitPeriod.Monthly]?.remainingAmount !== -1 &&
      currentUserLimits[CalculationLimitPeriod.Monthly]?.remainingAmount <
        currentUserLimits[CalculationLimitPeriod.Daily]?.remainingAmount
    ) {
      currentUserLimits[CalculationLimitPeriod.Daily].remainingAmount =
        currentUserLimits[CalculationLimitPeriod.Monthly].remainingAmount
    }

    return currentUserLimits
  }, [userLimits])

  const getLimitText = useCallback(
    (period: CalculationLimitPeriod) => {
      let text = ''

      if (modifiedLimits[period].noLimits) {
        text = 'No limits'
      } else {
        if (period === CalculationLimitPeriod.Daily) {
          text = `$${modifiedLimits[period].remainingAmount} ($${modifiedLimits[period].totalLimit}/day)`
        }
        if (period === CalculationLimitPeriod.Monthly) {
          text = `$${modifiedLimits[period].remainingAmount} ($${modifiedLimits[period].totalLimit}/month)`
        }
      }

      return text
    },
    [modifiedLimits]
  )

  return (
    <>
      {!isEmpty(modifiedLimits) && (
        <div className="limits">
          <div>
            <p>Daily limits:</p>
            <p>{getLimitText(CalculationLimitPeriod.Daily)}</p>
          </div>
          <div>
            <p>Monthly limits:</p>
            <p>{getLimitText(CalculationLimitPeriod.Monthly)}</p>
          </div>
        </div>
      )}
    </>
  )
}

export default Limits
