import { ChangeEvent, memo, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import NumberFormat from 'react-number-format'
import { Icon } from '../icons'
import { checkForDateExistence, shouldItUseShowPicker } from '../../helpers/utils'

const isShowPickerUsed = shouldItUseShowPicker()

type FormCustomDateInputProps = {
  placeholder: string
  error?: string
  value: string | undefined
  disabled?: boolean
  onParentChange: (value: string) => void
  label?: string
  hidden?: boolean
}

const FormCustomDateInput = ({
  placeholder,
  error,
  disabled,
  value,
  onParentChange,
  label,
  hidden,
}: FormCustomDateInputProps) => {
  const [nativeDateInputValue, setNativeDateInputValue] = useState('')
  const [uiDateInputValue, setUiDateInputValue] = useState('')

  const nativeDateInputRef = useRef<HTMLInputElement>(null)

  useEffect(() => {
    if (value) {
      const dateForUiDateInput = value.split('-').reverse().join('-')

      setUiDateInputValue(dateForUiDateInput)

      if (checkForDateExistence(value)) {
        setNativeDateInputValue(value)
      } else {
        setNativeDateInputValue('2000-01-01')
      }
    } else {
      setNativeDateInputValue('2000-01-01')
    }
  }, [value])

  const currentClassName = useMemo(() => {
    let className = 'form-date-input-ui'

    if (error) {
      className = className + ' error'
    }
    return className
  }, [error])

  const onClick = () => {
    // @ts-ignore
    nativeDateInputRef.current!.showPicker()
  }

  const onChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const { value } = event.target
      const dateForNativeInput = value.split('-').reverse().join('-')

      if (checkForDateExistence(dateForNativeInput)) {
        setNativeDateInputValue(dateForNativeInput)
      }

      onParentChange(dateForNativeInput)
      setUiDateInputValue(value)
    },
    [onParentChange]
  )

  const onChangeNativeInput = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const { value } = event.target
      const dateForInput = value.split('-').reverse().join('-')

      onParentChange(value)
      setNativeDateInputValue(value)
      setUiDateInputValue(dateForInput)
    },
    [onParentChange]
  )

  return (
    <div className="form-input" hidden={hidden}>
      {label && (
        <label className="form-input-label" onClick={onClick}>
          {label}
        </label>
      )}
      <div
        className={disabled ? 'form-input-block cursor-notAllowed disabled' : 'form-input-block'}
      >
        <NumberFormat
          allowNegative={false}
          onChange={onChange}
          value={uiDateInputValue}
          format={'##-##-####'}
          type="tel"
          inputMode="numeric"
          disabled={disabled}
          placeholder={placeholder}
          autoComplete="off"
          className={currentClassName}
        />
        <span className="form-date-input-icon" {...(isShowPickerUsed && { onClick })}>
          <Icon.Calendar />
        </span>
        <input
          ref={nativeDateInputRef}
          type="date"
          disabled={disabled}
          value={nativeDateInputValue}
          onChange={onChangeNativeInput}
          className={`form-date-input${isShowPickerUsed ? '' : ' show-date-input'}`}
        />
      </div>
      <p className="form-input-error">{error}</p>
    </div>
  )
}

export default memo(FormCustomDateInput) as typeof FormCustomDateInput
