import NumberFormat from 'react-number-format'
import * as Flags from 'country-flag-icons/react/3x2'
import { Dispatch, SetStateAction, useCallback, useEffect, useMemo, useState } from 'react'
import { useAppDispatch, useAppSelector } from '../../hooks/dispatch'
import { recipientCountryInLocalStorage } from '../../helpers/localStorage/recipientCountry'
import { calculationHelper } from '../../helpers/calculationData'
import { BeneficiaryType, CompanyRecipient, KWLRecipient } from '../../interfaces/beneficiary'
import { userActions } from '../../redux/action-creators/userActions'
import { CalculationLimitPeriod, UserStatus } from '../../interfaces/user'
import { Link, useLocation } from 'react-router-dom'
import IndividualBeneficiaryDetails from './IndividualBeneficiaryDetails'
import CompanyBeneficiaryDetails from './CompanyBeneficiaryDetails'
import { localStorageHelper } from '../../helpers/useLocalStorage'

export const isIndividualBeneficiary = (
  beneficiary: KWLRecipient | CompanyRecipient
): beneficiary is KWLRecipient => {
  return (beneficiary as KWLRecipient).firstName !== undefined
}

type CalculationSectionProps = {
  mode:
    | 'calculation-input'
    | 'calculation-input-with-recipient'
    | 'calculation-block-with-recipient'
  receiveAmount: number | undefined | string
  setIsDisabledButton?: Dispatch<SetStateAction<boolean>>
  setReceiveAmount?: Dispatch<SetStateAction<number | undefined | string>>
  recipient?: KWLRecipient | CompanyRecipient
  isShownBeneficiaryAccount?: boolean
  currencySign: string
  currency: string
}

const CalculationSection = ({
  mode,
  receiveAmount,
  setIsDisabledButton,
  setReceiveAmount,
  recipient,
  isShownBeneficiaryAccount,
  currencySign,
  currency,
}: CalculationSectionProps) => {
  const dispatch = useAppDispatch()
  const { pathname } = useLocation()

  const { minAmountToReceive, maxAmountToReceive } = useAppSelector(state => state.calculation)
  const { userDetails, userLimits } = useAppSelector(state => state.user)
  const preset = useAppSelector(state => state.preset.preset)
  const recipientCountry = recipientCountryInLocalStorage.getRecipientCountry()

  let inputRef: any

  const [errorVariant, setErrorVariant] = useState<
    '' | 'instance-min-limit' | 'instance-max-limit' | 'user-max-limit' | 'multiples'
  >('')
  const [amountFocus, setAmountFocus] = useState('')

  const getLimits = useCallback(async () => {
    if (userDetails?.status === UserStatus.Accepted) {
      await dispatch(userActions.getUserCalculationLimit())
    } else {
      await dispatch(userActions.getAnonymousCalculationLimit())
    }
  }, [dispatch, userDetails?.status])

  useEffect(() => {
    if (mode === 'calculation-input' || mode === 'calculation-input-with-recipient') {
      getLimits()
    }
  }, [getLimits, mode])

  useEffect(() => {
    if (setReceiveAmount) {
      const amount = Number(calculationHelper.getCalculationData())

      if (amount) {
        setReceiveAmount(amount)
      } else {
        inputRef.focus()
      }
    }
  }, [inputRef, mode, setReceiveAmount])

  useEffect(() => {
    if (
      (mode === 'calculation-input' || mode === 'calculation-input-with-recipient') &&
      setIsDisabledButton
    ) {
      if (errorVariant || !receiveAmount) {
        setIsDisabledButton(true)
      } else {
        setIsDisabledButton(false)
      }
    }
  }, [errorVariant, mode, receiveAmount, setIsDisabledButton])

  //
  const FlagComponent = useMemo(() => {
    if (recipient && isIndividualBeneficiary(recipient) && recipient?.countryIso) {
      return Flags[`${recipient?.countryIso}`]
    }

    if (recipientCountry) {
      return Flags[`${recipientCountry.iso2Code}`]
    } else {
      return Flags['ZW']
    }
  }, [recipient, recipientCountry])

  const checkField = useCallback(
    (value: number) => {
      if (preset?.receiveAmountMultiplesOf && value % preset?.receiveAmountMultiplesOf) {
        setErrorVariant('multiples')
        return
      }

      if (minAmountToReceive !== 0 && value < minAmountToReceive) {
        setErrorVariant('instance-min-limit')
        return
      }

      if (userDetails?.status === UserStatus.Accepted) {
        const limits = userLimits.filter(
          limit =>
            limit.period !== CalculationLimitPeriod.MinPerTransaction &&
            limit.period !== CalculationLimitPeriod.MaxPerTransaction
        )

        const isExceededUserLimit = limits.find(
          limitItem => limitItem.remainingAmount !== -1 && limitItem.remainingAmount < value
        )
        if (isExceededUserLimit) {
          setErrorVariant('user-max-limit')
          return
        }
      }

      if (maxAmountToReceive !== 0 && value > maxAmountToReceive) {
        setErrorVariant('instance-max-limit')
        return
      }

      setErrorVariant('')
    },
    [
      maxAmountToReceive,
      minAmountToReceive,
      preset?.receiveAmountMultiplesOf,
      userDetails?.status,
      userLimits,
    ]
  )

  useEffect(() => {
    if (typeof receiveAmount === 'number') {
      checkField(receiveAmount)
    }
  }, [checkField, receiveAmount])

  const onReceiveChange = (value: number | undefined) => {
    setReceiveAmount && setReceiveAmount(value)
  }

  const onFocus = () => {
    setAmountFocus(' focus')
    setReceiveAmount && setReceiveAmount('')
  }

  const onBlur = () => {
    setAmountFocus('')
  }

  const errorText = useMemo(() => {
    if (errorVariant === 'multiples') {
      return `The amount must be a multiple of ${preset?.receiveAmountMultiplesOf}`
    }

    if (errorVariant === 'instance-min-limit') {
      return `The amount is less than the lower limit ${currencySign}${minAmountToReceive}`
    }

    if (errorVariant === 'instance-max-limit') {
      return `The amount is greater than the upper limit ${currencySign}${maxAmountToReceive}`
    }

    if (errorVariant === 'user-max-limit') {
      return (
        <>
          Your daily/monthly limit has been exceeded. More info&nbsp;
          <Link to="/profile" state={{ pathname, tab: 'limits' }}>
            here
          </Link>
        </>
      )
    }

    return ''
  }, [
    errorVariant,
    maxAmountToReceive,
    minAmountToReceive,
    pathname,
    preset?.receiveAmountMultiplesOf,
    currencySign,
  ])

  return (
    <>
      {mode === 'calculation-input' && (
        <div className="calculation-block">
          <div className={`amount-block${amountFocus}${errorVariant ? ' error' : ''}`}>
            <div className="amount-block__flag">
              <FlagComponent />
            </div>
            <div className="amount-block__title">They receive</div>
            <div className="amount-block__input">
              <NumberFormat
                name="received"
                className="amount"
                prefix={currencySign}
                value={receiveAmount}
                decimalScale={0}
                fixedDecimalScale={true}
                allowNegative={false}
                allowLeadingZeros={false}
                placeholder={`${currency}${currencySign} Amount`}
                type="tel"
                maxLength={6}
                autoComplete="off"
                onFocus={onFocus}
                onBlur={onBlur}
                getInputRef={(elem: NumberFormat<HTMLInputElement>) => (inputRef = elem)}
                onValueChange={value => onReceiveChange(value.floatValue)}
              />
            </div>
          </div>
          {errorVariant && <span className="form-input-error">{errorText}</span>}
        </div>
      )}

      {recipient && mode === 'calculation-block-with-recipient' && (
        <div className="user-info-block">
          <div className="user-info-block__row">
            <div className="user-info-block__title">
              They receive {currency} {Number(receiveAmount)?.toFixed(2)}
            </div>
            <div className="user-info-block__flag">
              <FlagComponent />
            </div>
            <div className="user-info-block__separate"></div>
          </div>
          {recipient.type === BeneficiaryType.Individual && (
            <IndividualBeneficiaryDetails
              recipient={recipient as KWLRecipient}
              isShownBeneficiaryAccount={isShownBeneficiaryAccount}
            />
          )}
          {recipient.type === BeneficiaryType.MobileWalletBeneficiary && (
            <IndividualBeneficiaryDetails
              recipient={recipient as KWLRecipient}
              isShownBeneficiaryAccount={isShownBeneficiaryAccount}
            />
          )}
          {recipient.type === BeneficiaryType.Company && (
            <CompanyBeneficiaryDetails
              recipient={recipient as CompanyRecipient}
              isShownBeneficiaryAccount={isShownBeneficiaryAccount}
            />
          )}
        </div>
      )}
      {recipient && mode === 'calculation-input-with-recipient' && (
        <div>
          <div className="calculation-block">
            <div className={`amount-block${amountFocus}${errorVariant ? ' error' : ''}`}>
              <div className="amount-block__flag">
                <FlagComponent />
              </div>
              <div className="amount-block__title">They receive</div>
              <div className="amount-block__input">
                <NumberFormat
                  name="received"
                  className="amount"
                  prefix={currencySign}
                  value={receiveAmount}
                  decimalScale={0}
                  fixedDecimalScale={true}
                  allowNegative={false}
                  allowLeadingZeros={false}
                  placeholder={`${currency}${currencySign} Amount`}
                  type="tel"
                  maxLength={10}
                  autoComplete="off"
                  onFocus={onFocus}
                  onBlur={onBlur}
                  getInputRef={(elem: NumberFormat<HTMLInputElement>) => (inputRef = elem)}
                  onValueChange={value => onReceiveChange(value.floatValue)}
                />
              </div>
            </div>
            {errorVariant && <span className="form-input-error">{errorText}</span>}
          </div>
          <div className="user-info-block">
            {/* {recipient.type === BeneficiaryType.Individual ? (
              <IndividualBeneficiaryDetails recipient={recipient as KWLRecipient} />
            ) : (
              <CompanyBeneficiaryDetails recipient={recipient as CompanyRecipient} />
            )} */}
            {recipient.type === BeneficiaryType.Individual && (
              <IndividualBeneficiaryDetails recipient={recipient as KWLRecipient} />
            )}
            {recipient.type === BeneficiaryType.MobileWalletBeneficiary && (
              <IndividualBeneficiaryDetails recipient={recipient as KWLRecipient} />
            )}
            {recipient.type === BeneficiaryType.Company && (
              <CompanyBeneficiaryDetails recipient={recipient as CompanyRecipient} />
            )}
          </div>
        </div>
      )}
    </>
  )
}

export default CalculationSection
