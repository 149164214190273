import { useCallback, useEffect, useMemo, useState } from 'react'
import InnerHeader from '../../../components/innerHeader'
import { useAppDispatch, useAppSelector } from '../../../hooks/dispatch'
import { Controller, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import FormInput from '../../../components/interfaceComponents/FormInput'
import { userYupSchemes } from '../../../helpers/yupSchemes/userYupSchemes'
import { useLocation, useNavigate } from 'react-router-dom'
import { userActions } from '../../../redux/action-creators/userActions'
import isEmpty from 'lodash/isEmpty'
import { trimObjectFields } from '../../../helpers/utils'
import { UserDetailsForm } from '../../../interfaces/user'
import { getDateFormatWithoutTimeForInput } from '../../../helpers/dateFormat'
import CalculationSection from '../../../components/calculation/CalculationSection'
import { calculationHelper } from '../../../helpers/calculationData'
// import FormDateInput from '../../../components/interfaceComponents/FormDateInput'
import FormCustomDateInput from '../../../components/interfaceComponents/FormCustomDateInput'
import { localStorageHelper } from '../../../helpers/useLocalStorage'

type LocationState = {
  state: {
    from: string
  }
}

const UserDetailsPage = () => {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  // const resendEmailDelayTime =
  //   useAppSelector(state => state.preset.preset?.emailDelayTimeInSeconds) || 60
  const userDetails = useAppSelector(state => state.user.userDetails)
  const location = useLocation() as LocationState
  const from = location?.state?.from || 'sing-up'
  const receivePaymentData = localStorageHelper.getData('receiveMethod')
  //receivePaymentData.currencySign

  const [receive, setReceive] = useState<number | undefined | string>()
  const [isDisabledBtn, setIsDisabledBtn] = useState(false)

  const parsedDate = useMemo(() => {
    const dateToMillisecond = userDetails?.dateOfBirth && Date.parse(userDetails?.dateOfBirth)
    const defaultDateToMillisecond = Date.parse('0001-01-01')

    if (userDetails?.dateOfBirth && dateToMillisecond !== defaultDateToMillisecond) {
      return userDetails.dateOfBirth
    } else {
      return ''
    }
  }, [userDetails?.dateOfBirth])

  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    setError,
    setValue,
  } = useForm<UserDetailsForm>({
    resolver: yupResolver(userYupSchemes.userDetailsScheme),
  })

  const isDisabledButton = !isEmpty(errors) || isDisabledBtn

  useEffect(() => {
    if (userDetails) {
      setValue('firstName', userDetails?.firstName || '')
      setValue('lastName', userDetails?.lastName || '')
      setValue('dateOfBirth', getDateFormatWithoutTimeForInput(parsedDate))
      setValue('email', userDetails?.email || '')
    }
  }, [parsedDate, setValue, userDetails])

  const onSubmit = async (data: UserDetailsForm) => {
    const dateOfBirth = getDateFormatWithoutTimeForInput(data.dateOfBirth)
    const body = trimObjectFields<UserDetailsForm>({
      firstName: data.firstName,
      lastName: data.lastName,
      dateOfBirth,
      email: data.email,
    })
    calculationHelper.setCalculationData(receive)
    await dispatch(userActions.updateUserDetailsAction(body, navigate, from, setError))
  }

  const onGoBack = () => {
    navigate('/')
  }

  return (
    <section className="h-100-mb">
      <div className="container container-md container-100">
        <InnerHeader title="Your Details 1/2" onGoBack={onGoBack} />
        <div id="login-page" className="login-page">
          {from === 'continue-transaction' && (
            <CalculationSection
              mode="calculation-input"
              receiveAmount={receive}
              setReceiveAmount={setReceive}
              setIsDisabledButton={setIsDisabledBtn}
              currency={receivePaymentData.currency}
              currencySign={receivePaymentData.currencySign}
            />
          )}
          <form id="sign-up-form" className="login-form" onSubmit={handleSubmit(onSubmit)}>
            <h4>Your Email Address</h4>
            <FormInput<UserDetailsForm>
              id="email"
              register={register}
              error={errors?.email?.message}
              className="formInput"
              type="text"
              label="Your email address"
              maxLength={64}
            />

            <h4>Your Personal Details</h4>
            <FormInput<UserDetailsForm>
              id="firstName"
              register={register}
              error={errors?.firstName?.message}
              className="formInput"
              type="text"
              label="First Name"
              maxLength={32}
            />
            <FormInput<UserDetailsForm>
              id="lastName"
              register={register}
              error={errors.lastName?.message}
              className="formInput"
              type="text"
              label="Last Name"
              maxLength={32}
            />
            {/* <Controller
              control={control}
              name="dateOfBirth"
              render={({ field: { onChange, value } }) => (
                <FormDateInput
                  placeholder="Date of Birth (dd/mm/yyyy)"
                  error={errors.dateOfBirth?.message}
                  value={value}
                  onParentChange={onChange}
                  label='Date of Birth'
                />
              )}
            /> */}
            <Controller
              control={control}
              name="dateOfBirth"
              render={({ field: { onChange, value } }) => (
                <FormCustomDateInput
                  placeholder="dd-mm-yyyy"
                  error={errors.dateOfBirth?.message}
                  value={value}
                  onParentChange={onChange}
                  label="Date of Birth"
                />
              )}
            />
          </form>
        </div>

        <div className="btn-wrapper center">
          <button form="sign-up-form" className="btn btn-primary" disabled={isDisabledButton}>
            Next
          </button>
        </div>
      </div>
    </section>
  )
}

export default UserDetailsPage
