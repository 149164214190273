import { useNavigate } from 'react-router-dom'

const MaintenancePage = () => {
  const navigate = useNavigate()

  const onClick = () => {
    navigate('/')
  }

  return (
    <section className='h-100-mb'>
      <div className='container container-md container-100'>
        <div className='success-page'>
          <h1>Under maintenance, please come back later</h1>

          <div className='btn-wrapper'>
            <button className='btn btn-next' onClick={onClick}>
              Retry
            </button>
          </div>
        </div>
      </div>
    </section>
  )
}
export default MaintenancePage
