import NumberFormat from 'react-number-format'
import { ChangeEvent, useEffect, useMemo, useState } from 'react'
import { PhoneNumberUtil } from 'google-libphonenumber'
import { getExampleNumber, CountryCode, formatNumber } from 'libphonenumber-js'
import examples from 'libphonenumber-js/examples.mobile.json'
import parsePhoneNumber from 'libphonenumber-js'

type Option = {
  value: string | number
  label: string
  iso2Code?: string
}

type PhoneNumberInputProps = {
  placeholder?: string
  onChange: (value: string) => void
  id: string
  valueFromServer?: string
  defaultSelectedValue: string
  error?: string
  value?: string
  disabled?: boolean
  options: Array<Option>
  format: 'national' | 'simple'
  mask?: string
  autofocus?: boolean
}

const PhoneNumberInput = ({
  id,
  error,
  // value,
  options,
  onChange,
  valueFromServer,
  defaultSelectedValue,
  disabled,
  format,
  mask,
  placeholder,
  autofocus = false,
}: PhoneNumberInputProps) => {
  const [inputValue, setInputValue] = useState('')
  const [selectValue, setSelectValue] = useState('')
  const [selectedIso2CodeCountry, setSelectedIso2CodeCountry] = useState('GB')

  const isError = error ? 'error' : ''

  useEffect(() => {
    if (valueFromServer) {
      const phoneUtil = PhoneNumberUtil.getInstance().parseAndKeepRawInput(valueFromServer)
      const phoneNumber = String(phoneUtil.getNationalNumber())
      const countryCode = `+${phoneUtil.getCountryCode()}`
      const currentIso2Code = options.find(option => option.value === countryCode)

      setSelectedIso2CodeCountry(currentIso2Code?.iso2Code || 'GB')
      setSelectValue(countryCode)
      setInputValue(phoneNumber)
    }
  }, [options, valueFromServer])

  useEffect(() => {
    if (!valueFromServer) {
      setSelectValue(defaultSelectedValue)
      onChange(defaultSelectedValue)
    }
  }, [defaultSelectedValue, onChange, valueFromServer])

  const currentFormatNumber = useMemo(() => {
    const exampleNumber = getExampleNumber(selectedIso2CodeCountry as CountryCode, examples)
    const parsedExampleNumber = exampleNumber?.number && parsePhoneNumber(exampleNumber?.number)
    let mask

    if (format === 'national') {
      const numberGroups = parsedExampleNumber
        ?.formatNational()
        .slice(1)
        .replace(/\d/g, '#')
        .match(/([#]+)/g)

      mask = numberGroups?.reduce((finalMask, currentNumberGroup, index) => {
        if (index === 0) {
          return finalMask + `${currentNumberGroup}`
        }
        if (index === 1) {
          return finalMask + ` ${currentNumberGroup}`
        }

        return finalMask + `-${currentNumberGroup}`
      }, '')
    }

    if (format === 'simple') {
      mask = parsedExampleNumber?.nationalNumber.replace(/\d/g, '#')
    }

    return mask
  }, [format, selectedIso2CodeCountry])

  const onChangeInputValue = (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value
    const fullString = `${selectValue} ${value}`

    setInputValue(value)
    onChange(fullString)
  }

  const onChangeCountryCode = (event: any) => {
    const value = event.target.value
    const currentOption = options.find(option => option.value === event.target.value)
    const fullString = `${value} ${inputValue}`

    setSelectedIso2CodeCountry(currentOption?.iso2Code || 'GB')
    setSelectValue(value)
    onChange(fullString)
  }

  return (
    <>
      <div className="mobile-number-input">
        <select
          value={selectValue}
          onChange={onChangeCountryCode}
          disabled={disabled}
          className={isError}
        >
          {options.map(optionItem => (
            <option key={optionItem.value} value={optionItem.value}>
              {optionItem.label}
            </option>
          ))}
        </select>
        <NumberFormat
          allowNegative={false}
          onChange={onChangeInputValue}
          value={inputValue}
          format={currentFormatNumber}
          // format={format}
          mask={mask || ''}
          id={id}
          type="tel"
          inputMode="numeric"
          disabled={disabled}
          placeholder={placeholder}
          autoComplete="off"
          className={isError}
          autoFocus={autofocus}
        />
      </div>
      <p className="form-input-error">{error}</p>
    </>
  )
}

export default PhoneNumberInput
