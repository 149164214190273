import { authHelper } from '../helpers/authData'
import FingerPrint from '../helpers/fingerPrint'
import { localStorageHelper } from '../helpers/useLocalStorage'
import {
  AuthData,
  Country,
  SumSubTokenResponse,
  VerifyCodeFromSMSServerRequest,
  VerifyMobileNumberServerRequest,
} from '../interfaces/auth'
import { FlowType } from '../interfaces/preset'
import { anonimRequests } from '../utils/anonimusRequest'
import { authorizeRequests } from '../utils/authorizeRequest'

async function verifyMobileNumber(phoneNumber: string, token: string) {
  const deviceId = await FingerPrint()

  const path = ['accounts', 'pre-register']
  const body = { phoneNumber, deviceId, token}

  return anonimRequests.postAnonimus<VerifyMobileNumberServerRequest, AuthData>(path, body)
}

async function verifyCodeFromSMS(code: string) {
  const deviceId = await FingerPrint()
  const referralCode = localStorage.getItem('referralCode')

  const path = ['accounts', 'code', 'confirm']

  const body = referralCode ? { deviceId, code, referralCode } : { deviceId, code }


  return authorizeRequests.putAuthorize<VerifyCodeFromSMSServerRequest, AuthData>(path, body)
}

function refreshToken() {
  const { refreshToken } = authHelper.getAuthData()

  const path = ['tokens', refreshToken, 'refresh']

  return anonimRequests.postAnonimus<null, AuthData>(path)
}

function logOut() {
  const path = ['accounts', 'logout']

  return authorizeRequests.postAuthorize<null, null>(path)
}

function getSumSubToken(flowType: FlowType) {
  const path = ['aml-checks', 'access-token']
  const query = { flowType }

  return authorizeRequests.authorizeRequest<null, SumSubTokenResponse>(path, 'GET', null, query)
}

function getUserCountryList() {
  const path = ['countries', 'source']

  return authorizeRequests.authorizeRequest<null, Array<Country>>(path)
}

export const authServices = {
  logOut,
  getUserCountryList,
  refreshToken,
  getSumSubToken,
  verifyMobileNumber,
  verifyCodeFromSMS,
}
