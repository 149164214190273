import { useMemo } from 'react'
import InnerHeader from '../../components/innerHeader'
import { Link, Navigate } from 'react-router-dom'



const FaqPage = () => {
  const isShownFaq = useMemo(
    () =>
      // window.location.hostname === 'localhost' ||
      window.location.hostname === 'remittance.dev.kuvacash.net' ||
      window.location.hostname === 'send.zikicash.com',
    []
  )
  
  if (!isShownFaq) {
    return <Navigate to="/"/>
  }

  return (
    <section className="faq-page">
      <div className="container container-md container-100">
        <InnerHeader title="FAQ" marginLeft="0" />
        <div className="faq-page-content">
          <h2>How do I close my profile?</h2>
          <p className="important-text">
            <u>Important:</u> You’ll lose access to your transfer history and saved information if
            you close your profile. 
          </p>
          <p>
            You can close your Zikicash profile yourself on MyProfile - Preferences - Delete account
            button.{' '}
          </p>
          <p>If you decide you want to close your profile yourself, follow these steps:</p>
          <ol>
            <li>
              Open the Zikicash app or the <Link to="/profile">Website</Link>
            </li>
            <li>Sign in and select MyProfile - Preferences - Delete account button</li>
            <li>
              Please, read the details and type the confirmation text to confirm account deletion
            </li>
            <li>Press the Delete account button to finish up.</li>
          </ol>
          <h2>What happens if I close my profile?</h2>
          <p>Here’s what you can expect:</p>
          <ul>
            <li>You can`t sign in to your account.</li>
            <li>
              You’ll lose access to your transfer history and other saved info like your recipients
              and payment details. If you need to keep a record of your transfers, save your
              transfer history before you close your profile.
            </li>
            <li>
              You'll need to add your info again if you decide to create a new profile after closing
              the old one.
            </li>
          </ul>
          <h2>Will my info be deleted?</h2>
          <p>
            Closing your profile doesn’t delete ALL your information. Zikicash is legally required
            to keep full records of our customers’ transaction histories. That means we cannot
            remove or delete transaction history entries.
          </p>
        </div>
      </div>
    </section>
  )
}

export default FaqPage
