import axios from 'axios'

const getAuthToken = `Basic ${window.btoa(
  'm.mordvintsev@newlinetechnologies.net:kdDa!@Q<&8bQ={mc'
)}`

const domain = 'https://kuva.zendesk.com/api/v2'

export const getTicketPayload = (body: string) => {
  const host = window.location.hostname

  return {
    ticket: {
      comment: {
        html_body: body,
      },
      priority: 'urgent',
      subject: `Query from ${host}`,
      tags: [host],
    },
  }
}

export const getRequestObj = (method: string, url: string, data = {}) => ({
  method,
  url: `${domain}/${url}`,
  headers: {
    Authorization: getAuthToken,
    'Content-Type': 'application/json',
  },
  data,
})

export const createTicketZendesk = (body: string) => {
  const payload = getTicketPayload(body)
  const url = 'tickets.json'

  return axios(getRequestObj('post', url, payload))
}
