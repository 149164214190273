import { CompanyRecipient, KWLRecipient } from '../interfaces/beneficiary'
import { localStorageHelper } from './useLocalStorage'

const saveRecipientDetails = (recipient: KWLRecipient | CompanyRecipient) => {
  localStorage.setItem('recipient', JSON.stringify(recipient))
}

const getRecipientDetails = (): KWLRecipient | CompanyRecipient => {
  const unParsed = localStorage.getItem('recipient')

  return unParsed !== null ? JSON.parse(unParsed) : null
}

const removeRecipientDetails = () => {
  localStorageHelper.removeData('recipient')
}


export const recipientDetailsInLocalStorage = {
  saveRecipientDetails,
  getRecipientDetails,
  removeRecipientDetails,
}
