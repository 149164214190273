import { Dispatch, SetStateAction, useCallback, useEffect, useMemo } from 'react'
import { useAppDispatch, useAppSelector } from '../../../hooks/dispatch'
import { CardPaymentButtonVariant } from '../../../interfaces/cardPayment'
import { remittanceActions } from '../../../redux/action-creators/remittanceActions'
import { PaymentMethod } from '../../../interfaces'

type ChallengeFormProps = {
  setCardPaymentStatusForBtn: Dispatch<SetStateAction<CardPaymentButtonVariant>>
}

const ChallengeForm = ({ setCardPaymentStatusForBtn }: ChallengeFormProps) => {
  const dispatch = useAppDispatch()
  const authCardInfo = useAppSelector(state => state.remittance.authCardInfo)
  const cardPaymentInfoForVerification = useAppSelector(
    state => state.remittance.cardPaymentInfoForVerification
  )

  const withdrawalPaymentMethod = useAppSelector(
    state => state.remittance.transaction.withdrawalMethod
  )

  const isBankAccountWithdrawal = useMemo(
    () => withdrawalPaymentMethod === PaymentMethod.BankTransfer,
    [withdrawalPaymentMethod]
  )

  const receiveMessage = useCallback(
    (event: MessageEvent) => {
      const specialUrl = window.location.origin

      if (event.origin === specialUrl && event.data === 'Challenge completed') {
        dispatch(
          remittanceActions.verifyCardPayment(
            cardPaymentInfoForVerification!,
            setCardPaymentStatusForBtn,
            isBankAccountWithdrawal
          )
        )
      }
    },
    [cardPaymentInfoForVerification, dispatch, setCardPaymentStatusForBtn, isBankAccountWithdrawal]
  )

  useEffect(() => {
    window.addEventListener('message', receiveMessage)

    return () => {
      window.removeEventListener('message', receiveMessage)
    }
  }, [receiveMessage])

  const challengeForm = useMemo(() => {
    return (
      authCardInfo?.challenge &&
      `<form id="challengeForm" method="POST" action=${authCardInfo?.challenge.url}><input type = "hidden" name= "JWT" value=${authCardInfo?.challenge.jwt} /><input type="hidden" name="MD" value="TEST" /></form><script>window.onload = function() { document.getElementById('challengeForm').submit() }</script>`
    )
  }, [authCardInfo?.challenge])
  return (
    <>
      {challengeForm && (
        <iframe className="card-payment__iframe" title="Chalenge Form" srcDoc={challengeForm} />
      )}
    </>
  )
}

export default ChallengeForm
