import { Dispatch, SetStateAction } from 'react'
import { useAppSelector } from '../../hooks/dispatch'
import BankTransferSection from './BankTransferSection'
import CardPaymentSection from './CardPaymentSection'
import { CardPaymentButtonVariant, PaymentSection } from '../../interfaces/cardPayment'
import { PaymentMethod } from '../../interfaces'
import PaymentProviderSection from './PaymentProviderSection'
import PaymentSummary from './PaymentSummary'
import { TransactionDetails } from '../../interfaces/remittanceType'
import { WithdrawalItemForSummary } from '../../interfaces/calculation'

type PaymentDetailsSectionProps = {
  transaction: TransactionDetails
  setPaymentSection: Dispatch<SetStateAction<PaymentSection>>
  paymentSection: PaymentSection
  setDisabledButton: Dispatch<SetStateAction<boolean>>
  setCardPaymentStatusForBtn: Dispatch<SetStateAction<CardPaymentButtonVariant>>
  withdrawalPaymentProviderOptions: Array<WithdrawalItemForSummary>
}

const PaymentDetailsSection = ({
  transaction,
  setPaymentSection,
  paymentSection,
  setDisabledButton,
  setCardPaymentStatusForBtn,
  withdrawalPaymentProviderOptions,
}: PaymentDetailsSectionProps) => {
  const selectPaymentSectionText = useAppSelector(
    state => state.preset.preset?.selectPaymentSectionText
  )

  const { paymentProviderList } = useAppSelector(state => state.remittance)

  return (
    <>
      <div className="price-info">
        {paymentSection === 'payment-providers' && (
          <PaymentProviderSection
            paymentProviderList={paymentProviderList}
            setPaymentSection={setPaymentSection}
            withdrawalPaymentProviderOptions={withdrawalPaymentProviderOptions}
          />
        )}
        {paymentSection === 'payment-summary' && (
          <PaymentSummary
            setPaymentSection={setPaymentSection}
            txId={transaction.topUpRequestExtId}
            withdrawalPaymentProviderOptions={withdrawalPaymentProviderOptions}
          />
        )}
        {paymentSection === PaymentMethod.CreditCard && (
          <CardPaymentSection
            setPaymentSection={setPaymentSection}
            setCardPaymentStatusForBtn={setCardPaymentStatusForBtn}
            setDisabledButton={setDisabledButton}
          />
        )}
        {paymentSection === PaymentMethod.BankTransfer && (
          <BankTransferSection transaction={transaction} setPaymentSection={setPaymentSection} />
        )}
      </div>
      <div className="past-transactions__additional-text">
        <p>{selectPaymentSectionText}</p>
      </div>
    </>
  )
}

export default PaymentDetailsSection
