import { Dispatch } from 'redux'
import { EarlyAccessForm } from '../../interfaces/preset'
import {
  GetAvailableFlow,
  GetPreset,
  PostEarlyAccess,
} from '../../interfaces/redux-types/presetReduxTypes'
import { presetServices } from '../../services/presetServices'
import { presetConstants } from '../constants/presetConstants'

const getPreset = () => async (dispatch: Dispatch<GetPreset>) => {
  dispatch({ type: presetConstants.GET_PRESET_REQUEST })

  return await presetServices.getPreset().then(
    res => {
      dispatch({ type: presetConstants.GET_PRESET_SUCCESS, payload: res })
      return res
    },
    error => {
      dispatch({
        type: presetConstants.GET_PRESET_FAILURE,
      })
    }
  )
}

const getAvailableFlow = () => async (dispatch: Dispatch<GetAvailableFlow>) => {
  dispatch({ type: presetConstants.GET_AVAILABLE_FLOW_REQUEST })

  return await presetServices.getAvailableFlow().then(
    res => {
      dispatch({ type: presetConstants.GET_AVAILABLE_FLOW_SUCCESS, payload: res })
    },
    error => {
      dispatch({
        type: presetConstants.GET_AVAILABLE_FLOW_FAILURE,
      })
    }
  )
}

const postEarlyAccess = (body: EarlyAccessForm) => async (dispatch: Dispatch<PostEarlyAccess>) => {
  dispatch({ type: presetConstants.POST_EARLY_ACCESS_REQUEST })

  return await presetServices.postEarlyAccess(body).then(
    res => {
      dispatch({ type: presetConstants.POST_EARLY_ACCESS_SUCCESS, payload: res })
      return res
    },
    error => {
      dispatch({ type: presetConstants.POST_EARLY_ACCESS_FAILURE })
    }
  )
}

export const presetActions = {
  getPreset,
  getAvailableFlow,
  postEarlyAccess,
}
