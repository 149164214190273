import {
  Dispatch,
  LegacyRef,
  SetStateAction,
  SyntheticEvent,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { recipientDetailsInLocalStorage } from '../../helpers/recipientData'
import {
  BeneficiaryType,
  CompanyRecipient,
  KWLRecipient,
  SanctionStatus,
} from '../../interfaces/beneficiary'
import { useAppDispatch, useAppSelector } from '../../hooks/dispatch'
import { calculationHelper } from '../../helpers/calculationData'
import { AmlStatus } from '../../interfaces/user'
import { userActions } from '../../redux/action-creators/userActions'
import IndividualTypeListItemContent from './IndividualTypeListItemContent'
import CompanyTypeListItemContent from './CompanyTypeListItemContent'
import { PaymentMethod } from '../../interfaces'
import { errorConstants } from '../../redux/constants/errorConstants'

type BeneficiaryListItemProps = {
  isDisabledButton: boolean
  receiveAmount: number | undefined | string
  beneficiary: KWLRecipient | CompanyRecipient
  reference: LegacyRef<any>
  searchedValue: string
  setIsOpenVerificationModal: Dispatch<SetStateAction<boolean>>
  setDeletingBeneficiaryId: Dispatch<SetStateAction<number>>
  setIsOpenDeletingBeneficiaryModal: Dispatch<SetStateAction<boolean>>
  setIsOpenedSomeTooltip: Dispatch<SetStateAction<boolean>>
  setIsOpenCompanyBeneficiaryWarning: Dispatch<SetStateAction<boolean>>
}

const BeneficiaryListItem = ({
  isDisabledButton,
  receiveAmount,
  beneficiary,
  reference,
  searchedValue,
  setIsOpenedSomeTooltip,
  setIsOpenDeletingBeneficiaryModal,
  setDeletingBeneficiaryId,
  setIsOpenVerificationModal,
  setIsOpenCompanyBeneficiaryWarning,
}: BeneficiaryListItemProps) => {
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const { pathname } = useLocation()

  const flowList = useAppSelector(state => state.preset.availableFlow)

  const [hover, setHover] = useState(false)
  const [showToolTip, setShowToolTip] = useState(false)

  const isSanctionedBeneficiary = useMemo(() => {
    return beneficiary.sanctionStatus === SanctionStatus.OnSanctionsListManualConfirmed
  }, [beneficiary.sanctionStatus])

  const isShownSendCash = useMemo(
    () => pathname === '/beneficiaries/select-beneficiary',
    [pathname]
  )

  useEffect(() => {
    if (!hover) {
      setShowToolTip(false)
    }
  }, [hover])

  const onEdit = (event: SyntheticEvent) => {
    event.stopPropagation()

    const from =
      pathname === '/beneficiaries/select-beneficiary'
        ? '/beneficiaries/select-beneficiary'
        : '/beneficiaries'

    navigate(`/beneficiary/${beneficiary.id}`, { state: { searchedValue, editMode: true, from } })
  }

  const moveAfterAmlCheck = useCallback(
    (userAmlStatus: AmlStatus | undefined) => {
      const from = pathname

      if (userAmlStatus === AmlStatus.Verified) {
        navigate('/transaction/new', { state: { searchedValue, from } })
      } else {
        setIsOpenVerificationModal(true)
      }
    },
    [navigate, pathname, searchedValue, setIsOpenVerificationModal]
  )

  const sendCash = useCallback(
    async (event: SyntheticEvent) => {
      event.stopPropagation()

      const receiveMethodFromLocalStorage = localStorage.getItem('receiveMethod')
      const receiveMethod =
        receiveMethodFromLocalStorage && JSON.parse(receiveMethodFromLocalStorage)
      const isSelectBeneficiaryPage = pathname === '/beneficiaries/select-beneficiary'
      const isCompanyType = beneficiary.type === BeneficiaryType.Company

      if (beneficiary.sanctionStatus === SanctionStatus.OnSanctionsListUnconfirmed) {
        dispatch({ type: errorConstants.SET_ERROR, payload: { errorCode: '4000000254' } })
        return
      }

      if (beneficiary.sanctionStatus === SanctionStatus.OnSanctionsListManualConfirmed) {
        dispatch({ type: errorConstants.SET_ERROR, payload: { errorCode: '4000000253' } })
        return
      }

      if (
        isSelectBeneficiaryPage &&
        receiveMethod?.paymentMethod !== PaymentMethod.BankTransfer &&
        isCompanyType
      ) {
        setIsOpenCompanyBeneficiaryWarning(true)
        return
      }

      if (isSelectBeneficiaryPage) {
        calculationHelper.setCalculationData(receiveAmount)
      }

      recipientDetailsInLocalStorage.saveRecipientDetails(beneficiary)
      dispatch(userActions.getUserAmlList(flowList, moveAfterAmlCheck))
    },
    [
      beneficiary,
      dispatch,
      flowList,
      moveAfterAmlCheck,
      pathname,
      receiveAmount,
      setIsOpenCompanyBeneficiaryWarning,
    ]
  )

  const handleMouseIn = () => {
    setHover(true)
    setIsOpenedSomeTooltip(true)
  }

  const handleMouseOut = (event: any) => {
    setHover(false)
    setIsOpenedSomeTooltip(false)
  }

  const onDelete = (event: SyntheticEvent) => {
    event.stopPropagation()

    setShowToolTip(false)
    beneficiary.id && setDeletingBeneficiaryId(beneficiary.id)
    setIsOpenDeletingBeneficiaryModal(true)
  }

  const onBeneficiaryDetails = (event: SyntheticEvent) => {
    event.stopPropagation()
    if (!isDisabledButton) {
      const from =
        pathname === '/beneficiaries/select-beneficiary'
          ? '/beneficiaries/select-beneficiary'
          : '/beneficiaries'

      calculationHelper.setCalculationData(receiveAmount)
      navigate(`/beneficiary/${beneficiary.id}`, {
        state: { searchedValue, editMode: false, from },
      })
    }
  }

  const openTooltip = (event: SyntheticEvent) => {
    event.stopPropagation()

    setShowToolTip(true)
  }

  const classNameForSanctionedBeneficiary = useMemo(() => {
    return isSanctionedBeneficiary ? ' sanctioned-beneficiary' : ''
  }, [isSanctionedBeneficiary])


  return (
    <div
      ref={reference}
      onMouseOver={handleMouseIn}
      onMouseOut={handleMouseOut}
      className={`table-row${classNameForSanctionedBeneficiary}`}
      style={{ position: 'relative' }}
      {...(!isDisabledButton && !isSanctionedBeneficiary && isShownSendCash && { onClick: sendCash })}
    >
      {beneficiary.type === BeneficiaryType.Individual && (
        <IndividualTypeListItemContent
          beneficiary={beneficiary as KWLRecipient}
          hover={hover}
          showToolTip={showToolTip}
          isDisabledButton={isDisabledButton}
          isShownSendCash={isShownSendCash}
          sendCash={sendCash}
          onBeneficiaryDetails={onBeneficiaryDetails}
          onDelete={onDelete}
          openTooltip={openTooltip}
          onEdit={onEdit}
        />
      )}
      {beneficiary.type === BeneficiaryType.Company && (
        <CompanyTypeListItemContent
          beneficiary={beneficiary as CompanyRecipient}
          hover={hover}
          showToolTip={showToolTip}
          isDisabledButton={isDisabledButton && !isShownSendCash}
          isShownSendCash={isShownSendCash}
          sendCash={sendCash}
          onBeneficiaryDetails={onBeneficiaryDetails}
          onDelete={onDelete}
          openTooltip={openTooltip}
          onEdit={onEdit}
        />
      )}
    </div>
  )
}

export default BeneficiaryListItem
