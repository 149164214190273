import { useMemo, useEffect, useState, useCallback } from 'react'
import { useForm } from 'react-hook-form'
import InnerHeader from '../../../components/innerHeader'
import { yupResolver } from '@hookform/resolvers/yup'
import FormInput from '../../../components/interfaceComponents/FormInput'
import { userYupSchemes } from '../../../helpers/yupSchemes/userYupSchemes'
import { useLocation, useNavigate } from 'react-router-dom'
import SelectForm from '../../../components/interfaceComponents/SelectForm'
import { useAppDispatch, useAppSelector } from '../../../hooks/dispatch'
import { authActions } from '../../../redux/action-creators/authActions'
import { userActions } from '../../../redux/action-creators/userActions'
import isEmpty from 'lodash/isEmpty'
import { trimObjectFields } from '../../../helpers/utils'
import { AmlStatus, UserAddressForm, UserDetails } from '../../../interfaces/user'
import CalculationSection from '../../../components/calculation/CalculationSection'
import { calculationHelper } from '../../../helpers/calculationData'
import { localStorageHelper } from '../../../helpers/useLocalStorage'

type LocationState = {
  state: {
    from: string
  }
}

const UserAddressPage = () => {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const location = useLocation() as LocationState
  const from = location?.state?.from || 'sing-up'

  const countryList = useAppSelector(state => state.auth.countryList)
  const resendEmailDelayTime =
    useAppSelector(state => state.preset.preset?.emailDelayTimeInSeconds) || 60
  const userDetails = useAppSelector(state => state.user.userDetails)
  const userAml = useAppSelector(state => state.user.userAml)

  const [errorField, setErrorField] = useState<string>()
  const [receive, setReceive] = useState<number | undefined | string>()
  const [isDisabledBtn, setIsDisabledBtn] = useState(false)

  const receivePaymentData = localStorageHelper.getData('receiveMethod')

  useEffect(() => {
    if (countryList.length === 0) {
      dispatch(authActions.getUserCountryList())
    }
  }, [countryList.length, dispatch])

  const modifiedCountryList = useMemo(() => {
    return countryList.map((country: any) => ({
      value: country.id,
      label: country.name,
    }))
  }, [countryList])

  const {
    register,
    handleSubmit,
    setValue,
    watch,
    formState: { errors },
  } = useForm<UserAddressForm>({
    resolver: yupResolver(userYupSchemes.userAddressScheme),
  })

  const isDisabledButton = !isEmpty(errors) || isDisabledBtn

  useEffect(() => {
    if (userDetails) {
      setValue('street', userDetails?.street || '')
      setValue('city', userDetails?.city || '')
      setValue('countryId', userDetails?.countryId || 228)
      setValue('postcode', userDetails?.postcode || '')
    }
  }, [setValue, userDetails])

  const makeRedirect = useCallback(async (userDetails: UserDetails) => {
    const isEmailConfirmed = userDetails?.email && userDetails?.isEmailConfirmed

    if (!isEmailConfirmed) {
      await dispatch(userActions.resendEmail(resendEmailDelayTime))
      navigate('/email-verification-pending')
      return
    }

    if (from === 'continue-transaction' && userAml?.status === AmlStatus.Verified) {
      navigate('/beneficiary/new', { state: { from } })
      return
    }

    if (from !== 'continue-transaction' && userAml?.status === AmlStatus.Verified) {
      navigate('/')
      return
    }

    if (userAml?.status === AmlStatus.Pending) {
      navigate('/pending', { state: { from } })
      return
    }
    if (userAml?.status === AmlStatus.RejectedWithRetry) {
      navigate('/verification')
    }

  }, [dispatch, from, navigate, resendEmailDelayTime, userAml?.status])

  const onSubmit = async (data: UserAddressForm) => {
    const body = trimObjectFields<UserAddressForm>(data)
    calculationHelper.setCalculationData(receive)

    try {
      await dispatch(userActions.updateUserAddressAction(body))
      await dispatch(userActions.getUserDetails(makeRedirect))
    } catch (error: any) {
      setErrorField(error.errorMessage)
    }
  }

  const onGoBack = () => {
    navigate('/sing-up/user-details', { state: { from } })
  }

  return (
    <section className="h-100-mb">
      <div className="container container-md container-100">
        <InnerHeader title="Your Details 2/2" onGoBack={onGoBack} />
        <div id="login-page" className="login-page">
          {from === 'continue-transaction' && (
            <CalculationSection
              mode="calculation-input"
              receiveAmount={receive}
              setReceiveAmount={setReceive}
              setIsDisabledButton={setIsDisabledBtn}
              currency={receivePaymentData.currency}
              currencySign={receivePaymentData.currencySign}
            />
          )}
          <h4>Your Address Details</h4>
          <form className="login-form" id="user-details-form" onSubmit={handleSubmit(onSubmit)}>
            <FormInput<UserAddressForm>
              id="street"
              register={register}
              error={errors?.street?.message}
              className="formInput"
              type="text"
              label="Street Name/Number"
              maxLength={64}
            />
            <div className="grid grid-col2">
              <FormInput<UserAddressForm>
                id="city"
                register={register}
                error={errors.city?.message}
                className="formInput"
                type="text"
                label="City"
                maxLength={32}
              />
              <FormInput<UserAddressForm>
                id="postcode"
                register={register}
                error={errors.postcode?.message}
                className="formInput"
                type="string"
                maxLength={16}
                label="Zip/Postcode"
              />
            </div>
            <SelectForm<UserAddressForm>
              id="countryId"
              register={register}
              label="Country"
              options={modifiedCountryList}
              className="select-form"
              defaultOption="Please Select"
              watch={watch}
            />
          </form>
        </div>
        <div className="btn-wrapper center">
          <button form="user-details-form" className="btn btn-primary" disabled={isDisabledButton}>
            Next
          </button>
        </div>

        {errorField && <div>{errorField}</div>}
      </div>
    </section>
  )
}

export default UserAddressPage
