import { useCallback, useEffect, useMemo, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { Icon } from '../../../components/icons'
import InnerHeader from '../../../components/innerHeader'
import CircleLoading from '../../../components/interfaceComponents/CircleLoading'
import { isUserDataExist } from '../../../helpers/userHelper'
import { useAppDispatch, useAppSelector } from '../../../hooks/dispatch'
import { AmlStatus, UserStatus } from '../../../interfaces/user'
import { userActions } from '../../../redux/action-creators/userActions'
import { beneficiaryService } from '../../../services/beneficiaryService'
import isEmpty from 'lodash/isEmpty'
import debounce from 'lodash/debounce'
import { authActions } from '../../../redux/action-creators/authActions'

type LocationState = {
  state: {
    from: string
  }
  pathname: string
}

const EmailVerificationPendingPage = () => {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const location = useLocation() as LocationState
  const from = location?.state?.from || 'sing-up'
  const pathname = location.pathname

  const userDetails = useAppSelector(state => state.user.userDetails)
  const userAml = useAppSelector(state => state.user.userAml)
  const resendEmailDelayTime =
    useAppSelector(state => state.preset.preset?.emailDelayTimeInSeconds) || 60

  const [isDisabledButton, setIsDisabledButton] = useState(false)

  const isEmailConfirmed = useMemo(() => {
    return userDetails?.isEmailConfirmed && userDetails?.email
  }, [userDetails?.email, userDetails?.isEmailConfirmed])

  useEffect(() => {
    if (!userDetails?.email) {
      navigate('/')
    }
  }, [navigate, userDetails?.email])

  useEffect(() => {
    let interval: ReturnType<typeof setInterval>
    if (!isEmailConfirmed) {
      interval = setInterval(() => {
        dispatch(userActions.getUserDetails())
      }, 5000)
      return () => clearInterval(interval)
    }
  }, [dispatch, isEmailConfirmed])

  const onGoBack = useCallback(() => {
    if (isEmailConfirmed) {
      navigate('/')
    } else {
      navigate('/sing-up/user-address', { state: { from } })
    }
  }, [from, isEmailConfirmed, navigate])

  const onClick = useCallback(async () => {
    const isFilledUserDetails = userDetails && isUserDataExist(userDetails)

    if (from === '/profile') {
      navigate(from)
      return
    }

    if (isFilledUserDetails) {
      if (userAml?.status === AmlStatus.Verified && from === 'continue-transaction') {
        const offset = 0
        const step = 1
        const beneficiaries = await beneficiaryService.getBeneficiaries(offset, step)

        if (isEmpty(beneficiaries.items)) {
          navigate('/beneficiary/new', { state: { from } })
          return
        } else {
          navigate('/beneficiaries/select-beneficiary')
          return
        }
      }
      if (userAml?.status === AmlStatus.Pending) {
        navigate('/pending')
        return
      }
      if (!userAml?.status || userAml?.status === AmlStatus.RejectedWithRetry) {
        navigate('/verification')
        return
      }
    } else {
      navigate('/sing-up/user-details', { state: { from } })
      return
    }

    return navigate('/', { state: { from: pathname } })
  }, [from, navigate, pathname, userAml?.status, userDetails])

  const debouncedResendEmail = useCallback(() => {
    dispatch(userActions.resendEmail(resendEmailDelayTime))
  }, [dispatch, resendEmailDelayTime])

  const debounceFn = useMemo(() => debounce(debouncedResendEmail, 500), [debouncedResendEmail])

  const onResendClick = () => {
    debounceFn()
  }

  const onChangeEmailClick = () => {
    navigate('/profile')
  }

  return (
    <section className="h-100-mb">
      <div className="container container-md container-100">
        {!isEmailConfirmed ? (
          <>
            <InnerHeader title="Email Verification" onGoBack={onGoBack} />
            <CircleLoading
              loadingText={`Email sent. Please verify your email using the link we have sent to your email inbox ${userDetails?.email}.`}
              icon={false}
              timer
              setIsDisabledButton={setIsDisabledButton}
            />
            <div className="btn-wrapper btn-noTransition">
              <button
                className="btn btn-cancel btn-50"
                onClick={onResendClick}
                disabled={isDisabledButton}
              >
                Resend email
              </button>

              <button className="btn btn-next btn-50" onClick={onChangeEmailClick}>
                Change Email
              </button>
            </div>
          </>
        ) : (
          <>
            <InnerHeader title="Email Verification" onGoBack={onGoBack} />
            <div className="confirmation-page">
              <Icon.BlueConfirmTick />
              <span className="verified-text">Email Verified</span>
            </div>
            <div className="btn-wrapper btn-wrapper-sm center m-b10">
              <button onClick={onClick} className="btn btn-primary ">
                Next
              </button>
            </div>
          </>
        )}
      </div>
    </section>
  )
}

export default EmailVerificationPendingPage
