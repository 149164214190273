import { useAppDispatch, useAppSelector } from '../../../hooks/dispatch'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { transactionYupSchemes } from '../../../helpers/yupSchemes/transactionYupSchemes'
import { Card } from '../../../interfaces/cardPayment'
import FormInput from '../../interfaceComponents/FormInput'
import { remittanceActions } from '../../../redux/action-creators/remittanceActions'
import { Dispatch, SetStateAction, useCallback, useEffect, useRef, useState } from 'react'
import { addWorldpayCheckoutToPage, loadCheckoutScript } from '../../../helpers/cardPayment'
import { PuffLoader } from 'react-spinners'

type NewCardFormProps = {
  setDisabledButton: Dispatch<SetStateAction<boolean>>
}

const NewCardForm = ({ setDisabledButton }: NewCardFormProps) => {
  const dispatch = useAppDispatch()
  const topUpId = useAppSelector(state => state.remittance.transaction.topUpNumber)
  const [isValidWPForm, setIsValidWPForm] = useState(false)
  const [isBankingScriptLoaded, setIsBankingScriptLoaded] = useState(false)

  const { register, handleSubmit, formState } = useForm<Card>({
    resolver: yupResolver(transactionYupSchemes.cardScheme),
    defaultValues: { cardHolderName: '' },
    mode: 'onChange',
  })

  const checkoutScriptUrl = process.env.REACT_APP_WP_INITIALIZATION_SCRIPT
  const checkout = useRef<any>(null)
  const formRef = useRef<HTMLFormElement>(null)

  function generateSessionT(data: Card) {
    checkout.current.generateSessionState(function (error: any, session: any) {
      if (error) {
        dispatch(remittanceActions.setCardPaymentFailure(error))
      } else {
        const body = {
          cardHolderName: data.cardHolderName,
          sessionHref: session,
          saveCard: data.savedCard,
          topUp: {
            id: topUpId,
          },
        }

        dispatch(remittanceActions.initializeByNewCard(body))
      }

      setDisabledButton(true)
    })
  }

  const [errors, setErrors] = useState({ pan: '', expiry: '', cvv: '' })
  useEffect(() => {
    if (formState.isDirty && isValidWPForm) {
      setDisabledButton && setDisabledButton(false)
    } else {
      setDisabledButton && setDisabledButton(true)
    }
  }, [formState.isDirty, isValidWPForm, setDisabledButton])

  const changeWPFields = useCallback(() => {
    // var form = document.getElementById('new-card-form')

    formRef.current!.addEventListener('wp:form:change', (event: any) => {
      if (event.detail['is-valid']) {
        setIsValidWPForm(true)
      } else {
        setIsValidWPForm(false)
      }
    })

    formRef.current!.addEventListener('wp:field:change', (event: any) => {
      if (!event.detail['is-valid']) {
        if (event.detail.field.name === 'pan') {
          setErrors((prevState: any) => ({
            ...prevState,
            pan: 'Invalid Card Number',
          }))
        }
        if (event.detail.field.name === 'expiry') {
          setErrors((prevState: any) => ({
            ...prevState,
            expiry: 'Invalid Expiry Date',
          }))
        }
        if (event.detail.field.name === 'cvv') {
          setErrors((prevState: any) => ({
            ...prevState,
            cvv: 'Invalid CVV',
          }))
        }
      } else {
        if (event.detail.field.name === 'pan') {
          setErrors((prevState: any) => ({
            ...prevState,
            pan: '',
          }))
        }
        if (event.detail.field.name === 'expiry') {
          setErrors((prevState: any) => ({
            ...prevState,
            expiry: '',
          }))
        }
        if (event.detail.field.name === 'cvv') {
          setErrors((prevState: any) => ({
            ...prevState,
            cvv: '',
          }))
        }
      }
    })
  }, [])

  useEffect(() => {
    changeWPFields()

    return () => checkout.current.remove()
  }, [changeWPFields])

  useEffect(() => {
    // const form = document.getElementById('new-card-form')

    formRef.current!.addEventListener('wp:form:ready', (event: any) => {
      setIsBankingScriptLoaded(true)
    })
  }, [])

  useEffect(() => {
    loadCheckoutScript(checkoutScriptUrl)
      .then(() => {
        addWorldpayCheckoutToPage()
          .then(checkoutInstance => {
            checkout.current = checkoutInstance
          })
          .catch(console.warn)
      })

      .catch(console.warn)
  }, [checkoutScriptUrl])

  const onSubmit = (data: Card) => {
    generateSessionT(data)
  }

  return (
    <>
      {!isBankingScriptLoaded && (
        <div className="spinner spinner-absolute">
          <PuffLoader size={70} color="#3171d8" />
        </div>
      )}
      <form
        ref={formRef}
        className="checkout new-card-form"
        id="new-card-form"
        onSubmit={handleSubmit(onSubmit)}
      >
        <section className="field">
          <section id="card-pan" />
          {errors.pan && <p className="form-input-error">{errors.pan}</p>}
        </section>
        <section className="field">
          <FormInput<Card>
            id="cardHolderName"
            register={register}
            className="formInput"
            type="text"
            label="Name on Card"
            maxLength={64}
            error={formState.errors.cardHolderName?.message}
          />
        </section>
        <section className="columns">
          <section className="field">
            <section className="test" id="card-expiry" />
            {errors.expiry && <p className="form-input-error">{errors.expiry}</p>}
          </section>
          <section className="field">
            <section id="card-cvv" />
            {errors.cvv && <p className="form-input-error">{errors.cvv}</p>}
          </section>
        </section>
        <div className="checkbox-wrapper checkbox-wrapper-green checkbox-wrapper-terms form-input">
          <input {...register('savedCard')} id="saved-card" type="checkbox" />
          <label htmlFor="saved-card" className="fz-xSmall">
            Save Card
          </label>
        </div>
      </form>
    </>
  )
}

export default NewCardForm
