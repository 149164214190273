import { useCallback, useEffect, useMemo } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { Icon } from '../../components/icons'
import InnerHeader from '../../components/innerHeader'
import useQuery from '../../helpers/useQuery'
import { useAppDispatch, useAppSelector } from '../../hooks/dispatch'
import { userActions } from '../../redux/action-creators/userActions'

const ConfirmEmailPage = () => {
  const navigate = useNavigate()
  const { pathname } = useLocation()
  const { isConfirmationEmailLoading, isConfirmationEmailLoaded, error } = useAppSelector(
    state => state.user
  )
  const query = useQuery()
  const dispatch = useAppDispatch()
  const token = query.get('token')

  useEffect(() => {
    if (token) {
      dispatch(userActions.confirmChangedEmail(token || ''))
    } else {
      navigate('/')
    }
  }, [dispatch, navigate, token])

  const onClick = useCallback(() => {
    // await dispatch(userActions.getUserDetails())
    navigate('/', { state: { from: pathname } })
  }, [navigate, pathname])

  const contentPage = useMemo(() => {
    const currentContentPage = {
      title: '',
      content: <></>,
    }
    if (error) {
      currentContentPage.title = 'Failure'

      if (typeof error !== 'string' && error.errorCode === '4000000105') {
        // currentContentPage.title = 'Failure'
        currentContentPage.content = (
          <>
            <span>
              <Icon.PaymentFailed />
            </span>
            <p>This link is no longer actual. Please resend confirmation email and use new link</p>
          </>
        )
      } else {
        currentContentPage.content = (
          <>
            <span>
              <Icon.PaymentFailed />
            </span>
            <p>Something went wrong</p>
          </>
        )
      }
    } else {
      currentContentPage.title = 'Success'
      currentContentPage.content = (
        <>
          <span>
            <Icon.BlueConfirmTick />
          </span>
          <p>Your email is confirmed</p>
        </>
      )
    }

    return currentContentPage
  }, [error])

  return (
    <section className="h-100-mb">
      {!isConfirmationEmailLoading && isConfirmationEmailLoaded ? (
        <div className="container container-md container-100">
          <InnerHeader title={contentPage.title} titlePosition="center" marginLeft="0px" />
          <div className="success-page">
            <div className="confirm-email">{contentPage.content}</div>
          </div>
          <div className="btn-wrapper btn-wrapper-sm center m-b10">
            <button className="btn btn-primary" onClick={onClick}>
              Go to Account
            </button>
          </div>
        </div>
      ) : null}
    </section>
  )
}
export default ConfirmEmailPage
