import { TransactionDataStateForNewTx } from '../../interfaces/remittanceType'
import { localStorageHelper } from '../useLocalStorage'

const setTransactionData = (transactionData: TransactionDataStateForNewTx) => {
  localStorage.setItem('transaction', JSON.stringify(transactionData))
}

const getTransactionData = (): TransactionDataStateForNewTx => {
  const unParsed = localStorage.getItem('transaction')

  return unParsed !== null ? JSON.parse(unParsed) : null
}

const removeTransactionData = () => {
  localStorageHelper.removeData('transaction')
}

export const transactionDataInLocalStorage = {
  setTransactionData,
  getTransactionData,
  removeTransactionData,
}
