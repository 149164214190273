import React from 'react'
import { AgentsLocationItemResponse } from '../../interfaces'
import AgentsLocationRow from './AgentsLocationItem'

type AgentsLocationListProps = {
  agentsLocationList: Array<AgentsLocationItemResponse>
}

const AgentsLocationList = ({ agentsLocationList }: AgentsLocationListProps) => {
  return (
    <div className="agents-location-list">
      {agentsLocationList.length > 0 ? (
        <>
          {agentsLocationList.map(agentLocation => (
            <AgentsLocationRow agentsLocationItem={agentLocation} key={agentLocation.agentId} />
          ))}
        </>
      ) : (
        <p className="no-available">No agents available</p>
      )}
    </div>
  )
}

export default AgentsLocationList
